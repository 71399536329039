/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Required<br />1 = Visible<br />2 = Hidden
 */
export enum ReductionMemberTextType {
    REQUIRED = 'Required',
    VISIBLE = 'Visible',
    HIDDEN = 'Hidden',
}