import React from 'react';
import Cancel from '../../components/shared/icons/Cancel/cancel';
import style from './notification-bar.module.scss';

function NotificationBar() {
    const ref = React.useRef<HTMLDivElement | null>();
    const typeref = React.useRef<HTMLDivElement | null>();
    const textref = React.useRef<HTMLDivElement | null>();

    const onCancelClick = () => {
        const body: HTMLDivElement | null = document.querySelector("#dlf_body");
        if (ref.current && body) {
            ref.current.style.display = "none";
            body.style.marginTop = "0px"

            if (textref.current)
                textref.current.innerText = "";
        }
    }

    return (
        <div ref={r => ref.current = r} style={{ display: "none" }} id="dlf_notification_bar" className={`${style.notificationcontainer}`}>
            {<div ref={r => typeref.current = r} id="dlf_notification_type">
                <div ref={r => textref.current = r} className={style.notificationmsg} id="dlf_notification_text"></div>
                <div id="dlf_notification_close" onClick={onCancelClick} className={style.cancelbutton}>Luk<Cancel darkbackground={true} /></div>
            </div>}
        </div>
    )
}

export default NotificationBar;