import ButtonBase, { BaseButtonProps } from "../ButtonBase/button-base";
import style from "../../Buttons/buttons.module.scss";

type CTAButtonProps = BaseButtonProps & { href?: string, target?: string, color?: string }
const CTAButton = (props: CTAButtonProps) => {
    if (props.href) {
        return <a href={props.href} target={props.target}>
            <ButtonBase {...props} className={`${style.cta} ${props.className || ""}`} style={{ backgroundColor: props.color }} />
        </a>
    }
    return <ButtonBase {...props} className={`${style.cta} ${props.className || ""}`} style={{ backgroundColor: props.color }}></ButtonBase>
}

export default CTAButton;