
function SmartPhone() {
  return (
    <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3471 0H2.52158C1.85281 0 1.21144 0.265663 0.738551 0.738551C0.265663 1.21144 0 1.85281 0 2.52158V17.651C0 18.3198 0.265663 18.9612 0.738551 19.4341C1.21144 19.9069 1.85281 20.1726 2.52158 20.1726H11.3471C12.0159 20.1726 12.6572 19.9069 13.1301 19.4341C13.603 18.9612 13.8687 18.3198 13.8687 17.651V2.52158C13.8687 1.85281 13.603 1.21144 13.1301 0.738551C12.6572 0.265663 12.0159 0 11.3471 0ZM12.6079 17.651C12.6079 17.9854 12.475 18.3061 12.2386 18.5425C12.0022 18.779 11.6815 18.9118 11.3471 18.9118H2.52158C2.18719 18.9118 1.86651 18.779 1.63006 18.5425C1.39362 18.3061 1.26079 17.9854 1.26079 17.651V2.52158C1.26079 2.18719 1.39362 1.86651 1.63006 1.63006C1.86651 1.39362 2.18719 1.26079 2.52158 1.26079H11.3471C11.6815 1.26079 12.0022 1.39362 12.2386 1.63006C12.475 1.86651 12.6079 2.18719 12.6079 2.52158V17.651Z" fill="#253154" />
      <path d="M11.3465 3.78223H2.52102C2.35383 3.78223 2.19349 3.84864 2.07527 3.96686C1.95705 4.08509 1.89062 4.24543 1.89062 4.41262C1.89062 4.57981 1.95705 4.74016 2.07527 4.85838C2.19349 4.9766 2.35383 5.04301 2.52102 5.04301H11.3465C11.5137 5.04301 11.6741 4.9766 11.7923 4.85838C11.9105 4.74016 11.9769 4.57981 11.9769 4.41262C11.9769 4.24543 11.9105 4.08509 11.7923 3.96686C11.6741 3.84864 11.5137 3.78223 11.3465 3.78223Z" fill="#253154" />
      <path d="M11.3465 14.499H2.52102C2.35383 14.499 2.19349 14.5654 2.07527 14.6837C1.95705 14.8019 1.89062 14.9622 1.89062 15.1294C1.89062 15.2966 1.95705 15.457 2.07527 15.5752C2.19349 15.6934 2.35383 15.7598 2.52102 15.7598H11.3465C11.5137 15.7598 11.6741 15.6934 11.7923 15.5752C11.9105 15.457 11.9769 15.2966 11.9769 15.1294C11.9769 14.9622 11.9105 14.8019 11.7923 14.6837C11.6741 14.5654 11.5137 14.499 11.3465 14.499Z" fill="#253154" />
      <path d="M5.67336 3.15239H8.19494C8.36213 3.15239 8.52246 3.08597 8.64069 2.96775C8.75891 2.84953 8.82533 2.68919 8.82533 2.522C8.82533 2.3548 8.75891 2.19446 8.64069 2.07624C8.52246 1.95802 8.36213 1.8916 8.19494 1.8916H5.67336C5.50617 1.8916 5.34584 1.95802 5.22762 2.07624C5.10939 2.19446 5.04297 2.3548 5.04297 2.522C5.04297 2.68919 5.10939 2.84953 5.22762 2.96775C5.34584 3.08597 5.50617 3.15239 5.67336 3.15239Z" fill="#253154" />
      <path d="M6.93387 18.2818C7.45611 18.2818 7.87946 17.8585 7.87946 17.3362C7.87946 16.814 7.45611 16.3906 6.93387 16.3906C6.41164 16.3906 5.98828 16.814 5.98828 17.3362C5.98828 17.8585 6.41164 18.2818 6.93387 18.2818Z" fill="#253154" />
    </svg>

  )
}

export default SmartPhone;