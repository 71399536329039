import style from './event-signup.module.scss'
import Wizard from '../shared/Wizard/wizard'
import { FormSteps, Step, FormError } from '../../models/step';
import Confirmation from '../shared/Confirmation/confirmation'
import ConsentForm from '../shared/ConsentForm/consent-form'
import React, { useEffect } from 'react';
import * as api from '../../api';
import { DropdownItem } from '../../models/dropdownItem';
import { EventSignupElement } from '../../models/umbracoElement';
import EventSignupQuestion from './EventSignupQuestions/event-signup-questions';
import { defaultEventSignUpModel } from '../../models/eventSignupModel';
import * as signupConstants from '../../constants/signup-constants'
import EventSignupContact from './EventSignupContact/event-signup-contact'
import EventSignupPayment from './EventSignupPayment/event-signup-payment'
import Loader from '../shared/Loader/loader';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import { getNewErrorList } from '../../common/getNewErrorList';
import { fetchErrorMsg, requiredErrorMsg } from '../../constants/common-constants';
import InformationBox from '../shared/InformationBox/information-box';

interface EventSignupProps {
    memberid: string,
    settingdata: EventSignupElement,
    event: api.Event;
    eventSignUpModel?: api.EventSignup,
    infomessage?: string,
    isPublicSignup: boolean;
    closeForm?: () => void;
    eventDetail?: JSX.Element;
}

function EventSignup(props: EventSignupProps) {

    const nonloggedinRequiredFields = [
        signupConstants.mail,
        signupConstants.mobilephone,
        signupConstants.municipalityId,
        signupConstants.city,
        signupConstants.zip,
        signupConstants.number,
        signupConstants.street,
        signupConstants.lastname,
        signupConstants.firstname,
        signupConstants.countryId
    ]


    const [signUpModel, setSignUpModel] = React.useState(props.eventSignUpModel ?? defaultEventSignUpModel);
    const [pricePackages, setPricePackages] = React.useState<DropdownItem[]>([]);
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [loading, setLoading] = React.useState(false)
    const [errorMsg, setErrorMessage] = React.useState("");
    const [requiredFields, setRequiredFields] = React.useState<string[]>([]);
    const [showErrorNotification, setShowErrorNotification] = React.useState(false);
    const [forms, setForms] = React.useState<Step[]>([]);

    useEffect(() => {
        const getDropdownData = async () => {
            try {
                let required: string[] = [];

                if (props.isPublicSignup) {
                    required = [...required, ...nonloggedinRequiredFields];
                }
                //if user is logged in and atendeetypes are defined, set atendeetype should be required
                if (!props.isPublicSignup && !!props.event?.eventInformation?.atendeeTypes && props.event?.eventInformation?.atendeeTypes.length > 0) {
                    required = [...required, signupConstants.atendeeType]
                }
                setRequiredFields(required);
                const pricePackages: api.PricePackage[] = await api.PricepackagesService.getPricePackages();
                const newPricePackages = pricePackages.map((x) => { return { id: x.pricePackageId!, text: x.name!, originalData: x } });
                setPricePackages(newPricePackages)

            } catch (error) {
                setErrorMessage(fetchErrorMsg);
            }
        }

        if (!props.infomessage) {
            setLoading(true);
            const call = async () => {
                await getDropdownData();
            }
            call().finally(() => { setLoading(false) });
        }
    }, []);

    const hasCustomRegustrationFields = React.useMemo(() =>
        props.event.eventInformation?.customRegistrationFields &&
        props.event.eventInformation?.customRegistrationFields.length > 0, [props.event]);

    React.useEffect(() => {
        const steps = [contactstep];
        if (props.event.eventInformation?.customRegistrationFields && props.event.eventInformation?.customRegistrationFields.length > 0) {
            questionstep.stepNo = steps.length + 1;
            steps.push(questionstep);
        }
        if (props.event.eventPriceInfomration?.eventwithprices) {
            paymentstep.stepNo = steps.length + 1;
            steps.push(paymentstep)
        }
        consentstep.stepNo = steps.length + 1;
        steps.push(consentstep);
        setForms(steps);
    }, [signUpModel, errorList, requiredFields]);

    useEffect(() => {
        console.log(`showErrorNotification ${showErrorNotification}`);
    }, [showErrorNotification]);


    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);
        if (newErrorlist.length == 0) {
            console.log('setting set show error false');
            setShowErrorNotification(false);
        }
        else {
            console.log('setting set show error true');
            setShowErrorNotification(true);
        }
    }
    const checkRequiredData = (required: string[], savedErrors: FormError[]) => {

        const otherErrors = errorList.filter(x => x.errorMsg !== requiredErrorMsg && !savedErrors.some(o => o.name === x.name));
        const newErrorlist: FormError[] = [];
        required.forEach((r) => {
            if (!(signUpModel as any)[r]) {
                if (requiredFields.includes(r))//required name is found in RequiredFields list => contact step
                {
                    if (!(signUpModel.contact as any)[r]) {
                        newErrorlist.push({ name: r, errorMsg: requiredErrorMsg });
                    }
                } else {
                    if (hasCustomRegustrationFields && !(signUpModel.registrationResponses?.find(x => x.questionId === r)?.answer)) {
                        newErrorlist.push({ name: r, errorMsg: requiredErrorMsg });
                    }
                }
            }
        });

        const allErrorList = [...otherErrors, ...newErrorlist];
        const otherSavedErrors = savedErrors.filter(x => x.errorMsg !== requiredErrorMsg && !allErrorList.some(o => o.name === x.name));
        setErrorList([...allErrorList, ...otherSavedErrors]);
        return allErrorList.length === 0;
    }

    const setModel = (name: string, value: string | boolean | object | undefined) => {
        setShowErrorNotification(false);
        let newSignUpModel = { ...signUpModel };
        (newSignUpModel as any)[name] = value;
        setSignUpModel(newSignUpModel);
    }

    const submitForm = async () => {
        const newSignUpModel = { ...signUpModel };
        newSignUpModel.eventId = props.event.eventId!;
        newSignUpModel.memberId = props.memberid;
        await api.EventsService.eventSignUp(newSignUpModel);
    }
  
    const close = () => {
        if (!isUserLoggedIn(props.memberid)) {
            props.closeForm!();
        }
        else {
            window.location.reload()
        }
    }

    const contactstep = {
        stepNo: 1,
        required: requiredFields,
        formdata: <EventSignupContact
            memberid={props.memberid}
            isPublicEvent={props.isPublicSignup}
            signUpModel={signUpModel}
            event={props.event}
            setError={(n, e) => { setError(n, e)}}
            errorList={errorList}
            handleChange={setModel}></EventSignupContact>
    };
    const questionstep = {
        stepNo: 2,
        required: props.event?.eventInformation?.customRegistrationFields?.filter(x => x.mandatory).map(x => x.id!) || [],
        formdata: <EventSignupQuestion questions={props.event?.eventInformation?.customRegistrationFields || []} signUpModel={signUpModel} handleChange={setModel} setError={setError} errorList={errorList}></EventSignupQuestion>
    };
    const paymentstep = {
        stepNo: 3,
        required: [signupConstants.pricepackage],
        formdata: <EventSignupPayment pricepackages={pricePackages} signUpModel={signUpModel} handleChange={setModel} setError={setError} errorList={errorList} priceDescription={props.settingdata.priceDescription} ></ EventSignupPayment>
    };
    const consentstep = {
        stepNo: 4,
        required: [],
        formdata: <ConsentForm value={signUpModel.eventConsent!} consentlist={props.settingdata.consentList} consentText={props.settingdata.consentText} handleChange={(key, value) => { setModel('eventConsent', value) }}></ConsentForm>
    };

    const formsteps: FormSteps = {
        title: props.settingdata.title || "Tilmeldingsformular",
        helptext: props.settingdata.helpText || "",
        previousLabel: "Forrige side",
        nextLabel: "Næste trin",
        submitString: "Godkend og tilmeld",
        lastStepNo: forms.length,
        steps:
            forms,
        confirmationPage:
            <Confirmation
                buttonLabel={"Luk"}
                title="Tak for din tilmelding"
                close={close}
                confirmationText={props.settingdata.confirmationText}
            />,
    }

    return (
        <>
            {props.infomessage ? <div className={style.infomsg}>
                <InformationBox title="" content={props.infomessage || ""} />
                {/* <div className={`${style.folded_infomration}`} dangerouslySetInnerHTML={{ __html: props.infomessage || "" }} />*/}
            </div>
                : (loading ? <Loader /> : <Wizard
                    setShowErrorNotification={setShowErrorNotification}
                    showErrorNotification={showErrorNotification}
                    errorMsg={errorMsg}
                    clearErrorMsg={() => setErrorMessage("")}
                    info={props.eventDetail}
                    checkRequiredData={checkRequiredData}
                    onCancelClick={props.closeForm}
                    confirmed={(signUpModel.consent && signUpModel.eventConsent !== undefined && signUpModel.eventConsent !== null) || false}
                    submit={submitForm}
                    navigationSettings={{showBackBar:true}}
                    errorList={errorList}
                    data={formsteps} />)
            }
        </>
    )
}
export default EventSignup;