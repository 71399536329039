/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Magazine } from '../models/Magazine';
import { request as __request } from '../core/request';

export class MagazinesService {

    /**
     * Description: Get a list of magazines to display in signup process.
 * Operation: GetMagazines.
 * Area: Magazines.
     * @returns Magazine Success
     * @throws ApiError
     */
    public static async getMagazines(): Promise<Array<Magazine>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/magazines`,
        });
        return result.body;
    }

}