import { MessageSentElement } from '../../models/umbracoElement';
import style from './message-sent.module.scss';
import React from 'react';
import Wrapper from '../shared/Wrapper/wrapper';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import ButtonZone from '../shared/ButtonZone/button-zone';
interface MessageSentProps {
    memberid: string;
    data: any;
}

function MessageSent(props: MessageSentProps) {
    const [data] = React.useState<MessageSentElement>(props.data ? JSON.parse(props.data) : undefined);
    const onReturnToDlf = () => {
        window.location.href = `${data.returnLink.url}`;
    }
    return (
        <Wrapper properties={data}
            bottomZone={<CTAButton onClick={onReturnToDlf}>{data.buttonText}</CTAButton>}
        >
            <div className={style.messagesentcontainer}>
                <div className={style.textcontainer}>
                    <div className={style.substitle}>
                        {data.subTitle}
                    </div>
                    <div className={style.text}>
                        {data.text}
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}


export default MessageSent;