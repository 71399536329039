/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderCreate } from '../models/OrderCreate';
import type { PaymentRedirect } from '../models/PaymentRedirect';
import { request as __request } from '../core/request';

export class OrderService {

    /**
     * Description: Create an order.
 * Operation: CreateOrder.
 * Area: Order.
     * @param requestBody 
     * @returns PaymentRedirect Success
     * @throws ApiError
     */
    public static async createOrder(
requestBody: OrderCreate,
): Promise<PaymentRedirect> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/order`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Finalize an outstanding order.
 * Operation: FinalizeOrder.
 * Area: Order.
     * @param orderId ID of order.
     * @returns PaymentRedirect Success
     * @throws ApiError
     */
    public static async finalizeOrder(
orderId: string,
): Promise<PaymentRedirect> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/order/${orderId}/complete`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}