import React, { useEffect } from 'react';
import { ConflictFrontpageElement, ElementHelpText } from '../../models/umbracoElement';
import style from './conflictfrontpage.module.scss';
import * as api from '../../api';
import Wrapper, { NotificationMessage } from '../shared/Wrapper/wrapper';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import ButtonZone from '../shared/ButtonZone/button-zone';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';

interface ConflictFrontPageProps {
    data: any;
    memberid: string;
}
function ConflictFrontPage(props: ConflictFrontPageProps) {
    const frontpageElem: ConflictFrontpageElement = JSON.parse(props.data);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [render, setRender] = React.useState(false);
    const [resultText, setResultText] = React.useState("");
    const [link, setLink] = React.useState("");
    const [buttonText, setButtonText] = React.useState("");
    const [conflictParticipation, setConflictParticipation] = React.useState<api.ConflictParticipation>();

    async function getConflictParticipationData() {
        var result: api.ConflictParticipation = await api.MembersService.getCurrentConflictParticipation(props.memberid);
        setConflictParticipation(result);
    }

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getConflictParticipationData();
        }
        call().then().catch(e => { console.error(e);}).finally(() => { setLoading(false) });
    }, []);

    useEffect(() => {
        if(!conflictParticipation) return;
        if (conflictParticipation?.conflictParticipationConfirmedSituation === false) {
            console.log("Situation ikke bekræftet, tilbydder situationslink");
            setValues(`${frontpageElem.linkConflictSituation.url}?csid=${conflictParticipation.conflictParticipationID}`, frontpageElem.textConflictSituation, frontpageElem.buttonConflictSituation);
            setRender(true);
            setLoading(false);
            return;
        }
        else if (conflictParticipation?.conflictType === "Strejke" && conflictParticipation.conflictParticipationRequestedSupport === null) {
            setValues(frontpageElem.linkConflictSupport.url, frontpageElem.textConflictSupport, frontpageElem.buttonConflictSupport);
            setRender(true);
            setLoading(false);
            return;
        }
        else if (conflictParticipation?.conflictType === "Lockout" && conflictParticipation.conflictParticipationRequestedLoan === null) {
            setValues(frontpageElem.linkConflictLoan.url, frontpageElem.textConflictLoan, frontpageElem.buttonConflictLoan);
            setRender(true);
            setLoading(false);
            return;
        }
        setRender(false);
        setLoading(false);
    }, [conflictParticipation]);
    
    function setValues(link: string, text: string, button: string){
        setLink(link);
        setResultText(text)
        setButtonText(button)

    }

    const goToNewPage = () => {
        window.location.href = link
    }
    return (
        <>
            {render ? (
                <Wrapper
                    properties={{ ...{} as ElementHelpText, title: frontpageElem?.title || "Konflikt" }}
                    loading={loading}
                    notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}
                >
                    <div className={style.textbox}>
                        <div className={style.title}>{frontpageElem.title}</div>
                        <div className={style.text}>{resultText}</div>
                        <div className={style.buttoncontainer}>
                            <CTAButton onClick={goToNewPage}>{buttonText}</CTAButton>
                        </div>
                    </div>
                </Wrapper>
            ) : undefined}
        </>
    );
}

export default ConflictFrontPage;