function Help(props: { darkbackground?: boolean }) {

  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13.5" cy="13.5" r="12.25" stroke={props.darkbackground ? "#ffffff" : "var(--themecolor)"} strokeWidth="1.5" />
      <path d="M14.0889 15.9912H11.707C11.707 15.4697 11.7451 15.0156 11.8213 14.6289C11.9033 14.2363 12.0381 13.8848 12.2256 13.5742C12.4131 13.2578 12.665 12.9561 12.9814 12.6689C13.2568 12.4287 13.4971 12.2031 13.7021 11.9922C13.9072 11.7754 14.0654 11.5527 14.1768 11.3242C14.2881 11.0957 14.3438 10.8467 14.3438 10.5771C14.3438 10.2549 14.2939 9.99121 14.1943 9.78613C14.1006 9.5752 13.96 9.41699 13.7725 9.31152C13.5908 9.2002 13.3623 9.14453 13.0869 9.14453C12.8584 9.14453 12.6445 9.19727 12.4453 9.30273C12.2461 9.40234 12.085 9.56055 11.9619 9.77734C11.8389 9.99414 11.7715 10.2783 11.7598 10.6299H9C9.01758 9.79199 9.20801 9.10645 9.57129 8.57324C9.93457 8.03418 10.4209 7.63867 11.0303 7.38672C11.6455 7.12891 12.3311 7 13.0869 7C13.9248 7 14.6426 7.13184 15.2402 7.39551C15.8438 7.65918 16.3037 8.04883 16.6201 8.56445C16.9424 9.08008 17.1035 9.70996 17.1035 10.4541C17.1035 10.9639 17.0068 11.4121 16.8135 11.7988C16.6201 12.1797 16.3623 12.5371 16.04 12.8711C15.7178 13.1992 15.3604 13.542 14.9678 13.8994C14.6338 14.1982 14.4053 14.5088 14.2822 14.8311C14.1592 15.1475 14.0947 15.5342 14.0889 15.9912ZM11.3906 18.6807C11.3906 18.2881 11.5312 17.957 11.8125 17.6875C12.0996 17.418 12.4717 17.2832 12.9287 17.2832C13.3857 17.2832 13.7549 17.418 14.0361 17.6875C14.3174 17.957 14.458 18.2881 14.458 18.6807C14.458 19.0791 14.3174 19.4131 14.0361 19.6826C13.7549 19.9463 13.3857 20.0781 12.9287 20.0781C12.4717 20.0781 12.0996 19.9463 11.8125 19.6826C11.5312 19.4131 11.3906 19.0791 11.3906 18.6807Z" fill={props.darkbackground ? "#ffffff" : "var(--themecolor)"} />
    </svg>

  )
}

export default Help;