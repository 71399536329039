/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SituationType } from '../models/SituationType';
import { request as __request } from '../core/request';

export class SituationtypesService {

    /**
     * Description: Get a list of situationtypes.
 * Operation: GetSituationTypes.
 * Area: Situationtypes.
     * @returns SituationType Success
     * @throws ApiError
     */
    public static async getSituationTypes(): Promise<Array<SituationType>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/situationtypes`,
        });
        return result.body;
    }

}