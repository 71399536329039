import style from "./list-item.module.scss";
import Label from "../../FormLabel/form-label";

export interface ListItemProps {
    label?: string;
    childrenitems: string[];
}
const ListItem = ({ label, childrenitems }: ListItemProps) => {
    return (childrenitems.length > 0 ? <div className={style.listitem}>
        <Label title={label} />
        {childrenitems.map((item, i) => <div className={style.listitemchildren} key={`children-item-${i}`}>{item}</div>)}
    </div> : null)
}

export default ListItem;