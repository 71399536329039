import style from './inbox.module.scss'
import React from 'react';
import * as api from '../../api';
import { InboxElement } from '../../models/umbracoElement';
import LoadMoreWrapper from '../shared/LoadMoreWrapper/load-more-wrapper';
import OverviewItem from '../shared/OverviewList/OverviewItem/overview-item';
import OverviewList from '../shared/OverviewList/overview-list';
import MessageMain from './MessageMain/message-main';
import { getLongDateStr } from '../../common/dateutil';
import Wrapper from '../shared/Wrapper/wrapper';
import InformationBox from '../shared/InformationBox/information-box';
import ReductionsList from '../ReductionsList/reduction-list';

interface InboxProps {
    memberid: string;
    data: any;
}

function Inbox(props: InboxProps) {
    const [data] = React.useState<InboxElement>(props.data ? JSON.parse(props.data) : undefined);
    const [loadingMessage, setLoadingMessage] = React.useState(false);
    const [messages, setMessages] = React.useState<api.Message[]>([]);
    const [currentMessage, setCurrentMessage] = React.useState<api.Message>();
    const [currentPage, setCurrentPage] = React.useState(1);

    const elementsPerPage = 10;
    const getMessageData = React.useCallback(async () => {
        setLoadingMessage(true);
        try {
            const data: api.Message[] = await api.MembersService.getMessages(props.memberid);
            setMessages(data);
        }
        finally {
            setLoadingMessage(false);
        }
    }, [props.memberid])

    React.useEffect(() => {
        setLoadingMessage(true);
        const call = async () => {
            await getMessageData();
        }
        call().finally(() => { setLoadingMessage(false) });
    }, [getMessageData]);

    const toggleOpen = (message: api.Message) => {

        if (currentMessage?.messageId === message.messageId) {
            setCurrentMessage(undefined);
        } else {
            setCurrentMessage(message);
        }
    }
    const loadMore = () => {

        if (currentPage * elementsPerPage < messages.length) {
            setCurrentPage(currentPage + 1)
        }

    }
    const messagesToDisplay = React.useMemo(() => {
        return messages?.slice(0, currentPage * elementsPerPage >= messages.length ? undefined : currentPage * elementsPerPage) || []
    }, [currentPage, messages])

    const hasMoreMessages = React.useMemo(() => {
        return messagesToDisplay.length < messages.length;
    }, [messagesToDisplay, messages.length])

    return (
        <Wrapper properties={data}
            loading={loadingMessage}
        >
            {loadingMessage ? null :
                <LoadMoreWrapper hasMore={hasMoreMessages} loadMore={loadMore}>
                    {
                        messages.length > 0

                            ?
                            <OverviewList
                                items={messagesToDisplay.map((message, key) =>
                                    <div className={style.messagebox} key={`message-${key} `}>
                                        <OverviewItem
                                            onClick={() => toggleOpen(message)}
                                            isOpen={currentMessage?.messageId === message.messageId}
                                            main={<MessageMain
                                                onClick={() => toggleOpen(message)}
                                                message={message}
                                                key={message.messageId}
                                                isOpen={currentMessage?.messageId === message.messageId}
                                                memberId={props.memberid}

                                            />}
                                            detail={<div className={`${style.date} wrappedhidden`}> {getLongDateStr(new Date(message.date!), true)}</div>
                                            }
                                        ></OverviewItem>


                                    </div>
                                )}
                            />
                            : <InformationBox content={data?.noMessagesText} title="" />
                    }
                </LoadMoreWrapper>
            }
        </Wrapper>


    )
}


export default Inbox;