import React from 'react';
import { ChangePaymentLandingpageElement } from '../../models/umbracoElement';
import Wrapper, { NotificationMessage } from '../shared/Wrapper/wrapper';
import style from './change-payment-landingpage.module.scss';
import * as api from '../../api';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import { postErrorMsg } from '../../constants/common-constants';
import { setNotificationMsg } from '../../common/notificationHelper';

export type ChangePaymentLandingpageProps = {
    data: any;
    memberid: string;
    orderId?: string;
}

function ChangePaymentLandingpage(props: ChangePaymentLandingpageProps) {
    const changePaymentLandingpageElem: ChangePaymentLandingpageElement = JSON.parse(props.data);
    const [notification, setNotification] = React.useState<NotificationMessage | undefined>();

    React.useEffect(() => {
        const saveData = async () => {
            let urlParams = new URLSearchParams(window.location.search);
            let orderId = urlParams.get('orderId');

            try {
                if (!orderId) throw new Error("No orderId provided");


                const paymentRedirect: api.PaymentRedirect = await api.OrderService.finalizeOrder(orderId);                
                window.location.href = paymentRedirect.redirectUri ?? `/mine-kontaktoplysninger/aendre-betalingsmetode`;
            } catch (error) {
                setNotificationMsg(postErrorMsg, NotificationBarType.Danger, setNotification);
                console.log(error)
            }
        };       

        saveData();
    }, [props.orderId]);

    return (<Wrapper
        properties={changePaymentLandingpageElem}
        loading={true}
        notification={notification}
        children={
            <div className={style.changepaymentlandingpagecontainer}>
                {changePaymentLandingpageElem.waitText || "Vi er ved at processere dine ændringer, vent venligst..."}
            </div>
        }
    />
    )
}

export default ChangePaymentLandingpage;