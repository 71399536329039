import { useEffect } from 'react';
import { setupMenu, toggleVisibility } from '../../../common/menuHelper';
import { useWindowSize } from '../../../hooks/windowsize';
import style from './burger-menu.module.scss'
function BurgerMenu() {
    const size = useWindowSize();
    useEffect(() => {
        setupMenu();
    }, [size])

    var svgString = '<svg id="dlf_burger_menu" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="122.879px" height="103.609px" viewBox="0 0 122.879 103.609" enable-background="new 0 0 122.879 103.609" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M10.368,0h102.144c5.703,0,10.367,4.665,10.367,10.367v0 c0,5.702-4.664,10.368-10.367,10.368H10.368C4.666,20.735,0,16.07,0,10.368v0C0,4.665,4.666,0,10.368,0L10.368,0z M10.368,82.875 h102.144c5.703,0,10.367,4.665,10.367,10.367l0,0c0,5.702-4.664,10.367-10.367,10.367H10.368C4.666,103.609,0,98.944,0,93.242l0,0 C0,87.54,4.666,82.875,10.368,82.875L10.368,82.875z M10.368,41.438h102.144c5.703,0,10.367,4.665,10.367,10.367l0,0 c0,5.702-4.664,10.368-10.367,10.368H10.368C4.666,62.173,0,57.507,0,51.805l0,0C0,46.103,4.666,41.438,10.368,41.438 L10.368,41.438z"/></g></svg>';

    return (
        <div onClick={toggleVisibility} className={style.mobilemenu}>
            <div className={style.menutext}>Menu</div>
            <div className={style.menuicon} dangerouslySetInnerHTML={{ __html: svgString }} />
        </div>
    )
}

export default BurgerMenu;