import style from "../buttons.module.scss"

interface CustomButtonProps {
    label: string;
    onClick: () => void,
    disabled?: boolean;
    submit?: boolean;
    title?:string;
}

function CustomButton(props: CustomButtonProps) {
    return (
        <button title={props.title||""} type={props.submit ? "submit" : "button"} className={`${style.cta} ${props.disabled ? (style.disabled) : ""}`} disabled={props.disabled} onClick={props.onClick}>
            {props.label}
        </button>
    )
}

export default CustomButton;