import { useEffect, useRef } from 'react';
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as api from '../../../../api';
import style from './delivery-options-element.module.scss';

interface DeliveryOptionsElementProp {
    subscription: api.MagazineSubscription;
    magazine: api.Magazine;
    onSubscriptionChanged: (chagnedSubscription: api.MagazineSubscription) => void;
    physcialButtonLabel: string;
    electronicButtonLabel: string;
    unsubscribeButtonLabel: string;
    shouldFocus?: boolean;
}
function DeliveryOptionsElement(props: DeliveryOptionsElementProp) {
    const isPhyscialFormatPossible = props.magazine.phsicaldistribution!;
    const radioRef = useRef<HTMLInputElement>(null);

    const onFormatChange = (event: any) => {
        var value = event.target.value;
        let tempSubscription: api.MagazineSubscription = { ...props.subscription, deliveryOption: value };
        props.onSubscriptionChanged(tempSubscription);

    }

    useEffect(() => {
        if (props.shouldFocus) {
            radioRef.current?.focus();
        }
    }, [props.shouldFocus]);

    return (
        <div className={style.container}>
            <div className={style.deliverytitle}>
                {props.magazine.name}
            </div>
            <div className={style.buttoncontainer}>
                <div className={style.buttons}>
                    {isPhyscialFormatPossible ?
                        <div className={props.subscription.deliveryOption === "physical" ? style.checked : style.unChecked}>
                            <div className={style.buttonText}>
                                {props.physcialButtonLabel}
                            </div>
                            <div className={style.button}>
                                <input ref={props.subscription.deliveryOption === "physical" ? radioRef : null} type="radio" name={props.magazine.magazineId} value={"physical"} checked={props.subscription.deliveryOption === "physical"} onChange={(value) => onFormatChange(value)} />
                            </div>
                        </div>
                        : <></>
                    }
                
                    <div className={props.subscription.deliveryOption === "electronic" ? style.checked : style.unChecked}>
                        <div className={style.buttonText}>
                            {props.electronicButtonLabel}
                        </div>
                        <div className={style.button}>
                            <input ref={props.subscription.deliveryOption === "electronic" ? radioRef : null}  type="radio" name={props.magazine.magazineId} value={"electronic"} checked={props.subscription.deliveryOption === "electronic"} onChange={(value) => onFormatChange(value)} />
                        </div>
                    </div>
                    <div className={props.subscription.deliveryOption === "unsubscribed" ? style.checked : style.unChecked}>
                        <div className={style.buttonText}>
                            {props.unsubscribeButtonLabel}
                        </div>
                        <div className={style.button}>
                            <input ref={props.subscription.deliveryOption === "unsubscribed" ? radioRef : null} type="radio" name={props.magazine.magazineId} value={"unsubscribed"} checked={props.subscription.deliveryOption === "unsubscribed"} onChange={(value) => onFormatChange(value)} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default DeliveryOptionsElement;