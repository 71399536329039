import React, { useEffect } from 'react';
import * as api from '../../api';
import { SituationConfirmationElement } from '../../models/umbracoElement';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './situation-confirmation.module.scss';
import Loader from '../shared/Loader/loader'
interface SituationConfirmationProps {
    data: any;
    conflictselectionid: string
}
function SituationConfirmation(props: SituationConfirmationProps) {

    const confirmationElem: SituationConfirmationElement = JSON.parse(props.data);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [resultText, setResultText] = React.useState(confirmationElem.explanationText);

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await api.ConflictService.conflictsituationconfirmation(props.conflictselectionid)
        }
        call()
            .then(() => {
                setLoading(false)
            })
            .catch((error) => {
                setResultText(confirmationElem.failureText)
                setLoading(false);
            })
    }, []);

    return (
        <Wrapper properties={confirmationElem}
            notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined} >
            {!loading ?
                <div className={`${style.content}`}>
                    <div className={`${style.elementcontent} ${style.richtext}`} dangerouslySetInnerHTML={{ __html: resultText || "<div></div>" }} />
                </div> :
                <Loader />
                }

        </Wrapper>)
}

export default SituationConfirmation;