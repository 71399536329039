import style from './custom-table.module.scss'
import CustomButton from '../Buttons/CustomButton/custom-button';
import { useEffect } from 'react';
import { useWindowSize } from '../../../hooks/windowsize';
import React from 'react';

interface Row {
  data: any[],
  rawdata?: any;
  showOpenDetailButton?: boolean;
}

interface Column {
  columnName: string;
  index: number;
  visible: boolean;
}

interface CustomTableProps {
  columns?: Column[];
  rows?: Row[];
  showButtonLabel: string;
  emptyText?: string;
  openDetail?: (situation: Row) => void;
  showAllColumns?: boolean;
}
function CustomTable(props: CustomTableProps) {
  const tabledivref = React.useRef<HTMLDivElement | null>();
  const size = useWindowSize();

  const isTableOverflow = tabledivref.current ? tabledivref.current.scrollWidth > tabledivref.current.clientWidth : false;
  const [isOverflow, setIsOverflow] = React.useState(isTableOverflow);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsOverflow(isTableOverflow), [size])
  return (<>
    {props.rows &&
      <div className={style.tablediv} ref={r => tabledivref.current = r}>
        <table>
          <thead>
            <tr className={style.header}>
              {props.columns?.map((c, i) => {
                return <th className={i > 0 && !props.showAllColumns ? (isOverflow ? style.hiddencolumn : style.hiddenmd) : ""} key={i}>{c.columnName}</th>
              })}
              {props.openDetail && <th></th>}
            </tr>
          </thead>
          <tbody>
            {props.rows.length > 0 ? props.rows.map(
              (x, i) => {
                const showOpenDetailButton = x.showOpenDetailButton ?? true;
                return (
                  <tr key={`table${i}`} className={`${i < (props.rows || []).length - 1 ? style.divider : ""}`}>
                    {props.columns?.filter(s => s.visible).map((column, ic) => {
                      return <td className={ic > 0 && !props.showAllColumns ? (isOverflow ? style.hiddencolumn : style.hiddenmd) : ""} key={`data-${ic}`}>{x.data[column.index]}</td>
                    })}
                    {showOpenDetailButton && props.openDetail && <td className={style.showbuttoncolumn}><CustomButton label={props.showButtonLabel || "Vis detaljer"} onClick={() => props.openDetail!(x)} /></td>}
                  </tr>)
              }
            ) :
              <tr><td colSpan={100}>{props.emptyText}</td></tr>}
          </tbody>
        </table>
      </div>
    }
  </>)
}
export default CustomTable;