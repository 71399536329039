import React from 'react';
import { htmlregex } from '../../../constants/regex-expressions';
import style from './information-box.module.scss';
interface InformationBoxProps {
    title: string;
    content: string;
}
function InformationBox(props: InformationBoxProps) {
    const [contentHtml] = React.useState(new RegExp(htmlregex).test(props.content));
    return (
        <div className={`${style.informationbox}`}>
            <div className={`${style.title} header section`}>{props.title}</div>
            {contentHtml? <div dangerouslySetInnerHTML={{ __html: props.content || "<div/>" }} />
            :<div>{props.content}</div>}
        </div>
    )
}

export default InformationBox;