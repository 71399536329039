import React from 'react';
import * as api from '../../api';
import { fetchErrorMsg } from '../../constants/common-constants';
import { ChangePaymentElement } from '../../models/umbracoElement';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './change-payment.module.scss';
import { ApiError } from '../../api';

export type ChangePaymentProps = {
    data: any;
    memberid: string;
}

function ChangePayment(props: ChangePaymentProps) {
    const changePaymentElem: ChangePaymentElement = JSON.parse(props.data);

    const [paymentMethod, setPaymentMethod] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [loadingPaymentChange, setLoadingPaymentChange] = React.useState(false);

    React.useEffect(() => {
        const getData = async () => {
            try {
                const myinfo: api.Member = await api.MembersService.getMemberById(props.memberid);
                setPaymentMethod(myinfo.paymentMethod || "Ingen at vise");
            } catch (error) {
                setErrorMsg(fetchErrorMsg)
                console.log(error)
            }
        };
        setLoading(true);
        getData().finally(() => { setLoading(false) });
    }, [props.memberid]);

    const onChangePaymentClick = async () => {
        setLoadingPaymentChange(true);
        // Send user to backend and await 
        let request: api.PaymentBody = { memberId: props.memberid };
        try {

            const paymentRedirect: api.PaymentRedirect = await api.MembersService.changePaymentMethod(props.memberid, request);
            if (paymentRedirect) {
                window.location.href = `${paymentRedirect.redirectUri}?orderId=${paymentRedirect.orderId}`;
            }
        } catch (error) {
            if (error instanceof ApiError && error.status === 409) {
                setErrorMsg(error?.body || fetchErrorMsg);
            }
            else {
                setErrorMsg(fetchErrorMsg);
            }
            console.log(error);
        } finally {
            setLoadingPaymentChange(false);
        }
    };

    return (<Wrapper
        properties={changePaymentElem}
        loading={loading}
        notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}
        children={!loading &&
            <div className={style.changepaymentcontainer}>
                <div className={style.textcontainer}>
                    <div className={style.text}>
                        {changePaymentElem.text || "Din nuværende betalingsemtode:"}
                    </div>
                    <div className={style.paymentmethod}> {paymentMethod || "PBS"} </div>
                </div>
                <div className={style.button}>
                    <CTAButton onClick={onChangePaymentClick} disabled={loadingPaymentChange}>{changePaymentElem.buttonText}</CTAButton>
                </div>
            </div>
        }
    />
    )
}

export default ChangePayment;