import * as api from '../../../api';
import { getDateStr } from '../../../common/dateutil'
import CustomTable from '../../shared/CustomTable/custom-table';
import React from 'react';
import style from '../my-situations.module.scss';
interface SituationListProps {
    situations?: api.Situation[];
    showButtonLabel: string;
    noSituationText?: string;
    openDetail: (situation: api.Situation) => void
}
const columns =
    [{
        columnName: "Situationtype",
        visible: true,
        index: 0
    }, {
        columnName: "Sted",
        visible: true,
        index: 1
    }, {
        columnName: "Startdato",
        visible: true,
        index: 2
    }, {
        columnName: "Slutdato",
        visible: true,
        index: 3
    },
    ]

function SituationList(props: SituationListProps) {
  
    const rows = React.useMemo(() =>
        props.situations?.map((x) =>
            ({ rawdata: x, data: [x.type?.name || "", x.workLocation?.name || x.educationInstitution?.displayName || "-", getDateStr(new Date(x.startdate!)), x.enddate ? getDateStr(new Date(x.enddate!)) : "-"] }))
        , [props.situations])

    return (
        <div className={`${style.situationlist}`}>
            <CustomTable
                columns={columns}
                rows={rows}
                showButtonLabel={props.showButtonLabel || "Vis detaljer"}
                emptyText={props.noSituationText || "Der findes ingen situation"}
                openDetail={(row) => props.openDetail(row.rawdata as api.Situation)}         
            ></CustomTable>
        </div >)
}
export default SituationList;