const isLeapYear = (year: number) => {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
}
const getDaysInMonth = (year: number, month: number) => {
    return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

export const getToday = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
}

export const getDateStr = (date?: Date, showTime = false) => {

    if (!date) return "";

    const hour = date.getHours();
    const month = date.getMonth() + 1;
    const minute = date.getMinutes();
    const day = date.getDate();
    const monthStr = getMinimum2Digits(month);
    const dayStr = getMinimum2Digits(day);
    const minstr = getMinimum2Digits(minute);
    const hstr = getMinimum2Digits(hour);
    return `${dayStr}/${monthStr}/${date.getFullYear()}` + (showTime ? ` ${hstr}:${minstr}` : "");
}
const monthsDkStrings = ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"];

/** dd. MMM yyyy*/
export const getLongDateStr = (date?: Date, showTime = false) => {

    if (!date) return "";

    const hour = date.getHours();
    const month = date.getMonth();
    const minute = date.getMinutes();
    const day = date.getDate();
    const monthStr = monthsDkStrings[month];
    const dayStr = getMinimum2Digits(day);
    const minstr = getMinimum2Digits(minute);
    const hstr = getMinimum2Digits(hour);
    return `${dayStr}. ${monthStr} ${date.getFullYear()}` + (showTime ? ` - kl. ${hstr}:${minstr}` : "");
}
/** dd. MMM*/
export const getShortDateStr = (date?: Date) => {

    if (!date) return "";

    const month = date.getMonth();
    const day = date.getDate();
    const monthStr = monthsDkStrings[month].substring(0,3);
    const dayStr = getMinimum2Digits(day);
    return `${dayStr}. ${monthStr}`;
}
export const getDateStrFromDateStr = (datestr?: string | null) => {
    if (!datestr) return "";
    const date = new Date(datestr);
    return getDateStr(date, true);
}
export const getMinimum2Digits = (number: number) => {
    return number < 10 ? "0" + number : number.toString();
}

export const addMonth = (original: Date, addcount: number) => {
    const day = original.getDate();
    const month = original.getMonth();
    original.setDate(1);
    original.setMonth(month + addcount);
    original.setDate(Math.min(day, getDaysInMonth(original.getFullYear(), original.getMonth())));
    return original;
}
export const substractMonth = (original: Date, substcount: number) => {
    const day = original.getDate();
    const month = original.getMonth();
    let year = original.getFullYear();
    let resultmonth = month - substcount;
    do {
        resultmonth = resultmonth + 12;
        year = year - 1;
    } while (resultmonth < 0);
    original.setDate(1);
    original.setFullYear(year);
    original.setMonth(month + substcount);
    original.setDate(Math.min(day, getDaysInMonth(original.getFullYear(), original.getMonth())));
    return original;
}
export const getDurationFromMinutes = (minutes?: number | null | undefined) => {
    if (!minutes) return "";
    let time = "";
    let unit = "";
    // less than 24 h
    if (minutes < 1440) {
        time = Math.ceil(minutes / 60).toFixed(0);
        unit = `time${time === "1" ? "" : "r"}`
    }
    else {
        time = (Math.ceil(minutes / 60 / 24)).toFixed(0);
        unit = `dag${time === "1" ? "" : "e"}`

    }
    return `${time} ${unit}`
}

export const dateCompare = (a: string | null | undefined, b: string | null | undefined) => {
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;;
    const adate = new Date(a);
    const bdate = new Date(b);
    if (adate > bdate) return 1
    if (adate.getTime() === bdate.getTime()) return 0
    else return -1;
}