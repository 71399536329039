import { CSSProperties } from 'react';
import style from './top-label.module.scss';

interface TopLabelProps {
    labeltext: string;
    asTitle: boolean;
    customStyle?: CSSProperties;
}

function TopLabel(props: TopLabelProps) {
    return (
        <div className={style.toplabel_container}>
            <div className={props.asTitle ? style.toplabel_title : style.toplabel_text} dangerouslySetInnerHTML={{ __html: props.labeltext || "<div/>" }} />
        </div>
    )
}

export default TopLabel;