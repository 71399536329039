import { Event } from '../../../../api';
import { eventAnonymousPath, eventLoggedinPath, eventsignupModalOpenQuery } from '../../../../constants/common-constants';
import Place from '../../icons/Place/place';

import style from '../event-card.module.scss';

interface EventCardMainProps {
    item: Event;
    isLoggedIn: boolean;
}

function EventCardMain(props: EventCardMainProps) {

   

    const createPlaceName = (placename: string, city?: string, district?: string) => {
        const name = placename ? `${placename}, ` : "";
        if (district && city) {
            return `${name}${city} / ${district}`;
        }
        else if (!district && !city) {
            return placename;
        }
        else {
            return `${name}${city}${district}`;
        }
    }
    const eventid = props.item.eventId!;
    const title = props.item.eventInformation?.name || "";
    const place = createPlaceName(props.item.eventLocation?.name || "", props.item.eventLocation?.city || "", props.item.eventLocation?.country || "");
    const description = props.item.eventInformation?.shortDescription?.trimStart() || "";

    const getUrl = (openSignup: boolean) => {

        return `https://${window.location.host}${props.isLoggedIn ? eventLoggedinPath : eventAnonymousPath}${eventid}${openSignup ? eventsignupModalOpenQuery : ""}`;

    }

    const goToDetail = () => {
        window.location.href = getUrl(false);
    }
    return (
        <div className={`${style.main} eventcardmain`}>
            <div className={style.place}>{place && <Place />}<div className={style.placename}>{place}</div></div>
            <div className={`${style.title} eventcardtitle`} onClick={goToDetail}>{title}</div>
            <div className={style.descriptioncontainer}>
            <div className={style.description} >{description}</div> 
            </div>
            <div className={style.readmore} onClick={goToDetail} >Læs mere</div>
        </div>

    )
}

export default EventCardMain;