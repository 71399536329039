function DownCircle() {

    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="2.42 2.42 20 20">
            <path opacity="0.9" d="M22.417 12.4165C22.417 17.9315 17.931 22.4165 12.417 22.4165C6.90299 22.4165 2.41699 17.9315 2.41699 12.4165C2.41699 6.9025 6.90299 2.4165 12.417 2.4165C17.931 2.4165 22.417 6.9025 22.417 12.4165Z" fill="var(--ctabuttoncolor)" />
            <path d="M16.6376 10.974C16.6376 11.165 16.5646 11.357 16.4186 11.503L12.9486 14.99C12.8076 15.131 12.6166 15.21 12.4166 15.21C12.2176 15.21 12.0266 15.131 11.8856 14.99L8.41357 11.503C8.12157 11.21 8.12157 10.736 8.41557 10.443C8.70957 10.151 9.18457 10.152 9.47657 10.445L12.4166 13.398L15.3566 10.445C15.6486 10.152 16.1226 10.151 16.4166 10.443C16.5646 10.589 16.6376 10.782 16.6376 10.974" fill="white" />
        </svg>
    )
}

export default DownCircle;