/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MemberSignup } from '../models/MemberSignup';
import type { PaymentRedirect } from '../models/PaymentRedirect';
import { request as __request } from '../core/request';

export class SignupmemberService {

    /**
     * Description: New member signup.
 * Operation: SignupMember.
 * Area: Signupmember.
     * @param requestBody 
     * @returns PaymentRedirect Success
     * @throws ApiError
     */
    public static async signupMember(
requestBody: MemberSignup,
): Promise<PaymentRedirect> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/signupMember`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get sign up data from previous membership.
 * Operation: GetSignupMember.
 * Area: Signupmember.
     * @param memberId ID of member whos data needs to be fetched.
     * @returns MemberSignup Success
     * @throws ApiError
     */
    public static async getSignupMember(
memberId: string,
): Promise<MemberSignup> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/signupMember/${memberId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}