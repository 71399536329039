import style from './confirmation.module.scss'
import CTAButton from '../Buttons/CTAButton/cta-button';

interface ConfirmationProps {
    title: string;
    confirmationText?: string;
    contentElement?: JSX.Element;
    buttonLabel: string;
    close?: () => void
}
function Confirmation(props: ConfirmationProps) {

    return (<>
        {props.confirmationText ? (<div dangerouslySetInnerHTML={{ __html: props.confirmationText }} />) :
            (<>
                {props.title && (<div className={style.stepTitle}>{props.title}</div>)}
                <div className={style.toptext}>
                    {props.contentElement && props.contentElement}
                </div>
            </>)}
        {props.close && <CTAButton
            onClick={() => props.close!()} >{props.buttonLabel}
        </CTAButton>}

    </>
    )
}

export default Confirmation;