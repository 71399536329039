import { StepFormData } from '../../../models/step';
import { EventSignup } from '../../../api';
import { getErrorMsgByName } from "../../../common/getErrorMsgByName";
import CustomDropdown from '../../shared/CustomDropdown/custom-dropdown';
import InputWrapper from "../../shared/InputWrapper/input-wrapper";
import * as signupConstants from "../../../constants/signup-constants";
import { DropdownItem } from '../../../models/dropdownItem';
import style from './event-signup-payment.module.scss';

interface EventSignupPaymentProps extends StepFormData {
    pricepackages: DropdownItem[];
    signUpModel: EventSignup;
    priceDescription?: string;
}

function EventSignupPayment(props: EventSignupPaymentProps) {
    //TODO: change here when we know more about payment method Task #91795
    //const [paymentmethod, setpaymentMethod] = React.useState<string | undefined>(undefined)
    //const paymentPreferences = [{ id: "1", text: "Betalingskort", originalData: {} }, { id: "2", text: "Mobilepay", originalData: {} }, { id: "3", text: "Faktura", originalData: {} }]
    return (
        <div className={style.step}>
            <div className={style.stepText}>
                <div className={style.stepTitle}>Pris</div>
                {props.priceDescription && <div className={style.stepDescription}>{props.priceDescription}</div>}
            </div>
            <InputWrapper
                component={CustomDropdown}
                title="Vælg prispakke"
                required={true}
                name={signupConstants.pricepackage}
                data={props.pricepackages}
                hasCreateButton={false}
                showSearch={false}
                errorMsg={getErrorMsgByName(props.errorList, signupConstants.pricepackage)}
                setError={(errorMsg:string) => props.setError(signupConstants.pricepackage, errorMsg)}
                defaultSelectedId={props.signUpModel.pricePackageId || ""}
                setValue={(selected:DropdownItem) => props.handleChange(signupConstants.pricepackage, selected.id)}/>
            {/* TODO: change here when we know more about payment method Task #91795*/}
            {/* <div className={style.stepLabel}>Vælg betalingsmetode</div> */}
            {/* <CustomDropdown
                required={true}
                data={paymentPreferences}
                hasCreateButton={false}
                showSearch={false}
                // hasError={props.errorList.includes(signupConstants.pricepackage)} 
                // setError={(hasError) => props.setError(signupConstants.pricepackage, hasError)} 
                defaultSelectedId={paymentmethod}
                setValue={selected => setpaymentMethod(selected.id)} /> */}
            {/* <div className={style.yesnolist}>
                <div className={style.stepLabel}>Jeg ønsker kun at modtage beskeder online</div>
                <YesNoInput setInput={(input) => props.handleChange(signupConstants.receiveNotificationOnline, input)} />
                <div className={style.stepLabel}>Jeg ønsker at modtage notifikation på min mail</div>
                <YesNoInput setInput={(input) => props.handleChange(signupConstants.receiveNotificationMail, input)} />
                <div className={style.stepLabel}>Jeg ønsker at modtage nyheder og tilbud via mail</div>
                <YesNoInput setInput={(input) => props.handleChange(signupConstants.receiveNewsMail, input)} />
                <div className={style.stepLabel}>Jeg ønsker at modtage relevante magasiner</div>
            </div> */}

        </div >
    )
}

export default EventSignupPayment;