import React from 'react';
import * as api from '../../api';
import { ContingentElement } from '../../models/umbracoElement';
import ContingentInfo from '../Contingent/ContingentInfo/contingent-info';

interface ChargesProps {
    memberid: string;
    data: any;
}

function Charges(props: ChargesProps) {
    const [currentContingent, setCurrentContingent] = React.useState<api.Contingent>();
    const elementdata: ContingentElement = JSON.parse(props.data);

    const getContingentData = async () => {
        var contingent: api.Contingent = await api.MembersService.getBilling(props.memberid);

        if (contingent != null) {
            setCurrentContingent(contingent);
        }
        //setCurrentContingent({ "id": "7fc4a5ea-2bc4-472b-9f01-b1acef7ad94b", "districtName": "Slagelse Lærerkreds, fraktion 1", "fractionName": null, "billingInterval": "Månedskontingent", "billingStartDate": "2022-02-01T00:00:00+01:00", "billingEndDate": "2022-02-28T00:00:00+01:00", "balance": 400.00, "reductions": [], "billingDetails": [{ "rateDetials": "Centralt kontingent", "rateAmount": 225 }, { "rateDetials": "Slagelse Lærerkreds", "rateAmount": 317 }] });
    }

    return (
            <ContingentInfo memberid={props.memberid} umbracoData={elementdata} contingent={currentContingent} getDataFunc={getContingentData} showPeriod={true} />            
    )
}


export default Charges;