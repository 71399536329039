import * as api from '../api';

export type createMessageContent = api.Message&{file?:Blob}
export const defaultMessageModel: createMessageContent = {
    attachment: {name: "", basestring: "", type: ""},
    cc: "",
    content: "",
    date: "",
    file: undefined,
    messageId: "",
    priority: "",
    receiver: "",
    sender: "",
    subject: "",
    type: ""
}