export const moduluscontrol=(no: string)=>
{   
    const factorList = [4,3,2,7,6,5,4,3,2,1];
    let sumProd = 0;

    for (let i = 0; i < 10; i++)
    {
        const char: number = +(no.charAt(i));
        sumProd += char * factorList[i];
    }
    return sumProd % 11 === 0;
}