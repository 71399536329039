import ButtonBase, { BaseButtonProps } from "../ButtonBase/button-base";
import style from "../../Buttons/buttons.module.scss";
import Document from "../../icons/Document/document";


const DocumentButton = (props: BaseButtonProps & { download?: string, href?: string }) => {

    const { download, href,...rest } = props;
    const button = <ButtonBase {...rest} className={`${style.document} ${props.className || ""}`}><div className={style.flowchildren}> <Document />{props.children}</div></ButtonBase>

    if (props.download)
        return <a download={download} href={href} target="_blank" rel="noreferrer">{button}</a>

    else return button;
}

export default DocumentButton;