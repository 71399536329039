import { CreateInsurance } from '../api';
import { newid } from '../constants/common-constants';

interface BaseCreateInsuranceModel {
    consent: boolean
}
export type CreateFgInsuranceModel = BaseCreateInsuranceModel & CreateInsurance;

export const defaultCreateFgInsuranceModel: CreateFgInsuranceModel = {
    mainInsured: {
        memberId: newid,
        cpr: "",
        email: "",
        name: ""
    },
    coInsured: undefined,
    onlyCoInsured:false,
    consent: false
}
