import style from './corner.module.scss'
function Corner () { 
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={style.dlf_corner_outer} viewBox="-2196 -17981.305 16.665 17.805">
        <path className={style.dlf_corner_inner} d="M -2196 -17963.5 L -2196 -17981.3046875 L -2179.335205078125 -17981.3046875">
        </path>
      </svg>
    )
}

export default Corner;