
function HalfCircle () { 
       return (
        <svg width="40" height="22" viewBox="0 0 40 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M39.9012 22C39.9666 21.3422 40 20.675 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 20.675 0.0334389 21.3422 0.0987505 22H39.9012Z" fill="var(--ctabuttoncolor)"/>
        </svg>
  
      )
  }
  
  export default HalfCircle;

