import Select, { components, MultiValue } from 'react-select';
import { requiredErrorMsg } from '../../../constants/common-constants';
import { DropdownItem, SelectItem } from '../../../models/dropdownItem';
import { InputBase } from '../../../models/inputBase';
import ArrowDown from '../icons/ArrowDown/arrow-down';
import style from './custom-multiselect.module.scss';

type CustomMultiselectProps=InputBase& {
    data: DropdownItem[];
    setValues: (selectedItem: DropdownItem[]) => void;
    showSearch: boolean,
    defaultSelectedIds: string[];
}

function CustomMultiselect(props: CustomMultiselectProps) {
    const setSelected = (items: MultiValue<SelectItem>) => {
        let hasSomethingChosen = items.length > 0

        props.setValues(items.map(s => { return { text: s.label, id: s.value } }));

        if (props.setError && props.required)
            props.setError(!hasSomethingChosen?requiredErrorMsg:"");
    }
    const CaretDownIcon = () => {
        return <ArrowDown />;
      };
      
      const DropdownIndicator = (props :any)=> {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownIcon />
          </components.DropdownIndicator>
        );
    }

    return (
        <div className={style.dropdown}>
            {/* <div className={style.errorMessage}>{props.hasError ? requiredErrorMsg : ""} {props.required && props.defaultSelectedIds.length === 0 && <div className={style.requiredstar} >*</div>}</div> */}
        <Select
                options={props.data.map<SelectItem>((x) => { return { value: x.id!, label: x.text! } })}
                defaultValue={props.data.map<SelectItem>((x) => { return { value: x.id!, label: x.text! } }).filter(s => props.defaultSelectedIds.includes(s.value))}
                className='dlf-multiselect'
                classNamePrefix='dlfms'
                isMulti={true}
                components={{DropdownIndicator}}
                closeMenuOnSelect={false}
                placeholder={props.placeholder||""}
                isDisabled={props.disabled}
                autoFocus={props.setFocus}                
                noOptionsMessage={value => 'Indlæser...'}
                onChange={selected => setSelected(selected)}
                menuPlacement={"auto"}
            />
        </div>
    )
}

export default CustomMultiselect;