import React from 'react';
import style from './custom-upload-button.module.scss';
import UploadButton from '../Buttons/UploadButton/upload-button';
import Cancel from '../icons/Cancel/cancel';
interface CustomUploadButtonProps {
    onFileAttached: React.ChangeEventHandler<HTMLInputElement>;
    onRemoveClicked: () => void;
    uploadKey: string;
    styles?: React.CSSProperties;
    id?: string;
    allowMultiple?: boolean;
}

function CustomUploadButton(props: CustomUploadButtonProps) {
    const inputref = React.useRef<(HTMLInputElement | null)>();

    const onClick = () => {
        if (inputref?.current)
            inputref.current.click();
    }
    const getFilename = (path?: string) => {
        if (path) {
            var lastindexofslash = path.lastIndexOf("\\");
            if (lastindexofslash > -1 && path.length > lastindexofslash)
                return path.substring(lastindexofslash + 1);
        }
        return path
    }
    const onRemoveClicked = () => {
        if (inputref.current)
            inputref.current.value = "";
        props.onRemoveClicked()
    }

    return (
        <div style={props.styles || undefined} className={style.fileattachtment}>
            <input key={props.uploadKey} style={{ visibility: "hidden" }} multiple={props.allowMultiple ? true : false} ref={r => inputref.current = r} id={props.id || "customupload"} type="file" onChange={props.onFileAttached} />
            <div className={style.uploadbtngroup}>
                <div className={style.customfileupload}>

                    <UploadButton type={"button"} onClick={onClick} >Vælg fil</UploadButton>

                    <div title={getFilename(inputref.current?.value)} className={style.filename}>{getFilename(inputref.current?.value) || "Ingen fil valgt"}</div>
                    {inputref.current?.value && <div className={style.cancelicon} onClick={onRemoveClicked}><Cancel /></div>}
                </div>
                <div className={`${style.inputinformationtxt}`}>(Maks. 5 MB)</div>
            </div>
        </div>
    )
}

export default CustomUploadButton;