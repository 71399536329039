import style from './reduction-form.module.scss'
import { ReductionFormElement } from '../../models/umbracoElement';
import React, { useEffect } from 'react';
import CustomDatePicker from '../shared/CustomDatePicker/custom-date-picker';
import CustomDropdown from '../shared/CustomDropdown/custom-dropdown';
import { addMonth, dateCompare } from '../../common/dateutil'
import Confirmation from '../shared/Confirmation/confirmation';
import Wizard from '../shared/Wizard/wizard';
import { FormSteps, FormError } from '../../models/step';
import * as api from '../../api';
import * as constants from '../../constants/reduction-form-constants';
import { getNewErrorList } from '../../common/getNewErrorList'
import * as reductionConstants from '../../constants/change-situation-constants';
import { DropdownItem } from '../../models/dropdownItem'
import { fetchErrorMsg, requiredErrorMsg } from '../../constants/common-constants';
import { defaultReductionModel } from '../../models/reductionModel';
import CustomTextArea from '../shared/CustomTextArea/custom-textarea';
import InputWrapper from '../shared/InputWrapper/input-wrapper';
import { getErrorMsgByName } from '../../common/getErrorMsgByName';

interface RedcutionFormProps {
    data: string;
    memberid: string;
}
function ReductionForm(props: RedcutionFormProps) {
    const formsetting: ReductionFormElement = JSON.parse(props.data);
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [reduction, setReduction] = React.useState<api.ReductionApplication>(defaultReductionModel);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [confirmationtext, setConfirmationText] = React.useState("");
    const [confirmBeforeSubmitText, setConfirmBeforeSubmitText] = React.useState("");
    const [reductionReasonList, setReductionReasonList] = React.useState<DropdownItem[]>([]);
    const [preselectedReductionReason, setPreSelectedReductionReason] = React.useState<api.ReductionReason>();
    const [selectedReductionReason, setSelectReductionReason] = React.useState<api.ReductionReason>();
    const [loading, setLoading] = React.useState(false)
    const [showErrorNotification, setShowErrorNotification] = React.useState(false);
    const getDateStr = (datestr?: string) => {
        if (datestr) {
            const date = new Date(datestr);
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
        }
        else {
            return "";
        }
    }
    const replaceTokens = (txt: string) => {
        let replaced = txt.replace(reductionConstants.fromReplaceToken, getDateStr(reduction?.startDate || ""));
        replaced = replaced.replace(reductionConstants.toReplaceToken, getDateStr(reduction?.endDate || ""));
        return replaced;
    }

    useEffect(() => {
        setConfirmBeforeSubmitText(replaceTokens(formsetting.confirmText));
        setConfirmationText(replaceTokens(formsetting.confirmationText));
    }, [reduction?.startDate, reduction?.endDate]);

    useEffect(() => { setShowErrorNotification(false) }, [reduction])

    useEffect(() => {
        setLoading(true);

        api.ReductionreasonsService.getReductionreasons().then(res => {
            let listitems = res.map(x => ({ id: x.reductionReasonId, text: x.name || "", originalData: x } as DropdownItem))
            if (formsetting.typeToShow) {
                const typeToShow = res.find(x => x.reductionReasonId === formsetting.typeToShow) || res.find(x => x.name?.toLowerCase() === formsetting.typeToShow.toLowerCase())
                setPreSelectedReductionReason(typeToShow);
                setSelectReductionReason(typeToShow);
                if (typeToShow)
                    setReduction({ ...reduction, reductionReasonId: typeToShow.reductionReasonId });
            }
            else if (formsetting.typesToHide) {
                const hidinglist = formsetting.typesToHide.toLowerCase().split(",");
                listitems = listitems.filter(x => !(hidinglist.includes(x.id!.toLowerCase()) || hidinglist.includes(x.text.toLowerCase())));
            }
            setReductionReasonList(listitems);
        }
        ).catch((error) => {
            setErrorMsg(fetchErrorMsg); console.log(error)
        }).finally(() => setLoading(false));
    }, [formsetting.confirmationText, formsetting.confirmText])


    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);
    }

    const onDateChange = (date: any, from: boolean) => {
        const newReduction: api.ReductionApplication = {
            ...reduction,
        };
        if (from) {
            if (dateCompare(date, newReduction.endDate) > 0) {
                newReduction.endDate = undefined;
            }
        }
        else {
            if (dateCompare(date, newReduction.startDate) < 0) {
                newReduction.startDate = undefined;
            }
        }

        from ? newReduction.startDate = date : newReduction.endDate = date;
        setReduction(newReduction);

    }


    const onSubmit = async () => {
        if (reduction) {

            return api.MembersService.membersReduction(props.memberid, reduction);

        }
    }

    const getMinMaxFrom = React.useCallback((start: boolean) => {
        if (start) {
            return addMonth(new Date(), (formsetting.fromDateStart || -3))
        }
        else
            return addMonth(new Date(), (formsetting.fromDateEnd || 24))
    }, [formsetting.fromDateEnd, formsetting.fromDateStart])
    const getMinMaxTo = (start: boolean) => {
        if (start)
            return addMonth(new Date(), (formsetting.toDateStart || -3))
        else
            return addMonth(new Date(), (formsetting.toDateEnd || 24))
    }
    const close = () => {
        window.location.href = formsetting.goBackTo._url || "/"
    }
    const checkRequiredData = () => {
        const newErrorList: FormError[] = [];
        if (!reduction?.startDate) {
            newErrorList.push({ name: constants.startDate, errorMsg: requiredErrorMsg });
        }
        if (!reduction?.endDate) {
            newErrorList.push({ name: constants.endDate, errorMsg: requiredErrorMsg });

        }
        if (!preselectedReductionReason && !reduction?.reductionReasonId) {
            newErrorList.push({ name: constants.reductionTypeId, errorMsg: requiredErrorMsg });
        }
        if (selectedReductionReason && selectedReductionReason.memberText === api.ReductionMemberTextType.REQUIRED && !reduction.memberText) {

            newErrorList.push({ name: constants.memberText, errorMsg: requiredErrorMsg });
        }
        setErrorList(newErrorList);
        return newErrorList.length === 0;
    }
    const onChooseReductionType = (item: DropdownItem) => {
        const newReduction = { ...reduction };
        newReduction.reductionReasonId = item.id;
        setReduction(newReduction);
        setSelectReductionReason(item.originalData);
        if (item.originalData.memberText !== api.ReductionMemberTextType.REQUIRED) {
            setError(constants.memberText, "");
        }
    }
    const getErrorMsg = (name: string) => {
        return getErrorMsgByName([...errorList], name);
    }
    const formsteps: FormSteps = {
        title: formsetting.title,
        helptext: "",
        previousLabel: formsetting.previousLabel || "Forrige side",
        nextLabel: formsetting.nextLabel || "Næste trin",
        submitString: formsetting.submitButtonLabel || "Indsend",
        lastStepNo: 2,
        steps: [{
            stepNo: 1,
            required: preselectedReductionReason ? [
                constants.startDate,
                constants.endDate

            ] : [
                constants.startDate,
                constants.endDate,
                constants.reductionTypeId
            ],
            formdata:
                <div className={style.reductioncontainer}>
                    <div className={style.reductioncontent} dangerouslySetInnerHTML={{ __html: formsetting.contentText }} />
                    {!preselectedReductionReason && <>
                        <InputWrapper
                            component={CustomDropdown}
                            title={"Reduktionsårsag"}
                            name={constants.reductionTypeId}
                            data={reductionReasonList}
                            setValue={onChooseReductionType}
                            defaultSelectedId={reduction?.reductionReasonId || undefined}
                            required={true}
                            errorMsg={getErrorMsg(constants.reductionTypeId)}
                            setError={(errorMsg: string) => setError(constants.reductionTypeId, errorMsg)}

                        />

                    </>}
                    {(selectedReductionReason && selectedReductionReason.memberText !== api.ReductionMemberTextType.HIDDEN) && <>
                        <br />
                        <InputWrapper
                            component={CustomTextArea}
                            title={"Begrundelse"}
                            name={constants.memberText}
                            value={reduction.memberText || ''}
                            setInput={(s: string) => setReduction({ ...reduction, memberText: s })}
                            required={selectedReductionReason.memberText === api.ReductionMemberTextType.REQUIRED}
                            errorMsg={getErrorMsg(constants.memberText)}
                            maxLength={2000}
                        />
                    </>
                    }
                    <div className={style.datepickercontainer}>
                        <div className={`${style.dategroup}`}>
                            <InputWrapper
                                name={constants.startDate}
                                component={CustomDatePicker}
                                title={"Startdato"}
                                minDate={getMinMaxFrom(true)}
                                maxDate={getMinMaxFrom(false)}
                                onDateChange={(date: any) => onDateChange(date, true)}
                                required={true}
                                selectedDate={reduction?.startDate || undefined}
                                errorMsg={getErrorMsg(constants.startDate)}
                                setError={(hasError: string) => setError(constants.startDate, hasError)}
                                alwaysOpen={true}

                            />
                        </div>
                        <div className={`${style.dategroup}`}>
                            <InputWrapper
                                name={constants.endDate}
                                component={CustomDatePicker}
                                title={"Slutdato"}
                                minDate={getMinMaxTo(true)}
                                maxDate={getMinMaxTo(false)}
                                onDateChange={(date: any) => onDateChange(date, false)}
                                required={true}
                                selectedDate={reduction?.endDate || undefined}
                                errorMsg={getErrorMsg(constants.endDate)}
                                setError={(hasError: string) => setError(constants.endDate, hasError)}
                                alwaysOpen={true}

                            />
                        </div>
                    </div>
                </div>
        },

        {
            stepNo: 2,
            required: [
            ],
            formdata: <div className={`${style.reductioncontainer}`}>
                <div className={style.reductioncontent}>
                    <div className={style.confirmbefore} dangerouslySetInnerHTML={{ __html: confirmBeforeSubmitText }} />

                </div>
            </div>
        }
        ],
        confirmationPage:
            <Confirmation
                buttonLabel={formsetting.closeLabel || "Luk"}
                title={""}
                close={close}
                confirmationText={confirmationtext}
            />,
    }
    return (
        !loading ?
            <Wizard
                errorMsg={errorMsg}
                setShowErrorNotification={setShowErrorNotification}
                showErrorNotification={showErrorNotification}
                clearErrorMsg={() => setErrorMsg("")}
                checkRequiredData={checkRequiredData}
                confirmed={errorList.length === 0}
                submit={onSubmit}
                cancelLabel={formsetting.cancelLabel}
                onCancelClick={close}
                navigationSettings={formsetting}
                errorList={errorList}
                data={formsteps} />
            : null

    )
}

export default ReductionForm;