import React, { useEffect } from 'react';
import * as api from '../../api';
import { addMonth, dateCompare } from '../../common/dateutil';
import { getErrorMsgByName } from '../../common/getErrorMsgByName';
import { getNewErrorList } from '../../common/getNewErrorList';
import * as changeSituationConstants from '../../constants/change-situation-constants';
import { fetchErrorMsg, fixBeforeContinue, requiredErrorMsg } from '../../constants/common-constants';
import * as constants from '../../constants/signup-constants';
import { defaultSituationModel, SituationChangeModel } from '../../models/situationModel';
import { FormSteps, FormError } from '../../models/step';
import { ElementHelpText, SituationChangeSetting } from '../../models/umbracoElement';
import Confirmation from '../shared/Confirmation/confirmation';
import CustomCheckbox from '../shared/CustomCheckbox/custom-checkbox';
import CustomDatePicker from '../shared/CustomDatePicker/custom-date-picker';
import NotificationBarSetting, { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import TopTitle from '../shared/TopTitle/top-title';
import InputWrapper from '../shared/InputWrapper/input-wrapper';
import Wizard from '../shared/Wizard/wizard';
import style from './situation-change.module.scss';
import InformationBox from '../shared/InformationBox/information-box';
import Wrapper from '../shared/Wrapper/wrapper';

interface SituationChangeProps {
    data: string;
    memberid: string;
}
function SituationChange(props: SituationChangeProps) {
    const setting: SituationChangeSetting = JSON.parse(props.data);
    const [changeDistrict, setChangeDistrict] = React.useState(false);
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [situation, setSituation] = React.useState<SituationChangeModel | undefined>(defaultSituationModel);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [confirmationtext, setConfirmationText] = React.useState("");
    const [confirmBeforeSubmitText, setConfirmBeforeSubmitText] = React.useState("");
    const [onChangeDistrictText, setOnChangeDistrictText] = React.useState("");
    const [situationList, setSituationList] = React.useState<api.Situation[]>([]);
    const [haveSameSituation, setHaveSameSituation] = React.useState<boolean>();
    const [loading, setLoading] = React.useState(false);

    const [showErrorNotification, setShowErrorNotification] = React.useState(false);
    const getDateStr1 = (datestr?: string) => {
        if (datestr) {
            const date = new Date(datestr);
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
        }
        else {
            return "";
        }
    }
    useEffect(() => {
        const replaceTokens = (txt: string) => {
            let replaced = txt.replace(changeSituationConstants.fromReplaceToken, getDateStr1(situation?.startdate || ""));
            replaced = replaced.replace(changeSituationConstants.toReplaceToken, getDateStr1(situation?.enddate || ""));
            return replaced;
        }

        setConfirmBeforeSubmitText(replaceTokens(setting.confirmText));
        setConfirmationText(replaceTokens(setting.confirmationText));
        setOnChangeDistrictText(replaceTokens(setting.textOnChangeDistrict));
    }, [situation?.startdate, situation?.enddate, setting.textOnChangeDistrict, setting.confirmText, setting.confirmationText])

    useEffect(() => {
        setLoading(true)
        setSituation({ ...situation, situationtypeId: setting.type })
        api.MembersService.getMemberById(props.memberid).then(res => {
            setSituationList(res.situations || [])
            var currentSituation = res.situations?.[0]?.type;
            setHaveSameSituation(currentSituation?.situationTypeId === setting.type);
        }
        ).catch((error) => {
            setErrorMsg(fetchErrorMsg);
            console.log(error);
        }).finally(() => setLoading(false));
    }, [props.memberid, setting.type])

    useEffect(() => {
        setShowErrorNotification(false)
        setErrorMsg("")
    }, [situation]);
    useEffect(() => {
        setErrorMsg(showErrorNotification ? fixBeforeContinue : "")
    }, [showErrorNotification]);
    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);

    }

    const onDateChange = (date: any, from: boolean) => {
        const newSituation: api.Situation | undefined = situation ? {
            ...situation,
        } : defaultSituationModel;
        if (newSituation) {
            from ? newSituation.startdate = date : newSituation.enddate = date;
            setSituation(newSituation);
        }

    }
    const onChecked = (checked?: boolean) => {
        setChangeDistrict(checked || false)
    }

    const onSubmit = async () => {
        const submittingSituation: api.NewSituation = ({
            ...situation,
            situationtypeId: setting.type,
            changeDistrict: changeDistrict,
            startdate: situation!.startdate!,
            enddate: situation!.enddate ? (addMonth(new Date(situation!.startdate!), setting.fromDateEnd || 6)) as unknown as string : undefined
        });
        return api.MembersService.membersSituation(props.memberid, submittingSituation);
    }

    const getStartDate = React.useCallback(() => {
        const activeSituationStart = situationList.find(x => !x.enddate)?.startdate;
        const activeSituationStartDate = activeSituationStart ? new Date(activeSituationStart) : new Date();
        return activeSituationStartDate > new Date() ? activeSituationStartDate : new Date();
    }, [situationList]);

    const getMaximumAllowedStartDate = React.useCallback(() => {
        const activeSituationStart = situationList.find(x => !x.enddate)?.startdate;
        const mostRecentSituationEndDate = activeSituationStart ? new Date(activeSituationStart) : new Date();
        const maxAllowedStartDate = mostRecentSituationEndDate > new Date() ? mostRecentSituationEndDate : new Date();
        return addMonth(maxAllowedStartDate, (setting.fromDateEnd || 24))
    }, [setting.fromDateEnd, situationList]);

    const getMinAllowedStartDate = React.useCallback(() => {
        const oldestAllowedDate = addMonth(new Date(), (setting.fromDateStart));
        if (setting.fromDateStartMustBeFirstInMonth) {
            oldestAllowedDate.setDate(1);
        }
        const activeSituationStart = situationList.find(x => !x.enddate)?.startdate;
        const sortedEndDates = situationList.filter(x => !!x.enddate).map(x => x.enddate).sort(dateCompare).reverse();
        let mostRecentEndDate = activeSituationStart ? new Date(activeSituationStart) : oldestAllowedDate
        if (sortedEndDates && sortedEndDates.length > 0) {
            //don't overwrap start date with previeous situations.
            if (sortedEndDates[0])
                mostRecentEndDate = new Date(sortedEndDates[0]);
        }
        //Return the most recent date of the two
        return mostRecentEndDate > oldestAllowedDate ? mostRecentEndDate : oldestAllowedDate;

    }, [situationList, setting.fromDateStart, setting.fromDateStartMustBeFirstInMonth]);

    const getMaximumAllowedEndDate = () => {
        return addMonth(new Date(), (setting.toDateEnd || 24))
    }

    const getMinAllowedEndDate = () => {
        return addMonth(new Date(), (setting.toDateStart || -3))
    }

    const close = () => {
        window.location.href = setting.goBackTo._url || "/"
    }
    const checkRequiredData = () => {
        const newErrorList: FormError[] = [];
        if (!situation?.startdate) {
            newErrorList.push({ name: constants.startdate, errorMsg: requiredErrorMsg });
        }
        if (setting.toDateRequired && !situation?.enddate) {
            newErrorList.push({ name: constants.enddate, errorMsg: requiredErrorMsg });

        }
        setErrorList(newErrorList);
        return newErrorList.length === 0;
    }
    const getErrorMsg = (name: string) => {
        return getErrorMsgByName([...errorList], name);
    }
    const formsteps: FormSteps = {
        title: setting.title,
        helptext: "",
        previousLabel: setting.previousLabel || "Forrige side",
        nextLabel: setting.nextLabel || "Næste trin",
        submitString: setting.submitButtonLabel || "Indsend",
        lastStepNo: 2,
        steps: [{
            stepNo: 1,
            required: (setting.toDateRequired ? [
                constants.startdate,
                constants.enddate

            ] : [constants.startdate]
            ),
            formdata:
                <div >
                    <div className={style.changesituationcontent} dangerouslySetInnerHTML={{ __html: setting.contentText }} />
                    <div className={style.datepickercontainer}>
                        {!setting.hideFromDate && situation &&
                            <div className={`${style.dategroup} ${setting.hideToDate ? style.onedatebox : ""}`}>
                                <InputWrapper
                                    title="Startdato"
                                    name={constants.startdate}
                                    component={CustomDatePicker}
                                    startDate={getStartDate()}
                                    minDate={getMinAllowedStartDate()}
                                    maxDate={getMaximumAllowedStartDate()}
                                    onDateChange={(date: any) => onDateChange(date, true)}
                                    required={true}
                                    selectedDate={situation?.startdate || undefined}
                                    errorMsg={getErrorMsg(constants.startdate)}
                                    setError={(errorMsg: string) => setError(constants.startdate, errorMsg)} alwaysOpen={true}


                                />
                            </div>}
                        {!setting.hideToDate &&
                            <div className={`${style.dategroup} ${setting.hideFromDate ? style.onedatebox : ""}`}>
                                <InputWrapper
                                    title="Slutdato"
                                    name={constants.enddate}
                                    component={CustomDatePicker}
                                    minDate={getMinAllowedEndDate()}
                                    maxDate={getMaximumAllowedEndDate()}
                                    onDateChange={(date: any) => onDateChange(date, false)}
                                    required={setting.toDateRequired}
                                    selectedDate={situation?.enddate || undefined}
                                    errorMsg={getErrorMsg(constants.enddate)}
                                    setError={(errorMsg: string) => setError(constants.enddate, errorMsg)}
                                    alwaysOpen={true}

                                />
                            </div>}
                    </div>
                    {setting.changeDistrictLabel && <div className={style.checkboxdiv}>
                        <InputWrapper
                            title=""
                            name={constants.changeDistrict}
                            component={CustomCheckbox}
                            deactivateClickLabel={true} checked={changeDistrict} setInput={onChecked} htmlLabel={setting.changeDistrictLabel} />
                    </div>}
                </div>
        },

        {
            stepNo: 2,
            required: [
            ],
            formdata: <div>
                <div className={style.changesituationcontent}>
                    <div className={style.confirmbefore} dangerouslySetInnerHTML={{ __html: confirmBeforeSubmitText }} />
                    {changeDistrict && <InformationBox title="" content={onChangeDistrictText} />}

                </div>
            </div>
        }
        ],
        confirmationPage:
            <Confirmation
                buttonLabel={setting.closeLabel || "Luk"}
                title={""}
                close={close}
                confirmationText={confirmationtext}
            />,
    }
    return (
        !loading ? <>
            {setting.sameSituationText && haveSameSituation === true
                ? <Wrapper properties={{ ...{} as ElementHelpText, title: setting.title }}>
                    <InformationBox title={""} content={setting.sameSituationText} />
                </Wrapper>
                : <>
                    {haveSameSituation === false && <>
                        <Wizard
                            checkRequiredData={checkRequiredData}
                            confirmed={errorList.length === 0}
                            setShowErrorNotification={setShowErrorNotification}
                            showErrorNotification={showErrorNotification}
                            submit={onSubmit}
                            cancelLabel={setting.cancelLabel}
                            onCancelClick={close}
                            navigationSettings={setting}
                            errorList={errorList}
                            errorMsg={errorMsg}
                            data={formsteps} />
                    </>}
                </>
            }
        </> : null

    )
}

export default SituationChange;