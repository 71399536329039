import { useEffect } from 'react';

interface NotificationBarProps {
    text: string;
    type: NotificationBarType;
}

export enum NotificationBarType {
    Danger = "danger",
    Success = "success",
    Warning = "warning",
    Info = "info"
}

function NotificationBarSetting(props: NotificationBarProps) {
    useEffect(() => {
        const notificationbar = document.getElementById("dlf_notification_bar");
        const typediv = document.getElementById("dlf_notification_type");
        const textdiv = document.getElementById("dlf_notification_text");
        const body: HTMLDivElement | null = document.querySelector("#dlf_body");
        const topbar = document.getElementById("dlf-topbar");

        if (notificationbar && props.text) {

            notificationbar.style.display = "flex" 
            notificationbar.style.paddingTop = (topbar?.clientHeight || 0) + "px";
            notificationbar.style.zIndex = "-1";

            const paddingTop = parseInt(notificationbar.style.paddingTop);
            const newPadding = (notificationbar.clientHeight || 0) - (isNaN(paddingTop) ? 0 : paddingTop);
            body!.style.marginTop = newPadding + "px";

            if (typediv) {
                typediv.style.height = "auto";
                typediv.className = props.type.toString();
            }
            if (textdiv)
                textdiv.innerText = props.text;
        }

    }, [props])



    return (<></>
    )
}

export default NotificationBarSetting;