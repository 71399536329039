import style from './yes-no-input.module.scss';
import React from 'react';
import CustomCheckbox from '../../shared/CustomCheckbox/custom-checkbox';

interface YesNoInputProps {
    setInput: (input: boolean | undefined) => void;
    yesLabel?: string;
    noLabel?: string;
    required?: boolean;
    shouldFocus?: boolean;
    name?:string;
}
function YesNoInput(props: YesNoInputProps) {
    const [yesChecked, setYesChecked] = React.useState<boolean>(false)
    const [noChecked, setNoChecked] = React.useState<boolean>(false)

    const onYesChange = (input: boolean | undefined) => {
        if (input) {
            setYesChecked(true);
            if (noChecked) {
                setNoChecked(false);
            }
        } else {
            setYesChecked(false);
        }
        props.setInput(GetAnswer(input || false, noChecked))
    }
    const onNoChange = (input: boolean | undefined) => {
        if (input) {
            setNoChecked(true);
            if (yesChecked) {
                setYesChecked(false);
            }
        } else {
            setNoChecked(false);
        }
        props.setInput(GetAnswer(yesChecked, input || false))
    }
    const GetAnswer = (yes: boolean, no: boolean) => {
        if (yes) return true;
        else if (no) return false;
        else return undefined;
    }
    return (
        <div className={style.yesnocontainer}>
            <div className={style.group}>
                <CustomCheckbox name={`${props.name}-yes`} setFocus={props.shouldFocus} checked={yesChecked} setInput={onYesChange} label={props.yesLabel || "Ja"} />
            </div>
            <div className={style.group}>
                <CustomCheckbox name={`${props.name}-no`} checked={noChecked} setInput={onNoChange} label={props.noLabel || "Nej"} />
            </div>
            
            {props.required && <div className={style.requiredstar}>*</div>}   
        </div>
    )
}

export default YesNoInput;