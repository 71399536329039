import React from 'react';
import * as api from '../../../api';
import { InsuranceFormElement } from '../../../models/umbracoElement';
import style from './insurance-status-main.module.scss';

interface InsuranceStatusMainProps {
    insurance: api.Insurance;
    formElem: InsuranceFormElement;
}

function InsuranceStatusMain(props: InsuranceStatusMainProps) {
    return (
        <div className={style.maincontainer}>
            {
                renderSwitch(props)
            }
        </div>
    )
}
export default InsuranceStatusMain;


function renderSwitch(props: InsuranceStatusMainProps) {
    switch (props.insurance.status) {
        case "Overf�rt": //Never used in the frontend        
        case "Tilmeldt":
            return renderTilmeldt(props)
        case "Tilmelder":
            return renderTilmelder(props);
        case "Afvist":
            return renderAfvist(props);
        case "Afmeldt":
        case "Afmelder":
            return renderAfmeldt(props);
        default:
            return <div />
    }
}


function renderAfvist(props: InsuranceStatusMainProps) {
    return <div className={style.insurancecontainer}>
        <div className={style.statuscontainer}>
            <div dangerouslySetInnerHTML={{
                __html: props.formElem.rejectedText.replace("#{REJECTED_REASON}#", props.insurance.statusReason || 'Ukendt')
            }} />
        </div>
    </div>;
}

function renderTilmelder(props: InsuranceStatusMainProps) {
    return <div className={style.insurancecontainer}>
        <div className={style.statuscontainer}>
            <div dangerouslySetInnerHTML={{ __html: props.formElem.processingText || "" }} />
        </div>
    </div>;
}

function renderTilmeldt(props: InsuranceStatusMainProps) {
    return <div className={style.insurancecontainer}>
        <div className={style.statuscontainer}>
            <div dangerouslySetInnerHTML={{ __html: props.formElem.signedupText || "" }} />
        </div>
    </div>;
}

function renderAfmeldt(props: InsuranceStatusMainProps) {
    return <div className={style.insurancecontainer}>
        <div className={style.statuscontainer}>
            <div dangerouslySetInnerHTML={{ __html: props.formElem.unsubscribedText || "" }} />
        </div>
    </div>;
}

