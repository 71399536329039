import { NewSituation } from "../api";
export const defaultCreateSituationModel: NewSituation = {
        jobTypeId: "",
        changeDistrict: false,
        educationInstitutionId: undefined,
        educationMainSubjectIds: [],
        educationOrientationId: undefined,
        expectedGraduationDate: undefined,
        jobTitleId: undefined,
        situationtypeId: undefined,
        startdate: undefined,
        workLocationId: undefined,
        unknownWorkLocation: "",
        
}