/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateInsurance } from '../models/CreateInsurance';
import type { HealthInformationLinks } from '../models/HealthInformationLinks';
import type { Insurance } from '../models/Insurance';
import { request as __request } from '../core/request';

export class InsuranceService {

    /**
     * Description: Cancel an Insurance Policy at Forende Gruppeliv.
 * Operation: CancelInsurancePolicy.
 * Area: Insurance.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async cancelInsurancePolicy(
requestBody: Insurance,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/insurance/cancelpolicy`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Create a new Insurance Policy at Forende Gruppeliv.
 * Operation: CreateInsurancePolicy.
 * Area: Insurance.
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static async createInsurancePolicy(
requestBody: CreateInsurance,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/insurance/createpolicy`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Edit the personal information in a given Insurance Policy.
 * Operation: EditInsurancePolicy.
 * Area: Insurance.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async editInsurancePolicy(
requestBody: Insurance,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/insurance/editpolicy`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Retrieve all Insurance Policies for loggedin user.
 * Operation: GetInsurancePolicies.
 * Area: Insurance.
     * @returns Insurance Success
     * @throws ApiError
     */
    public static async getInsurancePolicies(): Promise<Array<Insurance>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/insurance/getpolicies`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get Link for HealthInformation.
 * Operation: GetHealthInformationLink.
 * Area: Insurance.
     * @param memberId ID of member.
     * @returns HealthInformationLinks Success
     * @throws ApiError
     */
    public static async getHealthInformationLink(
memberId: string,
): Promise<HealthInformationLinks> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/insurance/healthinformationlink/${memberId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Undo a cancelling of an Insurance Policy at Forende Gruppeliv.
 * Operation: UndoCancelInsurancePolicy.
 * Area: Insurance.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async undoCancelInsurancePolicy(
requestBody: Insurance,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/insurance/undocancelpolicy`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}