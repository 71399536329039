function Information () { 
    return (
<svg width="24" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#253154" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 9V14" stroke="#253154" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.9502 6H10.0502V6.1H9.9502V6Z" stroke="#253154" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

      
    )
}

export default Information;