import * as api from '../../../api';
import CustomTable from '../../shared/CustomTable/custom-table';

const columns = [
    {
        columnName: 'Navn',
        visible: true,
        index: 0
    },
    {
        columnName: 'Email',
        visible: true,
        index: 1
    },
    {
        columnName: 'Kreds',
        visible: true,
        index: 2
    }
]

interface EventDetailsParticipantsProps {
    participants: api.EventParticipantLimited[];
}

function EventDetailsParticipants(props: EventDetailsParticipantsProps) {
    return <CustomTable columns={columns} showButtonLabel='' showAllColumns={true} rows={props.participants.map(s => { return { data: [`${s.firstname} ${s.lastname}`, s.email, s.district] } })}></CustomTable>
}

export default EventDetailsParticipants;