import React from "react";
import * as api from '../../../api';
import { getErrorMsgByName } from "../../../common/getErrorMsgByName";
import { isUserLoggedIn } from '../../../common/isUserLoggedIn';
import CprInput from '../../shared/CprInput/cpr-input';
import CustomDropdown from "../../shared/CustomDropdown/custom-dropdown";
import CustomInput from "../../shared/CustomInput/custom-input";
import NotificationBarSetting, { NotificationBarType } from '../../shared/NotificationBarSetting/notification-bar-setting';
import PhoneInput from "../../shared/PhoneInput/phone-input";
import InputWrapper from "../../shared/InputWrapper/input-wrapper";
import { defaultCountryCode, emailErrorMsg, fetchErrorMsg } from '../../../constants/common-constants';
import { cpr, email } from '../../../constants/regex-expressions';
import * as signupConstants from "../../../constants/signup-constants";
import { DropdownItem } from '../../../models/dropdownItem';
import { StepFormData } from "../../../models/step";
import style from './event-signup-contact.module.scss';

export interface EventSignupContactProps extends StepFormData {
    signUpModel: api.EventSignup;
    memberid?: string;
    event?: api.Event;
    isPublicEvent?: boolean;
}

function EventSignupContact(props: EventSignupContactProps) {

    const countryCode: DropdownItem[] = [{ id: "1", text: "+45" }, { id: "2", text: "+46" }, { id: "3", text: "+47" }]
    const [countries, setCountries] = React.useState<DropdownItem[]>([]);
    const [municipalities, setMunicipalities] = React.useState<DropdownItem[]>([]);
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [atendeeTypes, setAtendeeTypes] = React.useState<DropdownItem[]>([]);
    const [loading, setLoading] = React.useState(false)
    const [defaultCountryItem, setDefaultCountryItem] = React.useState<DropdownItem>();

    const handleContactChange = (key: string, value: any) => {
        const newContact = { ...props.signUpModel.contact } as any
        newContact[key] = value;
        props.handleChange(signupConstants.contact, newContact)
    }

    const getErrorMsg = (name: string) => {
        return getErrorMsgByName(props.errorList, name);
    }

    React.useEffect(() => {
        if (props.event) {
            setLoading(true);
            const call = async () => {
                try {
                    if (props.isPublicEvent) {
                        const countryres: api.Country[] = await api.CountriesService.getCountries();
                        const newcountries = countryres.map((x) => { return { id: x.countryId!, text: x.name!, originalData: x } });
                        setCountries(newcountries);
                        const defaultCountry = newcountries.find(x => x.originalData.countryCode === defaultCountryCode);
                        setDefaultCountryItem(defaultCountry);
                        handleContactChange(signupConstants.countryId, defaultCountry?.id);
                        const municipalityres: api.Municipality[] = await api.MunicipalitiesService.getMunicipalities();
                        const newmuniciparities = municipalityres.map((x) => { return { id: x.municipalityid!, text: x.name!, originalData: x } });
                        setMunicipalities(newmuniciparities);
                    } else {
                        const newAtendeeTypes = props.event?.eventInformation?.atendeeTypes?.map((x) => { return { id: x.eventAtendeeTypeId!, text: x.name!, originalData: x } })
                        setAtendeeTypes(newAtendeeTypes || []);

                    }
                } catch (error) {
                    setErrorMsg(fetchErrorMsg);
                }
            }
            call().finally(() => { setLoading(false) });
        }
    }, [props.event, props.event?.eventInformation?.atendeeTypes, props.memberid]);

    React.useEffect(() => {
        setLoggedIn(isUserLoggedIn(props.memberid));
    }, [props.memberid]);

    return (
        <div className={style.step}>
            <div className={style.stepTitle}>Dine informationer</div>
            {loggedIn && !props.isPublicEvent
                ?
                <>
                    {<InputWrapper
                        title="Deltagertype"
                        component={CustomDropdown}
                        required={atendeeTypes.length > 0}
                        data={atendeeTypes}
                        hasCreateButton={false}
                        showSearch={true}
                        name={signupConstants.atendeeType}
                        defaultSelectedId={props.signUpModel.atendeeType?.eventAtendeeTypeId || undefined}
                        setValue={(selected: DropdownItem) => props.handleChange(signupConstants.atendeeType, selected.id ? selected.originalData : undefined)}
                        setError={(errorMsg: string) => props.setError(signupConstants.atendeeType, errorMsg)}
                        errorMsg={getErrorMsg(signupConstants.atendeeType)}
                    />}
                </>
                :
                <>                  
                    <InputWrapper
                        title="Fornavn"
                        component={CustomInput}
                        required={true}
                        name={signupConstants.firstname}
                        setError={(errorMsg: string) => props.setError(signupConstants.firstname, errorMsg)}
                        setInput={(input: string) => handleContactChange(signupConstants.firstname, input)}
                        maxLength={50}
                        errorMsg={getErrorMsg(signupConstants.firstname)}
                        value={props.signUpModel.contact?.firstname || ""}
                    />
                    <InputWrapper
                        title="Efternavn"
                        component={CustomInput}
                        required={true}
                        maxLength={50}
                        name={signupConstants.lastname}
                        value={props.signUpModel.contact?.lastname || ""}
                        setInput={(input: string) => handleContactChange(signupConstants.lastname, input)}
                        setError={(errorMsg: string) => props.setError(signupConstants.lastname, errorMsg)}
                        errorMsg={getErrorMsg(signupConstants.lastname)}
                    />

                    <InputWrapper
                        title="Adresse"
                        component={CustomInput}
                        required={true}
                        maxLength={250}
                        name={signupConstants.street}
                        value={props.signUpModel.contact?.street || ""}
                        setError={(errorMsg: string) => props.setError(signupConstants.street, errorMsg)}
                        setInput={(input: string) => handleContactChange(signupConstants.street, input)}
                        errorMsg={getErrorMsg(signupConstants.street)} />

                    <InputWrapper
                        title="Hus nr."
                        component={CustomInput}
                        name={signupConstants.number}
                        value={props.signUpModel.contact?.number || ""}
                        maxLength={250}
                        errorMsg={getErrorMsg(signupConstants.number)}
                        setError={(errorMsg: string) => props.setError(signupConstants.number, errorMsg)}
                        required={true}
                        setInput={(input: string) => handleContactChange(signupConstants.number, input)} />
                    <InputWrapper
                        title="Bogstav"
                        component={CustomInput}
                        name={signupConstants.alphabet}
                        maxLength={250}
                        value={props.signUpModel.contact?.alphabet || ""}
                        setInput={(input: string) => handleContactChange(signupConstants.alphabet, input)}
                    />

                    <InputWrapper
                        title="Etage"
                        component={CustomInput}
                        name={signupConstants.floor}
                        value={props.signUpModel.contact?.floor || ""}
                        setInput={(input: string) => handleContactChange(signupConstants.floor, input)} />

                    <InputWrapper
                        title="Side"
                        component={CustomInput}
                        name={signupConstants.door}
                        setInput={(input: string) => handleContactChange(signupConstants.door, input)}
                        value={props.signUpModel.contact?.door || ""}
                    />

                    <InputWrapper
                        title="C/O"
                        component={CustomInput}
                        name={signupConstants.co}
                        value={props.signUpModel.contact?.co || ""}
                        required={false}
                        setInput={(input: string) => handleContactChange(signupConstants.co, input)} />

                    <InputWrapper
                        title="Postnummer"
                        description="Indtast dit postnummer ex.1656"
                        name={signupConstants.zip}
                        component={CustomInput}
                        value={props.signUpModel.contact?.zip || ""}
                        maxLength={20}
                        required={true}
                        setError={(errorMsg: string) => props.setError(signupConstants.zip, errorMsg)}
                        errorMsg={getErrorMsg(signupConstants.zip)}
                        setInput={(input: string) => handleContactChange(signupConstants.zip, input)} />

                    <InputWrapper
                        title="By"
                        component={CustomInput}
                        name={signupConstants.city}
                        maxLength={80}
                        value={props.signUpModel.contact?.city || ""}
                        required={true}
                        setError={(errorMsg: string) => props.setError(signupConstants.city, errorMsg)}
                        errorMsg={getErrorMsg(signupConstants.city)}
                        setInput={(input: string) => handleContactChange(signupConstants.city, input)} />

                    <InputWrapper
                        title="Stednavn"
                        component={CustomInput}
                        name={signupConstants.county}
                        maxLength={50}
                        value={props.signUpModel.contact?.county || ""}
                        setInput={(input: string) => handleContactChange(signupConstants.county, input)} />


                    <InputWrapper
                        title="Kommune"
                        component={CustomDropdown}
                        required={true}
                        data={municipalities}
                        name={signupConstants.municipalityId}
                        hasCreateButton={false}
                        showSearch={true}
                        defaultSelectedId={props.signUpModel.contact?.municipalityId || ""}
                        setValue={(selected: DropdownItem) => handleContactChange(signupConstants.municipalityId, selected.id || "")}
                        setError={(errorMsg: string) => props.setError(signupConstants.municipalityId, errorMsg)}
                        errorMsg={getErrorMsg(signupConstants.municipalityId)}
                    />
                    <InputWrapper
                        title="Land"
                        component={CustomDropdown}
                        name={signupConstants.countryId}
                        data={countries}
                        hasCreateButton={false}
                        showSearch={true}
                        defaultSelectedId={props.signUpModel.contact?.countryId || defaultCountryItem?.id}
                        setValue={(selected: DropdownItem) => handleContactChange(signupConstants.countryId, selected.id)} />


                    <InputWrapper
                        title="Mobiltelefon"
                        component={PhoneInput}
                        required={true}
                        phoneAreaCodes={countryCode}
                        maxLength={50}
                        value={props.signUpModel.contact?.mobilephone || ""}
                        name={signupConstants.mobilephone}
                        errorMsg={getErrorMsg(signupConstants.mobilephone)}
                        setError={(errorMsg: string) => props.setError(signupConstants.mobilephone, errorMsg)}
                        setInput={(input: { areaCode?: string, telNo?: string }) => {
                            handleContactChange(signupConstants.mobilephone, ((input.areaCode || "") + (input.telNo || "")))
                        }} />

                    <InputWrapper
                        title="Arbejdstelefon"
                        component={PhoneInput}
                        phoneAreaCodes={countryCode}
                        name={signupConstants.workphone}
                        value={props.signUpModel.contact?.workphone || ""}
                        maxLength={50}
                        errorMsg={getErrorMsg(signupConstants.workphone)}
                        setError={(errorMsg: string) => props.setError(signupConstants.workphone, errorMsg)}
                        setInput={(input: { areaCode?: string, telNo?: string }) => {
                            handleContactChange(signupConstants.workphone, ((input.areaCode || "") + (input.telNo || "")))
                        }} />
                    <InputWrapper
                        title="E-mail (privat)"
                        component={CustomInput}
                        required={true}
                        type={"email"}
                        name={signupConstants.mail}
                        value={props.signUpModel.contact?.mail || ""}
                        pattern={{ patternStr: email, errorMsg: emailErrorMsg }}
                        maxLength={100}
                        errorMsg={getErrorMsg(signupConstants.mail)}
                        setError={(errorMsg: string) => props.setError(signupConstants.mail, errorMsg)}
                        setInput={(input: string) => handleContactChange(signupConstants.mail, input)} />
                    <InputWrapper
                        title="E-mail (arbejde)"
                        component={CustomInput}
                        type={"email"}
                        name={signupConstants.workmail}
                        required={false}
                        value={props.signUpModel.contact?.workmail || ""}
                        pattern={{ patternStr: email, errorMsg: emailErrorMsg }}
                        maxLength={100}
                        errorMsg={getErrorMsg(signupConstants.workmail)}
                        setError={(errorMsg: string) => props.setError(signupConstants.workmail, errorMsg)}
                        setInput={(input: string) => handleContactChange(signupConstants.workmail, input)} />
                </>
            }
        </div>)
}

export default EventSignupContact;