import style from './search.module.scss'
function Search () { 
    return (
      <svg className={style.searchicon_outer} viewBox="0 0 11.9 11.9">
				<path className={style.searchicon_inner} d="M 11.89999961853027 10.85220146179199 L 9.430188179016113 8.382390022277832 C 10.10377311706543 7.484276294708252 10.47798728942871 6.436478137969971 10.47798728942871 5.238993644714355 C 10.47798728942871 2.320125579833984 8.157861709594727 0 5.238993644714355 0 C 2.320126056671143 0 -6.860966550448211e-08 2.320125579833984 -6.860966550448211e-08 5.238993644714355 C -6.860966550448211e-08 8.157861709594727 2.320125579833984 10.47798728942871 5.238993644714355 10.47798728942871 C 6.436478137969971 10.47798728942871 7.484276294708252 10.10377311706543 8.382390022277832 9.43018913269043 L 10.85220146179199 11.90000057220459 L 11.89999961853027 10.85220146179199 Z M 1.496855139732361 5.238993644714355 C 1.496855139732361 3.143395900726318 3.143395900726318 1.49685525894165 5.238993644714355 1.49685525894165 C 7.334590911865234 1.49685525894165 8.981132507324219 3.143395900726318 8.981132507324219 5.238993644714355 C 8.981132507324219 7.334590911865234 7.334590911865234 8.981132507324219 5.238993644714355 8.981132507324219 C 3.143395900726318 8.981132507324219 1.496855139732361 7.334590911865234 1.496855139732361 5.238993644714355 Z">
				</path>
			</svg>
    )
}

export default Search;