import style from './caret-right.module.scss'
function ArrowRight (props:{darkbackground?:boolean}) { 

    return (
      <svg className={style.arrowright_outer} viewBox="8.59 6 6.084 9.852">
      <path className={style.arrowright_inner} d="M 8.590000152587891 14.69479846954346 L 12.35035705566406 10.92623138427734 L 8.590000152587891 7.15766429901123 L 9.747664451599121 6 L 14.67389583587646 10.92623138427734 L 9.747664451599121 15.85246276855469 L 8.590000152587891 14.69479846954346 Z">
      </path>
    </svg>
    )
}

export default ArrowRight;