import React, { useEffect } from 'react';
import * as api from '../../api';
import { DistrictElement } from '../../models/umbracoElement';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import { AccordionElementProps } from '../shared/CustomAccordion/AccordionElement/accordion-element';
import CustomAccordion from '../shared/CustomAccordion/custom-accordion';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './district.module.scss';

export type DistrictProps = {
    data: any;
    memberid?: string;
}

function District(props: DistrictProps) {
    const districtElem: DistrictElement = JSON.parse(props.data);
    const [loading, setLoading] = React.useState(false);
    const [districtInformation, setDistrictInformation] = React.useState<api.DistrictInformation>();

    const getDistrictData = async () => {
        var data: api.DistrictInformation = await api.DistrictinformationService.getDistrictInformation();
        setDistrictInformation(data);
    }
    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getDistrictData();
        }
        call().catch(e => { console.error(e);}).finally(() => { setLoading(false) });
    }, []);

    const businessHoursRender = (districtInformation: api.DistrictInformation) => {
        return districtInformation.businessHours!.split('\n').map((h) => {
            return (<div>{h}</div>)
        });
    };

    const createAccordionData = React.useCallback(() => {

        let districtdata: AccordionElementProps[] =
            [{
                byDefaultOpen: true,
                title: districtElem.membershipTitle,
                dropdownValues: [
                    { title: "Kredsnavn", value: districtInformation?.name || "" },
                    { title: "Kredsnummer", value: districtInformation?.districtNumber || "" }
                ]
            }];

        if (districtElem.unfoldAddress &&
            (districtInformation?.mail || districtInformation?.phonenumber || districtInformation?.website || districtInformation?.businessHours)) {
            districtdata.push({
                byDefaultOpen: districtElem.unfoldContact,
                title: districtElem.contactTitle,
                dropdownValues: [
                    { title: "Email", value: districtInformation?.mail ? (<a href={`mailto:${districtInformation?.mail}`}>{districtInformation?.mail}</a>) : "" },
                    { title: "Hjemmeside", value: districtInformation?.website ? (<a href={districtInformation?.website}>{districtInformation?.website}</a>) : "" },
                    { title: "Telefonnummer", value: districtInformation?.phonenumber ? (<a href={`tel:${districtInformation?.phonenumber}`}>{districtInformation?.phonenumber}</a>) : "" },
                    {
                        title: "Telefontider", value: districtInformation?.businessHours ? <div>{businessHoursRender(districtInformation)}</div> : ""
                    },
                ].filter(x => x.value !== '')
            });
        }

        if (districtElem.unfoldAddress
            && (districtInformation?.address || districtInformation?.city || districtInformation?.zipcode)) {
            districtdata.push({
                byDefaultOpen: districtElem.unfoldAddress,
                title: districtElem.addressTitle,
                dropdownValues: [
                    { title: "Adresse", value: districtInformation?.address || "" },
                    { title: "Postnummer", value: districtInformation?.zipcode || "" },
                    { title: "By", value: districtInformation?.city || "" },                    
                ].filter(x => x.value !== '')
            });
        }

        return districtdata;
    }, [districtInformation])

    const goToNewPage = () => {
        if (districtElem.link.url)
            window.location.href = districtElem.link.url;
    }

    console.log(districtElem.link)
    const { title, ...rest } = districtElem;
    return (<Wrapper properties={rest} loading={loading}>
        <div className={style.districtcontainer}>
            {!loading &&
                <>
                    <CustomAccordion
                        data={createAccordionData()}
                    ></CustomAccordion>
                    {districtElem.link &&
                        <div className={style.button}>
                            <CTAButton onClick={goToNewPage}>{districtElem.buttonText}</CTAButton>
                        </div>
                    }
                </>}
        </div>
    </Wrapper>
    )
}

export default District;
