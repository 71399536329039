import React from "react";
import { MemberSignup } from "../../../api";
import { getErrorMsgByName } from "../../../common/getErrorMsgByName";
import CustomDatePicker from "../../shared/CustomDatePicker/custom-date-picker";
import CustomDropdown from "../../shared/CustomDropdown/custom-dropdown";
import CustomMultiselect from "../../shared/CustomMultiselect/custom-multiselect";
import InputWrapper from "../../shared/InputWrapper/input-wrapper";
import * as signupConstants from "../../../constants/signup-constants";
import { DropdownItem } from '../../../models/dropdownItem';
import { StepFormData } from "../../../models/step";
import style from './signup-fourth-step.module.scss';

interface SignupFourthStepProps extends StepFormData {
    educationalInstitutions: DropdownItem[],
    educationalOrientations: DropdownItem[],
    mainSubjects: DropdownItem[],
    signUpModel: MemberSignup;
    isStudent: boolean;
}
function SignupFourthStep(props: SignupFourthStepProps) {

    const onStartDateChange = (ev: any) => {
        props.handleChange(props.isStudent ? signupConstants.startdate : signupConstants.educationStartdate, ev)
    }
    const onEndDateChange = (ev: any) => {
        props.handleChange(props.isStudent ? signupConstants.enddate : signupConstants.educationEnddate, ev)
    }

    const getErrorMsg = (name: string) => {
        return getErrorMsgByName(props.errorList, name);
    }
    return (
        <div className={style.step}>
            <div className={style.stepTitle}>Uddannelse</div>
            <InputWrapper
                component={CustomDropdown}
                title={"Uddannelsessted"}                
                name={signupConstants.educationInstitutionId}
                setFocus={true}
                placeholder=""
                data={props.educationalInstitutions}
                hasCreateButton={false}
                showSearch={true}
                defaultSelectedId={props.signUpModel.educationInstitutionId || undefined}
                required={props.isStudent}
                errorMsg={getErrorMsg(signupConstants.educationInstitutionId)}
                setError={(errorMsg: string) => props.setError(signupConstants.educationInstitutionId, errorMsg)}
                setValue={(selected: DropdownItem) => props.handleChange(signupConstants.educationInstitutionId, selected.id?.toString())}/>

            <InputWrapper
                component={CustomDropdown}
                title={"Uddannelsesretning"}
                name={signupConstants.educationOrientationId}
                data={props.educationalOrientations}
                required={props.isStudent}
                hasCreateButton={false}
                showSearch={false}
                defaultSelectedId={props.signUpModel.educationOrientationId || undefined}
                errorMsg={getErrorMsg(signupConstants.educationOrientationId)}
                setError={(errorMsg: string) => props.setError(signupConstants.educationOrientationId, errorMsg)}
                setValue={(selected: DropdownItem) => props.handleChange(signupConstants.educationOrientationId, selected.id?.toString())}/>

            <InputWrapper
                component={CustomMultiselect}
                title={"Linjefag"}
                name={signupConstants.educationMainSubjectIds}
                data={props.mainSubjects}
                showSearch={false}
                required={false}//main subjects are not required. as it is often that the new started student doesn't know the mainsubject yet
                defaultSelectedIds={props.signUpModel.educationMainSubjectIds || []}
                setError={(errorMsg: string) => props.setError(signupConstants.educationMainSubjectIds, errorMsg)}
                errorMsg={getErrorMsg(signupConstants.educationMainSubjectIds)}
                setValues={(selected: DropdownItem[]) => props.handleChange(signupConstants.educationMainSubjectIds, selected.map(x => x.id))}/>

            <InputWrapper
                component={CustomDatePicker}
                title={"Start dato"}
                onDateChange={onStartDateChange}
                name={signupConstants.startdate}
                required={props.isStudent}
                selectedDate={props.isStudent ? (props.signUpModel.startdate || undefined) : (props.signUpModel.educationStartdate || undefined)}
                errorMsg={getErrorMsg(props.isStudent ? signupConstants.startdate : signupConstants.educationStartdate)}
                setError={(errorMsg: string) => props.setError(signupConstants.startdate, errorMsg)} />

            <InputWrapper
                component={CustomDatePicker}
                title={props.isStudent ? "Forventet dimission" : "Slut dato"}
                onDateChange={onEndDateChange}
                name={signupConstants.enddate}
                minDate={props.isStudent ? new Date() : undefined}
                required={props.isStudent}
                selectedDate={props.isStudent ? (props.signUpModel.enddate || undefined) : (props.signUpModel.educationEnddate || undefined)}
                errorMsg={getErrorMsg(props.isStudent ? signupConstants.enddate : signupConstants.educationEnddate)}
                setError={(errorMsg: string) => props.setError(signupConstants.enddate, errorMsg)} />

        </div>
    )
}

export default SignupFourthStep;