import { Fragment } from "react";
import Filter, { FilterProps } from "../Filter/filter";
import style from  "./overview-list.module.scss";
interface OverviewListProps {
    filterprops?: FilterProps,
    items: JSX.Element[],
}

const OverviewList = (props: OverviewListProps) => {
    return <div>
        <div className={style.filter}>
        {props.filterprops && <Filter {...props.filterprops} />}
        </div>
        {props.items.map((x: JSX.Element, i: number) => <Fragment key={i}>{x}</Fragment>)}
    </div>

}

export default OverviewList;