import React from 'react';
import * as api from '../../api';
import { ContingentElement } from '../../models/umbracoElement';
import ContingentInfo from './ContingentInfo/contingent-info';
interface ContingentProps {
    memberid: string;
    data: any;
}

function Contingent(props: ContingentProps) {
    const [currentContingent, setCurrentContingent] = React.useState<api.Contingent>();
    const elementdata: ContingentElement = JSON.parse(props.data);

    const getContingentData = async () => {
        var contingent: api.Contingent = await api.MembersService.getContingent(props.memberid);

        if (contingent != null) {
            setCurrentContingent(contingent);
        }
    }

    return (
            <ContingentInfo memberid={props.memberid} umbracoData={elementdata} contingent={currentContingent} getDataFunc={getContingentData} showPeriod={false} />
    )
}


export default Contingent;