import * as api from '../api';

export type createSubscriptionContent = api.Subscription
export const defaultSubscriptionModel: createSubscriptionContent = {
    memberId: "00000000-0000-0000-0000-000000000000",
    subscriptionId: "00000000-0000-0000-0000-000000000000",
    deliveryOption: "unsubscribed",
    magazine: {
        magazineId: "00000000-0000-0000-0000-000000000000",
        name: "",
        phsicaldistribution: true,
        price: 0,
        details: "" }
}

export enum deliveryOptions {
    electronic = "electronic",
    physical = "physical",
    unsubscribed = "unsubscribed"
}