import React, { useEffect } from 'react';
import * as api from '../../api';
import { addMonth, dateCompare } from '../../common/dateutil';
import { getErrorMsgByName } from '../../common/getErrorMsgByName';
import { getNewErrorList } from '../../common/getNewErrorList';
import * as commonconstants from '../../constants/common-constants';
import { fetchErrorMsg, requiredErrorMsg } from '../../constants/common-constants';
import * as retirementConstants from '../../constants/retirement-constants';
import * as constants from '../../constants/signup-constants';
import { DropdownItem } from '../../models/dropdownItem';
import { defaultSituationModel, SituationChangeModel } from '../../models/situationModel';
import { FormError, FormSteps } from '../../models/step';
import { ElementHelpText, RetirementSetting } from '../../models/umbracoElement';
import Confirmation from '../shared/Confirmation/confirmation';
import CustomCheckbox from '../shared/CustomCheckbox/custom-checkbox';
import CustomDatePicker from '../shared/CustomDatePicker/custom-date-picker';
import CustomDropdown from '../shared/CustomDropdown/custom-dropdown';
import InformationBox from '../shared/InformationBox/information-box';
import InputWrapper from '../shared/InputWrapper/input-wrapper';
import NotificationBarSetting, { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import Wizard from '../shared/Wizard/wizard';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './retirement.module.scss';

interface RetirementProps {
    data: string;
    memberid: string;
}
function Retirement(props: RetirementProps) {
    const setting: RetirementSetting = JSON.parse(props.data);
    const [changeDistrict, setChangeDistrict] = React.useState(false);
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [situation, setSituation] = React.useState<SituationChangeModel>(defaultSituationModel);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [confirmationtext, setConfirmationText] = React.useState("");
    const [confirmBeforeSubmitText, setConfirmBeforeSubmitText] = React.useState("");
    const [situationList, setSituationList] = React.useState<api.Situation[]>([]);
    const [situationtypes, setSituationtypes] = React.useState<DropdownItem[]>([]);
    const [haveSameSituation, setHaveSameSituation] = React.useState<boolean>();
    const [loading, setLoading] = React.useState(false);
    const [onChangeDistrictText, setOnChangeDistrictText] = React.useState("");
    const [showErrorNotification, setShowErrorNotification] = React.useState(false);
    const getDateStr = (datestr?: string) => {
        if (datestr) {
            const date = new Date(datestr);
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
        }
        else {
            return "";
        }
    }

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            try {
                const situationtyperes: api.SituationType[] = await api.SituationtypesService.getSituationTypes();

                const newSituationTypes = situationtyperes.filter(s => s.situationTypeNo === commonconstants.retirementRecipientEnumNo
                    || s.situationTypeNo === commonconstants.pensionistEnumNo).map((x) => { return { id: x.situationTypeId!, text: x.name!, originalData: x } });
                setSituationtypes(newSituationTypes);

            } catch (error) {
                console.log(error)
            }
        }
        call().finally(() => { setLoading(false) });
    }, []);

    useEffect(() => {
        const replaceTokens = (txt: string) => {

            let replaced = txt.replace(retirementConstants.fromReplaceToken, getDateStr(situation?.startdate || ""));
            replaced = replaced.replace(retirementConstants.toReplaceToken, getDateStr(situation?.enddate || ""));
            replaced = replaced.replace(retirementConstants.situationReplaceToken, situation?.situationtypeId?.toLowerCase() || "");

            return replaced;
        }

        let replaceText = situation?.situationtypeId === commonconstants.retirementRecipientEnumType ? setting.confirmTextRetirementPay : setting.confirmText
        setConfirmBeforeSubmitText(replaceTokens(replaceText));
        setConfirmationText(replaceTokens(replaceText));
        setOnChangeDistrictText(replaceTokens(setting.textOnChangeDistrict));
    }, [situation?.startdate, situation?.enddate, situation?.situationtypeId, setting])

    useEffect(() => {
        setLoading(true)
        setSituation({ ...situation })
        api.MembersService.getMemberById(props.memberid).then(res => {
            setSituationList(res.situations || [])
            var currentSituation = res.situations?.[0]?.type;
            setHaveSameSituation(currentSituation?.situationTypeId === commonconstants.retirementRecipientEnumType || currentSituation?.situationTypeId === commonconstants.pensionistEnumType);
        }
        ).catch((error) => {
            setErrorMsg(fetchErrorMsg);
            console.log(error);
        }).finally(() => setLoading(false));
    }, [props.memberid])

    useEffect(() => setShowErrorNotification(false), [situation])
    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);

    }

    const onDateChange = (date: any, from: boolean) => {
        const newSituation: api.Situation = situation ? {
            ...situation,
        } : defaultSituationModel;
        if (from) {
            if (dateCompare(date, newSituation.enddate) > 0) {
                newSituation.enddate = undefined;
            }
        }
        else {
            if (dateCompare(date, newSituation.startdate) < 0) {
                newSituation.startdate = undefined;
            }
        }
        from ? newSituation.startdate = date : newSituation.enddate = date;
        setSituation(newSituation);
    }


    const onSubmit = async () => {
        const submittingSituation: api.NewSituation = ({
            ...situation,
            situationtypeId: situation?.situationtypeId,
            changeDistrict: changeDistrict,
            startdate: situation!.startdate!,
            enddate: situation!.enddate ? (addMonth(new Date(situation!.startdate!), setting.fromDateEnd || 6)) as unknown as string : undefined
        });

        if (submittingSituation.situationtypeId === commonconstants.retirementRecipientEnumType) {
            // Create pension starting from when the other situation ended
            const submitPension: api.NewSituation = ({
                ...submittingSituation,
                situationtypeId: commonconstants.pensionistEnumType,
                changeDistrict: changeDistrict,
                startdate: situation!.enddate ? (addMonth(new Date(situation!.startdate!), setting.fromDateEnd || 6)) as unknown as string : undefined
            });
            api.MembersService.membersSituation(props.memberid, submitPension);
        }

        return api.MembersService.membersSituation(props.memberid, submittingSituation);
    }

    const getStartDate = React.useCallback(() => {
        const activeSituationStart = situationList.find(x => !x.enddate)?.startdate;
        const activeSituationStartDate = activeSituationStart ? new Date(activeSituationStart) : new Date();
        return activeSituationStartDate > new Date() ? activeSituationStartDate : new Date();
    }, [situationList]);

    const getMaximumAllowedStartDate = React.useCallback(() => {
        const activeSituationStart = situationList.find(x => !x.enddate)?.startdate;
        const mostRecentSituationEndDate = activeSituationStart ? new Date(activeSituationStart) : new Date();
        const maxAllowedStartDate = mostRecentSituationEndDate > new Date() ? mostRecentSituationEndDate : new Date();
        return addMonth(maxAllowedStartDate, (setting.fromDateEnd || 24))
    }, [setting.fromDateEnd, situationList]);

    const getMinAllowedStartDate = React.useCallback(() => {
        const oldestAllowedDate = addMonth(new Date(), (setting.fromDateStart));
        if (setting.fromDateStartMustBeFirstInMonth) {
            oldestAllowedDate.setDate(1);
        }
        const activeSituationStart = situationList.find(x => !x.enddate)?.startdate;
        const sortedEndDates = situationList.filter(x => !!x.enddate).map(x => x.enddate).sort(dateCompare).reverse();
        let mostRecentEndDate = activeSituationStart ? new Date(activeSituationStart) : oldestAllowedDate
        if (sortedEndDates && sortedEndDates.length > 0) {
            //don't overwrap start date with previeous situations.
            if (sortedEndDates[0])
                mostRecentEndDate = new Date(sortedEndDates[0]);
        }
        //Return the most recent date of the two
        return mostRecentEndDate > oldestAllowedDate ? mostRecentEndDate : oldestAllowedDate;

    }, [situationList, setting.fromDateStart, setting.fromDateStartMustBeFirstInMonth]);

    const getMaximumAllowedEndDate = () => {
        return addMonth(getMinAllowedStartDate(), (setting.toDateEnd || 24))
    }

    const getMinAllowedEndDate = () => {
        return getMinAllowedStartDate()
    }

    const close = () => {
        window.location.href = setting.goBackTo._url || "/"
    }
    const checkRequiredData = (required: string[]) => {
        const newErrorList: FormError[] = [];
        required.forEach((r) => {
            if (!(situation as any)[r]) {
                newErrorList.push({ name: r, errorMsg: requiredErrorMsg });
            }
        })

        setErrorList(newErrorList);
        return newErrorList.length === 0;
    }
    const getErrorMsg = (name: string) => {
        return getErrorMsgByName([...errorList], name);
    }

    const handleChange = (name: string, value: any) => {
        const newSituation: api.Situation | undefined = defaultSituationModel;
        if (newSituation) {
            (newSituation as any)[name] = value;
            setSituation(newSituation);
        }
    }
    const retirementRecipientChosen = React.useMemo(() => situation.situationtypeId === commonconstants.retirementRecipientEnumType, [situation.situationtypeId]);

    const formsteps: FormSteps = {
        title: setting.title || "",
        helptext: "",
        previousLabel: setting.previousLabel || "Forrige side",
        nextLabel: setting.nextLabel || "Næste trin",
        submitString: setting.submitButtonLabel || "Indsend",
        lastStepNo: 3,
        steps: [{
            stepNo: 1,
            required: [
                constants.situationtypeId
            ],
            formdata:
                <div >
                    <div className={style.changesituationcontent} dangerouslySetInnerHTML={{ __html: setting.contentText }} />
                    <div className={style.datepickercontainer}>
                        {situation &&
                            <div className={`${style.dategroup}`}>
                                <InputWrapper
                                    component={CustomDropdown}
                                    title="Vælg situation"
                                    name={setting.title}
                                    required={true}
                                    hasCreateButton={false}
                                    showSearch={false}
                                    data={situationtypes}
                                    defaultSelectedId={situation.situationtypeId!}
                                    setValue={(selected: DropdownItem) => handleChange(constants.situationtypeId, selected.id)}
                                    setError={(errorMsg: string) => setError(constants.situationtypeId, errorMsg)}
                                    errorMsg={getErrorMsg(constants.situationtypeId)}
                                />
                            </div>}
                    </div>
                </div>
        },

        {
            stepNo: 2,
            required: (retirementRecipientChosen ? [
                constants.startdate,
                constants.enddate
            ] : [constants.startdate]
            ),
            formdata: <div>
                <div className={style.changesituationcontent}>
                    <div dangerouslySetInnerHTML={{
                        __html:
                            (retirementRecipientChosen ?
                                setting.retirementRecipientDateDescription
                                : setting.pensionistDateDescription) || ""
                    }}></div>
                    <InputWrapper
                        title="Startdato"
                        name={constants.startdate}
                        component={CustomDatePicker}
                        startDate={getStartDate()}
                        minDate={getMinAllowedStartDate()}
                        maxDate={getMaximumAllowedStartDate()}
                        onDateChange={(date: any) => onDateChange(date, true)}
                        required={true}
                        selectedDate={situation?.startdate || undefined}
                        errorMsg={getErrorMsg(constants.startdate)}
                        setError={(errorMsg: string) => setError(constants.startdate, errorMsg)} alwaysOpen={true}
                    />
                    {retirementRecipientChosen &&
                        <InputWrapper
                            title="Slutdato"
                            name={constants.enddate}
                            component={CustomDatePicker}
                            startDate={getStartDate()}
                            minDate={getMinAllowedEndDate()}
                            maxDate={getMaximumAllowedEndDate()}
                            onDateChange={(date: any) => onDateChange(date, false)}
                            required={true}
                            selectedDate={situation?.enddate || undefined}
                            errorMsg={getErrorMsg(constants.enddate)}
                            setError={(errorMsg: string) => setError(constants.enddate, errorMsg)}
                            alwaysOpen={true}
                        />
                    }
                    {setting.changeDistrictLabel && <div className={style.checkboxdiv}>
                        <InputWrapper
                            title=""
                            name={constants.changeDistrict}
                            component={CustomCheckbox}
                            deactivateClickLabel={true}
                            checked={changeDistrict}
                            setInput={(input?: boolean) => setChangeDistrict(input || false)}
                            htmlLabel={setting.changeDistrictLabel} />
                    </div>}
                </div>
            </div>
        },
        {
            stepNo: 3,
            required: [
            ],
            formdata: <div className={`${style.situationchangecontainer}`}>
                <div className={style.changesituationcontent}>
                    <div className={style.confirmbefore} dangerouslySetInnerHTML={{ __html: confirmBeforeSubmitText }} />
                    {changeDistrict && <InformationBox title="" content={onChangeDistrictText} />}
                </div>
            </div>
        }
        ],
        confirmationPage:
            <Confirmation
                buttonLabel={setting.closeLabel || "Luk"}
                title={""}
                close={close}
                confirmationText={confirmationtext}
            />,
    }

    return (
        <>
            {setting.sameSituationText && haveSameSituation === true
                ?
                <Wrapper properties={{ ...{} as ElementHelpText, title: setting.title }}>
                    <InformationBox title="" content={setting.sameSituationText || ""} />
                </Wrapper>
                : <>
                    {haveSameSituation === false && !loading && <>
                        <Wizard
                            checkRequiredData={checkRequiredData}
                            confirmed={errorList.length === 0}
                            setShowErrorNotification={setShowErrorNotification}
                            showErrorNotification={showErrorNotification}
                            submit={onSubmit}                           
                            errorList={errorList}
                            navigationSettings={setting}
                            cancelLabel={setting.cancelLabel}
                            data={formsteps} />
                        { <NotificationBarSetting text={errorMsg} type={NotificationBarType.Danger}  />}
                    </>}
                </>
            }
        </>
    )
}

export default Retirement;