import { NotificationBarType } from "../components/shared/NotificationBarSetting/notification-bar-setting";
import { NotificationMessage } from "../components/shared/Wrapper/wrapper";

export const setNotificationMsg = (msg: string, type: NotificationBarType, setNotification:(value: React.SetStateAction<NotificationMessage | undefined>) => void) => {
    setNotification({
        message: msg,
        type: type,
        onClick: () => setNotification(undefined)
    })
}

export const getNotificationPadding = () => {
    const navbar: HTMLDivElement | null = document.querySelector(".dlf_navigationbar");
    const topbar = document.getElementById("dlf-topbar");
    return (navbar?.clientHeight || 0) + (topbar?.clientHeight || 0)

}

export const closeNotification=()=>{
    const closediv = document.getElementById("dlf_notification_close");
    closediv?.click();

}