import { FormField } from '../models/formModels'
import * as formconstant from '../constants/form-item-constants'
import CustomInput from '../components/shared/CustomInput/custom-input'
import CustomDropdown from '../components/shared/CustomDropdown/custom-dropdown'
import CustomTextArea from '../components/shared/CustomTextArea/custom-textarea'
import { DropdownItem } from '../models/dropdownItem'
import CustomCheckbox from '../components/shared/CustomCheckbox/custom-checkbox'
import CprInput from '../components/shared/CprInput/cpr-input'
import { cpr as cprregex } from '../constants/regex-expressions';
import CustomMultiselect from '../components/shared/CustomMultiselect/custom-multiselect'
import InputWrapper from '../components/shared/InputWrapper/input-wrapper'
export const getCompoent = (caption: string, formItem: FormField, value: any, func: any, required?: boolean, pattern?: { patternStr: string, errorMsg?: string }, setError?: (errorMsg: string) => void, disabled?: boolean, errorMsg?: string, label?: string) => {

    if (label && !disabled && label.toLowerCase().startsWith("cpr")) {
        return <InputWrapper
            component={CprInput}
            title={caption}
            setInput={(input: string) => func(formItem.alias, input)}
            required={required}
            pattern={{ patternStr: cprregex, errorMsg: "Forkert CPR-format." }}
            value={value || ""}
            setError={setError}
            errorMsg={errorMsg}
            moduluscheck={{ errorMsg: "Forkert CPR nummer." }}
            name="cpr"
        />
    }

    if (formItem.type === formconstant.text)
        return (<InputWrapper
            name={formItem.alias}
            component={CustomInput}
            title={caption}
            errorMsg={errorMsg}
            disabled={disabled}
            pattern={pattern ? { patternStr: pattern.patternStr, errorMsg: pattern.errorMsg } : undefined}
            setError={setError}
            required={required || false}
            value={value || ""}
            setInput={(input: string) => func(formItem.alias, input)} />)

    else if (formItem.type === formconstant.select) {
        const dropdowndata: DropdownItem[] = formItem.preValues?.map((pv) => { return { id: pv, text: pv } as DropdownItem }) || [];
        if (formItem.settings?.allowMultipleSelections.toLowerCase() === "true") {
            return (
                <InputWrapper
                    component={CustomMultiselect}
                    name={formItem.alias}
                    title={caption}
                    errorMsg={errorMsg}
                    required={required || false}
                    setError={setError}
                    defaultSelectedIds={value.split(", ")}
                    data={dropdowndata}
                    showSearch={false}
                    setValues={(input: DropdownItem[]) => dropdownSetValues(formItem.alias, input.map(x => x.id).join(", "), func)} />)
        }
        return (
            <InputWrapper
                component={CustomDropdown}
                name={formItem.alias}
                title={caption}
                errorMsg={errorMsg}
                required={required || false}
                setError={setError}
                defaultSelectedId={value}
                data={dropdowndata}
                showSearch={false}
                hasCreateButton={false}
                setValue={(input: DropdownItem) => dropdownSetValue(formItem.alias, input, func)} />)
    }
    else if (formItem.type === formconstant.checkbox) {
        return (
            <InputWrapper
                component={CustomCheckbox}
                name={formItem.alias}
                title={caption}
                errorMsg={errorMsg} label={label} required={required || false}
                setInput={(input?: boolean) => checkboxSetValue(formItem.alias, input, func)} checked={value === "true"} />)
    }
    else if (formItem.type === formconstant.textarea) {
        return (
            <InputWrapper
                name={formItem.alias}
                component={CustomTextArea}
                title={caption}
                errorMsg={errorMsg} required={required || false}
                setError={setError}
                value={value || ""}
                setInput={(input: string) => func(formItem.alias, input)} />)
    }
}

const dropdownSetValue = (alias: string, input: DropdownItem, func: any) => {
    return func(alias, input.id)
}
const dropdownSetValues = (alias: string, input: string, func: any) => {
    return func(alias, input)
}
const checkboxSetValue = (alias: string, input: boolean | undefined, func: any) => {
    var res = input || false;
    return func(alias, res.toString())
}

