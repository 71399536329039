
function Email() {
  return (
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.651 0H2.52158C1.85281 0 1.21144 0.265663 0.738551 0.738551C0.265663 1.21144 0 1.85281 0 2.52158V11.9775C0 12.6463 0.265663 13.2876 0.738551 13.7605C1.21144 14.2334 1.85281 14.4991 2.52158 14.4991H17.651C18.3198 14.4991 18.9612 14.2334 19.4341 13.7605C19.9069 13.2876 20.1726 12.6463 20.1726 11.9775V2.52158C20.1726 1.85281 19.9069 1.21144 19.4341 0.738551C18.9612 0.265663 18.3198 0 17.651 0ZM18.9118 11.9775C18.9118 12.3119 18.779 12.6326 18.5425 12.869C18.3061 13.1054 17.9854 13.2383 17.651 13.2383H2.52158C2.18719 13.2383 1.86651 13.1054 1.63006 12.869C1.39362 12.6326 1.26079 12.3119 1.26079 11.9775V2.52158C1.26079 2.18719 1.39362 1.86651 1.63006 1.63006C1.86651 1.39362 2.18719 1.26079 2.52158 1.26079H17.651C17.9854 1.26079 18.3061 1.39362 18.5425 1.63006C18.779 1.86651 18.9118 2.18719 18.9118 2.52158V11.9775Z" fill="#253154"/>
    <path d="M17.5062 3.38517C17.4537 3.32087 17.3891 3.26758 17.3159 3.22833C17.2428 3.18908 17.1627 3.16465 17.0801 3.15645C16.9975 3.14825 16.9141 3.15644 16.8347 3.18054C16.7553 3.20464 16.6814 3.24419 16.6173 3.29691L10.0864 8.64265L3.54925 3.29691C3.48637 3.23811 3.41211 3.19281 3.33106 3.1638C3.25 3.1348 3.16386 3.12271 3.07795 3.12827C2.99204 3.13383 2.90818 3.15693 2.83154 3.19614C2.7549 3.23535 2.6871 3.28985 2.63233 3.35627C2.57755 3.42269 2.53697 3.49962 2.51307 3.58233C2.48917 3.66503 2.48245 3.75176 2.49335 3.83716C2.50425 3.92255 2.53252 4.00481 2.57643 4.07886C2.62034 4.15291 2.67895 4.21718 2.74865 4.26771L9.68299 9.94126C9.7964 10.0352 9.93915 10.0865 10.0864 10.0863C10.2316 10.0851 10.3718 10.0339 10.4836 9.94126L17.4179 4.26771C17.5459 4.16189 17.627 4.00984 17.6435 3.84459C17.66 3.67934 17.6107 3.51424 17.5062 3.38517Z" fill="#253154"/>
    <path d="M14.9406 8.37765C14.8224 8.26023 14.6627 8.19434 14.4961 8.19434C14.3296 8.19434 14.1698 8.26023 14.0517 8.37765C13.9926 8.43625 13.9457 8.50597 13.9137 8.58279C13.8817 8.65961 13.8652 8.74201 13.8652 8.82523C13.8652 8.90845 13.8817 8.99084 13.9137 9.06766C13.9457 9.14448 13.9926 9.2142 14.0517 9.2728L16.5733 11.7944C16.6321 11.8527 16.7018 11.899 16.7784 11.9304C16.855 11.9618 16.9371 11.9778 17.0199 11.9775C17.1028 11.9773 17.1847 11.9607 17.2611 11.9287C17.3376 11.8967 17.4069 11.85 17.4653 11.7912C17.5237 11.7325 17.5699 11.6628 17.6013 11.5861C17.6327 11.5095 17.6487 11.4274 17.6485 11.3446C17.6482 11.2617 17.6316 11.1798 17.5996 11.1034C17.5676 11.027 17.5209 10.9576 17.4621 10.8992L14.9406 8.37765Z" fill="#253154"/>
    <path d="M5.25087 8.37765L2.72929 10.8992C2.61059 11.0171 2.54356 11.1773 2.54297 11.3446C2.54238 11.5119 2.60827 11.6725 2.72614 11.7912C2.84401 11.9099 3.00421 11.977 3.17149 11.9775C3.33878 11.9781 3.49945 11.9123 3.61815 11.7944L6.13973 9.2728C6.19881 9.2142 6.24571 9.14448 6.27771 9.06766C6.30972 8.99084 6.32619 8.90845 6.32619 8.82523C6.32619 8.74201 6.30972 8.65961 6.27771 8.58279C6.24571 8.50597 6.19881 8.43625 6.13973 8.37765C6.02162 8.26023 5.86184 8.19434 5.6953 8.19434C5.52876 8.19434 5.36898 8.26023 5.25087 8.37765Z" fill="#253154"/>
    </svg>
    
  )
}

export default Email;