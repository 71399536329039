/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { EventSignup } from '../models/EventSignup';
import type { SessionMember } from '../models/SessionMember';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * Description: Get a list of events.
 * Operation: GetEvents.
 * Area: Events.
     * @returns Event Success
     * @throws ApiError
     */
    public static async getEvents(): Promise<Array<Event>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/events`,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

    /**
     * Description: Find event by ID.
 * Operation: GetEventById.
 * Area: Events.
     * @param eventId ID of event that needs to be fetched.
     * @returns Event Success
     * @throws ApiError
     */
    public static async getEventById(
eventId: string,
): Promise<Event> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/events/${eventId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Cancel event.
 * Operation: CancelEvent.
 * Area: Events.
     * @param eventId ID of event to cancel.
     * @returns void 
     * @throws ApiError
     */
    public static async cancelEvent(
eventId: string,
): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/events/${eventId}/registrations/cancel`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Cancel session.
 * Operation: CancelSession.
 * Area: Events.
     * @param eventId EventID of session to cancel.
     * @param sessionId Id of session to cancel.
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static async cancelSession(
eventId: string,
sessionId: string,
requestBody: SessionMember,
): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/events/${eventId}/sessions/${sessionId}/cancel`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Signup session.
 * Operation: SignupSession.
 * Area: Events.
     * @param eventId Event Id of the session.
     * @param sessionId ID of session to register.
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static async signupSession(
eventId: string,
sessionId: string,
requestBody: SessionMember,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/events/${eventId}/sessions/${sessionId}/registration`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Signup to event given by ID.
 * Operation: EventSignUp.
 * Area: Events.
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static async eventSignUp(
requestBody: EventSignup,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/events/signup`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}