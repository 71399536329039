export const situationtypeId="situationtypeId";
export const startdate="startdate";
export const enddate="enddate";
export const changeDistrict="changeDistrict";
export const workLocationId= "workLocationId";
export const unknownWorkLocation="unknownWorkLocation";
export const jobTypeId="jobTypeId";
export const jobTitleId="jobTitleId";
export const educationInstitutionId="educationInstitutionId";
export const educationOrientationId="educationOrientationId";
export const educationMainSubjectIds="educationMainSubjectIds";
export const educationExpecedGraduationDate="expectedGraduationDate";