import { EventSignup } from '../api';


export const defaultEventSignUpModel: EventSignup = {
    consent: true,
    eventConsent:undefined,
    memberId: undefined,
    eventId: "",
    registrationResponses: [],
    pricePackageId:undefined,
    atendeeType:undefined,
    contact:{
        lastname:"",
        alphabet:"",
        city:"",
        co:"",
        firstname: "",
        initials: "",
        street: "",
        countryId: undefined,
        county: "",
        door: "",
        floor: "",
        municipalityId: undefined,
        number: "",
        zip: "",
        mail: "",
        workmail: "",
        mobilephone: "",
        privatephone: "",
        workphone: "",
        notes: ""
    }
}