import React, { useEffect, useMemo } from 'react';
import * as api from '../../api';
import { getNewErrorList } from '../../common/getNewErrorList';
import { fetchErrorMsg, requiredErrorMsg } from '../../constants/common-constants';
import * as createInsuranceConstants from "../../constants/create-insurance-constants";
import { defaultCreateFgInsuranceModel } from '../../models/createInsuranceModel';
import { FormSteps, FormError } from '../../models/step';
import { InsuranceFormElement } from '../../models/umbracoElement';
import Confirmation from '../shared/Confirmation/confirmation';
import ConsentForm from '../shared/ConsentForm/consent-form';
import CreateInsuranceContact from './steps/InsuranceContact/insurance-contact';
import CreateInsuranceHealth from './steps/InsuranceHealth/insurance-health';
import InsuranceStatus from './InsuranceStatus/insurance-status';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import TopTitle from '../shared/TopTitle/top-title';
import Wizard from '../shared/Wizard/wizard';
import { getInsuranceState, InsuranceState, isInsuranceActive } from './insurance-state';
import Wrapper, { NotificationMessage } from '../shared/Wrapper/wrapper';
import CustomButton from '../shared/Buttons/CustomButton/custom-button';

interface InsuranceFormProps {
    data: any;
    memberid: string;
}

function InsuranceForm(props: InsuranceFormProps) {
    const formElm: InsuranceFormElement = JSON.parse(props.data);
    const coinsuredPrefix = "co-";
    const defaultContactRequired = [createInsuranceConstants.email, createInsuranceConstants.name];
    const coInsuredRequired = [`${coinsuredPrefix}${createInsuranceConstants.cpr}`, `${coinsuredPrefix}${createInsuranceConstants.name}`, `${coinsuredPrefix}${createInsuranceConstants.email}`]
    const [createInsuranceModel, setCreateInsuranceModel] = React.useState(defaultCreateFgInsuranceModel);
    const [coInsurance, setcoInsurance] = React.useState<api.Insurance>();
    const [loading, setLoading] = React.useState(false);
    const [errorList, setErrorList] = React.useState<FormError[]>([])
    const [notification, setNotification] = React.useState<NotificationMessage | undefined>();
    const [isCoInsuredOpen, setCoInsuredOpen] = React.useState(false);
    const [contactRequired, setContactRequired] = React.useState<string[]>(defaultContactRequired);
    const [mainInsurance, setMainInsurance] = React.useState<api.Insurance>();
    const [insurances, setInsurances] = React.useState<api.Insurance[]>();
    const [member, setMember] = React.useState<api.Member>();
    const [healthBaseLinks, setHealthBaseLinks] = React.useState<api.HealthInformationLinks>();
    const [showWizard, setShowWizard] = React.useState(false);
    const [showErrorNotification, setShowErrorNotification] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            try {
                const member: api.Member = await api.MembersService.getMemberById(props.memberid);
                setMember(member);
                const baseLinks: api.HealthInformationLinks = await api.InsuranceService.getHealthInformationLink(props.memberid);
                setHealthBaseLinks(baseLinks);
                const insurances: api.Insurance[] = await api.InsuranceService.getInsurancePolicies();

                const newModel = defaultCreateFgInsuranceModel;
                newModel.mainInsured!.cpr = member.cpr;
                newModel.mainInsured!.email = member.mail;
                newModel.mainInsured!.name = member.firstname + " " + member.lastname;
                newModel.mainInsured!.memberId = member.memberId!;
                if (insurances.length > 0) {
                    insurances.map((i) => {
                        if (i.statusCode !== null) {
                            i.statusReason = formElm.insuranceStatusCodes.find((sc) => sc.statusCode === i.statusCode!)?.description

                        }
                        return i;
                    });
                    var memberInsurances = insurances.filter(x => !x.insuranceType?.startsWith("2"));
                    setInsurances(memberInsurances);
                    setMainInsurance(memberInsurances.length > 0 ? memberInsurances[0] : undefined);

                    const coinsured = insurances.find(x => x.insuranceType?.startsWith("2") && getInsuranceState(x.status!) !== InsuranceState.NoInsurance);
                    if (!!coinsured) {
                        setcoInsurance(coinsured);
                    }
                    else {
                        setCoInsuredOpen(true && memberInsurances.length > 0);
                        newModel.onlyCoInsured = true;
                        newModel.coInsured = {
                            cpr: "",
                            name: ""
                        }
                    }
                }

                setShowWizard(insurances.filter(x => !x.insuranceType?.startsWith("2")).length === 0);

                setCreateInsuranceModel(newModel);
            } catch (error) {
                setNotification({ message: fetchErrorMsg, type: NotificationBarType.Danger, onClick: () => setNotification(undefined) });
            }
        }
        call().finally(() => { setLoading(false) });
    }, [props.memberid]);


    const setError = (name: string, errorMessage: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMessage);
        setErrorList(newErrorlist);
    }
    const setCoInsured = (open: boolean) => {
        setCoInsuredOpen(open);
        if (!open) {
            let newModel = { ...createInsuranceModel };
            newModel.coInsured = undefined;
            setCreateInsuranceModel(newModel);
            setError(coinsuredPrefix + createInsuranceConstants.name, requiredErrorMsg);
            setError(coinsuredPrefix + createInsuranceConstants.cpr, requiredErrorMsg);
        }
    }

    const checkRequiredData = (required: string[]) => {
        const newErrorlist: FormError[] = [];
        required.forEach((r) => {
            let addTolist = false;
            if (r === createInsuranceConstants.consent) {
                addTolist = createInsuranceModel.consent === false;
            }
            else if (r.startsWith(coinsuredPrefix)) {
                if ( !(errorList.filter(x => x.name === r).length === 0 && !!(createInsuranceModel.coInsured as any)[r.replace(coinsuredPrefix, "")])) {
                    addTolist = true;
                } 
            }
            else {
                if (!(createInsuranceModel.mainInsured as any)[r]) {
                    addTolist = true;
                }
            }

            if (addTolist) {
                const errormsg = errorList.filter(x => x.name === r).length === 0 ? requiredErrorMsg : errorList.filter(x => x.name === r)[0].errorMsg;
                newErrorlist.push({ name: r, errorMsg: errormsg});
            }
        });
        setErrorList(newErrorlist);
        return newErrorlist.length === 0;
    }
    React.useEffect(() => {
        let newModel = { ...createInsuranceModel };
        if (isCoInsuredOpen) {
            newModel.coInsured = {
                cpr: "",
                name: ""
            }
            if (shouldShowOnlyCoInsured(coInsurance, mainInsurance)) {
                //if user has already cretaed insurance, only coinsurance fields are needed
                newModel.onlyCoInsured = true;
                setContactRequired([...coInsuredRequired])
            } else {
                setContactRequired([...defaultContactRequired, ...coInsuredRequired]);
            }
        } else {
            newModel.coInsured = undefined;
            setContactRequired(defaultContactRequired)
        }
        setCreateInsuranceModel(newModel);
    }, [isCoInsuredOpen, coInsurance, mainInsurance])

    const setModel = (name: string, value: string | boolean | object | undefined) => {
        let newModel = { ...createInsuranceModel };
        setShowErrorNotification(false);
        if (name === createInsuranceConstants.consent) {
            const answer = value as boolean;
            if (!answer)
                setError(createInsuranceConstants.consent, requiredErrorMsg);
            newModel.consent = answer;
        }
        else {
            (newModel.mainInsured as any)[name] = value;
        }
        setCreateInsuranceModel(newModel);
    }
    const setCoInsuredModel = (name: string, value: string | boolean | object | undefined) => {
        let newCoinsuredModel = { ...createInsuranceModel.coInsured };

        setShowErrorNotification(false);

        (newCoinsuredModel as any)[name] = value;
        let newModel = { ...createInsuranceModel };
        newModel.coInsured = newCoinsuredModel;
        setCreateInsuranceModel(newModel);
    }

    const submitForm = async () => {
        const newmodel = { ...createInsuranceModel };
        //CRM doesn't want "-" in cprnumber. Removing "-" before sending.
        newmodel.mainInsured!.cpr = newmodel.mainInsured!.cpr!.replace("-", "");
        if (newmodel.coInsured)
            newmodel.coInsured!.cpr = newmodel.coInsured!.cpr!.replace("-", "");
        await api.InsuranceService.createInsurancePolicy(newmodel);
    }
    const close = () => {
        window.location.reload();
    }

    const shouldShowOnlyCoInsured = (coInsurance: api.Insurance | undefined, mainInsurance: api.Insurance | undefined) => {
        const co = (!coInsurance || getInsuranceState(coInsurance.status!) === InsuranceState.NoInsurance)
        const main = (mainInsurance && getInsuranceState(mainInsurance.status!) !== InsuranceState.NoInsurance);
        return (co && main) || false;
    }

    const showOnlyCoInsured = useMemo(() => shouldShowOnlyCoInsured(coInsurance, mainInsurance), [coInsurance, mainInsurance])

    const formsteps: FormSteps = {
        title: showOnlyCoInsured ? formElm.coInsuredTitle : (formElm.createInsuranceTitle || "Opret forsikring"),
        helptext: (showOnlyCoInsured ? formElm.coInsuredHelpText : formElm.helpText) || "",
        previousLabel: formElm.previousButtonLabel || "Forrige trin",
        nextLabel: formElm.nextButtonLabel || "Næste trin",
        submitString: formElm.signupButtonLabel || "Bekræft og indsend",
        lastStepNo: 3,
        steps: [{
            stepNo: 1,
            required: contactRequired,
            formdata: <CreateInsuranceContact
                showOnlyCoInsured={showOnlyCoInsured}
                coInsuredOpen={showOnlyCoInsured || isCoInsuredOpen}
                setCoInsuredOpen={setCoInsured}
                model={createInsuranceModel}
                setError={setError}
                errorList={errorList}
                handleChange={setModel}
                handleCoInsuredChange={setCoInsuredModel} />
        }, {
            stepNo: 2,
            required: [createInsuranceConstants.consent],
            progressable: createInsuranceModel.consent,
            formdata: <ConsentForm
                consentlist={formElm.consentList}
                value={createInsuranceModel.consent}
                consentText={formElm.consentText}
                handleChange={(key, val) => { setModel(createInsuranceConstants.consent, val) }}></ConsentForm>

        }, {
            stepNo: 3,
            required: [],
            formdata: <CreateInsuranceHealth
                coInsuredHealthLinkText={formElm.coInsuredHealthLinkText}
                healthLinkText={formElm.healthLinkText}
                healthBaseLinks={healthBaseLinks}
                onlyCoInsured={showOnlyCoInsured}
                insuranceModel={createInsuranceModel}
                member={member}
                contentHtml={formElm.healthInformationText}
            />
        }
        ],
        confirmationPage:
            <Confirmation
                buttonLabel={"Luk"}
                title="Tak for din tilmelding"
                close={close}
                confirmationText={formElm.confirmationText}
                contentElement={<div></div>} />,
    }

    const canAddNewInsurances = () => {
        /** 
         * show wizard if following matches
         * - maininsurance or coInsurance is undefined
         * - maininsurace is rejected 
         * - co-insurance is reject
         * - or all insurances are cancelled
         */
        return (!mainInsurance || (!coInsurance && isInsuranceActive(mainInsurance.status!))) ||
            (coInsurance && coInsurance.status === 'Afvist') ||
            (mainInsurance.status === 'Afvist') ||
            (insurances?.length === insurances?.filter((i) => i.status !== "Tilmelder" && i.status !== "Afmelder" && i.status !== "Tilmeldt" && i.status !== "Afvist").length)
    }

    const onAddInsurance = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setShowWizard(true)
    }

    return (
        <>
            {showWizard && canAddNewInsurances()
                ?
                <Wizard
                    setShowErrorNotification={setShowErrorNotification}
                    showErrorNotification={showErrorNotification}
                    onCancelClick={formElm.showBackBar && (insurances && insurances.length > 0) ? () => setShowWizard(false) : undefined}
                    cancelLabel={formElm.cancelButtonLabel}
                    checkRequiredData={checkRequiredData}
                    confirmed={createInsuranceModel.consent || false}
                    submit={submitForm}
                    navigationSettings={formElm}
                    errorList={errorList}
                    data={formsteps} />
                :
                <Wrapper
                    loading={loading}
                    properties={formElm}
                    notification={notification}>
                    <div>
                        {!showWizard && insurances && <>
                            <InsuranceStatus
                                insurances={insurances} formElm={formElm} />
                            {coInsurance &&
                                <>
                                    <TopTitle titletext={formElm.myCoinsurancesOverviewTitle} />
                                <InsuranceStatus
                                    insurances={[coInsurance]} formElm={formElm} hideButton={(mainInsurance?.status === "Afmelder" || mainInsurance?.status === "Afmeldt") && coInsurance.status === "Afmelder"} />
                                </>
                            }

                            {canAddNewInsurances() && <CustomButton label={showOnlyCoInsured ? formElm.coInsuredTitle : (formElm.createInsuranceTitle || "Opret forsikring")} onClick={onAddInsurance}></CustomButton>}
                        </>
                        }
                    </div >
                </Wrapper>
            }
        </>
    )
}
export default InsuranceForm;