// /* type constants*/

export const text = "text";
export const select = "select";
export const checkbox = "checkbox";
export const textarea = "textarea";
export const multiselect = "multiselect";


/* selection yes or no*/

export const yes= "JA";
export const no = "NEJ";



export enum FormConditionRuleOperatorType {
  Is = 'IS',
  IsNot = 'IS_NOT',
  GreaterThen = 'GREATER_THEN',
  LessThen = 'LESS_THEN',
  Contains = 'CONTAINS',
  StartsWith = 'STARTS_WITH',
  EndsWith = 'ENDS_WITH',
}


export enum FormConditionLogicType {
  All = 'ALL',
  Any = 'ANY',
}

export enum FormConditionActionType {
  Show = 'SHOW',
  Hide = 'HIDE',
}



export enum FormFieldIndicationType {
  NoIndicator = 'NO_INDICATOR',
  MarkMandatoryFields = 'MARK_MANDATORY_FIELDS',
  MarkOptionalFields = 'MARK_OPTIONAL_FIELDS',
}

