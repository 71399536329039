import React, { useEffect } from "react";
import { MemberSignup } from "../../../api";
import { getErrorMsgByName } from "../../../common/getErrorMsgByName";
import CustomDatePicker from '../../shared/CustomDatePicker/custom-date-picker';
import CustomDropdown from "../../shared/CustomDropdown/custom-dropdown";
import InputWrapper from "../../shared/InputWrapper/input-wrapper";
import * as commonconstants from '../../../constants/common-constants';
import { newid } from '../../../constants/common-constants';
import * as signupConstants from "../../../constants/signup-constants";
import { DropdownItem } from '../../../models/dropdownItem';
import { StepFormData } from "../../../models/step";
import style from './signup-third-step.module.scss';
import { SignupElement } from "../../../models/umbracoElement";

interface SignupThirdStepProps extends StepFormData {
    chosenWork?: DropdownItem,
    workplaces: DropdownItem[],
    jobtypes: DropdownItem[],
    jobtitles: DropdownItem[],
    situationTypes: DropdownItem[],
    loading: boolean;
    setIsStudent: (isStudent: boolean) => void,
    isStudent?: boolean;
    signUpModel: MemberSignup;
    addedWorkplace?:string;
    setAddedWorkplace:(workplace?:string)=>void;
    elementdata:SignupElement
}
function SignupThirdStep(props: SignupThirdStepProps) {

    const [shouldHideOccupation, setHideOccupation] = React.useState(false);
    const [filteredJobtitles, setFilteredJobTitles] = React.useState(props.jobtitles);    
    const onDateChange = (ev: any) => {
        props.handleChange(signupConstants.startdate, ev)
    }

    useEffect(() => {
        const occupationsToShow = [commonconstants.inJobEnumNo];
        const currentsituation = props.situationTypes.find(x => x.id === props.signUpModel.situationtypeId);
        const deactivate = currentsituation ? !occupationsToShow.includes(currentsituation.originalData!.situationTypeNo) : false;
        const isStudent = currentsituation?.originalData!.situationTypeNo === commonconstants.studentEnumNo;
        setHideOccupation(deactivate);
        props.setIsStudent(isStudent);
    }, [props.signUpModel.situationtypeId, props])

 

    useEffect(() => {
        if (!props.signUpModel.workLocationId || (props.signUpModel.workLocationId && props.signUpModel.workLocationId !== newid)) {
            props.setAddedWorkplace(undefined)
        }
    }, [props.signUpModel.workLocationId]);

    const onJobSituationChange = (item: DropdownItem) => {
        props.handleChange(signupConstants.situationtypeId, item.id?.toString())
    }

    const handleWorkplace = (item: DropdownItem) => {
    
            props.handleChange(signupConstants.workLocationId, item.id?.toString())

    }

    const getErrorMsg = (name: string) => {
        return getErrorMsgByName(props.errorList, name);

    }

    const onJobTypeChange = (item: DropdownItem) => {
        if (item.id) {
            const filtered = props.jobtitles.filter(x => x.originalData?.jobType.jobTypeId === item.id);
            if (props.signUpModel.jobTitleId) {
                if (!filtered.some(x => x.id === props.signUpModel.jobTitleId)) {
                    props.handleChange(signupConstants.jobTitleId, undefined);
                }
            }
            setFilteredJobTitles(filtered);
        } else {
            setFilteredJobTitles(props.jobtitles)
        }
        props.handleChange(signupConstants.jobTypeId, item.id?.toString())
    }
    return (
        <div className={style.step}>

            <div className={style.stepTitle}>Beskæftigelse</div>
            <InputWrapper
                component={CustomDropdown}
                title={"Vælg beskæftigelse"}
                setFocus={true}
                name={signupConstants.situationtypeId}
                data={props.situationTypes}
                hasCreateButton={false}
                showSearch={false}
                required={true}
                errorMsg={getErrorMsg(signupConstants.situationtypeId)}
                setValue={onJobSituationChange}
                setError={(errorMsg: string) => props.setError(signupConstants.situationtypeId, errorMsg)}
                defaultSelectedId={props.signUpModel.situationtypeId || ""}
            />
            {!shouldHideOccupation && props.signUpModel.situationtypeId &&
                <InputWrapper
                    component={CustomDropdown}
                    title={"Arbejdsplads"}
                    data={props.workplaces}
                    name={signupConstants.workLocationId}
                    hasCreateButton={true}
                    showSearch={true}
                    required={true}
                    errorMsg={getErrorMsg(signupConstants.workLocationId)}
                    setError={(errorMsg: string) => props.setError(signupConstants.workLocationId, errorMsg)}
                    defaultSelectedId={props.signUpModel.workLocationId || ""}
                    setValue={handleWorkplace}
                    setNewItem={props.setAddedWorkplace}
                    newItem={props.addedWorkplace}
                    notFoundInListLabel={props.elementdata.workPlaceNotListedLabel}
                    addNewDescription={props.elementdata.addNewWorkPlaceDescription}
                    addNewTitle={props.elementdata.addNewWorkPlaceTitle}
                    
                    />
            }

            {!props.isStudent && props.signUpModel.situationtypeId &&
                <InputWrapper
                    component={CustomDatePicker}
                    name={signupConstants.startdate}
                    title={"Start dato"}
                    description={!shouldHideOccupation ? "Ansættelsesdato" : ""}
                    required={true}
                    selectedDate={props.signUpModel.startdate || undefined}
                    errorMsg={getErrorMsgByName(props.errorList, signupConstants.startdate)}
                    setError={(errorMsg: string) => props.setError(signupConstants.startdate, errorMsg)}
                    onDateChange={onDateChange} />
            }
            {!shouldHideOccupation && props.signUpModel.situationtypeId && <>
                <InputWrapper
                    component={CustomDropdown}
                    name={signupConstants.jobTypeId}
                    title={"Stillingstype"}
                    data={props.jobtypes}
                    hasCreateButton={false}
                    required={true}
                    showSearch={true}
                    defaultSelectedId={props.signUpModel.jobTypeId || ""}
                    errorMsg={getErrorMsgByName(props.errorList, signupConstants.jobTypeId)}
                    setError={(errorMsg: string) => props.setError(signupConstants.jobTypeId, errorMsg)}
                    setValue={onJobTypeChange} />
                <InputWrapper
                    component={CustomDropdown}
                    title={"Stillingsbetegnelse"}
                    name={signupConstants.jobTitleId}
                    data={filteredJobtitles}
                    hasCreateButton={false}
                    required={true}
                    showSearch={true}
                    errorMsg={getErrorMsgByName(props.errorList, signupConstants.jobTitleId)}
                    setError={(errorMsg: string) => props.setError(signupConstants.jobTitleId, errorMsg)}
                    defaultSelectedId={props.signUpModel.jobTitleId || ""}
                    setValue={(selected: DropdownItem) => props.handleChange(signupConstants.jobTitleId, selected.id?.toString())}/>
            </>}
        </div >
    )
}

export default SignupThirdStep;