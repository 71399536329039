import React, { useEffect } from 'react';
import * as api from '../../../api';
import { dateCompare } from '../../../common/dateutil';
import { getErrorMsgByName } from '../../../common/getErrorMsgByName';
import { getNewErrorList } from '../../../common/getNewErrorList';
import { closeNotification, setNotificationMsg } from '../../../common/notificationHelper';
import * as commonconstants from '../../../constants/common-constants';
import { fetchErrorMsg } from '../../../constants/common-constants';
import * as constants from '../../../constants/new-situation-constants';
import { defaultCreateSituationModel } from '../../../models/createSituationModel';
import { DropdownItem } from '../../../models/dropdownItem';
import { FormError } from '../../../models/step';
import { MySituationElement } from '../../../models/umbracoElement';
import FlowButton from '../../shared/Buttons/FlowButton/flow-button';
import ButtonZone from '../../shared/ButtonZone/button-zone';
import CustomDatePicker from "../../shared/CustomDatePicker/custom-date-picker";
import CustomDropdown from '../../shared/CustomDropdown/custom-dropdown';
import CustomMultiselect from '../../shared/CustomMultiselect/custom-multiselect';
import InformationBox from '../../shared/InformationBox/information-box';
import InputWrapper from '../../shared/InputWrapper/input-wrapper';
import { NotificationBarType } from '../../shared/NotificationBarSetting/notification-bar-setting';
import OnePageFormWrapper from '../../shared/OnePageFormWrapper/one-page-form-wrapper';
import Wrapper, { NotificationMessage } from '../../shared/Wrapper/wrapper';
import style from './new-situations.module.scss';
interface NewSituationProps {
    memberid: string;
    elementdata: MySituationElement;
    situations: api.Situation[];
    setIsOpen: (isOpen: boolean, reload: boolean) => void;
    district?: string;
}

function NewSituation(props: NewSituationProps) {
    const [isStudentChosen, setIsStudentChosen] = React.useState<boolean>(false);
    const [newSituation, setNewSituation] = React.useState<api.NewSituation>(defaultCreateSituationModel);
    const [addedWorkplace, setAddedWorkplace] = React.useState<string>()
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [institutions, setInstitution] = React.useState<DropdownItem[]>([]);
    const [mainSubjects, setMainSubjects] = React.useState<DropdownItem[]>([]);
    const [orientations, setOrientations] = React.useState<DropdownItem[]>([]);
    const [jobtitles, setJobtitles] = React.useState<DropdownItem[]>([]);
    const [situationtypes, setSituationtypes] = React.useState<DropdownItem[]>([]);
    const [jobtypes, setJobtypes] = React.useState<DropdownItem[]>([]);
    const [workplaces, setWorkplaces] = React.useState<DropdownItem[]>([]);
    const [filteredJobTitles, setFilteredJobTitles] = React.useState<DropdownItem[]>(jobtitles);
    const [showDistrictChangeMessage, setShowDistrictChangeMessage] = React.useState<boolean>(false);

    const [notification, setNotification] = React.useState<NotificationMessage | undefined>();
    const commonRequired = [constants.situationtypeId, constants.startdate];
    const workRequired = [...commonRequired, constants.workLocationId, constants.jobTypeId, constants.jobTitleId];
    const educationRequired = [...commonRequired, constants.educationInstitutionId, constants.educationOrientationId, constants.educationExpecedGraduationDate];

    const onJobTypeChange = (item: DropdownItem) => {

        if (item.id) {
            const filtered = jobtitles.filter(x => x.originalData?.jobType.jobTypeId === item.id);
            if (newSituation.jobTitleId) {
                if (!filtered.some(x => x.id === newSituation.jobTitleId)) {
                    handleChange(constants.jobTitleId, undefined);
                }
            }
            setFilteredJobTitles(filtered);
        } else {
            setFilteredJobTitles(jobtitles)
        }
        handleChange(constants.jobTypeId, item.id?.toString())
    }

    const handleChange = (name: string, value: any) => {
        setErrorNotification("")
        let newSituationModel = { ...newSituation };
        if (name === constants.situationtypeId) {
            setShowDistrictChangeMessage(false)
            const situationType = (situationtypes.find(x => x.id === value)?.originalData as api.SituationType);
            if (situationType)
                setIsStudentChosen(situationType.situationTypeNo === commonconstants.studentEnumNo)
        }

        (newSituationModel as any)[name] = value;
        setNewSituation(newSituationModel);
    }

    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);

    }

    const checkRequiredData = (required: string[]) => {
        const newErrorList: FormError[] = [];
        required.forEach((r) => {
            if (!(newSituation as any)[r]) {
                newErrorList.push({ name: r, errorMsg: commonconstants.requiredErrorMsg })
            }
        });
        if (newSituation.workLocationId === commonconstants.newid) {
            if (!addedWorkplace) {
                newErrorList.push({ name: constants.unknownWorkLocation, errorMsg: commonconstants.requiredErrorMsg });
            }
        }
        setErrorList(newErrorList);
        setErrorNotification(newErrorList.length === 0 ? "" : commonconstants.fixBeforeContinue);

        return newErrorList.length === 0;
    }

    const setErrorNotification = (message: string) => {

        setNotificationMsg(message, NotificationBarType.Danger, setNotification);

    }

    useEffect(() => {
        handleChange(constants.unknownWorkLocation, addedWorkplace)
    }, [addedWorkplace])

    useEffect(() => {
        if (!newSituation.workLocationId || (newSituation.workLocationId && newSituation.workLocationId !== commonconstants.newid)) {
            setAddedWorkplace(undefined)
        }
    }, [newSituation.workLocationId])

    const handleWorkplace = (item: DropdownItem) => {
        const chosenWorklocationDistrict = (item.originalData as api.WorkLocation)?.district?.districtId;

        const isUnknownWorkLocation = item.id === commonconstants.newid;
        setShowDistrictChangeMessage(isUnknownWorkLocation || (!!chosenWorklocationDistrict && chosenWorklocationDistrict !== props.district));

        handleChange(constants.workLocationId, item.id?.toString())

    }
    const close = (reload: boolean) => {
        setNewSituation(defaultCreateSituationModel)
        props.setIsOpen(false, reload);
    }

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            try {
                const situationtyperes: api.SituationType[] = await api.SituationtypesService.getSituationTypes();
                //only studerende or ansat can be chosen. other types should be changed in changesituation component

                const newSituationTypes = situationtyperes.filter(s => s.situationTypeNo === commonconstants.studentEnumNo
                    || s.situationTypeNo === commonconstants.inJobEnumNo).map((x) => { return { id: x.situationTypeId!, text: x.name!, originalData: x } });
                setSituationtypes(newSituationTypes);
                /*populate dropdown that can be changed only students*/
                const educationalInstitutions: api.EducationInstitution[] = await api.EducationinstitutionService.getEducationInstitution();
                const newEducationalInstitutions = educationalInstitutions.map((x) => { return { id: x.educationInsitutionId!, text: x.displayName!, originalData: x } });
                setInstitution(newEducationalInstitutions)

                const mainsubjectres: api.EducationMainSubject[] = await api.EducationmainsubjectsService.getEducationMainSubjects();
                const newMainsubjects = mainsubjectres.map((x) => { return { id: x.educationMainSubjectId!, text: x.name!, originalData: x } });
                setMainSubjects(newMainsubjects);

                const educationalOrientations: api.EducationOrientation[] = await api.EducationorientationService.getEducationOrientation();
                const newEducationalOrientations = educationalOrientations.map((x) => { return { id: x.educationOrientationId!, text: x.name!, originalData: x } });
                setOrientations(newEducationalOrientations)

                const jobtitleres: api.JobTitle[] = await api.JobtitlesService.getJobTitles();
                const newjobtitles = jobtitleres.map((x) => { return { id: x.jobTitleId!, text: x.name!, originalData: x } });
                setJobtitles(newjobtitles);

                const jobtypes: api.JobType[] = await api.JobtypesService.getJobTypes();
                const newJobTypes = jobtypes.map((x) => { return { id: x.jobTypeId!, text: x.name!, originalData: x } });
                setJobtypes(newJobTypes);

                const workplaces: api.WorkLocation[] = await api.WorklocationsService.getWorkLocations();
                const newworkplaces = workplaces.map((x) => {
                    return {
                        id: x.workLocationId!,
                        text: `${x.name!}, ${x.address?.street ? x.address.street + ' ' + (x.address.number || '') + ',' : ''} ${x.address?.zip || ""} ${x.address?.city || ""}`,
                        originalData: x
                    }
                });
                setWorkplaces(newworkplaces)
            } catch (error) {
                setErrorNotification(fetchErrorMsg);
                console.log(error)
            }
        }
        call().finally(() => { setLoading(false) });
    }, []);

    useEffect(() => {
        const situation = { ...newSituation };

        if (isStudentChosen) {
            situation.jobTitleId = undefined;
            situation.workLocationId = undefined;
            situation.jobTypeId = undefined;

        } else {
            situation.educationInstitutionId = undefined;
            situation.educationMainSubjectIds = [];
            situation.educationOrientationId = undefined;
            situation.expectedGraduationDate = undefined;

        }
        situation.startdate = undefined;

        setNewSituation(situation)
    }, [isStudentChosen]);
    const getErrorMsg = (name: string) => getErrorMsgByName([...errorList], name);
    const createSituation = () => {
        if (checkRequiredData(isStudentChosen ? educationRequired : workRequired)) {
            setSubmitting(true)
            api.MembersService.membersSituation(props.memberid, newSituation)
                .then(() => { close(true) })
                .catch((error) => { setErrorNotification(commonconstants.postErrorMsg); console.log(error) })
                .finally(() => setSubmitting(false))
        }
    }
    const getMinFrom = React.useCallback(() => {
        const lastEnd = props.situations.filter(x => !!x.enddate).map(x => x.enddate).sort(dateCompare).reverse();
        console.log(lastEnd);

        if (lastEnd && lastEnd.length > 0) {
            //don't overwrap start date with previeous situations.
            if (lastEnd[0])
                return new Date(lastEnd[0]);
        }
        //if it comes here, there is no situations that has enddate.
        //set minimum to startdate of current/future (= enddate is null) situation
        const activeSituationStart = props.situations.find(x => !x.enddate)?.startdate;
        return activeSituationStart ? new Date(activeSituationStart) : new Date();

    }, [props.situations])

    return (<>
        <Wrapper properties={{ id: "", contentTypeAlias: "", title: "" }}
            loading={loading}
            notification={notification}
            backBarSettings={props.elementdata.showBackBar ? { backLabel: props.elementdata.cancelButtonLabel || "Annuller", onBackClick: () => props.setIsOpen(false, false) } : undefined}
            bottomZone={<ButtonZone twoButtons={!props.elementdata.showBackBar} className={`${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]}`}>
                {!props.elementdata.showBackBar && !loading && <FlowButton isBack={true} onClick={() => {
                    closeNotification();
                    props.setIsOpen(false, false);

                }
                }>{"Annuller"}</FlowButton>}
                {!loading && <FlowButton className={style.onebutton} isSubmit={true} onClick={createSituation} loading={submitting} disabled={submitting}>
                    {props.elementdata.submitLabel || "Opret"}
                </FlowButton>}
            </ButtonZone>}
        >
            {!loading && <OnePageFormWrapper
                className={`${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]}`}
                hasButton={true}
                title={props.elementdata.createNewTitle || "Ny situation"}>


                {showDistrictChangeMessage &&
                    <div className={style.formInformation}>
                        <InformationBox title="" content={props.elementdata.onChangeDistrictText} />
                    </div>}
                <InputWrapper
                    component={CustomDropdown}
                    title={"Vælg situationtype"}
                    name={constants.situationtypeId}
                    data={situationtypes}
                    hasCreateButton={false}
                    showSearch={false}
                    required={true}
                    errorMsg={getErrorMsg(constants.situationtypeId)}
                    setValue={(item: DropdownItem) => handleChange(constants.situationtypeId, item.id)}
                    setError={(errorMsg: string) => setError(constants.situationtypeId, errorMsg)}
                    defaultSelectedId={newSituation.situationtypeId || ""}
                />
                {!isStudentChosen && newSituation.situationtypeId &&
                    <InputWrapper
                        component={CustomDropdown}
                        title={"Arbejdsplads"}
                        name={constants.workLocationId}
                        data={workplaces}
                        hasCreateButton={true}
                        showSearch={true}
                        required={true}
                        errorMsg={getErrorMsg(constants.workLocationId)}
                        setError={(errorMsg: string) => setError(constants.workLocationId, errorMsg)}
                        defaultSelectedId={newSituation.workLocationId || ""}
                        setValue={handleWorkplace}
                        setNewItem={setAddedWorkplace}
                        newItem={addedWorkplace}
                        notFoundInListLabel={props.elementdata.workPlaceNotListedLabel}
                        addNewDescription={props.elementdata.addNewWorkPlaceDescription}
                        addNewTitle={props.elementdata.addNewWorkPlaceTitle}
                    />
                }
                {isStudentChosen && newSituation.situationtypeId && <>
                    <InputWrapper
                        component={CustomDropdown}
                        title={"Uddannelsessted"}
                        name={constants.educationInstitutionId}
                        data={institutions}
                        hasCreateButton={false}
                        showSearch={true}
                        defaultSelectedId={newSituation.educationInstitutionId || undefined}
                        required={isStudentChosen}
                        errorMsg={getErrorMsg(constants.educationInstitutionId)}
                        setError={(errorMsg: string) => setError(constants.educationInstitutionId, errorMsg)}
                        setValue={(selected: DropdownItem) => handleChange(constants.educationInstitutionId, selected.id?.toString())}
                    />
                    <InputWrapper
                        component={CustomDropdown}
                        title={"Uddannelsesretning"}
                        name={constants.educationOrientationId}
                        data={orientations}
                        required={isStudentChosen}
                        hasCreateButton={false}
                        showSearch={false}
                        defaultSelectedId={newSituation.educationOrientationId || undefined}
                        errorMsg={getErrorMsg(constants.educationOrientationId)}
                        setError={(errorMsg: string) => setError(constants.educationOrientationId, errorMsg)}
                        setValue={(selected: DropdownItem) => handleChange(constants.educationOrientationId, selected.id?.toString())}
                    />
                    <InputWrapper
                        component={CustomMultiselect}
                        title={"Linjefag"}
                        name={constants.educationMainSubjectIds}
                        data={mainSubjects}
                        showSearch={false}
                        required={false} //main subjects are not required. as it is often that the new started student doesn't know the mainsubject yet
                        defaultSelectedIds={newSituation.educationMainSubjectIds || []}
                        setError={(errorMsg: string) => setError(constants.educationMainSubjectIds, errorMsg)}
                        errorMsg={getErrorMsg(constants.educationMainSubjectIds)}
                        setValues={(selected: DropdownItem[]) => handleChange(constants.educationMainSubjectIds, selected.map(x => x.id))}
                    />
                </>}
                {newSituation.situationtypeId && <>
                    <InputWrapper
                        component={CustomDatePicker}
                        title={"Start dato"}
                        description={!isStudentChosen && newSituation.situationtypeId ? "Ansættelsesdato" : undefined}
                        minDate={new Date(new Date().setMonth(new Date().getMonth() - Number(props.elementdata.dateRestrictionsPast||3)))}
                        maxDate={new Date(new Date().setMonth(new Date().getMonth() + Number(props.elementdata.dateRestrictionsFuture||3)))}
                        name={constants.startdate}
                        required={true}
                        selectedDate={newSituation.startdate || undefined}
                        errorMsg={getErrorMsg(constants.startdate)}
                        setError={(errorMsg: string) => setError(constants.startdate, errorMsg)}
                        onDateChange={(date: any) => handleChange(constants.startdate, date)}
                    />
                </>}
                {!isStudentChosen && newSituation.situationtypeId && <>
                    <InputWrapper
                        component={CustomDropdown}
                        title={"Stillingstype"}
                        data={jobtypes}
                        name={constants.jobTypeId}
                        hasCreateButton={false}
                        required={true}
                        showSearch={true}
                        defaultSelectedId={newSituation.jobTypeId || ""}
                        errorMsg={getErrorMsg(constants.jobTypeId)}
                        setError={(errorMsg: string) => setError(constants.jobTypeId, errorMsg)}
                        setValue={onJobTypeChange}
                    />
                    <InputWrapper
                        component={CustomDropdown}
                        title={"Stillingsbetegnelse"}
                        name={constants.jobTitleId}
                        data={filteredJobTitles}
                        hasCreateButton={false}
                        required={true}
                        showSearch={true}
                        errorMsg={getErrorMsg(constants.jobTitleId)}
                        setError={(errorMsg: string) => setError(constants.jobTitleId, errorMsg)}
                        defaultSelectedId={newSituation.jobTitleId || ""}
                        setValue={(selected: DropdownItem) => handleChange(constants.jobTitleId, selected.id?.toString())}
                    />
                </>}

                {isStudentChosen && newSituation.situationtypeId &&
                    <InputWrapper
                        component={CustomDatePicker}
                        title={"Forventet dimission"}
                        name={constants.educationExpecedGraduationDate}
                        onDateChange={(date: any) => handleChange(constants.educationExpecedGraduationDate, date)}
                        minDate={new Date()}
                        required={isStudentChosen}
                        selectedDate={newSituation.expectedGraduationDate || undefined}
                        errorMsg={getErrorMsg(constants.educationExpecedGraduationDate)}
                        setError={(errorMsg: string) => setError(constants.educationExpecedGraduationDate, errorMsg)} />
                }

            </OnePageFormWrapper>}
        </Wrapper>
    </>)

}
export default NewSituation;