/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PricePackage } from '../models/PricePackage';
import { request as __request } from '../core/request';

export class PricepackagesService {

    /**
     * Description: Get a list of pricepackages.
 * Operation: GetPricePackages.
 * Area: Pricepackages.
     * @returns PricePackage Success
     * @throws ApiError
     */
    public static async getPricePackages(): Promise<Array<PricePackage>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/pricepackages`,
        });
        return result.body;
    }

}