import style from "./list.module.scss";
import ListItem, { ListItemProps } from "./ListItem/list-item";

interface ListProps {
    title?: string,
    itemClassName?: string,
    listItems: ListItemProps[]
}
const List = (props: ListProps) => {
    return <>
        {props.title && <h4>{props.title}</h4>}
        <div className={`${style.row}`}>
            {props.listItems.map((item, i) =>
                <div className={props.itemClassName || style["col-12"]}
                    key={`listitem-${i}`}>
                    <ListItem {...item} />
                </div>)
            }
        </div>
    </>
}

export default List;