import React from "react";
import * as api from '../../../api';
import { getErrorMsgByName } from "../../../common/getErrorMsgByName";
import { callingCodes } from "../../../constants/country-calling-codes";
import CustomDropdown from "../../shared/CustomDropdown/custom-dropdown";
import CustomInput from "../../shared/CustomInput/custom-input";
import PhoneInput from "../../shared/PhoneInput/phone-input";
import InputWrapper from "../../shared/InputWrapper/input-wrapper";
import { defaultCountryCode, denmarkId, emailErrorMsg, fetchErrorMsg, greenlandId, characterValidationErrorMsg } from '../../../constants/common-constants';
import { email, characterValidation } from '../../../constants/regex-expressions';
import * as signupConstants from "../../../constants/signup-constants";
import { DropdownItem } from '../../../models/dropdownItem';
import { StepFormData } from "../../../models/step";
import style from './signup-first-step.module.scss';
import Loader from "../../shared/Loader/loader";

export interface SignupFirstStepProps extends StepFormData {
    signUpModel: api.MemberSignup;
    countries: DropdownItem[];
    municipalities: DropdownItem[];
    loading: boolean;
}

function SignupFirstStep(props: SignupFirstStepProps) {
    const countryCode: DropdownItem[] = callingCodes.map<DropdownItem>(c => { return { id: c.country, text: c.code } })

    const [defaultCountryItem] = React.useState<DropdownItem | undefined>(props.countries.find(x => x.originalData.countryCode === defaultCountryCode));

    const getErrorMsg = (name: string) => {
        return getErrorMsgByName(props.errorList, name);
    }

    return (
        <div className={style.step}>
            <div className={style.stepTitle}>Dine informationer</div>
            {props.loading ? <div className={style.initialloader}>
                <Loader />
            </div> : <>
                <InputWrapper
                    title={"Cpr. Nummer"}
                    component={CustomInput}
                    required={true}
                    readonly={true}
                    name={signupConstants.cpr}
                    value={props.signUpModel.cpr || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                />
                <InputWrapper
                    title={"Fornavn"}
                    component={CustomInput}
                    required={true}
                    setFocus={true}
                    name={signupConstants.firstname}
                    setError={(errorMsg: string) => props.setError(signupConstants.firstname, errorMsg)}
                    setInput={(input: string) => props.handleChange(signupConstants.firstname, input)}
                    maxLength={50}
                    errorMsg={getErrorMsg(signupConstants.firstname)}
                    value={props.signUpModel.firstname}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                />
                <InputWrapper
                    title={"Efternavn"}
                    component={CustomInput}
                    required={true}
                    maxLength={50}
                    name={signupConstants.lastname}
                    value={props.signUpModel.lastname}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    setInput={(input: string) => props.handleChange(signupConstants.lastname, input)}
                    setError={(errorMsg: string) => props.setError(signupConstants.lastname, errorMsg)}
                    errorMsg={getErrorMsg(signupConstants.lastname)} />

                <InputWrapper
                    title={"Adresse"}
                    component={CustomInput}
                    name={signupConstants.street}
                    required={true}
                    maxLength={250}
                    value={props.signUpModel.street || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    setError={(errorMsg: string) => props.setError(signupConstants.street, errorMsg)}
                    setInput={(input: string) => props.handleChange(signupConstants.street, input)}
                    errorMsg={getErrorMsg(signupConstants.street)} />

                <InputWrapper
                    title={"Hus nr."}
                    component={CustomInput}
                    value={props.signUpModel.number || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    name={signupConstants.number}
                    maxLength={250}
                    errorMsg={getErrorMsg(signupConstants.number)}
                    setError={(errorMsg: string) => props.setError(signupConstants.number, errorMsg)}
                    required={true}
                    setInput={(input: string) => props.handleChange(signupConstants.number, input)} />


                <InputWrapper
                    title={"Bogstav"}
                    component={CustomInput}
                    maxLength={250}
                    name={signupConstants.alphabet}
                    value={props.signUpModel.alphabet || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    setInput={(input: string) => props.handleChange(signupConstants.alphabet, input)}        
                />

                <InputWrapper
                    title={"Etage"}
                    maxLength={2}
                    component={CustomInput}
                    name={signupConstants.floor}
                    value={props.signUpModel.floor || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    setInput={(input: string) => props.handleChange(signupConstants.floor, input)} />

                <InputWrapper
                    title={"Side"}
                    component={CustomInput}
                    maxLength={4}
                    name={signupConstants.door}
                    setInput={(input: string) => props.handleChange(signupConstants.door, input)}
                    value={props.signUpModel.door || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                />

                <InputWrapper
                    title={"C/O"}
                    component={CustomInput}
                    maxLength={100}
                    name={signupConstants.co}
                    value={props.signUpModel.co || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    required={false}
                    setInput={(input: string) => props.handleChange(signupConstants.co, input)} />

                <InputWrapper
                    title={"Postnummer"}
                    description={"Indtast dit postnummer ex.1656"}
                    component={CustomInput}
                    name={signupConstants.zip}
                    value={props.signUpModel.zip || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    maxLength={20}
                    required={true}
                    setError={(errorMsg: string) => props.setError(signupConstants.zip, errorMsg)}
                    errorMsg={getErrorMsg(signupConstants.zip)}
                    setInput={(input: string) => props.handleChange(signupConstants.zip, input)} />

                <InputWrapper
                    title={"By"}
                    component={CustomInput}
                    name={signupConstants.city}
                    maxLength={80}
                    value={props.signUpModel.city || ""}
                    required={true}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    setError={(hasError: string) => props.setError(signupConstants.city, hasError)}
                    errorMsg={getErrorMsg(signupConstants.city)}
                    setInput={(input: string) => props.handleChange(signupConstants.city, input)} />

                <InputWrapper
                    title={"Stednavn"}
                    component={CustomInput}
                    name={signupConstants.county}
                    maxLength={50}
                    value={props.signUpModel.county || ""}
                    pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    setInput={(input: string) => props.handleChange(signupConstants.county, input)} />

                <InputWrapper
                    title={"Kommune"}
                    component={CustomDropdown}
                    name={signupConstants.municipalityId}
                    required={props.signUpModel.countryId === denmarkId || props.signUpModel.countryId === greenlandId || props.signUpModel.countryId === undefined ? true : false}
                    disabled={props.signUpModel.countryId === denmarkId || props.signUpModel.countryId === greenlandId || props.signUpModel.countryId === undefined ? false : true}
                    data={props.municipalities}
                    hasCreateButton={false}
                    showSearch={true}
                    defaultSelectedId={props.signUpModel.municipalityId || ""}
                    setValue={(selected: DropdownItem) => props.handleChange(signupConstants.municipalityId, selected.id || "")}
                    setError={(errorMsg: string) => props.setError(signupConstants.municipalityId, errorMsg)}
                    errorMsg={getErrorMsg(signupConstants.municipalityId)}
                />
                <InputWrapper
                    title={"Land"}
                    component={CustomDropdown}
                    name={signupConstants.countryId}
                    data={props.countries}
                    hasCreateButton={false}
                    showSearch={true}
                    defaultSelectedId={props.signUpModel.countryId || defaultCountryItem?.id}
                    setValue={(selected: DropdownItem) => props.handleChange(signupConstants.countryId, selected.id)} />

                <InputWrapper
                    title={"Mobiltelefon"}
                    component={PhoneInput}
                    name={signupConstants.mobilephone}
                    required={true}
                    phoneAreaCodes={countryCode}
                    maxLength={50}
                    value={props.signUpModel.mobilephone}
                    //pattern={{ patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    errorMsg={getErrorMsg(signupConstants.mobilephone)}
                    setError={(errorMsg: string) => props.setError(signupConstants.mobilephone, errorMsg)}
                    setInput={(input: { areaCode?: string, telNo?: string }) => {
                        props.handleChange(signupConstants.mobilephone, ((input.areaCode || "") + (input.telNo || "")))
                    }} />

                <InputWrapper
                    title={"Arbejdstelefon"}
                    component={PhoneInput}
                    name={signupConstants.workphone}
                    phoneAreaCodes={countryCode}
                    maxLength={50}
                    value={props.signUpModel.workphone}
                    //pattern={{patternStr: characterValidation, errorMsg: characterValidationErrorMsg }}
                    errorMsg={getErrorMsg(signupConstants.workphone)}
                    setError={(errorMsg: string) => props.setError(signupConstants.workphone, errorMsg)}
                    setInput={(input: { areaCode?: string, telNo?: string }) => {
                        props.handleChange(signupConstants.workphone, ((input.areaCode || "") + (input.telNo || "")))
                    }} />

                <InputWrapper
                    title={"Primær mail"}
                    component={CustomInput}
                    name={signupConstants.mail}
                    required={true}
                    type={"email"}
                    value={props.signUpModel.mail || ""}
                    pattern={[{ patternStr: email, errorMsg: emailErrorMsg } ]}
                    maxLength={100}
                    errorMsg={getErrorMsg(signupConstants.mail)}
                    setError={(errorMsg: string) => props.setError(signupConstants.mail, errorMsg)}
                    setInput={(input: string) => props.handleChange(signupConstants.mail, input)} />

                <InputWrapper
                    title={"Sekundær mail"}
                    component={CustomInput}
                    name={signupConstants.workmail}
                    type={"email"}
                    required={false}
                    value={props.signUpModel.workmail || ""}
                    pattern={[{ patternStr: email, errorMsg: emailErrorMsg }]}
                    maxLength={100}
                    errorMsg={getErrorMsg(signupConstants.workmail)}
                    setError={(errorMsg: string) => props.setError(signupConstants.workmail, errorMsg)}
                    setInput={(input: string) => props.handleChange(signupConstants.workmail, input)} />
            </>}
            {/* {errorMsg && <NotificationBar text={errorMsg} type={NotificationBarType.Danger} close={() => setErrorMsg("")} />} */}
        </div>)
}

export default SignupFirstStep;