import * as api from '../../../api';
import { getDateStr } from '../../../common/dateutil'
import CustomTable from '../../shared/CustomTable/custom-table';
import React from 'react';
import style from '../my-appointments.module.scss';
import BookingTable from '../../shared/BookingTable/booking-table';
interface AppointmentListProps {
  appointments?: api.Appointment[];
  showButtonLabel: string;
  cancelButtonLabel?: string;
  noAppointmentText?: string;
  openDetail: (appointment: api.Appointment) => void;
  openCancel: (appointment: api.Appointment) => void;
  futureAppointmentList?: boolean;
}
const columns =
  [{
    columnName: "Rådgiver",
    visible: true,
    index: 0
  }, {
    columnName: "Tidspunkt",
    visible: true,
    index: 1
  }]

function AppointmentList(props: AppointmentListProps) {

  const rows = React.useMemo(() =>
    props.appointments?.map((x) =>
    ({
      rawdata: x,
      data: [
        x.counselor?.name || "-",
        getDateStr(new Date(x.date!), true)],
      showOpenDetailButton: x.hasReferral
    })),
    [props.appointments]
  )

  return (
    <div className={`${style.appointmentlist}`}>
      {props.futureAppointmentList ?
        <BookingTable
          columns={columns}
          rows={rows}
          showButtonLabel={props.showButtonLabel || "Vis"}
          cancelButtonLabel={props.cancelButtonLabel || "Aflys"}
          emptyText={props.noAppointmentText || "Der findes ingen samtaletid"}
          openCancel={(row) => props.openCancel(row.rawdata as api.Appointment)}
          openDetail={(row) => props.openDetail(row.rawdata as api.Appointment)}
          showAllColumns = {true}
        ></BookingTable>
        :
        <CustomTable
          columns={columns}
          rows={rows}
          showButtonLabel={props.showButtonLabel || "Book"}
          emptyText={props.noAppointmentText || "Der findes ingen samtaletid"}
          openDetail={(row) => props.openDetail(row.rawdata as api.Appointment)}
          showAllColumns = {true}
        ></CustomTable>}
    </div >)
}
export default AppointmentList;