import style from './event-signup-questions.module.scss'
import React from "react";
import * as formutil from '../../../common/get-form-component'
import * as api from '../../../api';
import * as formitemConstants from '../../../constants/form-item-constants'
import CustomMultiselect from '../../shared/CustomMultiselect/custom-multiselect';
import { DropdownItem } from '../../../models/dropdownItem';
import { registrationResponses } from '../../../constants/signup-constants';
import { multiplechoiceEnumNo, singlechoiceEnumNo } from '../../../constants/common-constants';
import { getErrorMsgByName } from '../../../common/getErrorMsgByName';
import { StepFormData } from '../../../models/step';
import InputWrapper from '../../shared/InputWrapper/input-wrapper';

export interface EventSignupQuestionProps extends StepFormData {
    questions: api.EventCustomRegistratonField[];
    title?: string;
    signUpModel: api.EventSignup;
}

function EventSignupQuestion(props: EventSignupQuestionProps) {
    const [answers, setAnswers] = React.useState<api.EventRegistrationResponse[]>([])
    React.useEffect(() => {
        if (props.signUpModel.registrationResponses && props.signUpModel.registrationResponses!.some(x => x.answer)) {
            setAnswers(props.signUpModel.registrationResponses!);
        } else {
            if (props.questions) {
                const initialAnswers = props.questions.map((q) => { return { questionId: q.id, answer: null } });
                setAnswers(initialAnswers);
            }
        }

    }, [props.questions])

    const onChange = (id: string, answer?: string) => {

        const newAnswers = answers.map((x) => {
            if (x.questionId === id)
                x.answer = answer;
            return x
        });
        props.handleChange(registrationResponses, newAnswers)
    }
    const getAnswer = (id?: string) => {
        if (!id) return "";
        return props.signUpModel.registrationResponses?.find(x => x.questionId === id)?.answer;
    }

    const getFieldType = (type: number) => {
        switch (type) {
            case singlechoiceEnumNo:
                return formitemConstants.select;
            case multiplechoiceEnumNo:
                return formitemConstants.multiselect;
            default:
                return formitemConstants.text;
        }

    }


    const getMultiSelect = (questiondata: api.EventCustomRegistratonField, i: number) => {

        const data = questiondata.choices?.map(x => { return { id: x, text: x, originalData: x } as DropdownItem }) || [];
        const selected = props.signUpModel.registrationResponses?.find(x => x.questionId === questiondata.id)?.answer || "";
        return questiondata.id ?
            <InputWrapper
                component={CustomMultiselect}
                title={questiondata.text || `Spørgsmål - ${i}`}
                data={data}
                name={questiondata.id!}
                defaultSelectedIds={selected.split(", ").filter(s => s)}
                setValues={(newselected: DropdownItem[]) => { onChange(questiondata.id!, newselected.map(x => x.id).join(", ")) }}
                showSearch={false}
                errorMsg={questiondata.mandatory ? getErrorMsgByName(props.errorList, questiondata.id!) : ""}
                key={"multi-" + questiondata.id}
                required={questiondata.mandatory}
                setError={(errorMsg: string) => props.setError(questiondata.id!, errorMsg)}
            /> : null
    }

    return (
        <div className={style.step}>
            <div className={style.stepTitle}>{props.title || "Spørgsmål til service"}</div>
            {props.questions.map((q, i) => {
                const fieldtype = getFieldType(q.fieldtype || 0);
                return <div key={`questionfield-${i}`} className={style.questionfield}>

                    {
                        fieldtype === formitemConstants.multiselect ?
                            getMultiSelect(q, i) :
                            formutil.getCompoent(
                                q.text || `Spørgsmål - ${i}`,
                                {
                                    type: fieldtype,
                                    settings: undefined,
                                    alias: q.id || "",
                                    caption: q.text || "",
                                    required: q.mandatory || false,
                                    preValues: q.choices || undefined
                                },
                                getAnswer(q.id),
                                onChange,
                                q.mandatory,
                                undefined,
                                (errorMsg) => props.setError(q.id!, errorMsg),
                                false,
                                q.mandatory ? getErrorMsgByName(props.errorList, q.id!) : "",
                                q.text || `Spørgsmål - ${i}`,
                            )
                    }
                </div>
            })}
        </div>)
}

export default EventSignupQuestion;