import { CSSProperties } from 'react';
import { PageTypes } from '../../models/pagetypes';
import Wrapper from '../shared/Wrapper/wrapper';

interface ContentBoxProps {
    title?: string;
    content?: string;
    pagetype?: PageTypes;
    imgurl?: string
    width?: string;
    height?: string;
}

function ContentBox(props: ContentBoxProps) {

    const imgStyle: CSSProperties = { maxWidth: "100%", width: props.width ? props.width + "px" : undefined, height: props.height ? props.height + "px" : undefined };

    return (
        <Wrapper properties={{ title: props.pagetype === PageTypes.CONTENT ? props.title || "" : "", contentTypeAlias: "", id: "" }}>
            {props.pagetype !== PageTypes.CONTENT &&
                <div className={`header ${[props.pagetype]}`}>{props.title}</div>}
            <div dangerouslySetInnerHTML={{ __html: props.content || "<div/>" }} />
            {props.imgurl && <img style={imgStyle} alt="" src={props.imgurl} />}
        </Wrapper>
    )
}

export default ContentBox;