/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import { request as __request } from '../core/request';

export class CountriesService {

    /**
     * Description: Get a list of countries.
 * Operation: GetCountries.
 * Area: Countries.
     * @returns Country Success
     * @throws ApiError
     */
    public static async getCountries(): Promise<Array<Country>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/countries`,
        });
        return result.body;
    }

}