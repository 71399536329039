
import * as api from '../../api';
import { RefusionElement } from '../../models/umbracoElement';
import HelperIcon from '../shared/HelperIcon/helper-icon';
import ZExpense from '../shared/ZExpense/zExpense';
import style from './refusion.module.scss';

interface RefusionProps {
    memberid: string;
    umbracoSetting: RefusionElement;
    currentItem: api.Event | api.EventSession;
}

function Refusion({ currentItem, umbracoSetting: refusionData, memberid }: RefusionProps) {

    return (currentItem.reimbursementInformation?.canApply && (new Date(currentItem.reimbursementInformation?.zExpenseOpenDate!) < new Date()) && (new Date(currentItem.reimbursementInformation?.zExpenseCloseDate!) > new Date()))
        ? <div className={style.refusion}>
            {refusionData.title && <div className={style.title}>
                <h4>{refusionData.title}{refusionData.helpText && refusionData.title && <div className={style.icon}><HelperIcon helptext={refusionData.helpText || ""} /></div>}</h4>
            </div>}
            <ZExpense memberId={memberid} buttonText={refusionData.buttonText} />
        </div>
        : <></>

}


export default Refusion;