import React from 'react';
import * as api from '../../../api';
import { postErrorMsg } from '../../../constants/common-constants';
import { EventSignupElement } from '../../../models/umbracoElement';
import FlowButton from '../../shared/Buttons/FlowButton/flow-button';
import ButtonZone from '../../shared/ButtonZone/button-zone';
import { NotificationBarType } from '../../shared/NotificationBarSetting/notification-bar-setting';
import Wrapper from '../../shared/Wrapper/wrapper';
import style from './session-deregistration.module.scss';

interface SessionRegistrationProps {
    eventid: string;
    memberid: string;
    session?: api.EventSession;
    settingdata: EventSignupElement;
    closeModal: (deregistered: boolean) => void;
}
function SessionDeregistration(props: SessionRegistrationProps) {

    const [loading, setLoading] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState("");
    const [success, setSuccess] = React.useState(false);

    const goBack = (reload: boolean) => {
        props.closeModal(reload);
        setSuccess(false);
        setErrorMsg("");
    }
    const cancelSession = async () => {
        setErrorMsg("");

        setLoading(true)
        return api.EventsService.cancelSession(props.eventid, props.session!.sessionId!, { memberId: props.memberid })
            .then(() => {
                setSuccess(true);
            }).catch((err) => {
                console.log(err)
                setErrorMsg(postErrorMsg)
            }).finally(() =>
                setLoading(false))

    }

    const { helpText, ...rest } = props.settingdata;
    return (
        <Wrapper properties={{ ...rest, title: props.session?.name || "Afmelding" }}
            notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger } : undefined}

            backBarSettings={{ backLabel: props.settingdata.cancelButtonLabel || "Annuller", onBackClick: () => goBack(false) }}
            bottomZone={<ButtonZone>
                {success ? <FlowButton className={style.onebutton} onClick={() => goBack(true)} >{props.settingdata.afterCancelLabel || "Luk"}</FlowButton>

                    : <FlowButton className={style.onebutton} isSubmit={true} onClick={cancelSession} loading={loading} disabled={loading}>
                        {props.settingdata.deregistrationButtonLabel || "Afmeld"}
                    </FlowButton>}
            </ButtonZone>}
        >
            {!success ? <div className={style.step}>
                <div className={style.cancelContent} dangerouslySetInnerHTML={{ __html: props.settingdata.sessionCancelText || "" }} />
            </div>
                :
                <div className={style.step}>
                    <div className={style.sessionRegisteredText} dangerouslySetInnerHTML={{ __html: props.settingdata.sessionCancelledText || "" }} />
                </div>
            }
        </Wrapper>
    )
}
export default SessionDeregistration;