/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EducationInstitution } from '../models/EducationInstitution';
import { request as __request } from '../core/request';

export class EducationinstitutionService {

    /**
     * Description: Get a list of Education Institutions.
 * Operation: GetEducationInstitution.
 * Area: Educationinstitution.
     * @returns EducationInstitution Success
     * @throws ApiError
     */
    public static async getEducationInstitution(): Promise<Array<EducationInstitution>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/educationinstitution`,
        });
        return result.body;
    }

}