import style from './shortcuts.module.scss'
import { ShortcutElement } from '../../models/umbracoElement';
import RightArrow from '../shared/icons/ArrowNext/arrow-next';
import React from 'react';
import Wrapper from '../shared/Wrapper/wrapper';

interface ShortcutsProps {
    data: string;
    username?: string;
}
function Shortcuts(props: ShortcutsProps) {
    const shortCutElem: ShortcutElement = JSON.parse(props.data);
    const onClick = (url: string) => {
        window.location.href = url;
    }
    return (
        <Wrapper properties={shortCutElem}>
            <div className={style.shortcutcontainer}>
                <div className={style.shortcut}>
                    {shortCutElem.shortcuts.map((shortcut,i) => {
                        return (
                            <a href={shortcut.link?.url}
                                className={style.shortcutitem} key={i}>
                                {shortcut.icon &&
                                    <div className={style.shorcuticon}>
                                        <img className="dlf_nav_lcl_icon" src={shortcut.icon._url} alt="icon"></img>
                                    </div>}
                                <div className={style.shortcutinfo}>
                                    <div className={style.title}> {shortcut.title}</div>
                                </div>
                                <div className={style.rightarrow}>
                                    <RightArrow darkbackground={false} />
                                </div>
                            </a>
                        )
                    })}
                </div>
                {shortCutElem.bottomLink &&
                    <div className={style.bottomlink}>
                    <a href={`${shortCutElem.bottomLink!._url}`}>{shortCutElem.bottomText}</a>
                    </div>
                }
            </div>
        </Wrapper>
    )
}

export default Shortcuts;