import React, { useMemo } from 'react';
import { DropdownItem } from '../../../models/dropdownItem';
import { InputBase } from '../../../models/inputBase';
import CustomDropdown from '../CustomDropdown/custom-dropdown';
import CustomInput from '../CustomInput/custom-input';
import style from './phone-input.module.scss';

type PhoneInputProps = InputBase & {
    phoneAreaCodes: DropdownItem[];
    setInput: (inputtext: { areaCode?: string, telNo?: string }) => void;
    pattern?: string;
    errorMsg: string;
    value?: string | null;
    maxLength?: number;
}

function PhoneInput(props: PhoneInputProps) {
    const [telno, setTelno] = React.useState("");
    const [areacode, setAreacode] = React.useState("+45");
    const [selectedAreaCodeItem, setselectedAreaCodeItem] = React.useState("");

    const memPhoneAreaCodes = useMemo(() => props.phoneAreaCodes.find(x => x.text === areacode)?.id?.toString() || "", [props.phoneAreaCodes, areacode])
    React.useEffect(() => {
        if (props.value && props.value.length > 0) {
            setAreacode(memPhoneAreaCodes ? props.value.substring(0, 3) : "");
            setTelno(memPhoneAreaCodes ? props.value.substring(3) : props.value);
        }
        setselectedAreaCodeItem(memPhoneAreaCodes);
    }, [areacode, memPhoneAreaCodes, props.value])


    const onInputChange = (input: string) => {
        setTelno(input || "");
        props.setInput({ telNo: input || "", areaCode: input ? areacode : "" })

    }
    const onLandCodeChange = (selectedItem: DropdownItem) => {
        const newAreacode = selectedItem.text === "Vælg..." ? "" : selectedItem.text || "";
        let tel = telno;
        if (props.maxLength && newAreacode.length + telno.length > props.maxLength) {
            tel = tel.substring(0, props.maxLength - newAreacode.length);
        }
        setAreacode(newAreacode);
        props.setInput({ telNo: tel, areaCode: newAreacode })
    }
    return (
        <div className={style.phoneinput}>
            <div className={style.landcode} >
                {!props.disabled ?
                    <CustomDropdown name={`${props.name || ""}_landcode`} data={props.phoneAreaCodes} setValue={onLandCodeChange} hasCreateButton={false} showSearch={false} defaultSelectedId={selectedAreaCodeItem} /> :
                    <CustomInput testId={props.testId} value={props.phoneAreaCodes.find(x => x.id === areacode)?.text || ""} setInput={onInputChange} disabled={true} ></CustomInput>}
            </div>
            <CustomInput
                maxLength={props.maxLength ? props.maxLength - areacode.length : undefined}
                name={props.name}
                type={"tel"}
                errorMsg={props.errorMsg || ""} value={telno || ""}
                required={props.required || false}
                pattern={{ patternStr: "^[0-9]*$", errorMsg: "Kun tal er tilladt" }}
                setInput={onInputChange}
                disabled={props.disabled || false} setError={props.setError}></CustomInput>

        </div>
    )
}
export default PhoneInput;