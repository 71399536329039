import { FormError } from "../models/step";

export const getNewErrorList = (errorList: FormError[], name: string, errorMsg: string) => {

    const nameIndex = errorList.findIndex(x=>x.name===name);

    if (nameIndex < 0) {
        if (errorMsg) {
            errorList.push({name:name,errorMsg:errorMsg});
        }
    }
    else {
        if (!errorMsg) {
            errorList.splice(nameIndex, 1);
        }
        else{
            errorList[nameIndex]={name:name,errorMsg:errorMsg}
        }
    }
    return errorList;
}