import React from 'react';
import * as api from '../../api';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import { fetchErrorMsg } from '../../constants/common-constants';
import { CollapsibleInformationItem, NewEventsElement } from '../../models/umbracoElement';
import CollapsibleInformation from '../shared/CollapsibleInformation/collapsible-information';
import EventCardDetail from '../shared/EventCard/Detail/event-card-detail';
import EventCardMain from '../shared/EventCard/Main/event-card-main';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import OverviewList from '../shared/OverviewList/overview-list';
import OverviewItem from '../shared/OverviewList/OverviewItem/overview-item';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './new-events.module.scss';

interface NewEventsProps {
    data: string,
    memberid: string
}

function NewEvents(props: NewEventsProps) {
    const [umbracoSettings] = React.useState<NewEventsElement>(JSON.parse(props.data));
    const [originalCourseList, setOriginalCourseList] = React.useState<api.Event[]>([]);
    const [courseList, setCourseList] = React.useState<api.Event[]>([]);
    const [filterList, setFilterList] = React.useState<{ title: string, checked: boolean }[]>([]);
    const [newfilterList, setNewFilterList] = React.useState<string[]>([]);
    const [showFilter, setShowFilter] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loggedIn] = React.useState(!(window.location.pathname.toLowerCase().indexOf('/kurser-offentlig') > -1));
    const [errorMsg, setErrorMsg] = React.useState("");

    React.useEffect(() => {
        const call = async () => {
            setLoading(true)
            try {
                const courses: api.Event[] = await api.EventsService.getEvents();
                const targetCategories: api.EventTargetCategory[] = await api.TargetcategoriesService.getEventTargetCategories();
                setOriginalCourseList(courses);
                setCourseList(courses);
                createTargetCategoryList(targetCategories);
            } catch (error) {
                console.error(error)
                setErrorMsg(fetchErrorMsg);
            }
            finally {
                setLoading(false);
            }
        }
        call();
    }, []);



    const createTargetCategoryList = (categories: api.EventTargetCategory[]) => {
        const filterlist = categories.map(x => { return { title: x.name!, checked: false } });
        setFilterList(filterlist);
    }

    const applyFilter = (newList: string[]) => {
        setNewFilterList(newList);
        if (newList.length === 0) {
            setCourseList([...originalCourseList]);
        } else {
            setCourseList([...originalCourseList].filter(x =>
                x.eventInformation && x.eventInformation.targetCategories && ((x.eventInformation.targetCategories || []).some(cat => newList.includes(cat)))
            ));
        }
    }


    return (<Wrapper properties={{ ...umbracoSettings, title: umbracoSettings.title || "Nye arrangementer" }}
        loading={loading}
        notification={errorMsg ? {
            message: errorMsg,
            type: NotificationBarType.Danger,
            onClick: () => setErrorMsg("")
        } : undefined}
        backBarSettings={undefined}
        bottomZone={undefined}
    >
        {
            <div >
                <div className={style.newevents}>
                    <div className={style.eventcardlist}>
                        <OverviewList
                            filterprops={{
                                currentFilters: newfilterList, filterList: filterList.map(x => x.title),
                                isOpen: showFilter,
                                title: "Vælg filtre og gem",
                                setOpen: setShowFilter,
                                setFilter: applyFilter
                            }}
                            items={courseList.map((list, key) =>
                                <div className={style.eventcard}>
                                    <OverviewItem
                                        key={`eventcard - ${key} `}
                                        main={<EventCardMain item={list} isLoggedIn={loggedIn}></EventCardMain>}
                                        detail={<EventCardDetail item={list} isLoggedIn={loggedIn}></EventCardDetail>}
                                    ></OverviewItem> </div>
                            )}
                        />


                    </div>
                </div>
            </div>}
    </Wrapper>
    )
}

export default NewEvents;
