import { MemberSignup } from '../api';
import { denmarkId } from '../constants/common-constants';


export const defaultSignUpModel: MemberSignup = {
    consents: {
        acceptedStorageOfData: false,
        acceptedContactFromDLF: false,
        acceptedTerms: false
    },
    firstname: "",
    lastname: "",
    initials: "",
    cpr: "",
    street: "",
    alphabet: "",
    changeDistrict: false,
    city: "",
    co: "",
    communicationPreferenceId: "",
    countryId: denmarkId,
    county: "",
    door: "",
    educationInstitutionId: undefined,
    educationMainSubjectIds: [],
    educationOrientationId: undefined,
    educationStartdate: undefined,
    educationEnddate: undefined,
    enddate: undefined,
    floor: "",
    genderId: "",
    jobTitleId: undefined,
    jobTypeId: "",
    municipalityId: undefined,
    number: "",
    situationtypeId: undefined,
    startdate: undefined,
    workLocationId: undefined,
    zip: "",
    mail: "",
    workmail: "",
    mobilephone: "",
    privatephone: "",
    workphone: "",
    notes: "",
    unknownWorkLocation: undefined,
    subscriptions: undefined,
    arrears: 0
}