/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserConfirmation } from '../models/UserConfirmation';
import { request as __request } from '../core/request';

export class UserconfirmationService {

    /**
     * Description: User confirms action provided in requestbody.
 * Operation: UserConfirmation.
 * Area: Userconfirmation.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async userConfirmation(
requestBody: UserConfirmation,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/userconfirmation`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
        return result.body;
    }

}