/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkLocation } from '../models/WorkLocation';
import { request as __request } from '../core/request';

export class WorklocationsService {

    /**
     * Description: Get a list of WorkLocations.
 * Operation: GetWorkLocations.
 * Area: Worklocations.
     * @returns WorkLocation Success
     * @throws ApiError
     */
    public static async getWorkLocations(): Promise<Array<WorkLocation>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/worklocations`,
        });
        return result.body;
    }

}