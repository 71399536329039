import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as api from '../../../api';
import { deliveryOptions } from '../../../models/subscriptionModel';
import { ChangeDeliveryDistributionFormElement } from '../../../models/umbracoElement';
import DeliveryOptionsElement from './DeliveryOptionsElement/delivery-options-element';
import style from './delivery-change.module.scss';

interface DeliveryChangeProps {
    magazines?: api.Magazine[];
    subscribedSubscriptions?: api.MagazineSubscription[];
    umbracodata: ChangeDeliveryDistributionFormElement;
    onDefaultSubscriptionsChanged: (changedSubscriptions: api.MagazineSubscription[]) => void;
    defaultDeliveryOption: deliveryOptions;
    shouldFocus?: boolean;
}
function DeliveryChange(props: DeliveryChangeProps) {


    const getSubscription = (magazineId: string): api.MagazineSubscription => {
        return props.subscribedSubscriptions?.find(s => s.magazineId === magazineId) || { deliveryOption: props.defaultDeliveryOption, magazineId: magazineId }
    }

    const onSubscriptionChanged = (changedSubscription: api.MagazineSubscription) => {
        let index = props.subscribedSubscriptions!.findIndex(s => s.magazineId === changedSubscription.magazineId);
        let newSubscriptions = [...props.subscribedSubscriptions!];
        newSubscriptions[index!] = changedSubscription


        if (props.onDefaultSubscriptionsChanged !== undefined) {
            props.onDefaultSubscriptionsChanged(newSubscriptions);
        }
    }

    return (
        <div className={style.deliverychangeborder}>
            {props.magazines ?
                <>
                    <div className={style.subscriptions}>
                        {
                            props.magazines.length > 0
                                ? <>
                                    <div className={style.subscriptiontitles}>
                                        <div className={props.onDefaultSubscriptionsChanged! != null ? style.defaultsubscriptionname : style.subscriptionname}>{props.umbracodata.subscriptionTitle}</div>
                                        <div className={props.onDefaultSubscriptionsChanged! != null ? style.defaultsubscriptionformat : style.subscriptionformat}>{props.umbracodata.formatTitle}</div>
                                    </div>
                                    {props.magazines.map((magazine, i) => (
                                        <DeliveryOptionsElement
                                            magazine={magazine}
                                            subscription={getSubscription(magazine.magazineId!)}
                                            onSubscriptionChanged={onSubscriptionChanged!}
                                            physcialButtonLabel={props.umbracodata.physcialButton}
                                            electronicButtonLabel={props.umbracodata.electronicButton}
                                            unsubscribeButtonLabel={props.umbracodata.unsubscribeButton}
                                            shouldFocus={i === 0 ? props.shouldFocus : false}
                                            key={magazine.magazineId!}
                                        />))}
                                </>
                                : <div className={style.nomessages} dangerouslySetInnerHTML={{ __html: props.umbracodata.noMagazineText || "Du har ingen aktive fagblade" }} />
                        }
                    </div>

                </> : <></>}
        </div>
    )
}
export default DeliveryChange;