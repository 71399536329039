import React from 'react';
import CaretDown from '../../../shared/icons/CaretDown/caret-down';
import CaretUp from '../../../shared/icons/CaretUp/caret-up';
import HelperIcon from '../../HelperIcon/helper-icon';
import style from './accordion-element.module.scss';

export type AccordionDropdownElement = {
    title: string;
    value: string | JSX.Element;
    helpText?: string;
}
export type AccordionElementProps = {
    title: string;
    dropdownValues: (AccordionDropdownElement | JSX.Element)[];
    byDefaultOpen?: boolean;
}

function AccordionElement(props: AccordionElementProps) {
    const [isOpen, setIsOpen] = React.useState(props.byDefaultOpen);
    return (
        <div className={style.element}>
            <div className={`${style.header}`} onClick={() => setIsOpen(!isOpen)}>
                <h4 className={style.headertitle}> {props.title} </h4>
                <span className={style.arrow}> {isOpen ? <CaretUp /> : <CaretDown />}</span>
            </div>
            {isOpen && props.dropdownValues &&
                <div className={`${style.collapseelements} ${isOpen ? style.headeropen : ""}`}>
                    {
                        props.dropdownValues.map((d, index) =>
                            <div className={style.collapseelement} key={index}>
                                {(d as JSX.Element).type ? d as JSX.Element :
                                    <>                                                     <div className={style.title}>
                                        {`${(d as AccordionDropdownElement).title} `} {(d as AccordionDropdownElement).helpText ?
                                            <div className={style.helpericon}><HelperIcon helptext={(d as AccordionDropdownElement).helpText!} /></div> : undefined} </div>
                                        <span className={style.text}>

                                            {(d as AccordionDropdownElement).value}
                                        </span>
                                    </>
                                }
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
}

export default AccordionElement;