const elements = ["dlf_menu"];

const getDisplayStyle = (elem: HTMLElement) => {
    let display = elem.style.display
    if (!display) {
        const style = getComputedStyle(elem);
        display = style.display;
    }
    return display;
}

const setMenuDisplayStyle = (shouldBeVisible: boolean = false) => {
    elements.forEach((elemclass) => {
        const elem = document.getElementById(elemclass);
        if (elem) {
                elem.style.display =shouldBeVisible? "block":"none";
        
        }
    })
}

export const setupMenu=()=>{
    const elms = document.getElementsByTagName("dlf-burger-menu");
    if (elms && elms.length > 0) {
        const displaystyle = getDisplayStyle((elms[0] as HTMLElement))
            setMenuDisplayStyle(displaystyle === "none");
    }
}

export const toggleVisibility=()=>{
    const elemToHide = elements;
    elemToHide.forEach((elemclass) => {
        const elem = document.getElementById(elemclass);
        if (elem) {
            elem.style.display = getDisplayStyle(elem) === "none" ? "block" : "none";
            elem.style.zIndex = "2" ? "5" : "2";
        }
    })
}