import React from 'react';
import Cancel from '../icons/Cancel/cancel';
import Help from '../icons/Help/help';
import style from './helper-icon.module.scss'

interface HelperIconProps {
  helptext: string;
}

const HelperIcon = React.forwardRef((props: HelperIconProps, ref: React.ForwardedRef<HTMLDivElement | null>) => {

  const [showtext, setShowText] = React.useState(false);

  const onClick = () => setShowText(!showtext);

  return (
    <>
      <div onClick={onClick} className={style.iconcontainer}>
        <Help />
      </div>
      <div className={`${style.hovershow} shadow ${showtext ? "" : style.hidden}`}>
        <div ref={ref} className={style.helpcontent}>
          <div className={style.close} onClick={() => setShowText(false)}><div className={style.closetext}>Luk</div><Cancel /></div>
          <div className={style.helptext}> {props.helptext}</div>
        </div>
      </div>
    </>
  )
});

export default HelperIcon;