import style from './custom-date-picker.module.scss';
import React from 'react';
import DatePicker from 'react-date-picker';
import { dateErrorMsg, requiredErrorMsg } from '../../../constants/common-constants';
import { InputBase } from '../../../models/inputBase';
import Cancel from '../icons/Cancel/cancel';
import ArrowDown from '../icons/ArrowDown/arrow-down';
import { getDateStr } from '../../../common/dateutil';

type CustomDatePickerProps = InputBase & {
    onDateChange: (date: any) => void
    selectedDate?: string;
    minDate?: Date;
    maxDate?: Date;
    startDate?: Date;
    alwaysOpen?: boolean;
}

function CustomDatePicker(props: CustomDatePickerProps) {
    const [isOpen, setOpen] = React.useState(false);
    const [yearElm, setYearElm] = React.useState<HTMLInputElement>();
    const [monthElm, setMonthElm] = React.useState<HTMLInputElement>();
    const [dateElm, setDateElm] = React.useState<HTMLInputElement>();
    const [invalid, setInvalid] = React.useState(false);
    const [key, setKey] = React.useState(new Date().getDate().toString());
    const calref = React.useRef<(HTMLDivElement | null)>();
    const defaultMinimum = new Date(1, 0, 1);

    const onDateChange = (date: any) => {
        if (props.setError) props.setError(!date && props.required ? requiredErrorMsg : "");
        if (!date) {
            props.onDateChange(undefined);
            //clearing date input by setting new key by setting new key
            //otherwise input remains even select date is undefined
            setKey(new Date().getTime().toString())
        } else {
            const chosenDate = new Date(date);
            const utcDatestring = getUtcDateString(chosenDate);
            props.onDateChange(new Date(utcDatestring));
        }
    }
    const getUtcDateString = (chosenDate: Date) => {
        return `${chosenDate.getFullYear()}-${chosenDate.getMonth() < 9 ? '0' : ''}${chosenDate.getMonth() + 1}`
            + `-${chosenDate.getDate() < 10 ? '0' : ''}${chosenDate.getDate()}T00:00Z`;
    }
    const getLocalDate = () => {
        if (props.selectedDate) {
            const utcDate = new Date(props.selectedDate)
            return new Date(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate());
        }
        return undefined;
    }
    React.useEffect(() => {
        setInvalid(false);
    }, [props.selectedDate])

    React.useEffect(() => {
        //refrreshing input element - manual input fields year, month and days
        setYearElm(calref.current?.getElementsByClassName("react-date-picker__inputGroup__year")?.[0] as HTMLInputElement);
        setMonthElm(calref.current?.getElementsByClassName("react-date-picker__inputGroup__month")?.[0] as HTMLInputElement);
        setDateElm(calref.current?.getElementsByClassName("react-date-picker__inputGroup__day")?.[0] as HTMLInputElement);
    }, [key]);


    //react datepicker validate date somewhere and if date is not valid, it doesn't return anything onChange. We need to validate manually
    const validateDate = () => {

        const empty = !yearElm?.value && !monthElm?.value && !dateElm?.value;
        if (empty && !props.alwaysOpen) {
            //closed without choosing anything. reset value.
            if (props.required) {
                props.setError && props.setError(requiredErrorMsg);

            }
        }
        if (!(yearElm!.validity.valid && monthElm!.validity.valid && dateElm!.validity.valid)) {

            const fromandto = props.minDate && props.maxDate;
            const fromdatemsg = props.minDate ? `Dato skal være ${(fromandto ? "mellem" : "fra med")} ${getDateStr(props.minDate)}` : "";
            const betweenmsg = fromandto ? " og" : ""
            const todatemsg = props.maxDate ? `${(props.minDate ? "" : "Datoen skal være til med ")} ${getDateStr(props.maxDate)}` : "";

            props.setError && props.setError(`${dateErrorMsg}. ${fromdatemsg}${betweenmsg}${todatemsg}`);
            setInvalid(true);
        }

        setOpen(false);
    }

    const onOpen = () => {
        if (invalid) props.onDateChange(undefined);
        setInvalid(false)
        props.setError && props.setError("");
        setOpen(true);
    }
    return (
        <div className={style.datepicker}
            ref={r => calref.current = r}>
            <DatePicker
                key={key}
                onChange={onDateChange}
                onCalendarOpen={onOpen}
                onCalendarClose={validateDate}
                className={`${props.alwaysOpen ? "always_open" : ""} ${props.errorMsg ? "has_error" : ""}`}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="åååå"
                format="dd/MM/yyyy"
                locale="da-DK"
                name={props.name}
                isOpen={props.alwaysOpen}
                minDate={props.minDate || defaultMinimum}
                maxDate={props.maxDate || new Date("9999/12/31")}
                value={props.selectedDate ? getLocalDate() : undefined}
                calendarIcon={<ArrowDown />}
                clearIcon={<Cancel />}
                defaultActiveStartDate={props.startDate ? props.startDate : new Date()}
            />
        </div>
    )
}

export default CustomDatePicker;