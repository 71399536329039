import TopTitle from '../TopTitle/top-title';
import TopLabel from '../TopLabel/top-label';
import HelperIcon from '../HelperIcon/helper-icon';
import style from './wrapper.module.scss';
import { ElementHelpText } from '../../../models/umbracoElement';
import NotificationBarSetting, { NotificationBarType } from '../NotificationBarSetting/notification-bar-setting';
import Loader from '../Loader/loader';
import NavigationBar, { NavigationBarProps } from '../NavigationBar/navigation-bar';
import React, { CSSProperties, useEffect } from 'react';
import { useWindowSize } from '../../../hooks/windowsize';

export interface NotificationMessage {
    message: string,
    type: NotificationBarType
    onClick?: () => void;
}

interface WrapperProps {
    properties: ElementHelpText;
    loading?: boolean;
    bottomZone?: JSX.Element;
    notification?: NotificationMessage;
    backBarSettings?: NavigationBarProps;
    fillWidth?: boolean;
}

const Wrapper = (props: React.PropsWithChildren<WrapperProps>) => {
    const toptitleref = React.createRef<HTMLDivElement | null>();
    const [customStyle, setCustomStyle] = React.useState<CSSProperties>();
    const wrappercontentref = React.useRef<HTMLDivElement | null | undefined>();
    const size = useWindowSize();
    useEffect(() => {
        // if helper text height is bigger than component self, helptext overflows, and create an extra scrollbar.
        // to prevent the extra scrollbar, set the wrapper minimum height to helptext height.
        if (wrappercontentref.current && toptitleref.current) {
            const titledivBottom = toptitleref.current?.getBoundingClientRect().bottom;
            const wrapperBottom = wrappercontentref.current?.getBoundingClientRect().bottom;
            const wrapperHeight = wrappercontentref.current?.clientHeight || 0;

            if (titledivBottom > wrapperBottom) {
                setCustomStyle({ minHeight: wrapperHeight + (titledivBottom - wrapperBottom) })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size])

    return (<>
        <NavigationBar {...props.backBarSettings}
            notificationbar={props.notification?.message ? < NotificationBarSetting text={props.notification?.message || ""} type={props.notification?.type || NotificationBarType.Danger} /> : undefined}
        />
        <div ref={r => wrappercontentref.current = r} style={customStyle} className={`${style.wrapperborder} ${style[props.fillWidth ? "col-12" : "col-10"]} ${style[props.fillWidth ? "col-12-lg" : "col-10-lg"]}  ${style["col-12-md"]} ${style["col-12-sm"]} ${props.backBarSettings?.onBackClick ? style.withbackbar : ""}`}>

            {props.properties.title
                ? <TopTitle titletext={props.properties.title || ""} hasLabel={!!props.properties.label} helpIcon={props.properties.helpText ? <HelperIcon ref={toptitleref} helptext={props.properties.helpText} /> : undefined} />
                : <></>
            }
            {props.properties.label
                ? <TopLabel labeltext={props.properties.label || ""} asTitle={!props.properties.title} />
                : <></>
            }
            <div className={`${style.wrappercontainer} ${style["col-12"]}`}>
                {props.children}
                <div>
                    {props.bottomZone}
                </div>
                {props.loading && <div className={style.updateloader}>
                    <Loader />
                </div>}
            </div>
        </div>
    </>
    )
}

export default Wrapper;