export const firstname = "firstname";
export const middlename = "middlename";
export const lastname= "lastname";
export const initials="initials";
export const cpr= "cpr";
export const street= "street";
export const zip= "zip";
export const alphabet= "alphabet";
export const city= "city";
export const co= "co";
export const communicationPreference= "communicationPreference";
export const country= "country";
export const county= "county";
export const door= "door";
export const floor= "floor";
export const municipality= "municipality";
export const number= "number";
export const mobilephone="mobilephone";
export const privatephone="privatephone";
export const workphone="workphone";
export const mail= "mail";
export const workmail="workmail";
export const gender="gender";
export const address="address"

