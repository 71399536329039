import React from "react";
import { BackBarSetting } from "../../../models/umbracoElement";
import ArrowBack from "../icons/ArrowBack/arrow-back";
import style from "./navigation-bar.module.scss";

export interface NavigationBarProps {
    onBackClick?: () => void;
    backLabel?: string;
    notificationbar?: JSX.Element;
    navigationSettings?: BackBarSetting;
}

const NavigationBar = (props: NavigationBarProps) => {
    return props.onBackClick && (props.navigationSettings?.showBackBar ?? false) ? <div className={`${style.backbar} dlf_navigationbar`}>
        <div
            className={style.canceldiv} ><span
                onClick={() => props.onBackClick ? props.onBackClick() : console.log("cancelclick is undefined")}><ArrowBack className={style.cancelicon} />{props.backLabel || "Annuller"}</span>

        </div>
        <div className={style.topborder} />
        {props.notificationbar}
    </div> : (props.notificationbar ? <div className={style.backbar}>{props.notificationbar} </div> : null)
}

export default NavigationBar;