export enum InsuranceState {
    NoInsurance,
    HasInsurance,
    InsuranceInProcess,    
}

export function getInsuranceState(status: string) {
    switch(status)
    {
        case "Overført":
        case "Afmeldt":
        case "Tilmeldt":
            return InsuranceState.HasInsurance;
        case "Afmelder":
        case "Tilmelder": 
        case "Gentilmelder": 
            return InsuranceState.InsuranceInProcess;
        case "Afvist":
            return InsuranceState.NoInsurance;
    }
}

export function isInsuranceActive(status: string) {
    switch(status)
    {
        case "Overført":
        case "Tilmelder": 
        case "Tilmeldt":
        case "Gentilmelder": 
            return true;
        case "Afmelder":
        case "Afmeldt":
        case "Afvist":
            return false;
    }
}