/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobTitle } from '../models/JobTitle';
import { request as __request } from '../core/request';

export class JobtitlesService {

    /**
     * Description: Get a list of work related job titles.
 * Operation: GetJobTitles.
 * Area: Jobtitles.
     * @returns JobTitle Success
     * @throws ApiError
     */
    public static async getJobTitles(): Promise<Array<JobTitle>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/jobtitles`,
        });
        return result.body;
    }

}