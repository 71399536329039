function CaretDown(props: {
    darkbackground?: boolean;
}) {

    return (

        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.63758 0.973986C8.63758 1.16499 8.56458 1.35699 8.41858 1.50299L4.94858 4.98998C4.80758 5.13098 4.61657 5.20998 4.41657 5.20998C4.21758 5.20998 4.02657 5.13098 3.88557 4.98998L0.413575 1.50299C0.121575 1.20999 0.121575 0.735986 0.415575 0.442986C0.709575 0.150986 1.18457 0.151986 1.47657 0.444986L4.41657 3.39798L7.35658 0.444986C7.64857 0.151986 8.12257 0.150986 8.41657 0.442986C8.56457 0.588986 8.63758 0.781986 8.63758 0.973986Z" fill={props.darkbackground ? "white" : "var(--themecolor)"} />
        </svg>

    )
}

export default CaretDown;