import React, { useEffect, useMemo } from 'react';
import * as api from '../../api';
import { DropdownItem } from '../../models/dropdownItem';
import { FormError } from '../../models/step';
import * as constants from '../../constants/new-situation-constants';
import * as commonconstants from '../../constants/common-constants';
import { defaultCreateSituationModel } from '../../models/createSituationModel';
import { getNewErrorList } from '../../common/getNewErrorList';
import { getErrorMsgByName } from '../../common/getErrorMsgByName';
import InputWrapper from '../shared/InputWrapper/input-wrapper';
import CustomDatePicker from '../shared/CustomDatePicker/custom-date-picker';
import CustomDropdown from '../shared/CustomDropdown/custom-dropdown';
import { getToday, substractMonth } from '../../common/dateutil';
import { GraduationElement } from '../../models/umbracoElement';
import Wrapper from '../shared/Wrapper/wrapper';
import NotificationBarSetting, { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import ButtonZone from '../shared/ButtonZone/button-zone';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';
import CustomTextArea from '../shared/CustomTextArea/custom-textarea';
import InformationBox from '../shared/InformationBox/information-box';
import style from './graduation.module.scss';
import OnePageFormWrapper from '../shared/OnePageFormWrapper/one-page-form-wrapper';
interface GraduationProps {
    memberid: string;
    data: string;
}
const otherSituationData = { name: "Andet", situationTypeId: "ANDET", situationTypeNo: -1 };
function Graduation(props: GraduationProps) {
    const [elementdata] = React.useState<GraduationElement>(props.data ? JSON.parse(props.data) : {})
    const [selectedSituation, setSelectedSituation] = React.useState<api.SituationType>();
    const [graduation, setGraduation] = React.useState<api.Graduation>(defaultCreateSituationModel);
    const [addedWorkplace, setAddedWorkplace] = React.useState<string>()
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [jobtitles, setJobtitles] = React.useState<DropdownItem[]>([]);
    const [situationtypes, setSituationtypes] = React.useState<DropdownItem[]>([]);
    const [jobtypes, setJobtypes] = React.useState<DropdownItem[]>([]);
    const [workplaces, setWorkplaces] = React.useState<DropdownItem[]>([]);
    const [filteredJobTitles, setFilteredJobTitles] = React.useState<DropdownItem[]>(jobtitles);
    const [showConfirmMessage, setShowConfirmMesssage] = React.useState<boolean>(false);
    const [showAlreadyRegisteredMessage, setShowAlreadyRegisteredMessage] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const commonRequired = [constants.situationtypeId, constants.graduationDate];
    const workRequired = [...commonRequired, constants.workLocationId, constants.jobTypeId, constants.jobTitleId];
    const otherRequired = [...commonRequired, constants.otherText];
    const [isInJob, setIsInJob] = React.useState(false);
    const onJobTypeChange = (item: DropdownItem) => {

        if (item.id) {
            const filtered = jobtitles.filter(x => x.originalData?.jobType.jobTypeId === item.id);
            if (graduation.jobTitleId) {
                if (!filtered.some(x => x.id === graduation.jobTitleId)) {
                    handleChange(constants.jobTitleId, undefined);
                }
            }
            setFilteredJobTitles(filtered);
        } else {
            setFilteredJobTitles(jobtitles)
        }
        handleChange(constants.jobTypeId, item.id?.toString())
    }

    const handleChange = (name: string, value: any) => {
        setErrorMsg("");
        let newGraduation = { ...graduation };
        if (name === constants.situationtypeId) {
            setSelectedSituation(situationtypes.find(x => x.id === value)?.originalData as api.SituationType)
        }

        (newGraduation as any)[name] = value;
        setGraduation(newGraduation);
    }

    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);

    }

    const checkRequiredData = (required: string[]) => {
        const newErrorList: FormError[] = [];
        required.forEach((r) => {
            if (!(graduation as any)[r]) {
                newErrorList.push({ name: r, errorMsg: commonconstants.requiredErrorMsg })
            }
        });
        if (graduation.workLocationId === commonconstants.newid) {
            if (!addedWorkplace) {
                newErrorList.push({ name: constants.unknownWorkLocation, errorMsg: commonconstants.requiredErrorMsg });
            }
        }
        setErrorList(newErrorList);
        return newErrorList.length === 0;;
    }

    useEffect(() => {
        handleChange(constants.unknownWorkLocation, addedWorkplace)
    }, [addedWorkplace])

    useEffect(() => {
        if (!graduation.workLocationId || (graduation.workLocationId && graduation.workLocationId !== commonconstants.newid)) {
            setAddedWorkplace(undefined)
        }
    }, [graduation.workLocationId])

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const educations = await api.MembersService.getMembersEducationalInformations(props.memberid);
                const openEducationalSituations = educations.filter(x => x.enddate == null);

                if (openEducationalSituations && openEducationalSituations.length > 0) {

                    let editGraduation = { ...graduation, };
                    const myinfo: api.Member = await api.MembersService.getMemberById(props.memberid);
                    if (myinfo && myinfo.situations) {
                        setIsInJob(myinfo.situations.some(x => x.type?.situationTypeNo === commonconstants.inJobEnumNo && (x.enddate && new Date(x.enddate) > getToday() || x.enddate === null)));
                        editGraduation.situationtypeId = commonconstants.inJobEnumNo.toString();
                    }

                    const currentEducation = await api.MembersService.getMembersEducationalInformations(props.memberid);
                    if (currentEducation) {
                        editGraduation.graduationDate = currentEducation.filter(x => x.expectedGraduationDate && new Date(x.expectedGraduationDate) > getToday())[0]?.expectedGraduationDate;
                    }
                    setGraduation(editGraduation);

                    const situationtyperes: api.SituationType[] = await api.SituationtypesService.getSituationTypes();
                    //only ledig or ansat can be chosen. 

                    const newSituationTypes = situationtyperes.filter(s => s.situationTypeNo === commonconstants.unemployedEnumNo
                        || s.situationTypeNo === commonconstants.inJobEnumNo).map((x) => { return { id: x.situationTypeId!, text: x.name!, originalData: x } });
                    newSituationTypes.push({ id: otherSituationData.situationTypeId, text: otherSituationData.name, originalData: otherSituationData })
                    setSituationtypes(newSituationTypes);

                    const jobtitleres: api.JobTitle[] = await api.JobtitlesService.getJobTitles();
                    const newjobtitles = jobtitleres.map((x) => { return { id: x.jobTitleId!, text: x.name!, originalData: x } });
                    setJobtitles(newjobtitles);

                    const jobtypes: api.JobType[] = await api.JobtypesService.getJobTypes();
                    const newJobTypes = jobtypes.map((x) => { return { id: x.jobTypeId!, text: x.name!, originalData: x } });
                    setJobtypes(newJobTypes);

                    const workplaces: api.WorkLocation[] = await api.WorklocationsService.getWorkLocations();
                    const newworkplaces = workplaces.map((x) => {
                        return {
                            id: x.workLocationId!,
                            text: `${x.name!}, ${x.address?.street ? x.address.street + ' ' + (x.address.number || '') + ',' : ''} ${x.address?.zip || ""} ${x.address?.city || ""}`,
                            originalData: x
                        }
                    });
                    setWorkplaces(newworkplaces)
                }
                else {
                    setShowAlreadyRegisteredMessage(true);
                }
            } catch (error) {
                setErrorMsg(commonconstants.fetchErrorMsg)
                console.log(error)
            } finally { setLoading(false) }
        })();
    }, []);

    const unemployedChosen = useMemo(() => selectedSituation?.situationTypeNo === commonconstants.unemployedEnumNo, [selectedSituation]);
    const otherChosen = useMemo(() => selectedSituation?.situationTypeNo === commonconstants.otherSituationNo, [selectedSituation]);
    const inJobChosen = useMemo(() => selectedSituation?.situationTypeNo === commonconstants.inJobEnumNo, [selectedSituation]);

    useEffect(() => {
        if (!inJobChosen) {
            const editingGraduation = { ...graduation };
            if (unemployedChosen || otherChosen) {
                editingGraduation.jobTitleId = undefined;
                editingGraduation.workLocationId = undefined;
                editingGraduation.jobTypeId = undefined;


            }
            if (!otherChosen) {
                editingGraduation.otherText = undefined;

            }
            setGraduation(editingGraduation)
        }
        setErrorList([])
    }, [selectedSituation]);

    const getErrorMsg = (name: string) => getErrorMsgByName([...errorList], name);
    const createSituation = () => {
        if (checkRequiredData(otherChosen ? otherRequired : unemployedChosen ? commonRequired : workRequired) || isInJob) {
            setSubmitting(true)
            api.MembersService.memberGraduation(props.memberid, graduation)
                .then(() => { setShowConfirmMesssage(true) })
                .catch((error) => { setErrorMsg(commonconstants.postErrorMsg); console.log(error) })
                .finally(() => setSubmitting(false))
        }
        else {
            setErrorMsg(commonconstants.fixBeforeContinue);
        }
    }

    useEffect(() => {
        console.log(isInJob);
    }, [graduation])

    return (
        <Wrapper
            properties={elementdata}
            loading={loading}
            notification={{ message: errorMsg, type: NotificationBarType.Danger }}
            backBarSettings={elementdata.showBackBar ? {
                backLabel: elementdata.goBackTo?.name || "Til min situation",
                onBackClick: elementdata.goBackTo?.url ? (() => window.location.href = elementdata.goBackTo!.url) : undefined
            } : undefined}
            bottomZone={(showConfirmMessage || showAlreadyRegisteredMessage) || loading ? undefined : <ButtonZone><FlowButton onClick={createSituation} loading={submitting} disabled={errorList.length > 0} isSubmit={true}>{elementdata.submitLabel}</FlowButton></ButtonZone>}
        >
            {showConfirmMessage ? <div dangerouslySetInnerHTML={{ __html: (otherChosen ? elementdata.otherChosenText : elementdata.afterRegistrationText) || "" }} /> :
                showAlreadyRegisteredMessage ? <div className={style.infobox}>
                    <InformationBox title="" content={elementdata.alreadyRegisteredText || ""} />
                </div> :
                    !loading ? <OnePageFormWrapper hasButton={true} title=""
                        className={`${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]}`}>
                        <InputWrapper
                            title={"Dimissionsdato"}
                            component={CustomDatePicker}
                            required={true}
                            minDate={substractMonth(new Date(), 6)}
                            selectedDate={graduation.graduationDate || undefined}
                            errorMsg={getErrorMsg(constants.graduationDate)}
                            setError={(errorMsg: string) => setError(constants.graduationDate, errorMsg)}
                            onDateChange={(date: any) => handleChange(constants.graduationDate, date)}
                        />
                        {graduation.graduationDate && !isInJob &&
                            <InputWrapper
                                component={CustomDropdown}
                                title="Vælg situationtype"
                                data={situationtypes}
                                name={constants.situationtypeId}
                                hasCreateButton={false}
                                showSearch={false}
                                required={true}
                                errorMsg={getErrorMsg(constants.situationtypeId)}
                                setValue={(item: DropdownItem) => handleChange(constants.situationtypeId, item.id)}
                                setError={(errorMsg: string) => setError(constants.situationtypeId, errorMsg)}
                                defaultSelectedId={graduation.situationtypeId || ""}
                            />
                        }
                        {inJobChosen && <>
                            <InputWrapper
                                title={"Startdato"}
                                component={CustomDatePicker}
                                required={true}
                                selectedDate={graduation.startdate || undefined}
                                errorMsg={getErrorMsg(constants.startdate)}
                                setError={(errorMsg: string) => setError(constants.startdate, errorMsg)}
                                onDateChange={(date: any) => handleChange(constants.startdate, date)}
                            />
                            <InputWrapper
                                component={CustomDropdown}
                                title="Arbejdsplads"
                                data={workplaces}
                                hasCreateButton={true}
                                name={constants.workLocationId}
                                showSearch={true}
                                required={true}
                                errorMsg={getErrorMsg(constants.workLocationId)}
                                setError={(errorMsg: string) => setError(constants.workLocationId, errorMsg)}
                                defaultSelectedId={graduation.workLocationId || ""}
                                setValue={(item: DropdownItem) => handleChange(constants.workLocationId, item.id?.toString() || "")}
                                setNewItem={setAddedWorkplace}
                                newItem={addedWorkplace}
                                notFoundInListLabel={elementdata.workPlaceNotListedLabel}
                                addNewDescription={elementdata.addNewWorkPlaceDescription}
                                addNewTitle={elementdata.addNewWorkPlaceTitle}
                            />


                            <InputWrapper
                                title="Stillingstype"
                                component={CustomDropdown}
                                name={constants.jobTypeId}
                                data={jobtypes}
                                hasCreateButton={false}
                                required={true}
                                showSearch={true}
                                defaultSelectedId={graduation.jobTypeId || ""}
                                errorMsg={getErrorMsg(constants.jobTypeId)}
                                setError={(errorMsg: string) => setError(constants.jobTypeId, errorMsg)}
                                setValue={onJobTypeChange} />

                            <InputWrapper
                                title="Stillingsbetegnelse"
                                component={CustomDropdown}
                                name={constants.jobTitleId}
                                data={filteredJobTitles}
                                hasCreateButton={false}
                                required={true}
                                showSearch={true}
                                errorMsg={getErrorMsg(constants.jobTitleId)}
                                setError={(errorMsg: string) => setError(constants.jobTitleId, errorMsg)}
                                defaultSelectedId={graduation.jobTitleId || ""}
                                setValue={(selected: DropdownItem) => handleChange(constants.jobTitleId, selected.id?.toString())} />
                        </>}

                        {otherChosen &&
                            <InputWrapper
                                title="Beskrivelser"
                                name={constants.otherText}
                                component={CustomTextArea}
                                required={true}
                                errorMsg={getErrorMsg(constants.otherText)}
                                value={graduation.otherText || ""}
                                setError={(errorMsg: string) => setError(constants.otherText, errorMsg)}
                                setInput={(input: string) => handleChange(constants.otherText, input)} />
                        }
                    </OnePageFormWrapper> : null}
        </Wrapper>)
}
export default Graduation;