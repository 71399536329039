interface WatchIconProps {
  color?: string
}
function Watch({ color = "#D4371C" }: WatchIconProps) {

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6406 11.848L13.6417 11.8487C13.8604 11.9965 13.924 12.2951 13.777 12.5156L13.7763 12.5167C13.6285 12.7354 13.3299 12.799 13.1094 12.652L9.73606 10.4031C9.73574 10.4029 9.73542 10.4027 9.7351 10.4024C9.67833 10.3653 9.6201 10.3143 9.57422 10.2513C9.52843 10.1885 9.48711 10.1023 9.48711 10V9.99882L9.51875 4.65625C9.51875 4.65598 9.51875 4.65571 9.51875 4.65543L9.51875 4.65507L9.71875 4.65625C9.71875 4.50086 9.84531 4.375 10 4.375C10.1547 4.375 10.2812 4.50086 10.2812 4.65625L13.6406 11.848ZM13.6406 11.848L10.4812 9.74179L13.6406 11.848ZM19.2 10C19.2 4.91845 15.0816 0.8 10 0.8C4.91845 0.8 0.8 4.91845 0.8 10C0.8 15.0816 4.91845 19.2 10 19.2C15.0816 19.2 19.2 15.0816 19.2 10ZM10 18.2375C5.45225 18.2375 1.7625 14.5477 1.7625 10C1.7625 5.45225 5.45225 1.7625 10 1.7625C14.5477 1.7625 18.2375 5.45225 18.2375 10C18.2375 14.5477 14.5477 18.2375 10 18.2375Z" fill={color} stroke={color} strokeWidth="0.4" />
    </svg>
  )
}

export default Watch;