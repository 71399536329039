import style from './modal.module.scss'
import Cancel from '../icons/Cancel/cancel';
interface ModalProps {
    children?: any;
    onCancelClick: () => void;
    isModalOpen: boolean;
}

function CustomModal(props: ModalProps) {
    return (
        <div className={`${style.modal} ${props.isModalOpen ? style.show : style.hide}`}>
            <div className={style.cancelicon}>
                <span onClick={props.onCancelClick} >
                    <Cancel />
                </span>
            </div>
            <div className={style.modalcontentcontainer}>
                <div className={style.modalbody}>
                    {props.children}
                </div>

            </div>
        </div>
    )
}


export default CustomModal;