import { useCallback, useEffect, useState } from 'react';
import { AdminService } from '../../api';
import { testuserid } from '../../constants/common-constants';
import { guid } from '../../constants/regex-expressions';

import Loader from '../shared/Loader/loader';
import style from './admin-impersonate.module.scss';
export type AdminImpersonateProps = {
}

function AdminImpersonate() {

    const [memberId, setMemberId] = useState<string>("");

    const login = useCallback(async (id?: string) => {
        if (!id) {
            id = testuserid;
        }
        else {
            setMemberId(id)
        }

        try {
            await AdminService.impersonate(id)
            window.location.href = "/forside";
        } catch (ex) {
            console.error(ex);
        }
    }, []);

    useEffect(() => {
        const query = new URL(window.location.href).search;

        if (query) {
            const id = new URLSearchParams(query).get("id");

            if (id && new RegExp(guid).test(id)) {
                login(id)
            } else {
                login();
            }
        } else {
            login();
        }

    }, [login]);
    return (
        <div className={style.admincontainer}>
            
            <Loader />
            <div>Vent venligst...</div>
            {/* this code can be activated if impersonation with id function is activated. 
            {memberId && <div>Logging ind som medlem med id: {memberId}
            </div>} */}
        </div>
    )
}

export default AdminImpersonate;