import style from './message-main.module.scss';
import * as api from '../../../api';
import { getShortDateStr } from '../../../common/dateutil';
import React from 'react';
import DocumentButton from '../../shared/Buttons/DocumentButton/document-button';
import ArrowDown from '../../shared/icons/ArrowDown/arrow-down';
import Email from '../../shared/icons/Email/email';
import { sentFromMe } from '../../../constants/common-constants';

interface MessageMainProps {
    message: api.Message; 
    memberId: string;
    isOpen?: boolean;
    onClick?:()=>void;
}

function MessageMain(props: MessageMainProps) {
const contentText=React.useMemo(()=>{
    var div = document.createElement("div");
    div.innerHTML = props.message.content!;
  return div.textContent || "";
},[props.message.content]);
   



    return (
        <div>
            <div className={`${style.name} ${props.message.sender===sentFromMe?style.outbound:""}`} ><ArrowDown /><Email />{props.message.sender} <div className={`${style.date} wrappedshow`}>{getShortDateStr(new Date(props.message.date!))}</div></div>
            <div className={style.messagetitle} onClick={props.onClick}> {props.message.subject!} </div>
            <div className={`${style.content} ${props.isOpen?style.opencontent:style.closedcontent}`}>              
                 {props.isOpen?<div className={style.content} dangerouslySetInnerHTML={{__html:props.message.content||""}}/>:contentText}
            </div>
            {props.message.attachment! != null
                ? <div className={style.documents}>
                    <DocumentButton download={props.message.attachment.name || undefined} href={`/api/v1/members/${props.memberId}/messages/${props.message.messageId}`} className={style.filename}>
                        <span> {props.message.attachment.name} </span>
                    </DocumentButton>
                </div>
                : <div></div>
            }
        </div>
    )
}

export default MessageMain;