import * as api from '../../../api';
import DeliveryChange from '../../shared/DeliveryChange/delivery-change';
import InputWrapper from "../../shared/InputWrapper/input-wrapper";
import { DropdownItem } from '../../../models/dropdownItem';
import { StepFormData } from "../../../models/step";
import { deliveryOptions } from "../../../models/subscriptionModel";
import style from './signup-fifth-step.module.scss';
import { ChangeDeliveryDistributionFormElement } from '../../../models/umbracoElement';

interface SignupFifthStepProps  extends StepFormData   {
    conactpreferences: DropdownItem[]; 
    signUpModel: api.MemberSignup;
    onDefaultSubscriptionsChanged: (chagnedSubscription: api.MagazineSubscription[]) => void;
    magazines: api.Magazine[];
    deliveryUmbracoData: ChangeDeliveryDistributionFormElement;
}
function SignupFifthStep(props: SignupFifthStepProps) {

    return (
        <div className={style.step}>
            <div className={style.stepTitle}>{"Valg af publikationer"}</div>
            <InputWrapper
            title={""}
            component={DeliveryChange} 
            shouldFocus={true} magazines={props.magazines} subscribedSubscriptions={props.signUpModel.subscriptions!} onDefaultSubscriptionsChanged={props.onDefaultSubscriptionsChanged} umbracodata={props.deliveryUmbracoData} defaultDeliveryOption={deliveryOptions.electronic} />
        </div>
    )
}

export default SignupFifthStep;