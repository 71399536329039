/*membersignup, contact*/
export const firstname = "firstname";
export const middlename = "middlename";
export const lastname = "lastname";
export const initials = "initials";
export const cpr = "cpr";
export const street = "street";
export const zip = "zip";
export const alphabet = "alphabet";
export const changeDistrict = "changeDistrict";
export const city = "city";
export const co = "co";
export const communicationPreferenceId = "communicationPreferenceId";
export const countryId = "countryId";
export const county = "county";
export const door = "door";
export const educationInstitutionId = "educationInstitutionId";
export const educationMainSubjectIds = "educationMainSubjectIds";
export const educationOrientationId = "educationOrientationId";
export const enddate = "enddate";
export const floor = "floor";
export const genderId = "genderId";
export const jobTitleId = "jobTitleId";
export const jobTypeId = "jobTypeId";
export const municipalityId = "municipalityId";
export const number = "number";
export const situationtypeId = "situationtypeId";
export const mobilephone = "mobilephone";
export const privatephone = "privatephone";
export const workphone = "workphone";
export const mail = "mail";
export const workmail = "workmail";
export const gender = "gender";
export const startdate = "startdate";
export const educationStartdate = "educationStartdate";
export const educationEnddate = "educationEnddate";
export const notes = "notes";
export const paymentPreferenceId = "paymentPreferenceId";
export const workLocationId = "workLocationId";
export const unknownWorkLocation = "unknownWorkLocation";
export const confirmedPayment = "confirmedPayment";

/*eventsignup*/
export const pricepackage = "pricePackageId"
export const atendeeType = "atendeeType"
export const contact = "contact"
export const registrationResponses = "registrationResponses";
export const consentCheckedList = "consentCheckedList";