import style from './spinner.module.scss';

function Spinner(props: { size?: number }) {
  const size = props.size || 50;

  return (<div className={style.spinnerdiv} style={{ width: size, height: size }}>
    <svg className={style.spinnercircle} width={size} height={size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="22" stroke="#E6DBD5" strokeWidth="6" />
    </svg>
    <svg className={style.quatercircle} width={size / 2} height={size / 2} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.0004 25.0001C22.0004 21.2401 21.0368 17.5429 19.2014 14.2613C17.3661 10.9797 14.7203 8.2232 11.5166 6.255C8.31288 4.2868 4.6582 3.17253 0.901367 3.01855" stroke="var(--themecolor)" strokeWidth="6" />
    </svg>
  </div>
  )
}

export default Spinner;