import { useState } from 'react';
import CustomCheckbox from '../CustomCheckbox/custom-checkbox';
import FilterButton from '../Buttons/FilterButton/filter-button';
import FlowButton from '../Buttons/FlowButton/flow-button';
import Cancel from '../icons/Cancel/cancel';
import style from './filter.module.scss';
export interface FilterProps {
    title?: string;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    filterList: string[],
    currentFilters: string[],
    setFilter: (newFilters: string[]) => void
}

function Filter(props: FilterProps) {
    const [filters, setFilters] = useState<string[]>(props.currentFilters)
    const setTempFilter = (key: string, checked: boolean) => {
        const indexofkey = filters.indexOf(key);
        if (indexofkey >= 0) {//found key
            if (!checked) {
                const currentFilters = [...filters];
                currentFilters.splice(indexofkey, 1)
                setFilters(currentFilters);
            }
        } else {
            if (checked) {
                setFilters([...filters, key]);
            }
        }
    }

    const onClose = () => {
        setFilters(props.currentFilters);//resetting state
        props.setOpen(false)
    }

    return (<div className={style.filtercontainer}>
        {props.isOpen ? <div className={style.filter}>

            <div className={style.close} onClick={onClose}><Cancel />Luk</div>
            {props.title &&
                <div className={`${style.filteritem} ${style.title}`}>{props.title}</div>}
            <ul>
                {props.filterList.sort().map((l, i) =>
                    <li key={`filteritem-${i}`}>
                        <div className={style.filteritem}>{l}
                            <CustomCheckbox label="" setInput={(checked) => setTempFilter(l, checked || false)} checked={filters.includes(l)} />
                        </div>
                    </li>)}
            </ul>
            <div className={style.savebtn}>
                <FlowButton isSubmit={true} onClick={() => { props.setFilter(filters); props.setOpen(false) }}>Gem filter</FlowButton>
            </div>
        </div> : <FilterButton onClick={() => props.setOpen(true)}><span>Filter</span>
            {filters.length > 0 && <div className={style.filternumber}>{filters.length}</div>}
        </FilterButton>}
    </div>
    )
}

export default Filter;