import React from 'react';
import * as api from '../../../api';
import { InsuranceFormElement } from '../../../models/umbracoElement';
import CustomModal from '../../shared/CustomModal/modal';
import HelperIcon from '../../shared/HelperIcon/helper-icon';
import InsuranceStatusDetail from '../InsuranceStatusDetail/insurance-status-detail';
import NotificationBarSetting, { NotificationBarType } from '../../shared/NotificationBarSetting/notification-bar-setting';
import OkCancelButtons from '../../shared/OkCancelButtons/ok-cancel-buttons';
import TopTitle from '../../shared/TopTitle/top-title';
import style from './insurance-status.module.scss';
import OverviewList from '../../shared/OverviewList/overview-list';
import OverviewItem from '../../shared/OverviewList/OverviewItem/overview-item';
import InsuranceMain from '../InsuranceStatusMain/insurance-status-main';

interface InsuranceStatusProps {
    insurances: api.Insurance[];
    formElm: InsuranceFormElement;
    hideButton?: boolean;
}
function InsuranceStatus(props: InsuranceStatusProps) {
    const [isCancelModalOpen, setCancelModalOpen] = React.useState(false);
    const [isUndoModalOpen, setUndoModalOpen] = React.useState(false);
    const [insuranceToCancel, setInsuranceToCancel] = React.useState<api.Insurance | undefined>();
    const [insuranceToUndo, setInsuranceToUndo] = React.useState<api.Insurance | undefined>();
    const [errorMsg, setErrorMsg] = React.useState("")
    const [loading, setLoading] = React.useState(false);

    const openCancelModal = (insurance: api.Insurance) => {
        setInsuranceToCancel(insurance);
        setCancelModalOpen(true);
    }
    const closeCancelModal = () => {
        setInsuranceToCancel(undefined);
        setCancelModalOpen(false)
    }

    const openUndoModal = (insurance: api.Insurance) => {
        setInsuranceToUndo(insurance);
        setUndoModalOpen(true);
    }
    const closeUndoModal = () => {
        setInsuranceToUndo(undefined);
        setUndoModalOpen(false)
    }

    const unsubscribe = () => {
        if (insuranceToCancel) {
            const unsubscribingInsurance = { ...insuranceToCancel };
            setLoading(true)
            api.InsuranceService.cancelInsurancePolicy(unsubscribingInsurance).then(() => {
                window.location.reload();
            }).catch(err => { setErrorMsg(err); setLoading(false); console.log(err) });
        }
    }

    const undo = () => {
        if (insuranceToUndo) {
            const unsubscribingInsurance = { ...insuranceToUndo };
            setLoading(true)
            api.InsuranceService.undoCancelInsurancePolicy(unsubscribingInsurance).then(() => {
                window.location.reload();
            }).catch(err => { setErrorMsg(err); setLoading(false); console.log(err) });
        }
    }

    return (
        <>
            {props.insurances && <OverviewList
                items={props.insurances.map((insurance, key) =>
                    <div className={style.insurancecard}>
                        <OverviewItem
                            key={`insurance - ${key} `}
                            main={<>
                                <div className={`${style.name} wrappedhidden`}>{insurance.name}</div>
                                <InsuranceMain
                                    key={insurance.insuranceId}
                                    insurance={insurance}
                                    formElem={props.formElm}
                                /></>}
                            detail={<InsuranceStatusDetail
                                key={insurance.insuranceId}
                                insurance={insurance}
                                formElem={props.formElm}
                                openUnscribeModal={openCancelModal}
                                openUndoUnsubscribeModal={openUndoModal}
                                hideButton={props.hideButton}
                            />}
                        ></OverviewItem>
                    </div>
                )}
            />}
            <CustomModal isModalOpen={isCancelModalOpen} onCancelClick={closeCancelModal}>
                <div className={style.elementcontainer}>
                    <TopTitle titletext={props.formElm.unsubscribeInsuranceTitleLabel || ""} helpIcon={props.formElm.unsubscribeInsuranceHelpText ? <HelperIcon helptext={props.formElm.unsubscribeInsuranceHelpText} /> : undefined} />
                    <div className={style.step}>
                        <div dangerouslySetInnerHTML={{ __html: props.formElm.unsubscribeContent || "" }} />
                        <OkCancelButtons
                            cancelLabel={props.formElm.unsubscribeCancelLabel}
                            loading={loading}
                            okLabel={props.formElm.unsubscribeOkLabel}
                            onClickCancel={closeCancelModal}
                            onClickOk={unsubscribe} />
                    </div>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isUndoModalOpen} onCancelClick={closeUndoModal}>
                <div className={style.elementcontainer}>
                    <TopTitle titletext={props.formElm.undoUnsubscribeInsuranceTitelLabel || "Fortryd opsigelse"} helpIcon={props.formElm.undoUnsubscribeInsuranceHelpText ? <HelperIcon helptext={props.formElm.undoUnsubscribeInsuranceHelpText} /> : undefined} />
                    <div className={style.step}>
                        <div dangerouslySetInnerHTML={{ __html: props.formElm.undoUnsubscribeContent || "" }} />
                        <OkCancelButtons
                            cancelLabel={props.formElm.undoUnsubscribeCancelLabel}
                            loading={loading}
                            okLabel={props.formElm.undoUnsubscribeOkLabel}
                            onClickCancel={closeUndoModal}
                            onClickOk={undo} />
                    </div>
                </div>
            </CustomModal>
            {<NotificationBarSetting text={errorMsg} type={NotificationBarType.Danger}/>}
        </>
    )
}

export default InsuranceStatus;