function Document () { 

    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9997 14H3.99968C3.63149 14 3.33301 13.7015 3.33301 13.3333L3.33301 2.66667C3.33301 2.29848 3.63148 2 3.99967 2L9.04173 2C9.22744 2 9.40473 2.07746 9.53091 2.21373L12.4888 5.40831C12.603 5.53154 12.6663 5.6933 12.6663 5.86125L12.6663 13.3333C12.6663 13.7015 12.3679 14 11.9997 14Z" stroke="#253154" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.667 6L9.33366 6C8.96547 6 8.66699 5.70152 8.66699 5.33333L8.66699 2" stroke="#253154" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
}

export default Document;
