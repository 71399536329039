import React from 'react';
import { InfoboxElement, InfoboxLink } from '../../models/umbracoElement';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import style from './infobox.module.scss';
interface InfoboxProps {
    data: any;
}
function Infobox(props: InfoboxProps) {
    const infoboxElem: InfoboxElement = JSON.parse(props.data);
    const [loading, setLoading] = React.useState(false);
    const themeColor = "theme_bgcolor" + (infoboxElem.themeColor || "1");//default color:1
    const isThemeWhite = infoboxElem.themeColor && infoboxElem.themeColor === "4";

    const onClick = (link: InfoboxLink) => {
        setLoading(true);
        window.location.href = link.url;
    }
    return (
        <div className={style.elementconainer}>
            <div className={`${style.elementcontent}`}>
                <div className={style.background} style={{ backgroundColor: `var(--${themeColor})` }}>
                    {infoboxElem.themeColor && <div className={infoboxElem.themeColor ? style.watermark : style.image} style={infoboxElem.backgroundImage?._url ? { backgroundImage: `url(${infoboxElem.backgroundImage._url})` } : undefined} />}
                </div>
                <div className={isThemeWhite ? style.titlewhite : style.titlecolor}>{infoboxElem.title || ""}</div>
                <div className={style.step}>
                    <div className={`${style.content} ${isThemeWhite ? style.white : style.color}`} >
                        <div dangerouslySetInnerHTML={{ __html: infoboxElem.beforeConfirmMessage || "<div></div>" }} />
                        <div className={style.buttoncontainer}>
                            {infoboxElem.infoboxLink.map((link, i) => {
                                return <CTAButton key={i} disabled={loading} onClick={() => onClick(link)} className={isThemeWhite ? style.ctawhite : style.ctacolor}>{link.name}</CTAButton>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Infobox;