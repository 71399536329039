import style from './insurance-health.module.scss'
import React, { useEffect } from "react";
import * as api from '../../../../api';
import { coInsuredHealthLinkTextToken, healthLinkTextToken } from '../../../../constants/common-constants';
import { getCoInsuredHealthInfoLinkParams, getHealthInfoLinkParams } from '../../getHealthInfoLinkParams'


export interface InsuranceHealthProps {
    contentHtml: string;
    onlyCoInsured: boolean;
    insuranceModel: api.CreateInsurance;
    member?: api.Member;
    coInsuredHealthLinkText?: string;
    healthLinkText?: string;
    healthBaseLinks?: api.HealthInformationLinks;
}

function InsuranceHealth(props: InsuranceHealthProps) {

    const [contentHtml, setContentHtml] = React.useState("");

    useEffect(() => {
        if (props.member && props.healthBaseLinks) {
            const link = props.healthBaseLinks.link;
            const coinsuredlink = props.healthBaseLinks.coInsuredLink;
            const coInsured={...props.insuranceModel.coInsured};
            const linkurl = link ? link + getHealthInfoLinkParams(props.member) : "";
            const coInsuredLinkUrl = props.insuranceModel.coInsured ? coinsuredlink + getCoInsuredHealthInfoLinkParams(props.member, coInsured.name!, coInsured.cpr!, (coInsured.email || undefined)) : undefined;
            let newcontentHtml = props.contentHtml.replace(healthLinkTextToken, (props.onlyCoInsured ? "" : `<a href="${linkurl}" target="_blank">${props.healthLinkText || "Hent helbredserklæring"}</a>`));
            newcontentHtml = newcontentHtml.replace(coInsuredHealthLinkTextToken, (props.insuranceModel.coInsured ? `<a href="${coInsuredLinkUrl}" target="_blank">${props.coInsuredHealthLinkText || "Hent helbredserklæring (Medforsikrede)"}</a>` : ""));
            setContentHtml(newcontentHtml);
        }
    }, [props.member, props.healthBaseLinks, props.insuranceModel, props.onlyCoInsured, props.coInsuredHealthLinkText, props.contentHtml, props.healthLinkText])



    return (
        <div className={style.step}>
            <div className={style.healthcontnent} dangerouslySetInnerHTML={{ __html: contentHtml }} />            
        </div >)
}

export default InsuranceHealth;