import React from 'react';
import * as api from '../../../api';
import { getLongDateStr } from '../../../common/dateutil';
import { InsuranceFormElement } from '../../../models/umbracoElement';
import CustomButton from '../../shared/Buttons/CustomButton/custom-button';
import Calendar from '../../shared/icons/Calendar/calendar';
import Information from '../../shared/icons/Information/information';
import style from './insurance-status-detail.module.scss';



interface InsuranceStatusProps {
    insurance: api.Insurance;
    formElem: InsuranceFormElement
    openUnscribeModal: (insurance: api.Insurance) => void
    openUndoUnsubscribeModal: (insurance: api.Insurance) => void
    hideButton?: boolean

}
function InsuranceStatusDetail(props: InsuranceStatusProps) {

    return (

        <div className={`${style.step} ${style.insurancebox}`}>
            {
                renderSwitch(props)
            }
        </div>

    )
}

export default InsuranceStatusDetail;


function renderSwitch(props: InsuranceStatusProps) {
    switch (props.insurance.status) {
        case "Overført": //Never used in the frontend        
        case "Tilmeldt":
            return renderTilmeldt(props)
        case "Tilmelder":
            return renderTilmelder(props);
        case "Afvist":
            return renderAfvist(props);
        case "Afmeldt":
        case "Afmelder":
            return renderAfmeldt(props);
        default:
            return <div />
    }
}

function renderAfvist(props: InsuranceStatusProps) {
    return <div className={style.insurancecontainer}>
        <div className={style.statuscontainer}>
            <div className={`${style.statustitle} ${style.rejected}`}>{props.formElem.rejectedTitle || "Afvist"}</div>
        </div>
    </div>;
}

function renderTilmelder(props: InsuranceStatusProps) {
    return <div className={style.insurancecontainer}>
        <div className={`${style.statustitle} ${style.awaiting}`}>{props.formElem.processingTitle || "Under behandling"}</div>
    </div>;
}

function renderTilmeldt(props: InsuranceStatusProps) {
    return <div className={style.insurancecontainer}>
        <div className={style.statuscontainer}>
            <div className={`${style.statustitle} ${style.signedup}`}>{props.formElem.signedupTitle || "Aktiv"}</div>
            <div className={`${style.activeperiod}`}>
                <Calendar />
                {`${getLongDateStr(new Date(props.insurance.startDate!), false)}`}

                {props.insurance.endDate &&
                    <div className={`${style.enddate}`}>
                        <div className={`${style.between}`}>|</div>
                        {`${getLongDateStr(new Date(props.insurance.endDate!), false)}`}
                    </div>}
            </div>
            <div className={style.groupcontainer}>
                <Information />
                <div className={`${style.group}`}> {"Gruppe " + props.insurance.insuranceType || ""}</div>
            </div>
        </div>
        {!props.insurance.endDate && <div className={style.buttoncontainer}>
            <CustomButton label={props.formElem.unsubscribeButtonLabel || "Opsig forsikring"} onClick={() => props.insurance.insuranceId && props.openUnscribeModal(props.insurance)} />
        </div>}
    </div>;
}

function renderAfmeldt(props: InsuranceStatusProps) {
    return <div className={style.insurancecontainer}>
        <div className={`${style.statustitle} ${style.unsubscribed}`}>{props.formElem.unsubscribedTitle || "Opsagt"}</div>
        <div className={`${style.activeperiod}`}>
            <Calendar />
            <div className={style.startdate}>
                {`${getLongDateStr(new Date(props.insurance.startDate!), false)}`}
            </div>
            {props.insurance.endDate &&
                <>
                <div className={style.between}> / </div>
                <div className={style.enddate}>
                    {`${getLongDateStr(new Date(props.insurance.endDate!), false)}`}
                </div></>}
        </div>
        <div className={style.groupcontainer}>
            <Information />
            <div className={`${style.group}`}> {"Gruppe " + props.insurance.insuranceType || ""}</div>
        </div>
        {props.insurance.canUndoCancel && !props.hideButton && <div className={style.buttoncontainer}>
            <CustomButton label={props.formElem.undoUnsubscribeButtonLabel || "Fortryd opsigelse"} onClick={() => props.insurance.insuranceId && props.openUndoUnsubscribeModal(props.insurance)} />
        </div>}
    </div>;
}



