import style from './custom-input.module.scss';
import React from 'react';
import * as errorMsgs from '../../../constants/common-constants'
import { moduluscontrol } from '../../../common/moduluscontrol';
import { InputBase } from '../../../models/inputBase';

export type CustomInputProps = InputBase & {
    setInput?: (inputtext: string) => void;
    value?: string;
    pattern?: { patternStr: string, errorMsg?: string } | { patternStr: string, errorMsg?: string }[];
    readonly?: boolean;
    hideErrorMsg?: boolean;
    maxLength?: number;
    moduluscheck?: { errorMsg?: string };
}
function CustomInput(props: CustomInputProps) {

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        props.setInput && props.setInput(input);
        validateInput(input);
    }

    const validateInput = (text?: string) => {
        let msg = "";
        let error = false;
        if (!text && props.required) {
            msg = errorMsgs.requiredErrorMsg;
            error = true;
        }
        else if (props.pattern && text) {
            if (Array.isArray(props.pattern)) {
                props.pattern.map(p => {
                    const regexp = new RegExp(p.patternStr)
                    if (!regexp.test(text)) {
                        msg = p.errorMsg || "";
                        error = true;
                    }
                })
            } else {
                const regexp = new RegExp(props.pattern.patternStr)
                if (!regexp.test(text)) {
                    msg = props.pattern.errorMsg || "";
                    error = true;
                }
            }
        }

        if (!error && props.moduluscheck && text) {
            var modulusOK = moduluscontrol(text.replace("-", ""))
            if (!modulusOK) {
                msg = props.moduluscheck.errorMsg || "";
                error = true;
            }
        }
        if (props.setError) {
            props.setError(msg);
        }
    }
    return (
        <div className={style.custominput}>
            <input
                name={props.name}
                type={props.type || "text"}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                readOnly={props.readonly}
                autoFocus={props.setFocus}
                value={props.value || ""}
                disabled={props.disabled}
                onChange={onInputChange}
                onBlur={() => { validateInput(props.value) }}
                required={props.required}
                className={`${style.input} 
              ${props.errorMsg ? style.error : ""}`} />

        </div>
    )
}

export default CustomInput;