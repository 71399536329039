import style from './ok-cancel-buttons.module.scss';
import React from 'react';
import Loader from '../Loader/loader';
import CustomButton from '../Buttons/CustomButton/custom-button';
import FlowButton from '../Buttons/FlowButton/flow-button';
import ButtonZone from '../ButtonZone/button-zone';

interface OkCancelButtonsProps {
    cancelLabel?: string;
    okLabel?: string;
    okDisabled?: boolean;
    onClickCancel: () => void;
    onClickOk: () => void;
    loading?: boolean;
}

function OkCancelButtons(props: OkCancelButtonsProps) {

    return (
        <div className={style.buttonscontainer}>
            <ButtonZone>
                <div className={style.cancelbuttoncontainer}>
                    <FlowButton isBack={true} onClick={props.onClickCancel}>{props.cancelLabel || "Annuller"}</FlowButton>
                </div>
                <div className={style.okbuttoncontainer}>
                    {props.loading ? <Loader /> : <FlowButton isSubmit={true} disabled={props.okDisabled || false} onClick={props.onClickOk}>{props.okLabel || "Ok"}</FlowButton>}
                </div>
            </ButtonZone>
        </div>
    )
}

export default OkCancelButtons;