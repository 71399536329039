import style from "./input-wrapper.module.scss";
import Label, { LabelProps } from "../FormLabel/form-label";
import React from 'react'
export type InputWrapperProps<T extends React.ComponentType<any>> =
   { component: T } & React.ComponentProps<T> & LabelProps ;

const InputWrapper =
   <T extends React.ComponentType<any>>({
      component: Component,
      ...props
   }: InputWrapperProps<T>) => {
      return <div className={style.inputwrapper}>
         <Label for={props.name} required={props.required} title={props.title} description={props.description} />
         <div className={style.formelem}>
            {<Component {...props} />}
            {<div className={style.errorMessage}>{!props.hideErrorMsg && props.errorMsg ? props.errorMsg : ""}</div>}
         </div>
      </div>
   }
export default InputWrapper;