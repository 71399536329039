import React, { useEffect } from 'react';
import { setupMenu, toggleVisibility } from '../../common/menuHelper';
import { useWindowSize } from '../../hooks/windowsize';
import { NavigationMenu } from '../../models/navigationMenu';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import Cancel from '../shared/icons/Cancel/cancel';
import MenuItemRow from './menu-item-row';
import style from './menu.module.scss';

interface MenuProps {
    data: string;
}
const Menu = (props: MenuProps) => {
    const [navMenu] = React.useState<NavigationMenu>(props.data ? JSON.parse(props.data) : {});
    const menuref = React.useRef<(HTMLDivElement | null)>();


    const onClickLogout = () => {
        window.location.assign("/?logout=true");
    }
    return (
        <div ref={r => menuref.current = r} className={`${style.shadow} ${style.menu} `}>
            <a href="/forside">
                <img className={style.logo} src={navMenu.HeaderLogoUrl} alt={"logo"} />
            </a>
            <div className={`${style.menuitemrow} ${style.closerow}`}>
                <span onClick={toggleVisibility}><Cancel /></span>
            </div>
            {navMenu.Navigations?.map((x, i) =>
                <MenuItemRow
                    key={`nav-${i}`}
                    childrenMenus={x.SubNavigations}
                    title={x.Title}
                    isCurrent={x.IsCurrent}
                    iconUrl={x.MenuIconUrl}
                    url={x.Url} />)}
            <div onClick={onClickLogout} className={`${style.logout}`}>
                <div className={style.logouttext}>Log ud</div></div>
            <div className={`${style.footer}`} dangerouslySetInnerHTML={{ __html: navMenu.Text?.Value || "<div/>" }}></div>

            {navMenu.Links?.map((link, i) =>
                <div key={`link-${i}`} className={`${style.contactbtn}`}>
                    <CTAButton href={link.Url} target={link.Target} >{link.Name}</CTAButton>
                </div>
            )}

        </div>
    )
}

export default Menu;