/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobType } from '../models/JobType';
import { request as __request } from '../core/request';

export class JobtypesService {

    /**
     * Description: Get a list of work related job types.
 * Operation: GetJobTypes.
 * Area: Jobtypes.
     * @returns JobType Success
     * @throws ApiError
     */
    public static async getJobTypes(): Promise<Array<JobType>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/jobtypes`,
        });
        return result.body;
    }

}