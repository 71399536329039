export const requiredErrorMsg = "Feltet skal udfyldes";
export const dateErrorMsg = "Ugyldig dato";
export const emailErrorMsg = "Ugyldig mailadresse";
export const telErrorMsg = "Ugyldigt telefonnummer";
export const fixBeforeContinue = "Udfyld venligst de påkrævede felter";
export const fetchErrorMsg = "Data kunne ikke hentes";
export const postErrorMsg = "Der er sket en fejl. Data kunne ikke gemmes"
export const characterValidationErrorMsg = "Feltet indeholder ugyldige tegn"

export const firstNameReplaceToken = "#{userfirstname}#";
export const lastNameReplaceToken = "#{userlastnanme}#";
export const fullNameReplaceToken = "#{userfullname}#";

export const cprReplaceToken = "#{usercpr}#";
export const emailReplaceToken = "#{useremail}#";
export const addressReplaceToken = "#{useraddress}#";
export const zipcodeReplaceToken = "#{userzipcode}#";
export const cityReplaceToken = "#{usercity}#";
export const telephoneReplaceToken = "#{usertelephone}#";
export const districtReplaceToken = "#{userdistrict}#";

export const healthLinkTextToken = "#{healthLinkText}#";
export const coInsuredHealthLinkTextToken = "#{coInsuredHealthLinkText}#";

export const newid = "00000000-0000-0000-0000-000000000000";
export const testuserid = "00000000-0000-0000-0000-000000000001";
export const logoutText = "Log ud";

export const eventLoggedinPath = "/kurser/detaljer/"
export const eventAnonymousPath = "/kurser-offentlig/detaljer/"
export const eventsignupModalOpenQuery = "?registering=true"

export const singlechoiceEnumNo = 100000003;
export const multiplechoiceEnumNo = 100000002;

export const studentEnumNo = 121290003;
export const inJobEnumNo = 121290000;
export const unemployedEnumNo = 121290001;
export const pensionistEnumNo = 121290002;
export const retirementRecipientEnumNo = 121290005;

export const pensionistEnumType = "Pensionist";
export const retirementRecipientEnumType = "Efterlønsmodtager";

export const otherSituationNo = -1;

export const defaultCountryCode = "DK";
export const denmarkId = "ef25361e-80a3-eb11-b1ac-000d3ab2a9e7";
export const greenlandId = "a04e7625-80a3-eb11-b1ac-000d3ab2a0e3";

export const defaultImage = `https://${window.location.host}/temp/event1.png`;
export const defaultEventImage = `https://${window.location.host}/temp/event_default.png`;

export const freeOfChargeStr = "Gratis";
export const sentFromMe = "Du har sendt";