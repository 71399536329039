/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class ConflictService {

    /**
     * Description: A member selected for conflict has confirmed their situation.
 * Operation: Conflictsituationconfirmation.
 * Area: Conflict.
     * @param conflictselectionid ID of conflictselection.
     * @returns string Success
     * @throws ApiError
     */
    public static async conflictsituationconfirmation(
conflictselectionid: string,
): Promise<string> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/conflict/situationconfirmation/${conflictselectionid}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}