import { EditorState } from 'draft-js';
import React, { useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as api from '../../api';
import { getErrorMsgByName } from '../../common/getErrorMsgByName';
import { getNewErrorList } from '../../common/getNewErrorList';
import { requiredErrorMsg } from '../../constants/common-constants';
import { DropdownItem } from '../../models/dropdownItem';
import { defaultMessageModel } from '../../models/messageModel';
import { FormError } from '../../models/step';
import { MessageElement } from '../../models/umbracoElement';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import CustomDropdown from '../shared/CustomDropdown/custom-dropdown';
import CustomInput from '../shared/CustomInput/custom-input';
import CustomUploadButton from '../shared/CustomUploadButton/custom-upload-button';
import InputWrapper from '../shared/InputWrapper/input-wrapper';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './message.module.scss';

interface MessageProps {
    memberid: string;
    data?: string;
}
function Message(props: MessageProps) {
    const [data] = React.useState<MessageElement>(props.data ? JSON.parse(props.data) : undefined);
    const [loading, setLoading] = React.useState(true);
    const [successMsg, setSuccessMsg] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");
    const [chosenContact, setChosenContact] = React.useState<any>();
    const getTicksStr = () => new Date().getTime().toString();
    const editorRef = React.useRef<Editor>(null);
    const blurdivRef = React.useRef<(HTMLInputElement | null)>();
    const [uploadkey, setUploadkey] = React.useState<string>(getTicksStr());
    const [attachment, setAttachment] = React.useState<File>();
    const [message, setMessage] = React.useState<api.Message>(defaultMessageModel);
    const [dropDownSubjects, setDropDownSubjects] = React.useState<DropdownItem[]>();
    const [disableButton, setDisableButton] = React.useState(false);
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty(),);
    const [showTooLongMsg, setShowTooLongMsg] = React.useState(false);
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const getMessageSubjectData = async () => {
        setLoading(true);
        try {
            var data: api.DlfMessageSubject[] = await api.DlfmessagesubjectsService.getDlfMessageSubjects();
            const ddSubjects: DropdownItem[] = data.map(x => { return { id: x.subjectId, text: x.text } as DropdownItem });
            setDropDownSubjects(ddSubjects);
            // If only one element in dropdown just select it.
            if (ddSubjects.length == 1) {
                onContactChange(ddSubjects[0]);
            }
        }
        finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getMessageSubjectData();
        }
        call().finally(() => { setLoading(false) });
    }, []);

    const onContactChange = (selectedItem: DropdownItem) => {
        setChosenContact(selectedItem.id);
        setMessage({ ...message, subjectId: selectedItem.id, receiver: selectedItem.text });
    }
    const onSubjectChange = (input: string) => {
        setModel("subject", input);
    }

    const replacer = (_: string, value: string) => {
        return value.replace(/\r?\n|\r/g, " ");
    }

    const onEditorChange = (draftEditorState: EditorState) => {
        setEditorState(draftEditorState)

        var content = draftEditorState.getCurrentContent();
        const isEditorEmpty = !content.hasText();
        const currentPlainText = content.getPlainText();
        const lengthOfTrimmedContent = currentPlainText.trim().length
        const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
        let toolong = false;
        let msgtxt = ""
        if (!isContainOnlySpaces) {
            // replacing new line to space. 
            msgtxt = JSON.stringify(draftEditorState.getCurrentContent().getPlainText(), replacer);
            // removing "" that are added by stringify 
            msgtxt = msgtxt.substring(1, msgtxt.length - 1);
            // prevent having more than 2000 characters
            if (msgtxt.length > 2000) {
                msgtxt = msgtxt.substring(1, 2000);
                toolong = true;
            }
            setModel("content", msgtxt);
            setError("content", "")
        }
        else {
            setError("content", data?.contentNotIncluded || requiredErrorMsg)
            setModel("content", undefined);
        }
        if (toolong) {
            /** text is too long. Set previous state and blur from editor.
             *  (as editorref has no blue, we set focus on invisible input)
             *  by bluring, editor can show the text of the previous state.
             */
            setEditorState(editorState);
            setTimeout(() => blurdivRef.current?.focus());
            setTimeout(() => editorRef.current?.focusEditor());

        }
        setShowTooLongMsg(msgtxt.length >= 2000);
    }

    const goToMessageSent = () => {
        window.location.href = data.redirectLink.url;
    }

    const checkRequiredData = () => {
        const newErrorList: FormError[] = [];
        if (typeof (chosenContact) === 'undefined' || chosenContact === 'Vælg...') {
            newErrorList.push({ name: "subjectId", errorMsg: data?.dlfSubjectNotChosen || requiredErrorMsg })
        }
        if (typeof (message?.subject) === 'undefined' || message.subject === '') {
            newErrorList.push({ name: "subject", errorMsg: data?.subjectNotIncluded || requiredErrorMsg })
        }
        if (typeof (message?.content) === 'undefined' || message.content === '') {
            newErrorList.push({ name: "content", errorMsg: data?.contentNotIncluded || requiredErrorMsg })
        }
        setErrorList(newErrorList);
        return newErrorList.length === 0;;

    }
    const onSendMessage = async () => {

        if (checkRequiredData()) {
            try {
                setDisableButton(true);

                // Set remaining values 
                message!.date = new Date().toUTCString();   // TODO check datetime 
                message!.messageId = props.memberid;        // TODO get messageid
                message!.sender = props.memberid;         // TODO get dlf contacts

                var formData = new FormData();
                formData.append("Request.Message.Date", message!.date!);
                formData.append("Request.Message.Content", message!.content!);
                formData.append("Request.Message.Date", message!.date || "");
                formData.append("Request.Message.MessageId", message!.messageId!);
                formData.append("Request.Message.Priority", message!.priority || "");
                formData.append("Request.Message.Receiver", message!.receiver!);
                formData.append("Request.Message.Sender", message!.sender!);
                formData.append("Request.Message.SubjectId", message!.subjectId!);
                formData.append("Request.Message.Subject", message!.subject!);

                formData.append("Request.Message.Cc", message!.cc || "");
                formData.append("Request.Message.Priority", message!.priority || "");

                // TODO Attachment and File are the same thing check which is proper 
                formData.append("Request.Message.Attachment.Name", message!.attachment!.name || "");
                formData.append("Request.Message.Attachment.Type", message!.attachment!.type || "");
                formData.append("Request.Message.Attachment.Basestring", message!.attachment!.basestring || "");

                if (!attachment) {
                    formData.append("Request.File", new Blob());
                }
                else {
                    formData.append("Request.File", attachment!)
                }

                //await api.MembersService.postMembersService(props.memberid, formData);    // TODO Fix formdata on memberservice
                await fetch('/api/v1/members/' + props.memberid + '/messages/create', {
                    method: 'post',

                    body: formData
                }).then(async (reps) => {
                    if (reps.status === 204) {
                        formData = new FormData();

                        await goToMessageSent();
                    }
                    else {
                        setErrorMsg("Din besked blev ikke indsendt, prøv igen, eller kontakt os på tlf");
                        setDisableButton(false);
                    }

                }).finally(() => {
                });

            } catch (e) {
                console.log(e);
                setDisableButton(false);
            }
        }
    }


    const setModel = (name: string, value: string | boolean | object | undefined) => {

        let newMessage: api.Message | undefined = message ? {
            ...message,
        } : defaultMessageModel;
        if (newMessage) {
            (newMessage as any)[name] = value;
            setMessage(newMessage);
        }
    }

    /**Forcing rerender upload button component */
    const setNewUploadKey = () => {
        setAttachment(undefined);
        setUploadkey(getTicksStr());

        const newMessage = { ...message };
        newMessage.attachment!.basestring = undefined;
        newMessage.attachment!.name = undefined;
        newMessage.attachment!.type = undefined;
        setMessage(newMessage);

    }
    const onFileAttached = (ev: { target: { files: any; }; }) => {
        if ((ev.target.files?.length || 0) > 0) {
            const size = ev.target.files?.item(0)?.size;
            if (size && size > 5000000) {
                setErrorMsg("Din filvedhæftning er større end det tilladte maksimum på 5 MB.")
                setAttachment(undefined);
                setUploadkey(new Date().getTime().toString())
            }
            else {
                setAttachment(ev.target.files?.item(0) || undefined);

                const newMessage = { ...message };
                newMessage.attachment = { name: ev.target.files![0].name, type: ev.target.files![0].type, basestring: "..." };

                setMessage(newMessage);
            }
        }
        else {
            setAttachment(undefined);
            setUploadkey(new Date().getTime().toString())

            const newMessage = { ...message };
            newMessage.attachment!.basestring = undefined;
            newMessage.attachment!.name = undefined;
            newMessage.attachment!.type = undefined;
            setMessage(newMessage);
        }
    }

    const onClickEditorDiv = () => {
        editorRef.current?.focusEditor();
    }

    const editorError = React.useMemo(() => getErrorMsgByName(errorList, "content"), [errorList])

    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);
    }

    return (<Wrapper properties={data} loading={loading}
        bottomZone={<CTAButton onClick={onSendMessage} disabled={disableButton}>Send besked</CTAButton>}
        notification={successMsg ? { message: successMsg, type: NotificationBarType.Success, onClick: () => setSuccessMsg("") } :
            errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}


    >
        {loading ? null : < div className={`${style["col-6"]} ${style["col-8-lg"]}  ${style["col-8-md"]} ${style["col-12-sm"]}`}>
            <form className={style.textfieldtop}>

                {
                    dropDownSubjects && dropDownSubjects.length > 1 ?
                        <InputWrapper
                            component={CustomDropdown}
                            setFocus={true}
                            required={true}
                            data={dropDownSubjects!}
                            setValue={(input: DropdownItem) => onContactChange(input)}
                            hasCreateButton={false}
                            showSearch={false}
                            errorMsg={getErrorMsgByName(errorList, "subjectId")}
                            setError={(errorMsg: string) => setError("subjectId", errorMsg ? data?.dlfSubjectNotChosen : "")}
                            defaultSelectedId={chosenContact}
                            title={data?.dropDownPlaceholder}
                        />
                        : <></>
                }
                <InputWrapper
                    component={CustomInput}
                    title={"Emne"}
                    required={true}
                    setError={(errorMsg: string) => setError("subject", errorMsg ? data?.subjectNotIncluded : "")}
                    errorMsg={getErrorMsgByName(errorList, "subject")}
                    value={message?.subject || undefined}
                    setInput={(input: string) => onSubjectChange(input)} />

            </form>
            <form className={style.textfieldbot}>
                <div className={style.fileattachtment}>
                    <CustomUploadButton onRemoveClicked={setNewUploadKey} uploadKey={uploadkey} onFileAttached={onFileAttached} />
                </div>
                <div className={`${style.editor} ${editorError ? style.erroreditor : ""}`} onClick={onClickEditorDiv} >
                    <Editor
                        ref={editorRef}
                        editorState={editorState}
                        onEditorStateChange={onEditorChange}
                        toolbar={{
                            options: ['history'],
                        }}
                    />
                </div>
                {(showTooLongMsg ? <div className={`${style.errorMessage} ${style.inputinformationtxt} ${style.toolongmsg}`}>(Beskeden må ikke være længere end 2000 tegn)</div>
                    : <div className={`${style.inputinformationtxt}`}>(Maks. 2000 tegn)</div>)}
                {editorError && <div className={`${style.errorMessage} ${style.toolongmsg}`}>{editorError}</div>}
            </form>
            <input className={style.hiddenblurdiv} ref={r => blurdivRef.current = r}></input>
        </div>

        }
    </Wrapper >

    )
}

export default Message;