import CTAButton from '../Buttons/CTAButton/cta-button';
import style from './load-more-wrapper.module.scss';

interface LoadMoreWrapperProps {
    children: any;
    loadMore: () => void;
    hasMore: boolean;
    className?:string;
}
function LoadMoreWrapper(props: LoadMoreWrapperProps) { 

    return (
        <div className={props.className}>
            {props.children}
            {props.hasMore&&<div className={style.loadmore}>
                <CTAButton onClick={props.loadMore}>Indlæs flere</CTAButton></div >}
        </div >
    )
}
export default LoadMoreWrapper;