import React, { useEffect } from 'react';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import NotificationBar from '../NotificationBar/notification-bar';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import Email from '../shared/icons/Email/email';
import SmartPhone from '../shared/icons/SmartPhone/smart-phone';
import style from './top-bar.module.scss';

interface TopBarProps {
    name?: string;
    email?: string;
    phone?: string;
    topbartitle?: string;
    showuserinfo?: string;
    memberid?: string;
    loan?: string;
}

function TopBar(props: TopBarProps) {
    const [showuserinfo] = React.useState<boolean>(props.showuserinfo === "true")
    const topbarref = React.useRef<(HTMLDivElement | null)>();

    const onClickLogout = () => {
        window.location.assign("/?logout=true");
    }

    return (
        <div ref={r => topbarref.current = r} id="dlf-topbar" className={`${style.topbar} ${showuserinfo ? "" : style.onlytitle}`}>
            <div className={`${style.topbarinner}`}>
                {showuserinfo ?
                    <div className={style.userinfo}>
                        <div className={style.username}>Hej {props.name || "-"}</div>
                        <div className={style.email}><div><Email /></div>{props.email || "-"}</div>
                        <div className={style.phonelink}>
                            <div className={style.phone}><div><SmartPhone /></div>{props.phone || "-"}</div>
                            <div className={style.link}><a href="/mine-kontaktoplysninger">Ret stamdata</a></div>
                        </div>
                        {(props.loan && parseFloat(props.loan) > 0) && <div className={style.loan}>Restgæld på lån: {parseFloat(props.loan).toLocaleString('da-dk', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kr.</div>}
                    </div>
                    : <div className={style.title}>{props.topbartitle || ""}</div>
                }
                {isUserLoggedIn(props.memberid) &&
                    <div className={style.logout}><CTAButton onClick={onClickLogout}>Log ud</CTAButton></div>}
            </div>
            <NotificationBar></NotificationBar>
        </div>
    )
}

export default TopBar;