import React from 'react';
import { MenuItem } from '../../models/menuItem';
import DownCircle from '../shared/icons/DownCircle/down-circle';
import Home from '../shared/icons/Home/home';

import style from './menu.module.scss';

interface MenuItemRowProps {
    url: string;
    title: string;
    iconUrl?: string;//no icon in children
    isCurrent: boolean;
    childrenMenus: MenuItem[];
    isSub?: boolean;
    isAnchor?: boolean;
    setParentSubnavOpen?: () => void;
}
const MenuItemRow = (props: MenuItemRowProps) => {
    const [subnavOpen, setSubnavOpen] = React.useState(false);
    const [currentAnchor, setCurrentAnchor] = React.useState("");

    React.useEffect(() => {
        if (props.isCurrent) {
            if (props.isSub) {
                const title = window.location.hash?.replace("#", "").replace("_", " ");
                if (title) {
                    setTimeout(() => scrollToView(title));
                }
            }
            else {
                setSubnavOpen(true);
            }
        }
    }, [])

    const scrollOrGotoPage = (title: string) => {
        if (!props.isCurrent) {
            const anchor = title.replaceAll(" ", "_");
            window.location.href = props.url + "#" + anchor;
        }
        else {
            scrollToView(title);
        }
    }
    const delay = (i: number) => {
        return new Promise(function (resolve) {
            setTimeout(() => {
                resolve(document.getElementsByClassName("loader")?.length > 0);

            }, i * 200);
        });
    }

    const waitForLoad = async () => {
        for (var i = 1; i < 10; i++) {
            let loading = await delay(i);
            if (!loading) {
                return;
            }
        }
    }

    const scrollToView = async (title: string) => {
        await waitForLoad();
        setCurrentAnchor(title);
        var elems = document.querySelectorAll("[class^='top-title_toptitle_text']");
        var topbar = document.querySelector("#dlf-topbar")
        var elemsArray = Array.from(elems);
        var elmToScroll = elemsArray.find(el => el.textContent === title);
        if (elmToScroll) {
            props.setParentSubnavOpen && props.setParentSubnavOpen();
        }
        elmToScroll?.scrollIntoView();
        if (topbar) {
            window.scrollBy(0, topbar.clientHeight * -1);
        }
    }
    return (<>
        <div className={style.menuitemrow}>
            <div className={style.menuitemicon}>
                {!props.isSub && (props.iconUrl ? <img src={props.iconUrl} alt={props.title} /> : null)}
            </div>
            <div className={style.menutitle}>
                {props.isAnchor ? <div className={`${style.menulink} ${props.isCurrent && currentAnchor === props.title ? style.current : ""}`} onClick={() => scrollOrGotoPage(props.title)}>{props.title}</div> :
                    <a className={`${style.menulink} ${props.isCurrent ? style.current : ""}`} href={props.url}>
                        {props.title}
                    </a>}
            </div>
            {props.childrenMenus.length > 0 &&
                <div className={`${style.submenuicon} ${subnavOpen ? style.submenuOpen : ""}`} onClick={() => setSubnavOpen(!subnavOpen)}>
                    <DownCircle />
                </div>}
        </div>
        <div className={subnavOpen ? "" : style.hidden}>
            {props.childrenMenus.map((x, i) => <MenuItemRow
                setParentSubnavOpen={() => setSubnavOpen(true)}
                key={`subnav-${i}`}
                isSub={true}
                childrenMenus={[]}
                title={x.Title}
                isCurrent={x.IsCurrent}
                iconUrl={x.MenuIconUrl}
                isAnchor={x.IsAnchor}
                url={x.Url} />)}
        </div>
    </>

    )
}

export default MenuItemRow;