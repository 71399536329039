function Timer () { 
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21C16.4183 21 20 17.4183 20 13C20 8.58172 16.4183 5 12 5C7.58172 5 4 8.58172 4 13C4 17.4183 7.58172 21 12 21Z" stroke="#253154" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.5 13C11.5 13.2761 11.7239 13.5 12 13.5C12.2761 13.5 12.5 13.2761 12.5 13H11.5ZM12.5 9C12.5 8.72386 12.2761 8.5 12 8.5C11.7239 8.5 11.5 8.72386 11.5 9H12.5ZM12.5 13V9H11.5V13H12.5Z" fill="#253154"/>
      <path d="M20 7L18 5" stroke="#253154" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 3L14 3" stroke="#253154" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      
    )
}

export default Timer;