import React, { useEffect } from 'react';
import { ChangeDeliveryDistributionFormElement } from '../../models/umbracoElement';
import DeliveryChange from '../shared/DeliveryChange/delivery-change';
import Wrapper, { NotificationMessage } from '../shared/Wrapper/wrapper';
import { Magazine, MagazinesService, MagazineSubscription, SubscriptionsService } from '../../api';
import { deliveryOptions } from '../../models/subscriptionModel';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import ButtonZone from '../shared/ButtonZone/button-zone';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';
import { closeNotification } from '../../common/notificationHelper';

interface ChangeDeliveryDistributionProps {
    data: string;
    memberid: string;
}

function DeliveryDistribution(props: ChangeDeliveryDistributionProps) {
    const [loading, setLoading] = React.useState(true);
    const [magazines, setMagazines] = React.useState<Magazine[]>([]);
    const [subscriptions, setSubscriptions] = React.useState<MagazineSubscription[]>();
    const [notification, setNotification] = React.useState<NotificationMessage>()

    const elementdata: ChangeDeliveryDistributionFormElement = JSON.parse(props.data);
    useEffect(() => {
        const call = async () => {
            const magazineData = await MagazinesService.getMagazines();
            const subscriptions = await SubscriptionsService.getMySubscriptions();

            setSubscriptions(magazineData.map(m => {
                const existingSub = subscriptions.find(s => s.magazine?.magazineId === m.magazineId);
                return { deliveryOption: existingSub?.deliveryOption || deliveryOptions.unsubscribed, magazineId: m.magazineId!, subscriptionId: existingSub?.subscriptionId };
            }));

            setMagazines(magazineData);
        }
        call().finally(() => { setLoading(false) });
    }, []);

    const subscriptionChanged = (changedSubscriptions: MagazineSubscription[]) => {
        setSubscriptions(changedSubscriptions);
    }

    const saveSubscriptions = () => {
        setLoading(true);
        SubscriptionsService.changeDelivery({ updatedSubscriptions: subscriptions }).then(() => {
            setNotification({ message: elementdata.messageAfterSuccess || "Opdateret successfuldt", type: NotificationBarType.Success });
            setTimeout(() => { 
                setNotification(undefined);
                 closeNotification(); }, 5000);
        })
            .catch((error) => {
                setNotification({
                    message: elementdata.messageError || "Dine præferencer for fagblade blev ikke opdateret, prøv igen, eller kontakt os på tlf",
                    type: NotificationBarType.Danger,
                    onClick: () => setNotification(undefined)
                });
            })
            .finally(() => {
                setLoading(false);
            });;
    }
    return (
        <Wrapper
            properties={elementdata}
            loading={loading}
            notification={notification ? { ...notification } : undefined}
            bottomZone={<ButtonZone><FlowButton isSubmit={true} onClick={saveSubscriptions} disabled={loading}>Gem</FlowButton></ButtonZone>}
        >
            {!loading && <DeliveryChange
                umbracodata={elementdata}
                shouldFocus={true}
                magazines={magazines}
                defaultDeliveryOption={deliveryOptions.electronic}
                onDefaultSubscriptionsChanged={subscriptionChanged}
                subscribedSubscriptions={subscriptions}
            />}
        </Wrapper>
    )
}

export default DeliveryDistribution;