
import * as api from '../../../api';
import { getDateStr, getDurationFromMinutes } from '../../../common/dateutil';
import { getPlaceString } from '../../../common/getPlaceString';
import Calendar from '../../shared/icons/Calendar/calendar';
import Place from '../../shared/icons/Place/place';
import style from '../event-details.module.scss';

interface EventDetailInfoWindowProps {
    eventImageUrl: string | undefined;
    currentEvent: api.Event;
    onClickBack?: () => void;
}

function EventDetailInfoWindow({ eventImageUrl, currentEvent, onClickBack }: EventDetailInfoWindowProps) {

    return <div className={style.detailwindow}>
        {eventImageUrl && <img src={eventImageUrl} alt={currentEvent.eventInformation?.name || "event"} />}
        <div className={style.detailinfo}>
            <div className={style.title}>
                {currentEvent.eventInformation?.name || ""}
            </div>
            {(currentEvent.eventLocation?.city || currentEvent.eventLocation?.country) && <div className={style.place}>
                <div className={style.icon}>
                    <Place />
                </div>
                {getPlaceString(currentEvent.eventLocation?.city || "", currentEvent.eventLocation?.country || "")}
            </div>}
            {currentEvent.eventInformation?.startdate &&
                <div className={style.date}>
                    <div className={style.icon}>
                        <Calendar />
                    </div>
                    {`${getDateStr(new Date(currentEvent.eventInformation!.startdate!))} ${currentEvent.eventInformation?.duration ? `-  ${getDurationFromMinutes(currentEvent.eventInformation?.duration)}` : ""}`}
                </div>}
            {currentEvent.eventInformation?.description &&
                <div className={style.description} dangerouslySetInnerHTML={{ __html: currentEvent.eventInformation.description }}></div>}
            {onClickBack && <div className={style.gobacklink} onClick={onClickBack}>Gå til kursus</div>}

        </div>
    </div>

}
export default EventDetailInfoWindow;