import style from './search-box.module.scss';
import React from 'react';
import Search from '../icons/Search/search';

interface SearchBoxProps {
    setInput: (inputtext: string) => void,
    focus?:boolean,
    onKeyDown?:(ev:any)=>void
    reference:any
}
function SearchBox(props: SearchBoxProps) {
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setInput(event.target.value);
    }
    return (
        <div className={style.searchbox}>
            <Search />
            <input ref={props.reference} onKeyDown={props.onKeyDown} autoFocus={props.focus} placeholder="Søg..." onChange={onInputChange} className={`${style.searchinput} ${style.input}`} />
        </div>
    )
}

export default SearchBox;