import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as api from '../../../api';
import CTAButton from '../Buttons/CTAButton/cta-button';

interface ZExpenseProps {
    memberId: string;
    buttonText: string;
}

function ZExpense(props: ZExpenseProps) {
    const getRemoteLogin = async () => {
        var remoteLoginLink = await api.ZexpenseService.getzexpense(props.memberId);
        if (remoteLoginLink) {
            window.open(remoteLoginLink, "_blank");
        }
    }

    return (
        <CTAButton onClick={getRemoteLogin} >{props.buttonText}</CTAButton>
    )
}
export default ZExpense;