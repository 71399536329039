import style from './custom-textarea.module.scss';
import React from 'react';
import * as errorMsgs from '../../../constants/common-constants';
import { InputBase } from '../../../models/inputBase';

interface CustomTextAreaProps extends InputBase {
    setInput?: (inputtext: string) => void;
    value?: string;
    pattern?: string;
    hideErrorMsg?: boolean;
    maxLength?: number;
    rowCount?: number;
}

function CustomTextArea(props: CustomTextAreaProps) {

    const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const input = event.target.value;
        props.setInput && props.setInput(input);
        validateInput(input);
    }

    const validateInput = (text?: string) => {
        let msg = "";
        if (!text && props.required) {
            msg = errorMsgs.requiredErrorMsg;
        }
        else if (props.pattern && text) {
            const regexp = new RegExp(props.pattern)
            if (!regexp.test(text)) {
                msg = props.errorMsg || "";
            }
        }

        if (props.setError) {
            props.setError(msg);
        }
    }
    return (
        <div className={style.custominput}>
             <textarea rows={props.rowCount || 3} maxLength={props.maxLength} autoFocus={props.setFocus} value={props.value || ""} disabled={props.disabled} onChange={onInputChange} onBlur={() => { validateInput(props.value) }} required={props.required} className={`${style.textarea} ${props.errorMsg ? style.error : ""}`} />
        </div>
    )
}
export default CustomTextArea;