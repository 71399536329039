import React from "react";
import * as api from '../../api';
import { LocalUnionInformationElement } from "../../models/umbracoElement";
import Loader from "../shared/Loader/loader";
import CustomTable from "../shared/CustomTable/custom-table";
import TopTitle from "../shared/TopTitle/top-title";

interface ILocalUnionInformationProps {
    data: string
    memberid: string
}

function LocalUnionInformation(props: ILocalUnionInformationProps) {

    const { data, memberid } = props;

    const [loading, setLoading] = React.useState(false);
    const [elementdata] = React.useState<LocalUnionInformationElement>(data ? JSON.parse(data) : {})
    const [LUI, setLUI] = React.useState<api.LocalUnionInformation | undefined>(undefined);

    const getData = React.useCallback(async (refresh: boolean = false) => {
        try {
            const lui: api.LocalUnionInformation = await api.MembersService.localUnionInformation(memberid);
            setLUI(lui);

        } catch (error) {
            console.log(error)
        }
    }, [])

    React.useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getData();
        }
        call().finally(() => setLoading(() => false));
    }, [getData]);


    const columns = [
        {
            columnName: elementdata.columnName,
            visible: true,
            index: 0
        }, {
            columnName: elementdata.columnRole,
            visible: true,
            index: 1
        }
    ];

    return (
        <>
            {loading
                ? <Loader />
                : <div>
                    <TopTitle titletext={elementdata.title} />
                    <div style={{ paddingBottom: "10px" }}>
                        <p>{LUI?.localUnionName}</p>
                    </div>

                    <TopTitle titletext={elementdata.myRole} />
                    <div style={{ paddingBottom: "10px" }}>
                        {LUI?.myRoles?.split(",").map(x => <><p>{x}</p></>)}
                    </div>
                    <TopTitle titletext={elementdata.chairmen} />
                    <div style={{ width: "90%" }}>
                        <CustomTable
                            columns={columns}
                            rows={LUI?.chairmen?.map(c => ({ data: [c.fullName, c.role], rawdata: null }))}
                            showButtonLabel=""
                            emptyText={"Kunne ikke finde formænd for lokalforeningen"}
                        />
                    </div>
                </div>
            }
        </>
    )
}

export default LocalUnionInformation;