import AccordionElement, { AccordionElementProps } from './AccordionElement/accordion-element';
import style from './custom-accordion.module.scss';



export type CustomAccordionProps = {
    data: AccordionElementProps[];
}

function CustomAccordion(props: CustomAccordionProps) {
    return (
        <div className={style.customaccordion}>
            {
                props.data.map((item, index) =>
                    <AccordionElement key={index} title={item.title} byDefaultOpen={item.byDefaultOpen} dropdownValues={item.dropdownValues} />
                )
            }
        </div>
    )
}

export default CustomAccordion;