import { MaterialFormat } from '../../../../constants/materialFormat';
import DocumentButton from '../../Buttons/DocumentButton/document-button';
import style from './material-element.module.scss';

interface MaterialElementProps {
    materialId: string;
    title: string;
    format: MaterialFormat;
    link: string;
    text?: string;
    hasSignedUp: boolean;
}


function MaterialElement(props: MaterialElementProps) {
    return (
        <div className={`${style.materialelementcontainer} ${style[props.format.toString()]}`}>
            {props.format === MaterialFormat.File
                ?
                <div className={style.filecontainer}>
                    <div>
                        <DocumentButton title={props.title} download={props.title} href={props.hasSignedUp ? `/api/v1/material/${props.materialId}` : undefined} className={style.materialname}>
                            <span> {props.title} </span>
                        </DocumentButton>
                        <div className={style.materialtext}> {props.text} </div>
                    </div>
                </div>
                : <div />
            }
            {
                props.format === MaterialFormat.Link
                    ?
                    <div className={style.linkcontainer}>
                        <div className={style.materialname}>
                            <a download={props.title} title= {props.title} href={props.hasSignedUp ? props.link : undefined} target="_blank" rel="noreferrer"> <span> {props.title} </span> </a>
                        </div>
                        <div className={style.materialtext}> {props.text} </div>
                    </div>
                    : <div />
            }
        </div >

    )
}

export default MaterialElement;