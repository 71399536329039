import * as api from '../../../api';
import { MaterialFormat } from '../../../constants/materialFormat';
import { EventMaterialElement } from '../../../models/umbracoElement';
import MaterialElement from './MaterialElement/material-element';
import style from './material.module.scss';

interface MaterialProps {
    eventMaterials: Array<api.EventMaterial>;
    hasSignedUp: boolean;
    data?: any;
    titletext?: string
}

function Material(props: MaterialProps) {
    const data: EventMaterialElement = JSON.parse(props.data);
    return (
    
            <div className={`${style.signedUpfilter} ${style[props.hasSignedUp + ""]}`}>
                {
                    props.eventMaterials.length > 0 ?
                        <div className={style.materialelements}>
                            <div className={style.materialtitle}>Filer</div>
                            <div className={`${style.materials} ${style[props.hasSignedUp + ""]}`}>
                                {
                                    props.eventMaterials.some(item => item.type === "DocuNoteFil") ?
                                        props.eventMaterials!.filter(m => m.type === "DocuNoteFil").map(m => (
                                            <div className={style.material}>
                                                {
                                                    <MaterialElement materialId={m.materialId!} title={m.name!} text={m.description!} format={MaterialFormat.File} link={m.link!} hasSignedUp={props.hasSignedUp} key={m.materialId} />
                                                }

                                            </div>))
                                        : <div className={style.nofiles}> Der er ingen filer at vise... </div>
                                }
                            </div>
                            <div className={style.materialtitle}>Links</div>
                            <div className={`${style.materials} ${style[props.hasSignedUp + ""]}`}>
                                {
                                    props.eventMaterials!.some(item => item.type === "OnlineMateriale") ?
                                        props.eventMaterials!.filter(m => m.type === "OnlineMateriale").map(m => (
                                            <div className={style.material}>
                                                {
                                                    <MaterialElement materialId={m.materialId!} title={m.link!} format={MaterialFormat.Link} text={m.description!} link={m.link!} hasSignedUp={props.hasSignedUp} key={m.materialId} />
                                                }

                                            </div>))
                                        : <div className={style.nofiles}> Der er ingen filer at vise... </div>
                                }
                            </div>
                        </div>
                        : <div className={style.nomaterial}>
                            {props.hasSignedUp
                                ? <div>{data.noMaterialsText}</div>
                                : <div />
                            }
                        </div>
                }
            </div>

    )
}

export default Material;