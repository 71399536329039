import React, { useEffect } from "react";
import { useWindowSize } from "../../../../hooks/windowsize";
import ArrowDown from "../../icons/ArrowDown/arrow-down";
import HalfCircle from "../../icons/HalfCircle/half-circle";
import style from "./overview-item.module.scss";

export interface OverviewItemProps {
    filterKey?: string;
    main: JSX.Element;
    detail: JSX.Element;
    isOpen?: boolean;
    onClick?: () => void
}
const OverviewItem = (props: OverviewItemProps) => {
    const size = useWindowSize();
    const mainref = React.useRef<(HTMLDivElement | null)>();
    const detailref = React.useRef<(HTMLDivElement | null)>();
    useEffect(() => {

        checkWrapped();

    }, [size]);
    useEffect(() => { checkWrapped(); }, [])
    const checkWrapped = () => {

        if (mainref.current && detailref.current) {
            const detailchild = detailref.current.firstElementChild;
            const mainchild = mainref.current.firstElementChild

            if (detailref.current.offsetLeft === mainref.current.offsetLeft) {
                detailref.current.style.maxWidth = "";
                mainref.current.style.maxWidth = ""
                if (detailchild &&
                    detailchild.className.indexOf(" wrappeddetail") < 0)
                    detailchild.className = detailchild.className + " wrappeddetail"

                if (mainchild &&
                    mainchild.className.indexOf(" wrappedmain") < 0)
                    mainchild.className = mainchild.className + " wrappedmain"
            }
            else {
                detailref.current.style.maxWidth = "30%";
                mainref.current.style.maxWidth = "70%"
                if (detailchild)
                    detailchild.className = detailchild.className.replaceAll(" wrappeddetail", "")
                if (mainchild)
                    mainchild.className = mainchild.className.replaceAll(" wrappedmain", "")
            }
        }
    }

    return <div className={`${style.overview} ${props.isOpen ? style.open : ""}`}>
        <div ref={r => mainref.current = r}
            className={style.main}>
            {props.main}
        </div>
        <div
            ref={r => detailref.current = r} className={style.detail}>
            {props.detail}
        </div>
        {props.onClick ? <div onClick={props.onClick} className={`${style.openbutton} ${props.isOpen ? style.open : ""}`}><div className={style.openicon}><HalfCircle /><ArrowDown /></div></div> : null}
    </div>
}

export default OverviewItem;