/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Monthly<br />1 = Hourly
 */
export enum SalaryType {
    MONTHLY = 'Monthly',
    HOURLY = 'Hourly',
}