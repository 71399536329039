import React, { useMemo } from 'react';
import * as api from '../../../api';
import { getDateStr, getDateStrFromDateStr, getDurationFromMinutes } from '../../../common/dateutil';
import { getEventPrice } from '../../../common/get-event-price';
import { getStatusElement } from '../../../common/get-event-status';
import { getPlaceString } from '../../../common/getPlaceString';
import { EventSignupElement } from '../../../models/umbracoElement';
import Refusion from '../../Refusion/refusion';
import CTAButton from '../../shared/Buttons/CTAButton/cta-button';
import { AccordionDropdownElement, AccordionElementProps } from '../../shared/CustomAccordion/AccordionElement/accordion-element';
import CustomAccordion from '../../shared/CustomAccordion/custom-accordion';
import Calendar from '../../shared/icons/Calendar/calendar';
import Place from '../../shared/icons/Place/place';
import InformationBox from '../../shared/InformationBox/information-box';
import Material from '../../shared/Material/material';
import style from '../event-details.module.scss';
import Sessions from '../sessions';
import EventDetailsParticipants from '../EventDetailsParticipants/event-details-participants';

interface EventDetailMainProps {
    currentEvent: api.Event;
    eventImageUrl: string | undefined;
    onSignupClick: () => void;
    onEventCancelClick: () => void;
    onSessionSignupClick: (session: api.EventSession, cancel?: boolean) => void
    errorMsg: { title: string, description: string };
    settingdata: EventSignupElement;
    signedIn: boolean;
    eventEnded: boolean;
    signedUpSessionIds: string[];
    infoMsg: string;
    memberid: string;
}
function EventDetailMain({
    memberid,
    infoMsg,
    onSessionSignupClick,
    onSignupClick,
    currentEvent,
    eventImageUrl,
    signedIn,
    settingdata,
    eventEnded,
    signedUpSessionIds,
    onEventCancelClick }: EventDetailMainProps) {

    const isAnonymousPage = !window.location.href.includes("/kurser/");
    const canCancel = React.useMemo(() => !currentEvent.eventInformation?.cancelDeadline ? true : new Date(currentEvent.eventInformation!.cancelDeadline!) > new Date(), [currentEvent]);
    const getEventdata = React.useCallback(() => {

        let eventdata: AccordionElementProps[] =
            [{
                byDefaultOpen: true,
                title: "Praktisk info",
                dropdownValues: [
                    { title: "Startdato", value: getDateStrFromDateStr(currentEvent?.eventInformation?.startdate || "") },
                    { title: "Slutdato", value: getDateStrFromDateStr(currentEvent?.eventInformation?.enddate || "-") },
                    { title: "Varighed", value: getDurationFromMinutes(currentEvent?.eventInformation?.duration) },
                    { title: "Type", value: currentEvent?.eventInformation?.eventType || "" }
                ]
            }];
        if (!currentEvent.sessions || currentEvent.sessions?.length === 0) {
            eventdata.push({
                title: "Sted",
                byDefaultOpen: true,
                dropdownValues: [
                    { title: "Sted", value: currentEvent?.eventLocation?.name || "" },
                    { title: "Område", value: currentEvent?.eventLocation?.country || "" },
                    { title: "By", value: currentEvent?.eventLocation?.city || "" }
                ]

            });
        }       

        if (signedIn && currentEvent?.eventMaterials && currentEvent?.eventMaterials.length > 0) {
            eventdata.push({
                title: "Kursusmateriale",
                byDefaultOpen: true,
                dropdownValues: [<Material data={JSON.stringify(settingdata)} hasSignedUp={currentEvent?.signedUp || false} eventMaterials={currentEvent?.eventMaterials || []} />]


            })
        }

        if (signedIn &&
            currentEvent?.participants && currentEvent?.participants.length > 0) {
            eventdata.push({
                title: "Deltagerliste",
                byDefaultOpen: false,
                dropdownValues: [<EventDetailsParticipants participants={currentEvent.participants!}></EventDetailsParticipants>]
            })
        }

        if (signedIn &&
            currentEvent?.registrationResponses && currentEvent?.registrationResponses.length > 0 &&
            currentEvent?.eventInformation?.customRegistrationFields && currentEvent?.eventInformation?.customRegistrationFields.length > 0) {
            eventdata.push({
                title: "Mine Svar",
                byDefaultOpen: true,
                dropdownValues: currentEvent.registrationResponses.filter(s => currentEvent.eventInformation?.customRegistrationFields?.findIndex(c => c.id === s.questionId) !== -1).map<AccordionDropdownElement>(r => { return { title: currentEvent.eventInformation?.customRegistrationFields!.filter(rf => rf.id === r.questionId)[0].text!, value: r.answer! } })
            })
        }
        
       
        return eventdata;
    }, [currentEvent, signedIn, settingdata])


    const getEventdata2 = React.useCallback(() => {
        

        let eventdata: AccordionElementProps[] = [
            {
                title: "Frister",
                byDefaultOpen: true,
                dropdownValues: [
                    { title: "Tilmeldingsfrist", value: getDateStrFromDateStr(currentEvent?.eventInformation?.stopWebRegistrationDate || "") || "-" },
                    { title: "Afmeldingsfrist", value: getDateStrFromDateStr(currentEvent?.eventInformation?.cancelDeadline || "") || "-" },
                ]
    
            },
        {
            title: "Pris",
            byDefaultOpen: true,
            dropdownValues: signedIn || !currentEvent.eventInformation?.signupWithoutLogin ? [
                { title: `Grundpakkepris`, value: getEventPrice(currentEvent?.eventPriceInfomration, currentEvent?.eventPriceInfomration?.attendingPriceForMembers) },
                { title: `Fuldpakkepris`, value: getEventPrice(currentEvent?.eventPriceInfomration, currentEvent?.eventPriceInfomration?.allinclusivePriceForMembers) },
            ] : isAnonymousPage ? [
                { title: `Grundpakkepris - Ikke-medlem`, value: getEventPrice(currentEvent?.eventPriceInfomration, currentEvent?.eventPriceInfomration?.attendingPriceForNonMembers) },
                { title: `Fuldpakkepris - Ikke-medlem`, value: getEventPrice(currentEvent?.eventPriceInfomration, currentEvent?.eventPriceInfomration?.allinclusivePriceForNonMembers) },
            ] : [
                { title: `Grundpakkepris - Medlem`, value: getEventPrice(currentEvent?.eventPriceInfomration, currentEvent?.eventPriceInfomration?.attendingPriceForMembers) },
                { title: `Grundpakkepris - Ikke-medlem`, value: getEventPrice(currentEvent?.eventPriceInfomration, currentEvent?.eventPriceInfomration?.attendingPriceForNonMembers) },
                { title: `Fuldpakkepris - Medlem`, value: getEventPrice(currentEvent?.eventPriceInfomration, currentEvent?.eventPriceInfomration?.allinclusivePriceForMembers) },
                { title: `Fuldpakkepris - Ikke-medlem`, value: getEventPrice(currentEvent?.eventPriceInfomration, currentEvent?.eventPriceInfomration?.allinclusivePriceForNonMembers) },
            ]
        }
        ]
       
        return eventdata;
    }, [currentEvent, signedIn, settingdata])

    const statusOrSignUpButton = useMemo(() => {
        const onLoginClick = () => {
            if (currentEvent.eventId)
                window.location.href = "/kurser/detaljer/" + currentEvent.eventId
        };
        const overdue = !currentEvent.eventInformation?.stopWebRegistrationDate ? false : new Date(currentEvent.eventInformation.stopWebRegistrationDate) <= new Date();

        return <>{!currentEvent.signedUp && !eventEnded && !overdue && !currentEvent.onWaitList ? <>
            {(currentEvent.eventInformation?.signupWithoutLogin || signedIn) && !infoMsg &&
                <CTAButton onClick={onSignupClick}>Tilmeld kursus</CTAButton>}
            {(!currentEvent.eventInformation?.signupWithoutLogin && !signedIn) &&
                <CTAButton onClick={onLoginClick}>Log ind for at tilmelde dig</CTAButton>}
        </>
            : <>{getStatusElement(style, (currentEvent.signedUp), currentEvent.onWaitList, currentEvent.eventInformation?.enddate, currentEvent.eventInformation?.stopWebRegistrationDate)}
                {currentEvent.signedUp && !eventEnded && canCancel && <CTAButton className={`${style.eventcancelbtn}`} onClick={onEventCancelClick}>{settingdata.deregistrationButtonLabel || "Afmeld"}</CTAButton>}
            </>}
            {infoMsg && !eventEnded && <div className={style.infomation}><InformationBox title="" content={infoMsg} /></div>}</>
    }, [currentEvent, signedIn, onSignupClick, canCancel, eventEnded, infoMsg])

    return (<>
        <div className="col-12 row">
            <div className={`${style.mainleft} col-7 col-12-lg col-12-sm col-12-md`}>
                <h1>{currentEvent.eventInformation?.name}</h1>
                <div className={style.shortdescription} >{currentEvent.eventInformation?.shortDescription || ""}</div>
                {(currentEvent.eventLocation?.city || currentEvent.eventLocation?.country) && <div className={style.place}>
                    <div className={style.icon}>
                        <Place />
                    </div>
                    {getPlaceString(currentEvent.eventLocation?.city || "", currentEvent.eventLocation?.country || "")}
                </div>}
                {currentEvent.eventInformation?.startdate &&
                    <div className={style.date}>
                        <div className={style.icon}>
                            <Calendar />
                        </div>
                        {`${getDateStr(new Date(currentEvent.eventInformation!.startdate!))} ${currentEvent.eventInformation?.duration ? `-  ${getDurationFromMinutes(currentEvent.eventInformation?.duration)}` : ""}`}
                    </div>}
            </div>

            <div className={`${style.mainright} col-5 col-12-lg col-12-md col-12-sm`}>
                {eventImageUrl && (<img src={eventImageUrl} alt={currentEvent.eventInformation?.name || "event"} />)}
            </div>

        </div>
        <div className={style.statusbutton}>
            {statusOrSignUpButton}
            {/* refusion button on event will be shown if event has no sessions */}
            {(!currentEvent.sessions || currentEvent.sessions.length === 0) && <Refusion memberid={memberid} currentItem={currentEvent} umbracoSetting={{ title: settingdata.refusionTitle || "", helpText: settingdata.refusionHelpText, id: "", contentTypeAlias: "", buttonText: settingdata.refusionButtonLabel || "Søg refusion" }} />}
        </div>


        <div className="col-12 row">
            <div className={`${style.practicalInfo}  col-7 col-12-lg col-12-sm col-12-md`}>
                {currentEvent.eventInformation?.description && <div >
                    <div className={style.divtitle}>Beskrivelse</div>
                    <div className={style.longdescription} dangerouslySetInnerHTML={{ __html: currentEvent.eventInformation?.description || "" }}></div>

                </div>}
                <CustomAccordion
                    data={getEventdata()}
                ></CustomAccordion>
            </div>
            <div className={`${style.buttoncontainer} col-5 col-12-lg col-12-md col-12-sm`}>
                {statusOrSignUpButton}
                {/* refusion button on event will be shown if event has no sessions */}
                    {(!currentEvent.sessions || currentEvent.sessions.length === 0) && <Refusion memberid={memberid} currentItem={currentEvent} umbracoSetting={{ title: settingdata.refusionTitle || "", helpText: settingdata.refusionHelpText, id: "", contentTypeAlias: "", buttonText: settingdata.refusionButtonLabel || "Søg refusion" }} />}
            </div>
        </div>
        <div className="col-12 row">
            <div className={`${style.practicalInfo}  col-7 col-12-lg col-12-sm col-12-md`}>
                {currentEvent.sessions && currentEvent.sessions.length > 0 &&
                    <Sessions
                        memberid={memberid}
                        isMemberSignedIn={signedIn}
                        signedUpSessionIds={signedUpSessionIds}
                        openSessionModal={onSessionSignupClick}
                        elementData={settingdata} event={currentEvent}
                        eventEnded={eventEnded}></Sessions>
                }
            </div>
        </div>
        <div className="col-12 row">
            <div className={`${style.practicalInfo}  col-7 col-12-lg col-12-sm col-12-md`}>
                <CustomAccordion data={getEventdata2()}
                ></CustomAccordion>
            </div>
        </div>
    </>)

}
export default EventDetailMain;