import ButtonBase, { BaseButtonProps } from "../ButtonBase/button-base";
import style from "../../Buttons/buttons.module.scss";
import React from "react";
import Loader from "../../Loader/loader";
import ArrowNext from "../../icons/ArrowNext/arrow-next";
import ArrowBack from "../../icons/ArrowBack/arrow-back";


const FlowButton = (props: BaseButtonProps & { isBack?: boolean, isSubmit?: boolean, loading?: boolean }) => {

    const buttonProps = React.useMemo(() => {
        const { isSubmit, isBack, loading, ...rest } = props;
        return {
            ...rest,
            className: `${style.flow} ${props.isBack ? style.back : props.isSubmit ? style.submit : ""} ${props.className || ""}`
        }
    }, [props])

    return <ButtonBase {...buttonProps} >
        <div id={`flow_${props.isSubmit ? "submit" : props.isBack ? "back" : "next"}`} className={style.flowchildren}>
            {props.loading ? <Loader size={28}/> : <>
                {props.isBack && <ArrowBack />}{props.children}{!props.isBack && !props.isSubmit && <ArrowNext darkbackground={true} />}
            </>}
        </div>
    </ButtonBase>
}

export default FlowButton;