
const CheckmarkCircle = () => {

  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#699A40" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.5303 8.53033C13.8232 8.23744 13.8232 7.76256 13.5303 7.46967C13.2374 7.17678 12.7626 7.17678 12.4697 7.46967L13.5303 8.53033ZM9 12L8.46967 12.5303C8.76256 12.8232 9.23744 12.8232 9.53033 12.5303L9 12ZM7.53033 9.46967C7.23744 9.17678 6.76256 9.17678 6.46967 9.46967C6.17678 9.76256 6.17678 10.2374 6.46967 10.5303L7.53033 9.46967ZM12.4697 7.46967L8.46967 11.4697L9.53033 12.5303L13.5303 8.53033L12.4697 7.46967ZM9.53033 11.4697L7.53033 9.46967L6.46967 10.5303L8.46967 12.5303L9.53033 11.4697Z" fill="#699A40"/>
</svg>

    
  )
}

export default CheckmarkCircle;


