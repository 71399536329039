import React, { Fragment, useEffect } from "react";
import { getDateStr } from "../../common/dateutil";
import CustomTable from "../shared/CustomTable/custom-table";
import Wrapper, { NotificationMessage } from "../shared/Wrapper/wrapper";
import * as api from '../../api';
import { fetchErrorMsg } from "../../constants/common-constants";
import { NotificationBarType } from "../shared/NotificationBarSetting/notification-bar-setting";
import { MyReductionElement } from "../../models/umbracoElement";
import CustomAccordion from "../shared/CustomAccordion/custom-accordion";
import { AccordionDropdownElement, AccordionElementProps } from "../shared/CustomAccordion/AccordionElement/accordion-element";
import style from "./reduction-list.module.scss";

interface ReductionsListProps {
    data: string
    memberid: string
}
const columns = [
    {
        columnName: 'Reduktionsårsag',
        visible: true,
        index: 0
    },
    {
        columnName: 'Fra',
        visible: true,
        index: 1
    },
    {
        columnName: 'Til',
        visible: true,
        index: 2
    },
    {
        columnName: 'Status',
        visible: true,
        index: 3
    },
];

function ReductionsList(props: ReductionsListProps) {
    const [elementdata] = React.useState<MyReductionElement>(props.data ? JSON.parse(props.data) : {});
    const [loading, setLoading] = React.useState(false);
    const [notification, setNotification] = React.useState<NotificationMessage>();
    const [reductionData, setReductionData] = React.useState<{
        rowData: { data: any[] }[],
        accordionData: AccordionElementProps[]
    }>({ rowData: [], accordionData: [] })

    const createReductionRows = (reductions: api.Reduction[]) =>
        reductions.map(x => ({ data: [x.reductionReason || "", x.startDate ? getDateStr(new Date(x.startDate)) : "", x.endDate ? getDateStr(new Date(x.endDate)) : "", x.status?.name || ""] }));

    const createAccordionData = (reductions: api.Reduction[]) => {
        const datalist: AccordionElementProps[] = [];
        reductions.forEach(x => {
            const ddvals: AccordionDropdownElement[] = [];
            ddvals.push({ title: "Fra", value: x.startDate ? getDateStr(new Date(x.startDate)) : "" });
            ddvals.push({ title: "Til", value: x.endDate ? getDateStr(new Date(x.endDate)) : "" });
            ddvals.push({ title: "Status", value: x.status?.name || "" })
            datalist.push({ title: x.reductionReason || "", byDefaultOpen: false, dropdownValues: ddvals })
        });
        return datalist;
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await api.MembersService.getReductions(props.memberid);
                setReductionData({ rowData: createReductionRows(res), accordionData: createAccordionData(res) });
            } catch (err) {
                setNotification({ message: fetchErrorMsg, type: NotificationBarType.Danger, onClick: () => setNotification(undefined) })
            } finally {
                setLoading(false);
            }
        })();
    }, [])

    return (
        <Wrapper properties={{ ...elementdata, title: elementdata.title || "Dine reduktioner" }}
            loading={loading}
            notification={notification}
        >
            <><div className={style.hiddenlg}>
                <CustomTable columns={columns} rows={reductionData.rowData}
                    showButtonLabel=""
                    emptyText="Ingen reduktioner registreret"
                />
            </div>
                <div className={style.showlg}>
                    <CustomAccordion data={reductionData.accordionData} />
                </div>
            </>
        </Wrapper>
    )
}

export default ReductionsList;