import React, { useEffect } from 'react';
import * as api from '../../../api';
import { getErrorMsgByName } from '../../../common/getErrorMsgByName';
import { getNewErrorList } from '../../../common/getNewErrorList';
import { replaceToken, ReplaceTokenRes } from '../../../common/replaceToken';
import { fetchErrorMsg, fixBeforeContinue, postErrorMsg } from '../../../constants/common-constants';
import * as formconstants from '../../../constants/form-item-constants';
import { isVisible } from '../../../common/use-condition';
import { FormCondition, FormData as UmbracoFormData, FormField, FormItemElement } from '../../../models/formModels';
import { FormError } from '../../../models/step';
import CustomButton from '../Buttons/CustomButton/custom-button';
import CustomUploadButton from '../CustomUploadButton/custom-upload-button';
import Loader from '../Loader/loader';
import NotificationBarSetting, { NotificationBarType } from '../NotificationBarSetting/notification-bar-setting';
import TopTitle from '../TopTitle/top-title';
import FieldItem from './field-item/field-item';
import style from './form-item.module.scss';
import { closeNotification } from '../../../common/notificationHelper';

interface FormItemProps {
    data: string;
    memberid: string;
}

function FormItem(props: FormItemProps) {

    const getTicksStr = () => new Date().getTime().toString();
    const [user, setUser] = React.useState<api.Member>();
    const [item, setItem] = React.useState<UmbracoFormData>({});
    const [loading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [successMsg, setSuccessMsg] = React.useState("");
    const [attachment, setAttachment] = React.useState<File>();
    const [attachments, setAttachments] = React.useState<File[]>();
    const [elm, setElm] = React.useState<FormItemElement>();
    const [uploadkey, setUploadkey] = React.useState<string>(getTicksStr());
    const [requiredList, setRequiredList] = React.useState<FormField[]>([]);

    useEffect(() => {
        const jsonelem: FormItemElement = JSON.parse(props.data)
        populateRequiredLIst(jsonelem);
        setElm(jsonelem)

        setLoading(true)
        api.MembersService.getMemberById(props.memberid)
            .then(x => setUser(x))
            .catch(error => { setErrorMsg(fetchErrorMsg); console.group(error) })
            .finally(() => {
                setLoading(false);
            })
    }, [props.data, props.memberid]);

    const onChange = (key: string, value: any) => {
        const newItem = { ...item };
        newItem[key] = value || "";
        setItem(newItem);
    }
    const reset = () => {
        setItem({});
        setNewUploadKey();
    }

    const handleError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        if (errorList.length !== newErrorlist.length) {
            setErrorList(newErrorlist);
        }
    }

    const getErrorMsg = (name: string) => {
        return getErrorMsgByName([...errorList], name);

    }

    const onFileAttached = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if ((ev.target.files?.length || 0) > 0) {
            let files: File[] = [];
            for (let i = 0; i < ev.target.files!.length; i++) {

                const size = ev.target.files?.item(i)?.size;
                if (size && size > 5000000) {
                    setErrorMsg("Din filvedhæftning er større end det tilladte maksimum på 5 MB.")
                }
                //setAttachment(ev.target.files?.item(0) || undefined);
                files.push(ev.target.files!.item(i)!);
            }
            setAttachments(files);
        }
        else {
            setAttachment(undefined);
            setUploadkey(new Date().getTime().toString())
        }
    }

    const checkRequired = () => {
        const newItem = { ...item };
        let valid = true;
        requiredList.forEach(x => {
            const visible = isVisible(newItem, x.condition)
            const isCheckbox = x.type === formconstants.checkbox;
            const val = newItem[x.alias]
            if (visible)
                if (!val || (isCheckbox && val === "false")) {
                    valid = false;
                }
        });
        return valid;
    }

    const onSubmitClick = async () => {
        let valid = true;
        if (requiredList.length > 0)
            valid = checkRequired();

        if (valid) {
            setLoading(true);
            setErrorMsg("");
            const formData = new FormData();
            formData.append("Request.Content", JSON.stringify(item))
            formData.append("Request.Workflowname", elm?.workflowName || "")
            if (attachment)
                formData.append("Request.AttachedFile", attachment)

            let applicationId = "";
            api.SubmitformService.submitForm(formData)
                .then((result) => {
                    applicationId = result;
                    reset();
                    setSuccessMsg("Indsendt succesfuldt")
                    setTimeout(() => { setSuccessMsg(""); closeNotification(); }, 5000)
                })
                .catch(() => {
                    setErrorMsg(postErrorMsg);
                })
                .finally(() => {
                    if (applicationId !== "" && attachments!.length > 0) {
                        for (let i = 0; i < attachments!.length; i++) {
                            let formData = new FormData();
                            formData.append("Request", attachments![i]);

                            api.SubmitformService.addAttachmentToApplication(applicationId, formData)
                                .then((r) => {
                                    reset();
                                    setSuccessMsg("Vedhæftet succesfuldt")
                                    setTimeout(() => { setSuccessMsg(""); closeNotification(); }, 5000)
                                })
                                .catch(() => {
                                    setErrorMsg(postErrorMsg);
                                })
                                .finally(() => {
                                    setLoading(false)
                                });
                        }
                        setLoading(false);
                    }
                    else {
                        setErrorMsg(fixBeforeContinue);
                    }

                });
        }
        else {
            setErrorMsg(fixBeforeContinue);
        }
    }

    const getFields = (formField: FormField, key: string, addPading: boolean, style: React.CSSProperties, replaceRes: ReplaceTokenRes) => {
        return <FieldItem
            addPadding={addPading}
            fieldstyle={style}
            key={key}
            topmodel={item}
            formItem={formField}
            disabled={replaceRes.replaced}
            func={onChange}
            value={item[formField.alias] || replaceRes.value}
            setError={(errorMsg: string) => handleError(formField.alias, errorMsg)}
            errorMsg={getErrorMsg(formField.alias)} />
    }

    const getColumns = (key: string, fieldstyle: React.CSSProperties, fields: JSX.Element[], caption?: string, condition?: FormCondition) => {
        return isVisible(item, condition) ?
            (<><div className={style.caption}>{caption}</div>
                <div key={key} className={style.fielditembox} style={fieldstyle} >
                    {fields}
                </div></>)
            : null;
    }

    /**Forcing rerender upload button component */
    const setNewUploadKey = () => {
        setAttachment(undefined);
        setUploadkey(getTicksStr());
    }

    const getFieldSets = (key: string, fieldstyle: React.CSSProperties, columns?: (JSX.Element | null)[], caption?: string, condition?: FormCondition) => {
        return isVisible(item, condition) && columns && columns.filter(x => x !== null).length > 0 ?
            <div key={key}><div className={style.caption}>{caption}</div><div className={style.fielditemboxWrapper}>{columns}</div></div>
            : null;
    }

    const populateRequiredLIst = (itemelm: FormItemElement) => {
        const newRequiredList: FormField[] = [];
        itemelm.form.pages.forEach((x) => {
            x.fieldsets.forEach(fs => {
                fs.columns.forEach((c) => {
                    c.fields.forEach((f) => {
                        if (f.required) {
                            newRequiredList.push(f);
                        }
                    });
                });
            });
        });
        setRequiredList(newRequiredList)
    }

    const renderFields = () => {
        const newfields = elm!.form.pages.map((x, xi) => {
            const fieldsets = x.fieldsets.map((fs, fsi) => {
                const fsKey = `${xi}-${fsi}`
                const fieldstyle = { width: (100 / fs.columns.length) + "%" };
                const columns = fs.columns.map((c, ci) => {
                    const fKey = `${fsKey}-${ci}`
                    const fields = c.fields.map((f, i) => {
                        const fkey = `${fKey}-${i}`;
                        const replaceRes = replaceToken(user, f.settings?.defaultValue);
                        const addPadding = (ci + 1) < fs.columns.length;

                        return getFields(f, fkey, addPadding, fieldstyle, replaceRes);
                    });
                    return getColumns(fKey, fieldstyle, fields, c.caption, fs.condition)

                });
                return getFieldSets(fsKey, fieldstyle, columns, fs.caption, fs.condition)
            });
            return fieldsets.filter(x => x !== null).length > 0 ? <div className={x.caption ? style.fieldsets : ""}><div className={style.caption}>{x.caption}</div>{fieldsets}</div> : null;
        });
        return newfields;
    }

    return (
        <div className={`${style.elementcontent}`}>
            {elm && user && <div className={style.formitem}>
                <TopTitle titletext={elm.form.name || ""} />
                <div className={style.step}>
                    {renderFields()}
                    {elm && elm.showFileupload && <div className={style.attachment}><CustomUploadButton allowMultiple={true} onRemoveClicked={setNewUploadKey} uploadKey={uploadkey} onFileAttached={onFileAttached} /></div>}
                    <div className={style.submitbtn}>
                        {loading
                            ? <Loader />
                            :
                            <>
                                <CustomButton disabled={errorList.length > 0} onClick={onSubmitClick} label={elm.form.submitLabel || "Submit"} />
                            </>
                        }
                    </div>
                    {<NotificationBarSetting text={errorMsg} type={NotificationBarType.Danger} />}
                    {<NotificationBarSetting text={successMsg} type={NotificationBarType.Success} />}
                </div>
            </div>}
        </div>
    )
}

export default FormItem;