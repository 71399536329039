import style from "./form-label.module.scss";

export type LabelProps = { title?: string, description?: string, required?: boolean ,for?:string};
const Label = (props: LabelProps) => {
    return <>
        {props.title && <label htmlFor={props.for} className={props.description?style.labelwithdescription:style.label}>
            {`${props.title}${props.required ? "*" : ""}`}
        </label>}
        {props.description && <div className={style.description}>{props.description}</div>}
    </>;
}
export default Label;