/* eslint-disable jsx-a11y/anchor-is-valid */
import style from './custom-dropdown.module.scss';
import { DropdownItem } from '../../../models/dropdownItem';
import SearchBox from '../SearchBox/search-box';
import React, { useEffect } from 'react';
import CustomInput from '../CustomInput/custom-input';
import { newid, requiredErrorMsg } from '../../../constants/common-constants';
import { InputBase } from '../../../models/inputBase';
import ArrowDown from '../icons/ArrowDown/arrow-down';
import InputWrapper from '../InputWrapper/input-wrapper';
import { useWindowSize } from '../../../hooks/windowsize';

type CustomDropdownProps = InputBase & {
    data: DropdownItem[];
    setValue: (selectedItem: DropdownItem) => void;
    hasCreateButton?: boolean;
    showSearch?: boolean,
    defaultSelectedId?: string;
    createButtonLabel?: string;
    /** can be used when hasCreateButton is true */
    notFoundInListLabel?: string;
    setNewItem?: (newText: string) => void;
    newItem?: string;
    addNewDescription?: string;
    addNewTitle?: string;
}

const emptyItem = { id: undefined, text: "", originalData: undefined };

function CustomDropdown(props: CustomDropdownProps) {
    const items = props.data;
    const addNewItem = { id: newid, text: props.notFoundInListLabel || "Ingen matchende i listen", originalData: undefined }

    const [addNewErrorMsg, setAddNewErrorMsg] = React.useState("");
    const [isOpen, setIsOpen] = React.useState(false);
    const [ddData, setDdData] = React.useState<DropdownItem[]>([]);
    const [isNewOpen, setIsNewOpen] = React.useState(!!props.newItem);
    const [currentIndex, setCurrentIndex] = React.useState(-1);
    const [itemSelected, setItemSelected] = React.useState<DropdownItem>(emptyItem);

    const [searchword, setSearchWord] = React.useState("");
    const onClickItem = (item: DropdownItem) => {
        if (props.required && props.setError) props.setError(item.id ? "" : requiredErrorMsg);
        setItemSelected(item);
        props.setValue(item);
        setIsOpen(false);
        setIsNewOpen(item.id === newid)
    }

    const filterData = (input: string) => {

        const emptyLabelItem = { id: undefined, text: "Vælg...", originalData: undefined };
        setSearchWord(input);
        if (!input) {
            const data = [emptyLabelItem, ...props.data];
            if (props.hasCreateButton && items.length > 1)
                data.push(addNewItem);
            setDdData(data)
        } else {
            const data = [...props.data];
            const startwithData = data.filter(x => x.text.toLowerCase().startsWith(input.toLowerCase()));
            const containData = data.filter(x => x.text.toLowerCase().includes(input.toLowerCase()) && !startwithData.some(s => s.id === x.id));
            const filteredSortedData = [emptyLabelItem, ...startwithData, ...containData]
            if (props.hasCreateButton && items.length > 1)
                filteredSortedData.push(addNewItem);
            setDdData(filteredSortedData)
        }
    }

    const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLButtonElement>) => {
        if (ev.key === "ArrowDown") {
            if (currentIndex < props.data.length) {
                setCurrentIndex(currentIndex + 1)
                ev.preventDefault();
            }
        }
        else if (ev.shiftKey && ev.key === "Tab") {
            setIsOpen(false);
        }
        else if (ev.key === "Tab") {
            setIsOpen(false);
        }
        else if (ev.key === "ArrowUp") {
            if (currentIndex >= 0) setCurrentIndex(currentIndex - 1);
        }
        else if (ev.key === "Enter") {
            if (ddData.length === 2 && !ddData[0].id) {
                props.setValue(ddData[1]);
                setItemSelected(ddData[1]);
            } else {
                if (ddData[currentIndex]) {
                    props.setValue(ddData[currentIndex]);
                    setItemSelected(ddData[currentIndex]);
                }
            }
            setIsOpen(false);
        }

    }
    const onClickOpen = () => {
        if (document.activeElement !== buttonRef.current) {
            setIsOpen(true)
        } else {
            setIsOpen(!isOpen)
        }
    }

    const itemsRef = React.useRef<(HTMLButtonElement | null)[]>([]);
    const buttonRef = React.useRef<(HTMLButtonElement | null)>();
    const listRef = React.useRef<(HTMLDivElement | null)>();
    const divRef = React.useRef<(HTMLDivElement | null)>();
    const searchRef = React.createRef<HTMLInputElement>();

    const handleClick = (event: any) => {
        if (!divRef?.current?.contains(event.target)) {
            setIsOpen(false)
        }
    }

    const handleDown = (ev: React.KeyboardEvent<HTMLButtonElement>) => {
        if (ev.key === "ArrowDown") {
            if (!isOpen) {
                setIsOpen(true);
                searchRef.current?.focus();
            }
        }
    }

    useEffect(() => {

        itemsRef.current = itemsRef.current.slice(0, props.data.length);
        setCurrentIndex(-1);
        document.addEventListener("mousedown", handleClick);
        if (props.setFocus) {
            buttonRef?.current?.focus?.();
        }
        return () => {
            //cleaning up
            document.removeEventListener("mousedown", handleClick);
        };
    }, [props.data, props.setFocus]);

    useEffect(() => {
        if (currentIndex >= 0) {
            if (itemsRef?.current) {
                itemsRef.current[currentIndex]?.focus()
                if (currentIndex > 0 && listRef.current!.scrollHeight > listRef.current!.clientHeight) {
                    itemsRef.current[currentIndex - 1]?.scrollIntoView();
                }
            }
        }
        if (props.showSearch && currentIndex < 0 && document.activeElement && document.activeElement?.className.indexOf("noid") >= 0) {
            searchRef.current?.focus();
        }
    }, [currentIndex, props.showSearch]);

    const [focusing, setForcusing] = React.useState(false)
    const windowsize = useWindowSize()
    useEffect(() => {
        if (isOpen) {

            const newData = [{ id: undefined, text: "Vælg...", originalData: undefined }, ...props.data]
            if (props.hasCreateButton)
                newData.push(addNewItem);

            //if mobile (max 768px) 
            //add margin under button. either items count * itemheight(54px) + list div padding buffer or max 250px 
            if ((windowsize?.width || 0) <= 768) {
                if (divRef.current?.parentElement?.parentElement) {
                    const listheight = newData.length * 54 + 10 + (props.showSearch ? 64 : 0);
                    const newmargin = listheight > 250 ? 250 : listheight;
                    divRef.current.parentElement.parentElement.style.marginBottom = `${newmargin}px`
                    divRef.current.parentElement.parentElement.previousElementSibling?.scrollIntoView(true)
                }
            }

            setDdData(newData);
            setSearchWord("");
            setCurrentIndex(-1);
            setForcusing(true)
        }
        else {
            if (divRef.current?.parentElement?.parentElement) {
                divRef.current.parentElement.parentElement.style.marginBottom = "0px";
            }
        }
    }, [isOpen, windowsize.width]);

    const getSelected = React.useCallback(() => {
        if (props.defaultSelectedId) {
            if (props.defaultSelectedId === newid) {
                return ""//tempSavedItem?.text;
            }
            else {
                return (items.find(x => x.id === props.defaultSelectedId) || emptyItem).text
            }
        }
        return props.placeholder ? props.placeholder : emptyItem.text;
    }, [props.defaultSelectedId, items, props.placeholder]);

    const getStyledText = React.useCallback((text: string, key: number) => {
        if (!searchword || !text.toLowerCase().includes(searchword.toLowerCase())) {
            return <span key={`text-${key}`}>{text}</span>;
        }

        const indexes = [...Array.from(text.matchAll(new RegExp(searchword, 'gi')))].map(a => a.index!);
        const textarray = [...Array.from(text)];
        let nextindex = -1;
        const textElem: JSX.Element[] = textarray.map((t, i) => {
            if (i >= nextindex) {
                if (indexes.includes(i)) {
                    let boldtext = ""
                    for (let lengthi = 0; lengthi < searchword.length; lengthi++) {

                        boldtext = boldtext + textarray[i + lengthi]
                    }
                    nextindex = i + searchword.length;
                    return <span key={`match-${i}`} className={style.match}>{boldtext}</span>
                }
                else {
                    return <span key={`nomatch-${i}`}>{t}</span>
                }
            }
            return <React.Fragment key={`empty-${i}`}></React.Fragment>

        })
        return <div key={`textelm-${key}`}>{textElem.map(x => x)}</div>
    }, [searchword]);

    return (<>
        <div className={style.dropdown} data-testid={props.testId || props.name} ref={r => divRef.current = r}>
            <a type="button">{/** a link to trigger focus on iOS */}
                <button
                    type="button"
                    disabled={props.disabled}
                    className={`${style.dropdown_header} ${props.errorMsg ? style.error : ""}`}
                    onClick={onClickOpen}
                    onKeyDown={handleDown}
                    ref={r => buttonRef.current = r}
                >
                    <div className={itemSelected.id === emptyItem.id ? style.dropdwon_header_title_placeholder : style.dropdwon_header_title}>{getSelected()}</div>
                    <ArrowDown />
                </button></a>
            {isOpen && (
                <div
                    role="list"
                    className={`${style.dropdown_option_list} ${ddData.length <= 5 ? style.shortlist : ""}`}
                    ref={el => listRef.current = el}
                >
                    {!!props.showSearch && <SearchBox reference={searchRef} onKeyDown={onKeyDown} setInput={filterData}></SearchBox>}

                    {ddData.map((item, i) => (
                        <button
                            type="button"
                            className={`${style.dropdown_option_item} ${!item.id ? "noid" : ""} ${i === currentIndex ? style.selected : ""}`}
                            key={`dddata-${item.id}`}
                            onKeyDown={onKeyDown}
                            onClick={() => onClickItem(item)}
                            ref={el => itemsRef.current[i] = el}
                            autoFocus={!item.id && !props.showSearch}
                        >
                            {getStyledText(item.text, i)}
                        </button>
                    ))}
                </div>
            )}
        </div>

        {isNewOpen && <InputWrapper
            setFocus={focusing}
            component={CustomInput}
            setError={setAddNewErrorMsg}
            description={props.addNewDescription}
            required={isNewOpen}
            errorMsg={isNewOpen && addNewErrorMsg}
            name={`${props.testId || props.name || ""}_addnew`}
            setInput={props.setNewItem}
            value={props.newItem}
            title={props.addNewTitle || "Tilføj ny"}
        />
        }
    </>
    )
}

export default CustomDropdown;