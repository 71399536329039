import React, { useCallback, useEffect } from 'react';
import { moduluscontrol } from '../../../common/moduluscontrol';
import * as errorMsgs from '../../../constants/common-constants';
import { CustomInputProps } from '../CustomInput/custom-input';
import style from './cpr-input.module.scss';

export type CprInputProps = CustomInputProps & {
    name?: string;
}
function CprInput(props: CprInputProps) {
    const [modulusOk, setModulusOk] = React.useState(true);
    const [doubleCheckValue, setDoubleCheckValue] = React.useState<string | undefined>();
    const inputref = React.useRef<(HTMLInputElement | null)>();

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        props.setInput && props.setInput(input);
        // validateInput(input);
    }
    const { pattern, setError, required, value } = props;
    const validateInput = useCallback((text?: string, dblcheckVal?: string) => {
        if (dblcheckVal)
            setDoubleCheckValue(dblcheckVal)
        let msg = "";
    
        let modulusCheckOk = true;
        if (!text && required) {
            msg = errorMsgs.requiredErrorMsg;
        }
        else if (props.pattern && text) {
            if (Array.isArray(props.pattern)) {
                props.pattern.map(p => {
                    const regexp = new RegExp(p.patternStr)
                    if (!regexp.test(text)) {
                        msg = p.errorMsg || "";
                        setDoubleCheckValue(undefined)
                    }
                })
            } else {
                const regexp = new RegExp(props.pattern.patternStr)
                if (!regexp.test(text)) {
                    msg = props.pattern.errorMsg || "";
                    setDoubleCheckValue(undefined)
                }
            }
        }
        if (!msg && text) {
            modulusCheckOk = moduluscontrol(text.replace("-", ""))
            if (!modulusCheckOk && (dblcheckVal?dblcheckVal: doubleCheckValue) !== value) {
                msg = "Bekræft cpr nr. "
            } else {
                msg = ""
            }
        }
        setModulusOk(modulusCheckOk)
        if (setError) {
            setError(msg);
        }
    }, [setError, required, value, pattern, doubleCheckValue]);

    const onDblCheckInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        validateInput(value, input);
    }

    useEffect(() => {
        props.setFocus && inputref.current && inputref.current.focus();
    }, [inputref, props.setFocus])

    return (
        <div className={style.custominput}>
            {/* <div className={style.errorMessage}>{props.errorMsg ? errorMsg : ""} {props.required && !props.value && <div className={style.requiredstar}>*</div>}</div> */}
            <div className={style.cprnrcontainer}>
                <input maxLength={props.maxLength} name={props.name || ""} ref={r => inputref.current = r} value={props.value || ""} disabled={props.disabled} onChange={onInputChange} onBlur={() => { validateInput(props.value) }} required={props.required} className={`${style.input} ${props.errorMsg ? style.error : ""}`} />
                {!modulusOk && <input maxLength={props.maxLength} autoFocus={props.setFocus} value={doubleCheckValue || ""} onChange={onDblCheckInputChange} onBlur={() => { validateInput(props.value) }} required={!modulusOk} className={`${style.input} ${style.doublecheckbox} ${props.value !== doubleCheckValue ? style.error : ""}`} />}
            </div>
        </div>
    )
}

export default CprInput;