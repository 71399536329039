import React from 'react';
import { CampaignElement } from '../../models/umbracoElement';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './campaign.module.scss';

export type CampaignProps = {
    data: any;
    email?: string;
}

function Campaign(props: CampaignProps) {
    const campaignElem: CampaignElement = JSON.parse(props.data);
    const themeColor = "theme_bgcolor" + campaignElem.themeColor;
    const goToNewPage = () => {
        if (campaignElem.link.url)
            window.location.href = campaignElem.link.url;
    }
    const { title, ...rest } = campaignElem;
    return (<Wrapper properties={rest}>
        <div className={style.campaigncontainer}>
            {campaignElem.alwaysShow || !!props.email ?
                <div className={style.campaign}>
                    <div className={style.background} style={{ backgroundColor: `var(--${themeColor}` }}>
                        <div className={campaignElem.themeColor ? style.watermark : style.image} style={{ backgroundImage: `url(${campaignElem.backgroundImage?._url})` }} />
                    </div>
                    <div className={style.textbox}>
                        <div className={style.title}>{campaignElem.title}</div>
                        {campaignElem.text && <div className={style.text}>{campaignElem.text}</div>}
                        <div className={style.button}>
                            <CTAButton onClick={goToNewPage} color={`var(--${themeColor}`}>{campaignElem.buttonText}</CTAButton>
                        </div>
                    </div>
                </div>
                : undefined
            }
        </div>
    </Wrapper>
    )
}

export default Campaign;