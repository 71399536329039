/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeDelivery } from '../models/ChangeDelivery';
import type { MissingDelivery } from '../models/MissingDelivery';
import type { Subscription } from '../models/Subscription';
import { request as __request } from '../core/request';

export class SubscriptionsService {

    /**
     * Description: Change delivery.
 * Operation: ChangeDelivery.
 * Area: Subscriptions.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async changeDelivery(
requestBody: ChangeDelivery,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscriptions/changedelivery`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Report missing delivery.
 * Operation: ReportMissingDelivery.
 * Area: Subscriptions.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async reportMissingDelivery(
requestBody: MissingDelivery,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscriptions/missingdelivery`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get magazine subscription signedup by user.
 * Operation: GetMySubscriptions.
 * Area: Subscriptions.
     * @returns Subscription Success
     * @throws ApiError
     */
    public static async getMySubscriptions(): Promise<Array<Subscription>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/subscriptions/my`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}