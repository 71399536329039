/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EducationMainSubject } from '../models/EducationMainSubject';
import { request as __request } from '../core/request';

export class EducationmainsubjectsService {

    /**
     * Description: Get a list of Education Specializations  (Mainsubjects/linjefag).
 * Operation: GetEducationMainSubjects.
 * Area: Educationmainsubjects.
     * @returns EducationMainSubject Success
     * @throws ApiError
     */
    public static async getEducationMainSubjects(): Promise<Array<EducationMainSubject>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/educationmainsubjects`,
        });
        return result.body;
    }

}