
const Checkmark = () => {

  return (
    <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4736 1.14106L6.87177 10.1608L1.32129 4.61035" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default Checkmark;


