import React, { useCallback, useEffect } from 'react';
import * as api from '../../api';
import { getDateStr } from '../../common/dateutil';
import { getErrorMsgByName } from '../../common/getErrorMsgByName';
import { getNewErrorList } from '../../common/getNewErrorList';
import * as commonconstants from '../../constants/common-constants';
import { fetchErrorMsg, fixBeforeContinue } from '../../constants/common-constants';
import * as constants from '../../constants/edit-education-constants';
import { DropdownItem } from '../../models/dropdownItem';
import { FormError } from '../../models/step';
import { MyEducationElement } from '../../models/umbracoElement';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';
import ButtonZone from '../shared/ButtonZone/button-zone';
import CustomDatePicker from "../shared/CustomDatePicker/custom-date-picker";
import CustomDropdown from '../shared/CustomDropdown/custom-dropdown';
import CustomMultiselect from '../shared/CustomMultiselect/custom-multiselect';
import CustomTable from '../shared/CustomTable/custom-table';
import InputWrapper from '../shared/InputWrapper/input-wrapper';
import List from '../shared/List/list';
import { ListItemProps } from '../shared/List/ListItem/list-item';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import OnePageFormWrapper from '../shared/OnePageFormWrapper/one-page-form-wrapper';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './my-educations.module.scss';
interface MyEducationsProps {
    memberid: string;
    data: string;
}

type MyEducationModel = api.EducationHistory;
function MyEducation(props: MyEducationsProps) {

    const elementdata: MyEducationElement = JSON.parse(props.data);
    const [institutions, setInsitutions] = React.useState<DropdownItem[]>();
    const [mainSubjects, setMainSubjects] = React.useState<DropdownItem[]>();
    const [orientations, setOrientations] = React.useState<DropdownItem[]>();
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [editingEducation, setEditingEducation] = React.useState<api.EditEducation>();
    const [myEducations, setMyEducations] = React.useState<MyEducationModel[]>();
    const [chosenEducation, setChosenEducation] = React.useState<MyEducationModel>();
    const [hasNoEducation, setHasNoEducation] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const updateRequirements = [constants.educationInstitutionId, constants.educationExpecedGraduationDate, constants.educationOrientationId, constants.startdate];
    const createRequirements = [constants.educationInstitutionId, constants.enddate, constants.educationOrientationId, constants.startdate];

    const getData = useCallback(async (refresh: boolean = false) => {
        try {
            const mydatares: api.EducationHistory[] = await api.MembersService.getMembersEducationalInformations(props.memberid);
            if (!refresh) {
                /*populate dropdown that can be changed only students*/
                const educationalInstitutions: api.EducationInstitution[] = await api.EducationinstitutionService.getEducationInstitution();
                const newEducationalInstitutions = educationalInstitutions.map((x) => { return { id: x.educationInsitutionId!, text: x.displayName!, originalData: x } });
                setInsitutions(newEducationalInstitutions);

                const mainsubjectres: api.EducationMainSubject[] = await api.EducationmainsubjectsService.getEducationMainSubjects();
                const newMainsubjects = mainsubjectres.map((x) => { return { id: x.educationMainSubjectId!, text: x.name!, originalData: x } });
                setMainSubjects(newMainsubjects);

                const educationalOrientations: api.EducationOrientation[] = await api.EducationorientationService.getEducationOrientation();
                const newEducationalOrientations = educationalOrientations.map((x) => { return { id: x.educationOrientationId!, text: x.name!, originalData: x } });
                setOrientations(newEducationalOrientations);
            }

            setHasNoEducation(mydatares.length === 0);
            setMyEducations(mydatares);

        } catch (error) {
            setErrorMsg(fetchErrorMsg)
            console.log(error)
        }
    }, [props.memberid])


    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getData();
        }
        call().finally(() => { setLoading(false) });
    }, [getData]);

    const openDetail = (education: MyEducationModel) => {
        setChosenEducation(education);
        setEditingEducation(
            {
                educationId: education.educationId,
                educationInstitutionId: education.educationInstitution?.educationInsitutionId,
                educationMainSubjectIds: education.educationMainSubject?.map(x => x.educationMainSubjectId!),
                educationOrientationId: education.educationOrientation?.educationOrientationId,
                enddate: education.enddate,
                expectedGraduationDate: education.expectedGraduationDate,
                startdate: education.startdate
            });
        setShowForm(true);
    }
    const handleChange = (name: string, value: any) => {
        let newEditEducationModel = { ...editingEducation };
        (newEditEducationModel as any)[name] = value;

        setEditingEducation(newEditEducationModel);

    }
    const getErrorMsg = (name: string) => {
        return getErrorMsgByName([...errorList], name);
    }
    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        if (errorList.length !== newErrorlist.length) {
            setErrorList(newErrorlist);
        }
    }
    const closeModal = (refreshdata: boolean = false) => {
        setShowForm(false);
        setChosenEducation(undefined);
        setEditingEducation(undefined);
        setErrorList([])
        setErrorMsg("");
        if (refreshdata) {
            setLoading(true);
            getData(refreshdata).finally(() => { setLoading(false) });
        }
    }

    const allRequiredFilled = (requiredFields: string[]) => {
        let allFilled = true;

        requiredFields.forEach(x => {
            if (!((editingEducation as any)[x])) {
                allFilled = false;
            }
        });

        return allFilled;
    }

    const updateEducation = () => {
        if (!allRequiredFilled(updateRequirements)) {
            setErrorMsg(fixBeforeContinue);
        } else {
            if (editingEducation) {

                setLoading(true);
                api.MembersService.membersEducationalInformation(props.memberid, editingEducation)
                    .then(() => {
                        closeModal(true)
                    })
                    .catch(error => {
                        console.log(error);
                        setErrorMsg("Der er sket fejl ved opdatering af uddannelse")
                        setLoading(false);
                    });

            }
        }
    }
    
    const createEducation = () => {
        if (!allRequiredFilled(createRequirements)) {
            setErrorMsg(fixBeforeContinue);
        } else {
            if (editingEducation) {
                if (new Date(editingEducation.enddate!) > new Date()){
                    setErrorMsg("Hvis du er i gang med en uddannelse skal du ændre din situation til studerende.");
                    return;
                }
                setLoading(true);
                api.MembersService.createMembersEducationalInformation(props.memberid, editingEducation)
                    .then(() => {
                        closeModal(true)
                    })
                    .catch(error => {
                        console.log(error);
                        setErrorMsg("Der er sket fejl ved oprettelsen af uddannelse")
                        setLoading(false);
                    });

            }
        }
    }
    const rows = React.useMemo(() =>
        myEducations?.map((x) =>
        ({
            rawdata: x, data: [
                x.educationInstitution?.displayName || "",
                x.educationOrientation?.name || "",
                x.startdate ? getDateStr(new Date(x.startdate!)) : "-",
                x.enddate ? "-" : (x.expectedGraduationDate ? getDateStr(new Date(x.expectedGraduationDate!)) : "-"),
                x.enddate ? getDateStr(new Date(x.enddate!)) : "-"
            ]
        }))
        , [myEducations])
    const columns =
        [{
            columnName: "Uddannelsessted",
            visible: true,
            index: 0
        }, {
            columnName: "Uddannelsesretning",
            visible: true,
            index: 1
        }, {
            columnName: "Startdato",
            visible: true,
            index: 2
        },
        {
            columnName: "Forventet dimission",
            visible: true,
            index: 3
            },
            {
                columnName: "Slutdato",
                visible: true,
                index: 4
            }]

    const readonlyItems: ListItemProps[] = React.useMemo(() => !chosenEducation ? [] :
        [
            {
                label: "Uddannelsessted",
                childrenitems: [chosenEducation?.educationInstitution?.displayName || ""]
            },

            {
                label: "Uddannelsesretning",
                childrenitems: [chosenEducation?.educationOrientation?.name || ""]
            },

            {
                label: "Linjefag",
                childrenitems: !chosenEducation?.educationMainSubject ? [""] : chosenEducation?.educationMainSubject.map(x => x.name || "")
            },
            {
                label: "Startdato",
                childrenitems: [chosenEducation.startdate ? getDateStr(new Date(chosenEducation.startdate)) : ""]
            },
            {
                label: "Slutdato",
                childrenitems: [chosenEducation.enddate ? getDateStr(new Date(chosenEducation.enddate)) : ""]
            },
        ]
        , [chosenEducation])

    const openCreateNew = () => {
        setShowForm(true);
    }
    return (
        !showForm ? <Wrapper
            properties={elementdata}
            loading={loading}
            notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}>
            {
                !loading ? <>
                    <CustomTable
                        columns={columns}
                        rows={rows}
                        showButtonLabel={elementdata.showButtonLabel || "Vis/rediger"}
                        emptyText={"Der findes ingen uddannelse"}
                        openDetail={(row) => openDetail(row.rawdata as MyEducationModel)}
                    ></CustomTable>
                    {
                        hasNoEducation ? <>
                            <div className={style.createnewbutton}>
                                <CTAButton color={"var(--themecolor)"} onClick={openCreateNew}>{elementdata.showCreateButtonLabel || "Opret ny uddannelse"}</CTAButton>
                            </div>
                        </>
                            : null
                    }
                </>
                    : null
            }
        </Wrapper>
            : <Wrapper
                properties={{ ...elementdata, title: "" }}
                loading={loading}
                backBarSettings={elementdata.showBackBar ? { backLabel: elementdata.cancelButtonLabel, onBackClick: closeModal } : undefined}
                bottomZone={<ButtonZone className={`${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]}`} twoButtons={!elementdata.showBackBar}>
                    {!elementdata.showBackBar && <FlowButton isBack={true} onClick={() => closeModal()} disabled={loading}>{elementdata.cancelButtonLabel}</FlowButton>}
                    {
                        hasNoEducation && chosenEducation === undefined
                            ? <FlowButton isSubmit={true} onClick={createEducation} disabled={loading}>{elementdata.createButtonLabel || "Opret"}</FlowButton>
                            : <>
                                {
                                    chosenEducation?.enddate ? null
                                        : < FlowButton isSubmit={true} onClick={updateEducation} disabled={loading}>{elementdata.okButtonLabel}</FlowButton>
                                }
                            </>
                    }
                </ButtonZone>}
                notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}
            > <OnePageFormWrapper hasButton={true} title=""
                className={`${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]}`}>
                    <h4>Min uddannelse</h4>
                    {!chosenEducation?.enddate ? <>
                        <InputWrapper
                            component={CustomDropdown}
                            title={"Uddannelsessted"}
                            name={constants.educationInstitutionId}
                            data={institutions || []}
                            hasCreateButton={false}
                            showSearch={true}
                            defaultSelectedId={editingEducation?.educationInstitutionId || undefined}
                            required={true}
                            errorMsg={getErrorMsg(constants.educationInstitutionId)}
                            setError={(hasError: string) => setError(constants.educationInstitutionId, hasError)}
                            setValue={(selected: DropdownItem) => handleChange(constants.educationInstitutionId, selected.id?.toString())} />

                        <InputWrapper
                            component={CustomDropdown}
                            title={"Uddannelsesretning"}
                            name={constants.educationOrientationId}
                            data={orientations || []}
                            required={true}
                            hasCreateButton={false}
                            showSearch={false}
                            defaultSelectedId={editingEducation?.educationOrientationId || undefined}
                            errorMsg={getErrorMsg(constants.educationOrientationId)}
                            setError={(hasError: string) => setError(constants.educationOrientationId, hasError)}
                            setValue={(selected: DropdownItem) => handleChange(constants.educationOrientationId, selected.id?.toString())} />


                        <InputWrapper
                            component={CustomMultiselect}
                            title={"Linjefag"}
                            name={constants.educationMainSubjectIds}
                            data={mainSubjects || []}
                            showSearch={false}
                            required={false}
                            defaultSelectedIds={editingEducation?.educationMainSubjectIds || []}
                            setError={(errorMsg: string) => setError(constants.educationMainSubjectIds, errorMsg)}
                            errorMsg={getErrorMsg(constants.educationMainSubjectIds)}
                            setValues={(selected: DropdownItem[]) => handleChange(constants.educationMainSubjectIds, selected.map(x => x.id))} />

                        <InputWrapper component={CustomDatePicker}
                            title={"Startdato"}
                            onDateChange={(date: any) => handleChange(constants.startdate, date)}
                            required={true}
                            name={constants.startdate}
                            selectedDate={editingEducation?.startdate || undefined}
                            errorMsg={getErrorMsg(constants.startdate)}
                            setError={(errorMsg: string) => setError(constants.startdate, errorMsg)} />
                        {editingEducation?.expectedGraduationDate ?
                            <InputWrapper component={CustomDatePicker}
                                title="Forventet dimissionsdato"
                                name={constants.educationExpecedGraduationDate}
                                onDateChange={(date: any) => handleChange(constants.educationExpecedGraduationDate, date)}
                                required={true}
                                minDate={new Date()}
                                selectedDate={(editingEducation?.expectedGraduationDate || undefined)}
                                errorMsg={getErrorMsg(constants.educationExpecedGraduationDate)}
                                setError={(errorMsg: string) => setError(constants.educationExpecedGraduationDate, errorMsg)}
                            /> : 
                            <InputWrapper component={CustomDatePicker}
                                title="Slutdato"
                                name={constants.enddate}
                                onDateChange={(date: any) => handleChange(constants.enddate, date)}
                                required={true}                                
                                selectedDate={(editingEducation?.enddate || undefined)}
                                errorMsg={getErrorMsg(constants.enddate)}
                                setError={(errorMsg: string) => setError(constants.enddate, errorMsg)}
                            />
                        }

                    </> : <List listItems={readonlyItems}></List>}
                    <div style={{ paddingTop: "37px" }}></div>
                </OnePageFormWrapper>
            </Wrapper>
    )
}


export default MyEducation;