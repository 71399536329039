/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommunicationPreference } from '../models/CommunicationPreference';
import { request as __request } from '../core/request';

export class CommunicationpreferencesService {

    /**
     * Description: Get a list of communicationPreference.
 * Operation: GetcommunicationPreferences.
 * Area: Communicationpreferences.
     * @returns CommunicationPreference Success
     * @throws ApiError
     */
    public static async getcommunicationPreferences(): Promise<Array<CommunicationPreference>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/communicationPreferences`,
        });
        return result.body;
    }

}