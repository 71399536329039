/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class SubmitformService {

    /**
     * Description: Submit form.
 * Operation: SubmitForm.
 * Area: Submitform.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async submitForm(
requestBody?: any,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/submitform`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Add attachment to support application.
 * Operation: AddAttachmentToApplication.
 * Area: Submitform.
     * @param applicationId ID of support application.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async addAttachmentToApplication(
applicationId: string,
requestBody?: any,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/submitform/${applicationId}/addattachment`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}