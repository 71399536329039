import { addressReplaceToken, cityReplaceToken, cprReplaceToken, districtReplaceToken, emailReplaceToken, firstNameReplaceToken, fullNameReplaceToken, lastNameReplaceToken, telephoneReplaceToken, zipcodeReplaceToken } from "../constants/common-constants";
import * as api from "../api";
export const replaceToken = (user?: api.Member, value?: string): ReplaceTokenRes => {
    if (!user || !value) return { replaced: false, value: value };

    const replacing = value?.includes("#{") && value.includes("}#");
    let useraddress = `${user.address.street} ${user.address.number} `;
    useraddress += (user.address.alphabet ? user.address.alphabet + " " : "");
    useraddress += (user.address.door ? user.address.door + " " : "");

    value = value.replace(firstNameReplaceToken, user.firstname);
    value = value.replace(lastNameReplaceToken, user.lastname);
    value = value.replace(fullNameReplaceToken, `${user.firstname} ${user.lastname}`);
    value = value.replace(emailReplaceToken, `${user.mail}`);
    value = value.replace(telephoneReplaceToken, `${user.mobilephone || user.privatephone}`);
    value = value.replace(cprReplaceToken, `${user.cpr}`);
    value = value.replace(addressReplaceToken, `${useraddress}`);
    value = value.replace(zipcodeReplaceToken, `${user.address.zip ? user.address.zip : ""}`);
    value = value.replace(cityReplaceToken, `${user.address.city ? user.address.city : ""}`);
    value = value.replace(districtReplaceToken, `${user.district?.name || ""}`);

    return { replaced: replacing, value: value };

}
export interface ReplaceTokenRes {
    replaced: boolean,
    value: string | undefined
}
