import style from './collapsible-information.module.scss';
import { CollapsibleInformationItem } from '../../../models/umbracoElement'
import React, { } from 'react';
import TopTitle from '../TopTitle/top-title';
import CaretDown from '../icons/CaretDown/caret-down';
import ArrowRight from '../icons/CaretRight/caret-right';

interface CollapsibleInformationProps {
    data: string;
}
function CollapsibleInformation(props: CollapsibleInformationProps) {

    const item: CollapsibleInformationItem = JSON.parse(props.data);
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleList = () => {
        setIsOpen(!isOpen);
    }
    return (<div className={`${style.collapsibleinfo}`}>
        <div onClick={toggleList} className={`${style.openbutton}`}>
            <TopTitle customStyle={{fontSize:20,lineHeight:"26px"}} titletext={item.label || ""}><div className={style.arrow}>{isOpen ? <CaretDown /> : <ArrowRight />}</div></TopTitle>
        </div>
        {isOpen && item.contentText && (
            <div className={`${style.folded_infomration}`} dangerouslySetInnerHTML={{ __html: item.contentText || "" }} />
        )}
    </div>)
}

export default CollapsibleInformation;