import * as api from "../../api"


const getAddressStr = (userinfo: api.Member) => (userinfo.address.street + " " + userinfo.address.number + (userinfo.address.alphabet || "") + " " + (userinfo.address.floor || "") + " " + (userinfo.address.door || "")).trim();
const getCityStr = (userinfo: api.Member) => (userinfo.address.zip!) + " " + (userinfo.address.city!)

export const getHealthInfoLinkParams = (userinfo: api.Member) => {

    const nameStr = userinfo.firstname + " " + userinfo.lastname;
    const addressStr = getAddressStr(userinfo);
    const cityStr = getCityStr(userinfo);
    return createLinkParam(nameStr, userinfo.cpr, userinfo.mobilephone!, userinfo.mail!, addressStr, cityStr);

}

export const getCoInsuredHealthInfoLinkParams = (userinfo: api.Member, coInsuredName: string, coInsuredCpr: string, coInsuredEmail?: string) => {
 
    const nameStr = coInsuredName;
    const addressStr = getAddressStr(userinfo);
    const cityStr = getCityStr(userinfo);

    return createLinkParam(nameStr, coInsuredCpr, userinfo.mobilephone!, coInsuredEmail || userinfo.mail!, addressStr, cityStr);

}

const createLinkParam = (nameStr: string, cpr: string, phone: string, mail: string, addressStr: string, cityStr: string) => {

    const param = `<udfylder><fld_navn>${nameStr}</fld_navn><fld_cprnummer>${cpr}</fld_cprnummer><fld_adresse>${addressStr}</fld_adresse><fld_telefon>${phone}</fld_telefon><fld_email>${mail}</fld_email><fld_stilling></fld_stilling><fld_arbejdsbeskrivelse></fld_arbejdsbeskrivelse><fld_postby>${cityStr}</fld_postby><fld_technical1/></udfylder>`;  
    return "&uDefault=" + btoa(unescape(encodeURIComponent(param)))
}
