import React from 'react';
import { ConflictLoanConfirmElement } from '../../models/umbracoElement';
import style from './conflictsupportconfirm.module.scss';
import * as api from '../../api';
import Wrapper, { NotificationMessage } from '../shared/Wrapper/wrapper';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import ButtonZone from '../shared/ButtonZone/button-zone';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';

interface ConflictLoanConfirmProps {
    data: any;
    memberid: string;
}
function ConflictLoanConfirm(props: ConflictLoanConfirmProps) {
    const confirmationElem: ConflictLoanConfirmElement = JSON.parse(props.data);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);


    const onClick = () => {
        setLoading(true);
        api.MembersService.conflictRequestSupport(props.memberid)
        .then(() => setConfirmed(true))
        .catch((error) => {
            let errorstr = "Der er sket en fejl i forbindelse med bekræftelse af ønske om konfliktstøtte. Kontakt venligst DLF direkte.";
            if(error.status === 409 && error.body)
                errorstr = error.body;
            if(error.body === "Already requested"){
                errorstr = confirmationElem.alreadyRequested === undefined ? "Vi har allerede registreret din bekræftelse af støtteønske. Du behøver ikke gøre mere." : confirmationElem.alreadyRequested;;
            }
            setErrorMsg(errorstr);
            console.log(JSON.stringify(error));
            setConfirmed(false);
        })
        .finally(() => setLoading(false));
        
    }

    return ( 
    <Wrapper properties={confirmationElem}
        notification={errorMsg ? {message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("")} : undefined}
        bottomZone={<ButtonZone><FlowButton isSubmit={true} loading={loading} onClick={onClick} disabled={loading || confirmed || errorMsg != ""} hidden={confirmed}>{confirmationElem.confirmButtonLabel}</FlowButton></ButtonZone>}
    >
        {!confirmed ?
                <div className={`${style.content}`} >
                    <div dangerouslySetInnerHTML={{ __html: confirmationElem.beforeConfirmMessage || "<div></div>" }} />
                </div>
                : <div className={style.content}>
                    <div className={`${style.elementcontent} ${style.richtext}`} dangerouslySetInnerHTML={{ __html: confirmationElem.message || "<div></div>" }} />
                </div>

            }
        </Wrapper>)
}

export default ConflictLoanConfirm;