import style from './my-appointments.module.scss'
import React, { useCallback, useEffect } from 'react';
import * as api from '../../api';
import { fetchErrorMsg } from '../../constants/common-constants';
import { closeNotification } from '../../common/notificationHelper';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import { ElementHelpText, MyAppointmentElement } from '../../models/umbracoElement';
import CustomModal from '../shared/CustomModal/modal';
import TopTitle from '../shared/TopTitle/top-title';
import HelperIcon from '../shared/HelperIcon/helper-icon';
import OkCancelButtons from '../shared/OkCancelButtons/ok-cancel-buttons';
import { getDateStr, getToday } from '../../common/dateutil'
import NewAppointmentBooking from './NewAppointmentBooking/new-appointment-bookings';
import AppointmentList from './AppointmentList/appointment-list';
import Wrapper, { NotificationMessage } from '../shared/Wrapper/wrapper';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';


interface MyAppointmentProps {
    memberid: string;
    data: string;
}
function MyAppointment(props: MyAppointmentProps) {
    const [elementdata] = React.useState<MyAppointmentElement>(props.data ? JSON.parse(props.data) : {});
    const [isBookNewOpen, setIsBookNewOpen] = React.useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);
    const [isDetailModalOpen, setIsDetailModalOpen] = React.useState(false);
    const [appointmentToCancel, setAppointmentToCancel] = React.useState<api.Appointment | undefined>();
    const [detailedAppointment, setDetailedAppointment] = React.useState<api.Appointment | undefined>();
    const [appointments, setAppointments] = React.useState<api.Appointment[]>();
    const [previousAppointments, setPreviousAppointments] = React.useState<api.Appointment[]>();
    const [psychologistReferrals, setPsychologistReferrals] = React.useState<api.Psychologistreferral[] | undefined>();
    const [futureAppointments, setFutureAppointments] = React.useState<api.Appointment[]>();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [notification, setNotification] = React.useState<NotificationMessage | undefined>();
    const [isPsychRefModalOpen, setIsPsychRefModalOpen] = React.useState(false);
    const [chosenPsychRefs, setChosenPsychRefs] = React.useState<api.Psychologistreferral[] | undefined>();


    const getData = useCallback(async () => {
        try {
            const availableAppointments: api.Appointment[] = await api.AppointmentsService.getAvailableAppointments();

            if (!availableAppointments)
                throw new Error("Kunne ikke finde ledige aftaler");

            var availMapped = availableAppointments.map(appointment => {
                const hasReferral = true;
                return { ...appointment, hasReferral };
            });

            setAppointments(availMapped);

            const memberReferrals: api.Psychologistreferral[] = await api.MembersService.getMemberPsychologistReferrals(props.memberid);

            await setPsychologistReferrals(memberReferrals);

            const memberAppointments: api.Appointment[] = await api.MembersService.getMemberAppointments(props.memberid);

            if (!memberAppointments)
                throw new Error("Kunne ikke finde aftaler for medlem");

            const prev = memberAppointments.filter(x => x.upcoming === false).map(appointment => {
                const hasReferral = memberReferrals.some(referral => referral.appointmentid === appointment.id);
                return { ...appointment, hasReferral };
            });

            setPreviousAppointments(prev);

            const future = memberAppointments.filter(x => x.upcoming === true);
            setFutureAppointments(future);


            
        } catch (error) {
            setErrorMsg(fetchErrorMsg)
            console.log(error)
        }
    }, [props.memberid])

    const openBookNew = async () => {
        setNotification(undefined);
        closeNotification();
        setBookNewOpen(true, true, false, false);
    }

    const openCancel = (appointment: api.Appointment) => {
        setNotification(undefined);
        closeNotification();
        openCancelModal(appointment);
    }

    const openDetail = (appointment: api.Appointment) => {
        setNotification(undefined);
        closeNotification();
        openDetailModal(appointment);
    }

    const openCancelModal = (appointment: api.Appointment) => {
        setAppointmentToCancel(appointment);
        setIsCancelModalOpen(true);
    }

    const openDetailModal = (appointment: api.Appointment) => {
        setDetailedAppointment(appointment);
        setIsDetailModalOpen(true);
    }

    const closeCancelModal = () => {
        setAppointmentToCancel(undefined);
        setIsCancelModalOpen(false)
    }
    const closeDetailModal = () => {
        setDetailedAppointment(undefined);
        setIsDetailModalOpen(false)
    }




    const openPsychologistReferral = (appointment: api.Appointment) => {
        setChosenPsychRefs(psychologistReferrals?.filter(x => x.appointmentid === appointment.id)); //.filter(x => x.appointmentId === appointment.appointmentId)[0]

        setIsPsychRefModalOpen(true);
    }
    const closePsychRefModal = () => {
        setChosenPsychRefs(undefined);
        setIsPsychRefModalOpen(false)
    }



    const setBookNewOpen = (open: boolean, refresh: boolean, successfulBooking: boolean, failedBooking: boolean) => {
        setLoading(true);
        if (refresh) {
            
            getData().finally(() => { setLoading(false) });
        }
        if (successfulBooking) {
            setLoading(false)
            setNotification({
                message: "Din valgte tid er booket successfuldt.",
                type: NotificationBarType.Success,
                onClick: () => setNotification(undefined)
            });
        }
        else if (failedBooking) {
            setLoading(false)
            setNotification({
                message: "Din valgte tid er ikke blevet booket.",
                type: NotificationBarType.Danger,
                onClick: () => setNotification(undefined)
            });
        } else {
            setLoading(false)
            getData().finally(() => { setLoading(false) });
        }
        setIsBookNewOpen(open);
    }

    const cancel = async () => {
        setLoading(true);
        let cancelRequest: api.CancelAppointment = { appointmentId: appointmentToCancel?.id }
        await api.MembersService.membersCancelAppointment(props.memberid, cancelRequest).then(() => {
            getData();
            setLoading(false)
            setNotification({
                message: "Din valgte tid er aflyst successfuldt.",
                type: NotificationBarType.Success,
                onClick: () => setNotification(undefined)
            });
        })
            .catch((error) => {
                setNotification({
                    message: "Din valgte tid er ikke blevet aflyst.",
                    type: NotificationBarType.Danger,
                    onClick: () => setNotification(undefined)
                });
            })
            .finally(() => {
                closeCancelModal();
            });;
    }

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getData();
        }
        call().finally(() => { setLoading(false) });
    }, [getData]);


    const wrapperProps: { available: ElementHelpText, previous: ElementHelpText, future: ElementHelpText } = React.useMemo(() => {
        const base = {
            contentTypeAlias: "",
            id: ""
        }
        return {
            available: {
                ...base,
                title: elementdata.availableAppointmentsTitle
            },
            future: {
                ...base,
                title: elementdata.title
            },
            previous: {
                ...base,
                title: elementdata.previousAppointmentsTitle
            }
        }
    }, [elementdata])

    return (<> <Wrapper properties={{ id: "", contentTypeAlias: "", title: "" }} loading={loading} notification={notification}></Wrapper>
        {!isBookNewOpen ? <>
            {futureAppointments && futureAppointments.length > 0
                ? <Wrapper
                    loading={loading}
                    children={!loading && !isBookNewOpen &&
                        <AppointmentList appointments={futureAppointments} futureAppointmentList={true} cancelButtonLabel={elementdata.cancelButtonLabel} showButtonLabel={elementdata.seeDetailButtonLabel} openCancel={openCancel} openDetail={openDetail} ></AppointmentList>
                    }
                    properties={wrapperProps.future}></Wrapper>
                : <Wrapper
                    properties={{} as ElementHelpText}
                    children={
                        <div className={style.createnewbutton}>
                                <CTAButton color={"var(--themecolor)"} onClick={openBookNew} disabled={futureAppointments && (futureAppointments.length > 0)}>{"Book ny samtale"}</CTAButton>
                              </div>
                    }
                ></Wrapper>
            }
            <Wrapper
                loading={loading}
                children={!loading && !isBookNewOpen &&
                    <AppointmentList appointments={previousAppointments} showButtonLabel={elementdata.seeGrantButtonLabel} openCancel={() => { }} openDetail={openPsychologistReferral} ></AppointmentList>
                }
                properties={wrapperProps.previous} ></Wrapper>
        </>
            : <NewAppointmentBooking appointments={appointments || []} elementdata={elementdata} setIsOpen={setBookNewOpen} memberid={props.memberid} />
        }
        <CustomModal isModalOpen={isCancelModalOpen} onCancelClick={closeCancelModal}>
            <div className={style.elementcontainer}>
                <TopTitle titletext={"Bekræft aflysning af samtale"} helpIcon={<HelperIcon helptext={"Bekræft at du ønsker at aflyse samtalen"} />} />
                <div className={style.step}>
                    <span className={style.bold}>Rådgiver:</span>
                    <div>{appointmentToCancel?.counselor?.name || ""}</div>
                    <span className={style.bold}>Tidspunkt:</span>
                    <div>{getDateStr(new Date(appointmentToCancel?.date!), true) || ""}</div>
                    <OkCancelButtons
                        cancelLabel={"Annuller"}
                        loading={loading}
                        okLabel={"Bekræft"}
                        onClickCancel={closeCancelModal}
                        onClickOk={cancel} />
                </div>
            </div>
        </CustomModal>
        <CustomModal isModalOpen={isDetailModalOpen} onCancelClick={closeDetailModal}>
            <div className={style.elementcontainer}>
                <TopTitle titletext={"Samtaledetaljer"} helpIcon={<HelperIcon helptext={"Her kan du se detaljerne for din samtale, inklusive den beskrivelse du har skrevet til os"} />} />
                <div className={style.step}>
                    <span className={style.bold}>Rådgiver:</span>
                    <div>{detailedAppointment?.counselor?.name || ""}</div>
                    <span className={style.bold}>Tidspunkt:</span>
                    <div>{getDateStr(new Date(detailedAppointment?.date!), true) || ""}</div>
                    <div style={{ paddingTop: 30 }}></div>
                    <FlowButton className={style.onebutton} isBack={true} onClick={closeDetailModal}> {"Tilbage"}</FlowButton>
                </div>
            </div>
        </CustomModal>
        <CustomModal isModalOpen={isPsychRefModalOpen} onCancelClick={closePsychRefModal}>
            <div className={style.elementcontainer}>
                <TopTitle titletext={"Psykolog henvisningsdetaljer"} helpIcon={<HelperIcon helptext={"Her kan du se din psykologhenvisning"} />} />

                {chosenPsychRefs && chosenPsychRefs.map((referral) =>
    <div className={style.step} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
        <div>
            <span className={style.bold}>Psykolog:</span>
            <div>{referral?.psychologist?.name || ""}</div>
            <span className={style.bold}>Adresse:</span>
            <div>{referral?.psychologist?.address || ""}</div>
            <span className={style.bold}>Telefon:</span>
            <div>{referral?.psychologist?.telephone || ""}</div>
            <span className={style.bold}>Email adresse:</span>
            <div>{referral?.psychologist?.email || ""}</div>
        </div>
        <div>
            <span className={style.bold}>Henvisning gyldig til:</span>
            <div>{getDateStr(new Date(referral?.validto!), true) || ""}</div>
            <span className={style.bold}>Psykologens telefontider:</span>
            <div>{referral?.psychologist?.description || ""}</div>
            <div style={{ paddingTop: 15 }}></div>
        </div>
    </div>
    
)}
                <FlowButton className={style.onebutton} isBack={true} onClick={closePsychRefModal}> {"Tilbage"}</FlowButton>
            </div>
        </CustomModal>
    </>)
}

export default MyAppointment;