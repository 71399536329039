import React, { CSSProperties } from 'react';
import Corner from '../icons/Corner/corner';
import style from './top-title.module.scss'

interface TopTitleProps {
    titletext: string;
    helpIcon?: JSX.Element;
    children?: any;
    hideCorner?: boolean;
    customStyle?: CSSProperties;
    hasLabel?: boolean;
}

function TopTitle(props: TopTitleProps) {

    return (
        <div className={`${style.toptitle_container} ${props.hasLabel ? style.haslabel : ""}`}>
            {!props.hideCorner && <div className={style.cornercontainer}>
                <Corner />
            </div>}
            <div style={props.customStyle} className={`${style.toptitle_text} ${props.hideCorner ? style.withoutcorner : ""}`}>
                <div className={style.text}>{props.titletext}</div>
                {props.helpIcon && (<div className={style.iconcontainer}>{props.helpIcon}</div>)}
                {props.children && props.children}
            </div>
        </div>
    )
}

export default TopTitle;