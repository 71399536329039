import style from './my-information.module.scss'
import React, { useCallback, useEffect, useMemo } from 'react';
import * as api from '../../api';
import { DropdownItem } from '../../models/dropdownItem';
import CustomInput from '../shared/CustomInput/custom-input';
import * as myInformationsConstants from '../../constants/my-information-constants';
import CustomDropdown from '../shared/CustomDropdown/custom-dropdown';
import { characterValidationErrorMsg, defaultCountryCode, emailErrorMsg, fetchErrorMsg, fixBeforeContinue, postErrorMsg, telErrorMsg } from '../../constants/common-constants';
import * as regexp from '../../constants/regex-expressions';
import { getNewErrorList } from '../../common/getNewErrorList';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import CustomCheckbox from '../shared/CustomCheckbox/custom-checkbox';
import { ListItemProps } from '../shared/List/ListItem/list-item';
import List from '../shared/List/list';
import { FormError } from '../../models/step';
import { getErrorMsgByName } from '../../common/getErrorMsgByName';
import Wrapper, { NotificationMessage } from '../shared/Wrapper/wrapper';
import ButtonZone from '../shared/ButtonZone/button-zone';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';
import OnePageFormWrapper from '../shared/OnePageFormWrapper/one-page-form-wrapper';
import InputWrapper from '../shared/InputWrapper/input-wrapper';
import Label from '../shared/FormLabel/form-label';
import { MyContactInformation } from '../../models/umbracoElement';
import { setNotificationMsg } from '../../common/notificationHelper';
import { closeNotification } from '../../common/notificationHelper';


interface MyInformationProps {
    data: string
    memberid: string
}

const successParam = "success=1";
function MyInformation(props: MyInformationProps) {

    const elementdata: MyContactInformation = JSON.parse(props.data);
    // const [communicationPrefs, setCommunicationPrefs] = React.useState<DropdownItem[]>([]);
    const [countries, setCountries] = React.useState<DropdownItem[]>([]);
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [editing, setEditing] = React.useState(false);
    const [myData, setMyData] = React.useState<api.Member>();
    const [notification, setNotification] = React.useState<NotificationMessage | undefined>();
    const [originalData, setOriginalData] = React.useState<api.Member>();
    const [disabled, setDisabled] = React.useState(false);//this will be used when we know the user's cpr is confirmed
    const [municipalities, setMunicipalities] = React.useState<DropdownItem[]>([]);
    const [saving, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [defaultCountryItem, setDefaultCountryItem] = React.useState<DropdownItem>();
    useEffect(() => {

        const searchQuery = window.location.search || "";
        if (searchQuery.replace("?", "") === successParam) {
            const url = new URL(window.location.href);
            url.searchParams.delete('success');
            (window.history as any).replaceState(null, null, url);
            setSuccessMsg("Dine oplysninger er gemt");
            setTimeout(() => {
                 setSuccessMsg("");
                 closeNotification(); }, 10000)
        }

    }, [])
    const getData = useCallback(async () => {

        try {
            // CommunicationPreference is currently hidden. DLF let us know when this should be shown.
            // const communicationres: api.CommunicationPreference[] = await api.CommunicationpreferencesService.getCommunicationpreferencesService();
            // const communicationprefs = communicationres.map((x) => { return { id: x.id!.toString(), text: x.name!, originalData: x } });
            // setCommunicationPrefs(communicationprefs);
            const mydatares: api.Member = await api.MembersService.getMemberById(props.memberid);
            setMyData(mydatares);
            setOriginalData(mydatares);
            setDisabled(mydatares.cprIntegration || false)
            const countrycoderes: api.Country[] = await api.CountriesService.getCountries();
            const newcountries = countrycoderes.map((x) => { return { id: x.countryId!, text: x.name!, originalData: x } });
            const defaultCountry = newcountries.find(x => x.originalData.countryCode === defaultCountryCode);
            setDefaultCountryItem(defaultCountry);
            setCountries(newcountries);

            const municipalityres: api.Municipality[] = await api.MunicipalitiesService.getMunicipalities();
            const newmuniciparities = municipalityres.map((x) => { return { id: x.municipalityid!, text: x.name!, originalData: x } });
            setMunicipalities(newmuniciparities);
        } catch (error) {
            setErrorMsg(fetchErrorMsg)
            console.log(error)
        }
    }, [props.memberid])

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getData();
        }
        call().finally(() => { setLoading(false) });
    }, [getData]);

    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);
    }
    const setModel = (name: string, value: string | boolean | object | undefined) => {
        setErrorMsg("")
        let newMyData: api.Member | undefined = myData ? {
            ...myData,
        } : undefined;
        if (newMyData) {
            (newMyData as any)[name] = value;
            setMyData(newMyData);
        }
    }

    const setAddress = (key: string, value: string | api.Country) => {
        if (myData && myData.address) {
            let addressCopy: api.Address = {
                ...myData.address,
                country: { ...myData.address.country }
            };
            (addressCopy as any)[key] = value;

            setModel(myInformationsConstants.address, addressCopy);
        }
    }
    const resetChange = () => {
        setMyData({ ...originalData! });
        setErrorList([]);
        setErrorMsg("");
        setEditing(false);
        closeNotification();
    }

    const saveInfo = async () => {
        if (errorList.length > 0) {
            setErrorMsg(fixBeforeContinue)
        } else {
            setSaving(true);
            const successQuery = encodeURIComponent(successParam);
            api.MembersService.updateMemberContact(myData?.memberId!, myData!)
                .then(() => {
                    window.location.href = `/Member/ReChallengeAuth?redirectQuery=${successQuery}&redirectUrl=${window.location.pathname}`
                })
                .catch((err) => {
                    setErrorMsg(postErrorMsg)
                    console.log(err)
                }).finally(() => {
                    setEditing(false);
                    setSaving(false);
                }
                )
        }
    }

    const updateConsent = (c: api.MemberConsent): void => {
        setMyData({ ...myData!, consent: c })
    }
    const getErrorMsg = (name: string) => {
        return getErrorMsgByName([...errorList], name);
    }
    const getConsentlist = (myData: api.Member) => {
        return [`${elementdata.allowContactText || 'Må vi kontakt dig?'} : ${myData.consent?.acceptedContactFromDLF ? "Ja" : "Nej"}`];
    }

    const setSuccessMsg = (msg: string) => {
        setNotificationMsg(msg, NotificationBarType.Success, setNotification);
    }

    const setErrorMsg = (msg: string) => {
        setNotificationMsg(msg, NotificationBarType.Danger, setNotification)
    }


    const readonlyItems: ListItemProps[] = useMemo(() => {

        if (!myData) {
            return [];
        }
        const addresschildren = [
            `${myData.address.street || ""} ${myData.address.number || ""}${myData.address.alphabet || ""} ${myData.address.floor || ""}${myData.address.door || ""}`,
            `${myData.address.zip || ""} ${myData.address.city || ""}`, `${myData.address.country?.name || ""}`,
        ];

        return [
            {
                label: "Medlemnummer",
                childrenitems: [myData.memberNumber || ""]
            },

            {
                label: "Fornavn",
                childrenitems: [myData.firstname || ""]
            },

            {
                label: "Efternavn",
                childrenitems: [myData.lastname || ""]
            },
            {
                label: "Cpr. Nummer",
                childrenitems: [myData.cpr || ""]
            },
            {
                label: "Samtykke",
                childrenitems: getConsentlist(myData)
            },
            {
                label: "Adresse",
                childrenitems: myData.address.co ? [`${myData.address.co ? `c/o ${myData.address.co}` : ""}`, ...addresschildren] : addresschildren
            },
            {
                label: "Stednavn",
                childrenitems: [myData.address.county || ""]
            },
            {
                label: "Kommune",
                childrenitems: [myData.address.municipality.name || ""]
            }, {
                label: "Mobiltelefon",
                childrenitems: [myData.mobilephone || ""]
            }, {
                label: "Arbejdstelefon",
                childrenitems: [myData.workphone || ""]
            }, {
                label: "Primær mail",
                childrenitems: [myData.mail || ""]
            }, {
                label: "Sekundær mail",
                childrenitems: [myData.workmail || ""]
            },
        ]
    }
        , [myData])


    return (
        <Wrapper properties={{ ...elementdata, title: "" }}
            loading={loading}
            notification={notification}
            backBarSettings={elementdata.showBackBar ? { backLabel: "Annuller", onBackClick: editing ? resetChange : undefined } : undefined}
            bottomZone={<ButtonZone twoButtons={!elementdata.showBackBar && editing} className={`${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]}`}>
                {!elementdata.showBackBar && editing && <FlowButton isBack={true} onClick={resetChange} disabled={loading}>{"Annuller"}</FlowButton>}
                {!loading && <FlowButton className={style.onebutton} isSubmit={editing} onClick={editing ? saveInfo : () => setEditing(true)} loading={saving} disabled={saving}>
                    {editing ? "Gem oplysninger" : "Rediger oplysninger"}
                </FlowButton>}
            </ButtonZone>}
        >
            {!loading && <OnePageFormWrapper
                helpText={elementdata.helpText}
                className={editing ? style["col-12"] : `${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]}`}
                hasButton={true}
                title={editing ? (elementdata.title || "Mine kontaktoplysninger") : ""}>
                {!editing ? <List title={elementdata.title || "Mine kontaktoplysninger"} listItems={readonlyItems} />
                    : myData && <div className={/*style.row*/""}>
                        <InputWrapper
                            component={CustomInput}
                            title="Medlemnummer"
                            name={"memberNo"}
                            value={myData.memberNumber || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={true}
                            required={true} />

                        <InputWrapper
                            component={CustomInput}
                            title="Fornavn"
                            name={myInformationsConstants.firstname}
                            value={myData.firstname || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            required={true}
                            maxLength={50}
                            errorMsg={getErrorMsg(myInformationsConstants.firstname)}
                            setError={(errorMsg: string) => setError(myInformationsConstants.firstname, errorMsg)}
                            setInput={(input: string) => setModel(myInformationsConstants.firstname, input)} />

                        <InputWrapper
                            component={CustomInput}
                            title="Efternavn"
                            name={myInformationsConstants.lastname}
                            value={myData.lastname || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            required={true}
                            maxLength={50}
                            errorMsg={getErrorMsg(myInformationsConstants.lastname)}
                            setError={(errorMsg: string) => setError(myInformationsConstants.lastname, errorMsg)}
                            setInput={(input: string) => setModel(myInformationsConstants.lastname, input)} />

                        <InputWrapper
                            component={CustomInput}
                            name={myInformationsConstants.cpr}
                            title="Cpr. Nummer"
                            value={myData.cpr || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={true}
                        />


                        <div className={style.consentlabel}><Label title={"Samtykke"} /></div>
                        <InputWrapper
                            component={CustomCheckbox}
                            name={"consent"}
                            htmlLabel={elementdata.allowContactText || 'Må vi kontakt dig?'} checked={myData.consent?.acceptedContactFromDLF}
                            setInput={(v?: boolean) => updateConsent({ ...myData.consent!, acceptedContactFromDLF: v! })} />
                        <InputWrapper
                            component={CustomInput}
                            title="Adresse"
                            name={myInformationsConstants.street}
                            value={myData.address.street || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            required={true}
                            maxLength={250}
                            errorMsg={getErrorMsg(myInformationsConstants.street)}
                            disabled={disabled || !editing}
                            setError={(errorMsg: string) => setError(myInformationsConstants.street, errorMsg)}
                            setInput={(input: string) => setAddress(myInformationsConstants.street, input)} />


                        <InputWrapper
                            component={CustomInput}
                            title="Hus nr."
                            maxLength={250}
                            name={myInformationsConstants.number}
                            setInput={(input: string) => setAddress(myInformationsConstants.number, input)}
                            setError={(errorMsg: string) => setError(myInformationsConstants.number, errorMsg)}
                            required={true}
                            errorMsg={getErrorMsg(myInformationsConstants.number)}
                            value={myData.address.number || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={disabled || !editing} />
                        <InputWrapper
                            component={CustomInput}
                            title="Bogstav"
                            maxLength={250}
                            name={myInformationsConstants.alphabet}
                            setInput={(input: string) => setAddress(myInformationsConstants.alphabet, input)}
                            value={myData.address.alphabet || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={disabled || !editing} />
                        <InputWrapper
                            component={CustomInput}
                            title="Etage"
                            maxLength={2}
                            name={myInformationsConstants.floor}
                            setInput={(input: string) => setAddress(myInformationsConstants.floor, input)}
                            value={myData.address.floor || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={disabled || !editing} />
                        <InputWrapper
                            component={CustomInput}
                            maxLength={4}
                            title="Side"
                            name={myInformationsConstants.door}
                            value={myData.address.door || ""} disabled={disabled || !editing} setInput={(input: string) => setAddress(myInformationsConstants.door, input)}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            />

                        <InputWrapper
                            title={"C/O"}
                            component={CustomInput}
                            maxLength={100}
                            name={myInformationsConstants.co}
                            value={myData.address.co || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={disabled || !editing}
                            required={false}
                            setInput={(input: string) => setAddress(myInformationsConstants.co, input)} />

                        <InputWrapper
                            component={CustomInput}
                            title="Postnummer"
                            name={myInformationsConstants.zip}
                            description="Indtast dit postnummer ex.1656"
                            value={myData.address.zip || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={disabled || !editing}
                            required={true}
                            maxLength={20}
                            errorMsg={getErrorMsg(myInformationsConstants.zip)}
                            setInput={(input: string) => setAddress(myInformationsConstants.zip, input)}
                            setError={(errorMsg: string) => setError(myInformationsConstants.zip, errorMsg)} />

                        <InputWrapper
                            component={CustomInput}
                            maxLength={80}
                            name={myInformationsConstants.city}
                            title="By"
                            value={myData.address.city || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={disabled || !editing}
                            setInput={(input: string) => setAddress(myInformationsConstants.city, input)} />

                        <InputWrapper
                            component={CustomInput}
                            name={myInformationsConstants.county}
                            maxLength={50}
                            title="Stednavn"
                            value={myData.address.county || ""}
                            pattern={{ patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }}
                            disabled={disabled || !editing}
                            setInput={(input: string) => setAddress(myInformationsConstants.county, input)} />

                        {!disabled ?
                            <InputWrapper
                                component={CustomDropdown}
                                title="Kommune"
                                name={myInformationsConstants.municipality}
                                defaultSelectedId={myData.address.municipality?.municipalityid || ""}
                                data={municipalities}
                                hasCreateButton={false}
                                showSearch={true}
                                setValue={(selected: DropdownItem) => setAddress(myInformationsConstants.municipality, selected.originalData)} />
                            : <InputWrapper
                                component={CustomInput}
                                name={myInformationsConstants.municipality}
                                title="Kommune"
                                value={municipalities.find(x => x.id === myData.address.municipality?.municipalityid)?.text}
                                disabled={true} />}

                        {!disabled ?
                            <InputWrapper
                                component={CustomDropdown}
                                title="Land"
                                name={myInformationsConstants.country}
                                defaultSelectedId={myData.address.country?.countryId || defaultCountryItem?.id}
                                data={countries}
                                hasCreateButton={false}
                                showSearch={true}
                                setValue={(selected: DropdownItem) => setAddress(myInformationsConstants.country, selected.originalData)} />
                            : <InputWrapper
                                component={CustomInput}
                                title="Land"
                                name={myInformationsConstants.country}
                                value={countries.find(x => x.id === myData.address.country?.countryId)?.text} disabled={true} />}

                        <InputWrapper
                            component={CustomInput}
                            title="Mobiltelefon"
                            value={myData.mobilephone || ""}
                            disabled={!editing}
                            name={myInformationsConstants.mobilephone}
                            type={"tel"}
                            maxLength={50}
                            required={true}
                            errorMsg={getErrorMsg(myInformationsConstants.mobilephone)}
                            pattern={[{ patternStr: regexp.telno, errorMsg: telErrorMsg }, { patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }]}
                            setInput={(input: string) => setModel(myInformationsConstants.mobilephone, input)}
                            setError={(errorMsg: string) => setError(myInformationsConstants.mobilephone, errorMsg)} />
                        <InputWrapper
                            component={CustomInput}
                            name={myInformationsConstants.workphone}
                            title="Arbejdstelefon"
                            value={myData.workphone || ""}
                            type={"tel"}
                            maxLength={50}
                            pattern={[{ patternStr: regexp.telno, errorMsg: telErrorMsg }, { patternStr: regexp.characterValidation, errorMsg: characterValidationErrorMsg }]}
                            disabled={!editing}
                            errorMsg={getErrorMsg(myInformationsConstants.workphone)}
                            setError={(errorMsg: string) => setError(myInformationsConstants.workphone, errorMsg)}
                            setInput={(input: string) => setModel(myInformationsConstants.workphone, input)} />

                        <InputWrapper
                            component={CustomInput}
                            name={myInformationsConstants.mail}
                            type={"email"}
                            title="Primær mail" value={myData.mail || ""}
                            disabled={!editing}
                            maxLength={100}
                            required={true}
                            pattern={[{ patternStr: regexp.email, errorMsg: emailErrorMsg }]}
                            errorMsg={getErrorMsg(myInformationsConstants.mail)}
                            setError={(errorMsg: string) => setError(myInformationsConstants.mail, errorMsg)}
                            setInput={(input: string) => setModel(myInformationsConstants.mail, input)} />

                        <InputWrapper
                            component={CustomInput}
                            type={"email"}
                            maxLength={100}
                            name={myInformationsConstants.workmail}
                            title="Sekundær mail" value={myData.workmail || ""} disabled={!editing}
                            pattern={[{ patternStr: regexp.email, errorMsg: emailErrorMsg }]}
                            setInput={(input: string) => setModel(myInformationsConstants.workmail, input)}
                            errorMsg={getErrorMsg(myInformationsConstants.workmail)}
                            setError={(errorMsg: string) => setError(myInformationsConstants.workmail, errorMsg)}/>

                    </div>

                }  </OnePageFormWrapper>}
        </Wrapper >

    )

}
export default MyInformation;


