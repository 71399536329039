/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Appointment } from '../models/Appointment';
import type { BookAppointment } from '../models/BookAppointment';
import type { CancelAppointment } from '../models/CancelAppointment';
import type { CommunicationPreference } from '../models/CommunicationPreference';
import type { ConflictParticipation } from '../models/ConflictParticipation';
import type { Contingent } from '../models/Contingent';
import type { EditEducation } from '../models/EditEducation';
import type { EducationHistory } from '../models/EducationHistory';
import type { Graduation } from '../models/Graduation';
import type { LocalUnionInformation } from '../models/LocalUnionInformation';
import type { Member } from '../models/Member';
import type { MemberBalance } from '../models/MemberBalance';
import type { Message } from '../models/Message';
import type { NewSituation } from '../models/NewSituation';
import type { PaymentBody } from '../models/PaymentBody';
import type { PaymentRedirect } from '../models/PaymentRedirect';
import type { Psychologistreferral } from '../models/Psychologistreferral';
import type { Reduction } from '../models/Reduction';
import type { ReductionApplication } from '../models/ReductionApplication';
import type { WorkHistory } from '../models/WorkHistory';
import type { WorkLocation } from '../models/WorkLocation';
import { request as __request } from '../core/request';

export class MembersService {

    /**
     * Description: Find member by ID.
 * Operation: GetMemberById.
 * Area: Members.
     * @param memberId ID of member that needs to be fetched.
     * @returns Member Success
     * @throws ApiError
     */
    public static async getMemberById(
memberId: string,
): Promise<Member> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get upcoming and past appointments for a member (there should only be one upcoming).
 * Operation: GetMemberAppointments.
 * Area: Members.
     * @param memberId ID of member who's apppointment needs to be fetched.
     * @returns Appointment Success
     * @throws ApiError
     */
    public static async getMemberAppointments(
memberId: string,
): Promise<Array<Appointment>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/appointments`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Retrieves the members current balance.
 * Operation: GetMembersBalance.
 * Area: Members.
     * @param memberId ID of member.
     * @returns MemberBalance Success
     * @throws ApiError
     */
    public static async getMembersBalance(
memberId: string,
): Promise<MemberBalance> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/Balance`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get latest contingent billing given a user.
 * Operation: GetBilling.
 * Area: Members.
     * @param memberId ID of member who's billing needs to be fetched.
     * @returns Contingent Success
     * @throws ApiError
     */
    public static async getBilling(
memberId: string,
): Promise<Contingent> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/billing`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Book an appointment for the current member.
 * Operation: MembersBookAppointment.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async membersBookAppointment(
memberId: string,
requestBody: BookAppointment,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/bookappointment`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                409: `Conflict`,
            },
        });
        return result.body;
    }

    /**
     * Description: Cancel an upcoming appointment for the current member.
 * Operation: MembersCancelAppointment.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async membersCancelAppointment(
memberId: string,
requestBody: CancelAppointment,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/cancelappointment`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                409: `Conflict`,
            },
        });
        return result.body;
    }

    /**
     * Description: Allows an existing member to change their payment method on file.
 * Operation: ChangePaymentMethod.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns PaymentRedirect Success
     * @throws ApiError
     */
    public static async changePaymentMethod(
memberId: string,
requestBody: PaymentBody,
): Promise<PaymentRedirect> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/changepaymentmethod`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Update a member's preferences wrt the prefered way of contacting the indiviual.
 * Operation: MembersCommunication.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async membersCommunication(
memberId: string,
requestBody: CommunicationPreference,
): Promise<string> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/members/${memberId}/communication`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Confirms that the member wants a loan.
 * Operation: ConflictRequestLoan.
 * Area: Members.
     * @param memberId ID of member who wants to request conflict loan.
     * @returns string Success
     * @throws ApiError
     */
    public static async conflictRequestLoan(
memberId: string,
): Promise<string> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/members/${memberId}/ConflictLoan`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Confirms that the member wants a support.
 * Operation: ConflictRequestSupport.
 * Area: Members.
     * @param memberId ID of member who wants to request conflict support.
     * @returns string Success
     * @throws ApiError
     */
    public static async conflictRequestSupport(
memberId: string,
): Promise<string> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/members/${memberId}/ConflictSupport`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Update a member's contact information.
 * Operation: UpdateMemberContact.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody Evt kombiner med Contact (Contact bruges for dynamics).
     * @returns string Success
     * @throws ApiError
     */
    public static async updateMemberContact(
memberId: string,
requestBody: Member,
): Promise<string> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/members/${memberId}/contact`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get current contingent regarding user.
 * Operation: GetContingent.
 * Area: Members.
     * @param memberId ID of member who's contingent needs to be fetched.
     * @returns Contingent Success
     * @throws ApiError
     */
    public static async getContingent(
memberId: string,
): Promise<Contingent> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/contingent`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Update a member's educational information.
 * Operation: MembersEducationalInformation.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async membersEducationalInformation(
memberId: string,
requestBody: EditEducation,
): Promise<string> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/members/${memberId}/educationalinformation`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Create a member's educational information.
 * Operation: CreateMembersEducationalInformation.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async createMembersEducationalInformation(
memberId: string,
requestBody: EditEducation,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/educationalinformation/create`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get a member's educational information.
 * Operation: GetMembersEducationalInformations.
 * Area: Members.
     * @param memberId ID of member.
     * @returns EducationHistory Success
     * @throws ApiError
     */
    public static async getMembersEducationalInformations(
memberId: string,
): Promise<Array<EducationHistory>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/educationalinformations`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Register graduation date and add a member's new situation.
 * Operation: MemberGraduation.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async memberGraduation(
memberId: string,
requestBody: Graduation,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/graduation`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Gets basic information about the member's local union including a list of the chairmen of the localunion.
 * Operation: LocalUnionInformation.
 * Area: Members.
     * @param memberId ID of member who's localunion information needs to be fetched.
     * @returns LocalUnionInformation Success
     * @throws ApiError
     */
    public static async localUnionInformation(
memberId: string,
): Promise<LocalUnionInformation> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/localUnionInformation`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Gets the current conflict that a member is a part of.
 * Operation: GetCurrentConflictParticipation.
 * Area: Members.
     * @param memberId Id of the member which conflict particiation information needs to be fetched.
     * @returns ConflictParticipation Success
     * @throws ApiError
     */
    public static async getCurrentConflictParticipation(
memberId: string,
): Promise<ConflictParticipation> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/MemberCurrentConflictParticipation`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get all messages related to the given member.
 * Operation: GetMessages.
 * Area: Members.
     * @param memberId 
     * @returns Message Success
     * @throws ApiError
     */
    public static async getMessages(
memberId: string,
): Promise<Array<Message>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/messages`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Find message given its Id and the member Id.
 * Operation: GetMessage.
 * Area: Members.
     * @param memberId ID of the respective member.
     * @param messageId ID of message that needs to be fetched.
     * @returns string Success
     * @throws ApiError
     */
    public static async getMessage(
memberId: string,
messageId: string,
): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/messages/${messageId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Create a message to send.
 * Operation: CreateMessage.
 * Area: Members.
     * @param memberId ID of the respective member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async createMessage(
memberId: string,
requestBody?: any,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/messages/create`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Gets the members psychologist referrals.
 * Operation: GetMemberPsychologistReferrals.
 * Area: Members.
     * @param memberId ID of member who's psychologist referrals needs to be fetched.
     * @returns Psychologistreferral Success
     * @throws ApiError
     */
    public static async getMemberPsychologistReferrals(
memberId: string,
): Promise<Array<Psychologistreferral>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/psychologistreferrals`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: create a reduction application.
 * Operation: MembersReduction.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static async membersReduction(
memberId: string,
requestBody: ReductionApplication,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/reduction`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Get all reductions related to the given member.
 * Operation: GetReductions.
 * Area: Members.
     * @param memberId 
     * @returns Reduction Success
     * @throws ApiError
     */
    public static async getReductions(
memberId: string,
): Promise<Array<Reduction>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/reductions`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Update a member's situation details.
 * Operation: MembersSituation.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async membersSituation(
memberId: string,
requestBody: NewSituation,
): Promise<string> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/v1/members/${memberId}/situation`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Update a member's work details.
 * Operation: MembersWorkInformation.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody If the WorkLocation is an existing WorkLocation, WorkLocationId will contain a reference to the WorkLocation. If it is a new WorkLocation the information should be provided in newWorkLocation.
     * @returns string Success
     * @throws ApiError
     */
    public static async membersWorkInformation(
memberId: string,
requestBody: WorkHistory,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/workinformation`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Description: Update a member's WorkLocation details.
 * Operation: MembersWorkLocation.
 * Area: Members.
     * @param memberId ID of member.
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async membersWorkLocation(
memberId: string,
requestBody: WorkLocation,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/WorkLocation`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}