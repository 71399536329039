import style from './custom-checkbox.module.scss';
import React, { useEffect, useRef } from 'react';
import { InputBase } from '../../../models/inputBase';
import Checkmark from '../icons/Checkmark/checkmark';

type CustomCheckboxProps = InputBase & {
    label?: string;
    htmlLabel?: string;
    setInput: (input: boolean | undefined) => void;
    checked?: boolean;
    deactivateClickLabel?: boolean;
    readonly?: boolean;
}

function CustomCheckbox(props: CustomCheckboxProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.disabled)
            return;
        const newChecked = event.target.checked;
        handleChecked(newChecked);
    }

    const handleChecked = (newChecked: boolean) => {
        if (props.disabled)
            return;
        props.setInput(newChecked);
    }

    useEffect(() => {
        if (props.setFocus) {
            inputRef.current?.focus();
        }
    }, [props.setFocus]);

    const addRequiredStar = (htmlstr: string) => {
        const regexp = new RegExp(/((<\/)\w+(>))/g);
        const matches = htmlstr.match(regexp);
        if (matches && htmlstr) {
            //getting last closing tag
            var closingtag = matches.pop();
            //adding star before closing tag
            return htmlstr.substring(0, htmlstr.length - (closingtag?.length || 1)) + "*" + (closingtag || "");
        }
        return htmlstr + "*";
    };

    return (
        <div className={`${style.checkboxgroup} ${!props.deactivateClickLabel ? style.clickactivated : ""}`} onClick={() => { if (!props.deactivateClickLabel) { handleChecked(!(props.checked || false)) } }}>
            <input
                disabled={props.disabled}
                ref={inputRef}
                name={props.name}
                required={props.required || false}
                className={`${style.checkbox} ${props.errorMsg ? style.error : ""}`}
                readOnly={props.readonly}
                type="checkbox"
                checked={props.checked || false} onChange={onChange}
            />
            <div id={props.name} className={style.checkspan} onClick={() => handleChecked(!(props.checked || false))}>
                {props.checked && <Checkmark />}
            </div>
            {props.htmlLabel ? <div className={style.checkboxlabel} dangerouslySetInnerHTML={{ __html: props.required ? addRequiredStar(props.htmlLabel) : props.htmlLabel }} />
                : (props.label && <div className={style.checkboxlabel}><span>{props.label}{props.required ? "*" : ""}</span></div>)}

        </div>
    )
}

export default CustomCheckbox;