export const educationInstitutionId = "educationInstitutionId";
export const educationMainSubjectIds = "educationMainSubjectIds";
export const educationOrientationId = "educationOrientationId";
export const enddate = "enddate";
export const educationExpecedGraduationDate="expectedGraduationDate";
export const situationtypeId = "situationtypeId";
export const startdate = "startdate";
export const changeDistrict = "changeDistrict";
export const workLocationId = "workLocationId";
export const unknownWorkLocation = "unknownWorkLocation";
export const jobTypeId = "jobTypeId";
export const jobTitleId = "jobTitleId";
export const otherText= "otherText";
export const graduationDate="graduationDate";
