import React from 'react';
import style from './field-item.module.scss'
import * as formutil from '../../../../common/get-form-component'
import { isVisible } from '../../../../common/use-condition'
import { FormField } from '../../../../models/formModels';

interface FieldItemProps {
  topmodel: any;
  formItem: FormField;
  value: any;
  func: any;
  fieldstyle: React.CSSProperties;
  addPadding: boolean;
  disabled: boolean;
  setError?: (errorMsg: string) => void;
  errorMsg?: string;
}

function FieldItem(props: FieldItemProps) {

  const visible = isVisible(props.topmodel, props.formItem.condition);

  return (
    <div className={style.fieldcontainer} >
      {visible && <div key={props.formItem.alias} className={`${style.formfield} ${props.addPadding ? style.addpadding : ""}`} >

        {formutil.getCompoent(
          props.formItem.caption,
          props.formItem, props.value || "",
          props.func,
          props.formItem.required,
          props.formItem.settings?.pattern ? { patternStr: props.formItem.settings?.pattern, errorMsg: props.formItem.settings?.patternInvalidErrorMessage } : undefined,
          props.setError,
          props.disabled,
          props.errorMsg,
          props.formItem.caption)}
      </div>}
    </div>)
}

export default FieldItem;