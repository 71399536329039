import { useMemo } from 'react';
import { Event } from '../../../../api';
import { getDurationFromMinutes, getLongDateStr } from '../../../../common/dateutil';
import { getStatusElement } from '../../../../common/get-event-status';
import Calendar from '../../icons/Calendar/calendar';
import Information from '../../icons/Information/information';
import Timer from '../../icons/Timer/timer';

import style from '../event-card.module.scss';

interface EventCardDetailProps {
    isLoggedIn: boolean;
    item: Event;
    showDeadlineInfo?: boolean;
}

function EventCardDetail(props: EventCardDetailProps) {
    const statusElem = useMemo(() =>
        getStatusElement(style, props.item.signedUp, props.item.onWaitList, props.item.eventInformation?.enddate)
        , [props])

    const showDeadlineInfo = useMemo(() =>
        (props.item.signedUp || props.showDeadlineInfo)
        && props.item.eventInformation?.cancelDeadline
        && new Date(props.item.eventInformation.cancelDeadline) > new Date()
        , [props.item, props.showDeadlineInfo])

    const eventduration = useMemo(() =>
        (props.item.eventInformation?.sessionCount && props.item.eventInformation?.sessionCount > 0) ? `${props.item.eventInformation!.sessionCount} session${props.item.eventInformation!.sessionCount === 1 ? "" : "er"}` : `Varighed: ${getDurationFromMinutes(props.item.eventInformation?.duration)}`
        , [props.item])

    return (
        <>{props.item &&
            <div className={`${style.detail} eventcarddetail`}>
                {statusElem && <div className={style.status}>{statusElem}</div>}
                <div className={style.detailline}><Calendar />{`${getLongDateStr(new Date(props.item.eventInformation!.startdate!))}`}</div>
                <div className={style.detailline}>{props.item.eventInformation?.duration ? <><Timer />{eventduration}</> : ""}</div>
                <div className={style.detailline}>
                    {showDeadlineInfo ? <><span className={style.deadline}>{`Afmeldingsfrist: ${getLongDateStr(new Date(props.item.eventInformation!.cancelDeadline!))}`}</span></> :
                        props.item.eventPriceInfomration?.eventwithprices ? <><Information />Betalingskursus</> : ""}
                </div>
            </div>
        }
        </>
    )
}

export default EventCardDetail;