
function Add() {

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4.81132V6.78113L0 6.78113L0 4.81132L12 4.81132ZM7.11824 0L7.11824 12H4.89379L4.89379 0L7.11824 0Z" fill="white" />
    </svg>
  )
}

export default Add;


