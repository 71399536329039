import style from './my-events.module.scss'
import * as api from '../../api';
import React, { useCallback } from 'react';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import { fetchErrorMsg } from '../../constants/common-constants';
import Wrapper from '../shared/Wrapper/wrapper';
import { ElementHelpText, MyEventsElement } from '../../models/umbracoElement';
import OverviewList from '../shared/OverviewList/overview-list';
import EventCardMain from '../shared/EventCard/Main/event-card-main';
import EventCardDetail from '../shared/EventCard/Detail/event-card-detail';
import OverviewItem from '../shared/OverviewList/OverviewItem/overview-item';
import InformationBox from '../shared/InformationBox/information-box';
interface MyEventsProps {
    data: string;
}


function MyEvents(props: MyEventsProps) {
    const [umbracoSettings] = React.useState<MyEventsElement>(props.data ? JSON.parse(props.data) : {})
    const [previousList, setPreviouseList] = React.useState<api.Event[]>([]);
    const [futureList, setFutureList] = React.useState<api.Event[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const courses: api.MyEvents = await api.MyeventsService.getMyEvents();

            setPreviouseList(courses.previousEvents || []);
            setFutureList(courses.futureEvents || []);

        } catch (error) {
            console.error(error)
            setErrorMsg(fetchErrorMsg);
        }
        finally {
            setLoading(false);
        }
    }, [])

    React.useEffect(() => {
        const call = async () => {
            await getData();
        }
        call();
    }, [getData]);



    return (
        <>
            {umbracoSettings.showComing && <Wrapper
                properties={{ ...{} as ElementHelpText, title: umbracoSettings?.title || "Tilmeldte arrangementer" }}
                loading={loading}
                notification={errorMsg ? {
                    message: errorMsg,
                    type: NotificationBarType.Danger,
                    onClick: () => setErrorMsg("")
                } : undefined}
                backBarSettings={undefined}
                bottomZone={undefined}
            >
                {<div >
                    {!loading && <>
                        {futureList.length === 0 ? <InformationBox title={umbracoSettings?.noComingEventText || "Du er endnu ikke tilmeldt nogen arrangementer"}
                            content={""}
                        /> :
                            <div className={style.joinedevents}>
                                <div className={style.eventcardlist}>
                                    <OverviewList
                                        items={futureList.map((list, key) =>
                                            <div className={style.eventcard}>
                                                <OverviewItem
                                                    key={`eventcard - ${key} `}
                                                    main={<EventCardMain item={list} isLoggedIn={true}></EventCardMain>}
                                                    detail={<EventCardDetail showDeadlineInfo={true} item={list} isLoggedIn={true}></EventCardDetail>}
                                                ></OverviewItem> </div>
                                        )}
                                    />
                                </div>
                            </div>}
                    </>}
                </div>}
            </Wrapper>}
            {umbracoSettings.showPrevious && <Wrapper properties={{ ...{} as ElementHelpText, title: umbracoSettings?.previousTitle || "Tidligere arrangementer" }}
                loading={loading}
                notification={errorMsg ? {
                    message: errorMsg,
                    type: NotificationBarType.Danger,
                    onClick: () => setErrorMsg("")
                } : undefined}
                backBarSettings={undefined}
                bottomZone={undefined}
            >
                {<div>
                    {!loading && <>
                        {previousList.length === 0 ? <InformationBox title={umbracoSettings?.noPreviousEventText || "Du har endnu ikke deltaget nogen arrangementer"}
                            content={""}
                        /> :
                            <div className={style.joinedevents}>

                                <div className={style.eventcardlist}>
                                    <OverviewList
                                        items={previousList.map((list, key) =>
                                            <div className={style.eventcard}>
                                                <OverviewItem
                                                    key={`eventcard - ${key} `}
                                                    main={<EventCardMain item={list} isLoggedIn={true}></EventCardMain>}
                                                    detail={<EventCardDetail item={list} isLoggedIn={true}></EventCardDetail>}
                                                ></OverviewItem> </div>
                                        )}
                                    />
                                </div>
                            </div>}
                    </>}
                </div>}
            </Wrapper>}
        </>
    )
}


export default MyEvents;