import style from './event-deregistration-form.module.scss'
import React from 'react';
import * as api from '../../api';
import { EventSignupElement } from '../../models/umbracoElement';
import { postErrorMsg } from '../../constants/common-constants';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';
import Wrapper from '../shared/Wrapper/wrapper';
import ButtonZone from '../shared/ButtonZone/button-zone';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
interface EventDeregistrationFormProps {
    eventid: string;
    settingdata: EventSignupElement;
    closeModal: (deregistered: boolean) => void;
}
function EventDeregistrationForm(props: EventDeregistrationFormProps) {
    const [loading, setLoading] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState("");
    const [success, setSuccess] = React.useState(false);


    const goBack = (reload: boolean) => {
        props.closeModal(reload);
        setSuccess(false);
        setErrorMsg("");
    }
    const cancelEvent = async () => {
        setErrorMsg("");
        setLoading(true)
        return api.EventsService.cancelEvent(props.eventid)
            .then(() => {
                setSuccess(true);
            }).catch((err) => {
                console.log(err)
                setErrorMsg(postErrorMsg)
            }).finally(() =>
                setLoading(false))
    }
    const { helpText, ...rest } = props.settingdata;
    return (
        <Wrapper properties={{ ...rest, title: "Afmeldingsformular" }}
            backBarSettings={{ backLabel: props.settingdata.cancelButtonLabel || "Annuller", onBackClick: () => goBack(false) }}
            notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}
            bottomZone={!success ? <ButtonZone>
                <FlowButton className={style.onebutton} isSubmit={true} onClick={cancelEvent} loading={loading} disabled={loading}>
                    {props.settingdata.deregistrationButtonLabel || "Afmeld"}
                </FlowButton>
            </ButtonZone> : undefined}
        >
            {!success ? <div className={style.cancelContent} dangerouslySetInnerHTML={{ __html: props.settingdata.cancelContentText || "" }} />

                : <>
                    <div className={`${style.cancelConfirmationText}`} dangerouslySetInnerHTML={{ __html: props.settingdata.cancelConfirmationText || "" }} />
                    <div>
                        <CTAButton onClick={() => goBack(true)} >{props.settingdata.afterCancelLabel || "Luk"}</CTAButton>
                    </div>
                </>
            }
        </Wrapper>
    )
}
export default EventDeregistrationForm;