import style from './wizard.module.scss'
import { FormSteps, FormError } from '../../../models/step';
import React, { useEffect } from 'react';
import { fixBeforeContinue, postErrorMsg } from '../../../constants/common-constants';
import Loader from '../Loader/loader';
import NotificationBarSetting, { NotificationBarType } from '../NotificationBarSetting/notification-bar-setting'
import { ProgressNumber } from './Progress/progress-number';
import FlowButton from '../Buttons/FlowButton/flow-button';
import NavigationBar from '../NavigationBar/navigation-bar';
import { closeNotification } from '../../../common/notificationHelper';
import { BackBarSetting } from '../../../models/umbracoElement';

interface WizardProps {
    data: FormSteps;
    submit: () => Promise<any>;
    errorList: FormError[];
    redirectUrl?: string;
    confirmed: boolean;
    checkRequiredData?: (required: string[], savedErrors: FormError[]) => boolean
    cancelLabel?: string;
    onCancelClick?: () => void;
    onSubmitted?: (submitted: boolean) => void;
    info?: JSX.Element;
    errorMsg?: string;
    clearErrorMsg?: () => void
    setShowErrorNotification: (show: boolean) => void
    showErrorNotification: boolean;
    navigationSettings: BackBarSetting;

}
function Wizard(props: WizardProps) {
    const isDisabled = (progressable: undefined | boolean) => {
        return progressable === undefined ? false : !(props.data.steps[currentStep - 1]?.progressable ?? false)
    }


    const [currentStep, setCurrentStep] = React.useState(1);
    const [submitted, setSubmitted] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [errormssage, setErrormssage] = React.useState("");
    const [disabled, setDisabled] = React.useState(isDisabled(props.data.steps[currentStep - 1]?.progressable));
    const [tempErrorList, setTempErrorList] = React.useState<FormError[]>([]);

    useEffect(() => {
        setDisabled(isDisabled(props.data.steps[currentStep - 1]?.progressable))
    }, [props.data.steps[currentStep - 1]?.progressable]);

    

    const formdata = { ...props.data };
    const steps = formdata.steps;
    const confirmationform = formdata.confirmationPage
    const submitForm = async () => {
        setSubmitting(true)
        await props.submit()
            .then(() => {
                setErrormssage("");
                setSubmitted(true);
                if (props.onSubmitted) {
                    props.onSubmitted(true);
                }
            })
            .catch((err) => {
                setErrormssage(postErrorMsg);
                console.error(err)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const onNextClick = async () => {
        const currentStepForm = steps.find(x => x.stepNo === currentStep);
        const required = currentStepForm?.required || [];
        let checkOk = true;
        if (props.checkRequiredData) {
            //reset current error on current step
            setTempErrorList([]);
            checkOk = props.checkRequiredData(required, tempErrorList)
        }
        if (checkOk) {
            props.setShowErrorNotification(false);
            const submit = currentStep === formdata.lastStepNo;
            if (submit) {
                await submitForm();
            }
            else {
                setCurrentStep(currentStep + 1);
                setDisabled(props.data.steps[currentStep].progressable === false && !props.confirmed);
            }
        }
        else { props.setShowErrorNotification(true) }

    }
    const onPreviousClick = () => {
        setCurrentStep(currentStep - 1);
        //save current error list before going back to be used when next is clicked again.
        setTempErrorList(props.errorList);
        setDisabled(false);
    }
    useEffect(() => {
        setErrormssage(props.errorMsg || "");
    }, [props.errorMsg])

    const createProgressButtons = () => {
        const buttons: JSX.Element[] = [];    
        for (let index = 0; index < formdata.lastStepNo; index++) {
            buttons.push(<ProgressNumber number={index + 1} key={`progress-${index}`} active={index + 1 === currentStep}></ProgressNumber>)
        }
        return buttons;
    }

    const clearErrorMsg = () => {
        setErrormssage("");
        closeNotification();
        if (props.errorMsg && props.clearErrorMsg) {
            props.clearErrorMsg();
        }
    }

    useEffect(() => {
        if (!props.showErrorNotification){            
            clearErrorMsg();
        }
    }, [props.showErrorNotification])
    return (
        <div className={`${style.wizard}`}>           
            <NavigationBar onBackClick={() => {
                closeNotification();
                if (props.onCancelClick)
                    props.onCancelClick();
            }
            }
                backLabel={props.cancelLabel || "Annuller tilmelding"}
                notificationbar={props.showErrorNotification ? <NotificationBarSetting text={fixBeforeContinue} type={NotificationBarType.Danger} /> : <></>}
                navigationSettings={props.navigationSettings}
            />
            <div className={`${style.wizardmain}`}>
                <div className={style.wizardform}>
                    <div className={style.toptitlecontainer}>
                        <h2>{formdata.title}</h2>
                    </div>
                    <div className={style.progress}>
                        {createProgressButtons()}
                    </div>
                    <div className={style.wizardcontent}>
                        {!submitted && (<>
                            {steps.filter(s => s.stepNo === currentStep).map(step => <div className={style.stepform} key={"form-" + step.stepNo} style={{ display: currentStep === step.stepNo ? "" : "none" }}>{step.formdata}</div>)}
                            <div className={`${style.flowbuttons}`}>
                                {currentStep > 1 ? <FlowButton
                                    isBack={true}
                                    onClick={() => onPreviousClick()} >{formdata.previousLabel}</FlowButton>
                                    : <div className={style.emptyflowbutton} />
                                }

                                {submitting ? <Loader /> :
                                    (<>
                                        {<FlowButton
                                            isSubmit={currentStep === formdata.lastStepNo}
                                            disabled={((currentStep === formdata.lastStepNo && !props.confirmed) || disabled) ? true : false}
                                            onClick={() => onNextClick()} >{currentStep === formdata.lastStepNo ? formdata.submitString : formdata.nextLabel}
                                        </FlowButton>}
                                    </>)}
                            </div>


                        </>)}
                        {submitted && confirmationform &&
                            (<div>
                                {confirmationform}
                            </div>
                            )}
                    </div>
                </div>
                {props.info &&
                    <div className={style.wizardinfo}>
                        {props.info}
                    </div>}
            </div>
        </div >
    )
}
export default Wizard;