import Spinner from "../icons/Spinner/spinner";
interface LoaderProps {
  size?: number
}
function Loader(props: LoaderProps) {
  return (
    <div><Spinner {...props} /></div>
  )
}

export default Loader;