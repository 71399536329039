import React, { useEffect } from 'react';
import * as api from '../../../api';
import { getDateStr } from '../../../common/dateutil';
import { ContingentElement } from '../../../models/umbracoElement';
import style from './contingent-info.module.scss';
import CustomAccordion from '../../shared/CustomAccordion/custom-accordion';
import { AccordionDropdownElement } from '../../shared/CustomAccordion/AccordionElement/accordion-element';
import Wrapper from '../../shared/Wrapper/wrapper';
import CustomTable from '../../shared/CustomTable/custom-table';

interface ContingentProps {
    memberid: string
    umbracoData: ContingentElement;
    contingent?: api.Contingent;
    getDataFunc: Function;
    showPeriod: boolean;
}

function ContingentInfo(props: ContingentProps) {
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await props.getDataFunc();
        }
        call().catch((err) => { }).finally(() => { setLoading(false) });
    }, []);

    const prepareData = (props: ContingentProps) => {
        const data =
        {
            byDefaultOpen: true,
            dropdownValues: [] as AccordionDropdownElement[],
            title: props.contingent?.districtName ?? ''
        }

        if (props.contingent?.fractionName) {
            data.dropdownValues.push({
                title: 'Fraktion',
                value: props.contingent?.fractionName
            });
        }

        if (props.contingent?.balance) {
            data.dropdownValues.push({
                title: 'Udestående',
                helpText: 'Beløbet der vises her er dit udestående til DLF. I perioden fra vi har sendt dig kontingentopkrævningen til den betales vil beløbet der fremgår være hvad du skylder os. Fra du betaler til beløbet går i 0, vil der kunne gå op til 5 bank hverdage.',
                value: `${props.contingent.balance.toFixed(2)}`
            })
        }

        data.dropdownValues.push({
            title: props.umbracoData.billingIntervalText,
            value: props.contingent?.billingInterval ?? ''
        })

        if (props.showPeriod) {
            data.dropdownValues.push({
                title: 'Betalingsperiode',
                value: `${getDateStr(new Date(props.contingent?.billingStartDate!))} - ${getDateStr(new Date(props.contingent?.billingEndDate!))}`
            })
        }

        return [data];
    }
    const rows = React.useMemo(() => {
        if (!props.contingent)
            return []
        const amountrows = props.contingent?.billingDetails?.map((x) =>
            ({ data: [x.rateDetials || "", x.rateAmount?.toFixed(2) || ""] })) || [];
        return [...amountrows, { data: ["i alt", props.contingent?.billingDetails?.map(x => x.rateAmount).reduce((a, b) => { return a! + b! })?.toFixed(2) || ""] }]

    }, [props.contingent])

    const columns =
        [{
            columnName: props.umbracoData.rateTitle,
            visible: true,
            index: 0
        }, {
            columnName: props.umbracoData.rateText,
            visible: true,
            index: 1
        }]

    return (<Wrapper
        properties={props.umbracoData}
        loading={loading}
    >
        {
            !loading &&
            <>
                {props.contingent ?
                    <>
                        <div className={style.toptext}>
                            <CustomAccordion data={
                                prepareData(props)
                            } />
                        </div>
                        {
                            props.contingent!.reductions!.length > 0
                                ? props.contingent!.reductions!.map(r => (
                                    <CustomAccordion data={[{
                                        byDefaultOpen: true,
                                        dropdownValues: [
                                            { title: 'Periode', value: `${getDateStr(new Date(r.startDate!))} - ${getDateStr(new Date(r.endDate!))}` },
                                            { title: 'Årsag', value: r.reasoning ?? '' }],
                                        title: "Reduktion " + r.reductionAmount + "%"
                                    }]} />
                                ))
                                : <div />
                        }
                        {
                            props.contingent.billingDetails && props.contingent.billingDetails.length > 0
                            && <div className={style.billingdetails}><CustomTable
                                columns={columns}
                                rows={rows}
                                showButtonLabel={""}
                                showAllColumns={true}
                                emptyText={props.umbracoData.noDataText || "Intet data at vise"}
                            /></div>
                        }
                    </> : <div>{props.umbracoData.noDataText} </div>
                }
            </>
        }
    </Wrapper>
    )
}

export default ContingentInfo;

