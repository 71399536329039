import React, { useCallback } from 'react';
import * as api from '../../api';
import { closeNotification } from '../../common/notificationHelper';
import { fetchErrorMsg, postErrorMsg } from '../../constants/common-constants';
import { DropdownItem } from '../../models/dropdownItem';
import { MissingDeliveryFormElement } from '../../models/umbracoElement';
import CustomButton from '../shared/Buttons/CustomButton/custom-button';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';
import ButtonZone from '../shared/ButtonZone/button-zone';
import CustomDropdown from '../shared/CustomDropdown/custom-dropdown';
import HelperIcon from '../shared/HelperIcon/helper-icon';
import Loader from '../shared/Loader/loader';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import TopTitle from '../shared/TopTitle/top-title';
import Wrapper, { NotificationMessage } from '../shared/Wrapper/wrapper';
import style from './missing-delivery.module.scss';

interface MissingDeliveryProps {
    data: string;
    memberid: string;
}

function MissingDelivery(props: MissingDeliveryProps) {

    const [subscriptions, setSubscriptions] = React.useState<DropdownItem[]>([]);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = React.useState<string>();
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [notification, setNotification] = React.useState<NotificationMessage>()
    const elementdata: MissingDeliveryFormElement = JSON.parse(props.data);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            let currentSubscriptions: api.Subscription[] = await api.SubscriptionsService.getMySubscriptions();
            if (elementdata.onlyPhysicalDelivery) {
                currentSubscriptions = currentSubscriptions.filter(x => Boolean(x.magazine?.phsicaldistribution) && x.deliveryOption === "physical")
            }
            const ddData: DropdownItem[] = currentSubscriptions.map(x => { return { id: x.subscriptionId, text: `${x.magazine?.name} ${!(x.magazine?.phsicaldistribution) ? "(Elektronisk)" : ""}`, originalData: x } as DropdownItem });
            setSubscriptions(ddData);
        } catch (error) {
            setNotification({ message: fetchErrorMsg, type: NotificationBarType.Danger, onClick: () => console.log("Indsendt fejlet") });
        }
        finally {
            setLoading(false);
        }
    }, [elementdata.onlyPhysicalDelivery])

    React.useEffect(() => {
        const call = async () => {
            await getData();
        }
        call();
    }, [getData]);

    const submit = () => {
        if (selectedSubscriptionId) {
            setSubmitting(true);
            api.SubscriptionsService.reportMissingDelivery({ memberId: props.memberid, subscriptionId: selectedSubscriptionId })
                .then(() => {
                    setNotification({ message: elementdata.messageAfterSuccess || "Indsendt successfuldt", type: NotificationBarType.Success, onClick: () => console.log("Indsendt successfuldt") });
                    setTimeout(() => { setNotification(undefined);closeNotification(); }, 5000);
                })
                .catch((error) => {
                    setNotification({ message: "", type: NotificationBarType.Danger, onClick: () => console.log("Indsendt fejlet")});

                })
                .finally(() => {
                    setSubmitting(false)
                    reset();
                });
        }
    }
    const reset = () => {
        setSelectedSubscriptionId(undefined);
    }
    return (
        <Wrapper
            properties={elementdata}
            loading={loading}
            notification={notification ? notification : undefined}
            bottomZone={<ButtonZone><FlowButton isSubmit={true} onClick={submit} disabled={loading || submitting || !selectedSubscriptionId}>{elementdata.submitButtonLabel || "Indsend"}</FlowButton></ButtonZone>}
        >
            {!loading && <div className={`${style.container}`}>
                {subscriptions && <div className={style.subscriptions}>
                    <div className={style.step}>
                        {
                            subscriptions.length === 0
                                ? <div className={style.noSubscriptionText} dangerouslySetInnerHTML={{ __html: elementdata.noMagazineText || "Du har ingen aktive fagblade" }} />
                                : <>
                                    <div className={`${style.content}`} dangerouslySetInnerHTML={{ __html: elementdata.contentText || "" }} />

                                    <div className={style.inputcontainer}>
                                        <div className={style.steplabel}>Abonnement</div>

                                        <CustomDropdown required={subscriptions.length > 0} disabled={subscriptions.length === 0} data={subscriptions} setValue={(selected) => setSelectedSubscriptionId(selected.id)} defaultSelectedId={selectedSubscriptionId} />
                                    </div>
                                </>}
                    </div>
                </div>
                }
            </div>}
        </Wrapper>
        
    )
}

export default MissingDelivery;