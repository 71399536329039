import HelperIcon from '../HelperIcon/helper-icon';
import TopTitle from '../TopTitle/top-title';
import style from './one-page-form-wrapper.module.scss'

interface OnePageFormWrapperProps {
    title: string;
    children: any;
    hasButton?: boolean;
    className?: string;
    helpText?: string;
}
function OnePageFormWrapper(props: OnePageFormWrapperProps) {

    return (
        <div className={props.className}>
            <div className={`${props.hasButton ? style.withButton : ""}`}>
                {props.title && <TopTitle hideCorner={true} titletext={props.title} helpIcon={props.helpText ? <HelperIcon helptext={props.helpText} /> : undefined}></TopTitle>}
                {props.children}
            </div>
        </div >
    )
}
export default OnePageFormWrapper;