/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MyEvents } from '../models/MyEvents';
import { request as __request } from '../core/request';

export class MyeventsService {

    /**
     * Description: Get events signedup by user.
 * Operation: GetMyEvents.
 * Area: Myevents.
     * @returns MyEvents Success
     * @throws ApiError
     */
    public static async getMyEvents(): Promise<MyEvents> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/myevents`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}