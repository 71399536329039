import * as api from '../../../../api';
import CprInput from '../../../shared/CprInput/cpr-input';
import CustomInput from "../../../shared/CustomInput/custom-input";
import { emailErrorMsg } from '../../../../constants/common-constants';
import * as createInsuranceConstants from "../../../../constants/create-insurance-constants";
import { cpr, email as emailregex } from '../../../../constants/regex-expressions';
import style from './insurance-contact.module.scss';
import { StepFormData } from '../../../../models/step';
import InputWrapper from '../../../shared/InputWrapper/input-wrapper';
import { getErrorMsgByName } from '../../../../common/getErrorMsgByName';

export interface InsuranceContactProps extends StepFormData{
    model: api.CreateInsurance;
    handleCoInsuredChange: (name: string, value: string | object | undefined) => void;
    coInsuredOpen: boolean;
    setCoInsuredOpen?: (isOpen: boolean) => void;
    showOnlyCoInsured: boolean;
}

function InsuranceContact(props: InsuranceContactProps) {
    const getErrorMsg = (name: string) => {
        return getErrorMsgByName(props.errorList, name);
    }
    return (
        <div className={style.step}>
            {!props.showOnlyCoInsured && <>
                <div className={style.stepTitle}>Dine oplysninger</div>
                <InputWrapper
                    component={CustomInput}
                    title={"Navn"}
                    disabled={true}
                    maxLength={100}
                    name={createInsuranceConstants.name}
                    value={props.model.mainInsured!.name || ""}
                />

                <InputWrapper
                    component={CustomInput}
                    title={"CPR nr."}
                    disabled={true}
                    pattern={{ patternStr: cpr, errorMsg: "Forkert CPR-format." }}
                    name={createInsuranceConstants.cpr}
                    value={props.model.mainInsured!.cpr || ""}
                />

                <InputWrapper
                    component={CustomInput}
                    title={"E-mail"}
                    type={"email"}
                    name={createInsuranceConstants.email}
                    value={props.model.mainInsured!.email || ""}
                    disabled={true}
                    maxLength={100}
                />
                <div className={style.addcoinsured} onClick={() => props.setCoInsuredOpen!(!props.coInsuredOpen)}>{props.coInsuredOpen ? "- Slet medforsikret" : "+ Tilføj medforsikret"}</div>
            </>}
            {props.coInsuredOpen && <div className={style.coinsuredcontainer}>
                <div className={style.stepTitle}>Den medforsikredes oplysninger</div>

                <InputWrapper
                    component={CustomInput}
                    title={"Navn"}
                    required={true}
                    name={`co-${createInsuranceConstants.name}`}
                    setError={(errorMsg: string) => props.setError(`co-${createInsuranceConstants.name}`, errorMsg)}
                    setInput={(input: string) => props.handleCoInsuredChange(createInsuranceConstants.name, input)}
                    maxLength={100}
                    errorMsg={getErrorMsg(`co-${createInsuranceConstants.name}`)}
                    value={props.model.coInsured?.name || ""}
                />
                <InputWrapper
                    component={CprInput}
                    title={"CPR nr."}                    
                    name={`co-${createInsuranceConstants.cpr}`}
                    value={props.model.coInsured?.cpr || ""}
                    pattern={{ patternStr: cpr, errorMsg: "Forkert CPR-format." }}
                    required={true}
                    setError={(errorMsg: string) => props.setError(`co-${createInsuranceConstants.cpr}`, errorMsg)}
                    setInput={(input: string) => props.handleCoInsuredChange(createInsuranceConstants.cpr, input)}
                    errorMsg={getErrorMsg(`co-${createInsuranceConstants.cpr}`)} />
                <div className={style.stepLabel}></div>
                <InputWrapper
                    component={CustomInput}
                    title={"E-mail"}                    
                    name={`co-${createInsuranceConstants.email}`}
                    type={"email"}
                    required={true}
                    value={props.model.coInsured?.email || ""}
                    pattern={{ patternStr: emailregex, errorMsg: emailErrorMsg }}
                    disabled={false}
                    maxLength={100}
                    errorMsg={getErrorMsg(`co-${createInsuranceConstants.email}`)}
                    setError={(errorMsg: string) => props.setError(`co-${createInsuranceConstants.email}`, errorMsg)}
                    setInput={(input: string) => props.handleCoInsuredChange(createInsuranceConstants.email, input)} />
            </div>}
        </div>)
}

export default InsuranceContact;