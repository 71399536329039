/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiRequestOptions } from './ApiRequestOptions';
import { getBrand } from '../../common/getbrand'

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

type Config = {
    BASE: string;
    VERSION: string;
    WITH_CREDENTIALS: boolean;
    TOKEN?: string | Resolver<string>;
    USERNAME?: string | Resolver<string>;
    PASSWORD?: string | Resolver<string>;
    HEADERS?: Headers | Resolver<Headers>;
}
const headers: Headers = { "x-dlf-brand": getBrand() }
export const OpenAPI: Config = {
    BASE: '',
    VERSION: '1.0.0',
    WITH_CREDENTIALS: true,
    TOKEN: undefined,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: async (options) => {

        let token = await validateCaptcha()

        return {
            "x-dlf-brand": getBrand(),
            'gcaptchatoken': token
        }
    }
}

const validateCaptcha = () => {
    return new Promise<string>((res, rej) => {
        if (window.grecaptcha === undefined) {
            res('');
        }
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute((window as any).DLFConfig.gchaptchakey, {
                action: 'homepage'                
            }).then((token: string) => {
                return res(token);
            })
        })
    })
}