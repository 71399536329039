import style from './pre-signup-step.module.scss'
import * as signupConstants from "../../../constants/signup-constants"
import { MemberSignup } from '../../../api';
import { StepFormData } from '../../../models/step';
import CustomCheckbox from '../../shared/CustomCheckbox/custom-checkbox';
import InputWrapper from '../../shared/InputWrapper/input-wrapper';
interface PreSignupStepProps extends StepFormData {
    signUpModel: MemberSignup;
    arrearsTitle?: string;
    arrearsDescription?: string;
    arrearsCheckboxLabel?: string;
}

function PreSignupStep(props: PreSignupStepProps) {
    return (
        <div className={style.step}>
            <div className={style.stepTitle}>{props.arrearsTitle}</div>
            <div dangerouslySetInnerHTML={{ __html: props.arrearsDescription || "" }} />
            <InputWrapper
                component={CustomCheckbox}
                name={signupConstants.confirmedPayment}
                required={true}
                htmlLabel={props.arrearsCheckboxLabel}
                checked={props.signUpModel.confirmedPayment || false}
                setInput={(input?: boolean) => props.handleChange(signupConstants.confirmedPayment, input || false)} />

        </div >
    )
}

export default PreSignupStep;