import style from './booking-table.module.scss'
import CustomButton from '../Buttons/CustomButton/custom-button';
import { useEffect } from 'react';
import { useWindowSize } from '../../../hooks/windowsize';
import React from 'react';
import CancelButton from '../Buttons/CancelButton/cancel-button';
import CTAButton from '../Buttons/CTAButton/cta-button';

interface Row {
    data: any[],
    rawdata?: any;
}

interface Column {
    columnName: string;
    index: number;
    visible: boolean;
}

interface BookingTableProps {
    columns?: Column[];
    rows?: Row[];
    showButtonLabel: string;
    cancelButtonLabel?: string;
    emptyText?: string;
    openDetail?: (situation: Row) => void;
    openCancel?: (situation: Row) => void;
    showAllColumns?: boolean;
}
function BookingTable(props: BookingTableProps) {
    const tabledivref = React.useRef<HTMLDivElement | null>();
    const size = useWindowSize();
    
    const isTableOverflow = tabledivref.current ? tabledivref.current.scrollWidth > tabledivref.current.clientWidth : false; 
    const [isOverflow,setIsOverflow]=React.useState(isTableOverflow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>setIsOverflow(isTableOverflow),[size])
    return (<>
        {props.rows &&
        <div className={style.tablediv} ref={r => tabledivref.current = r}>
            <table>
                <thead>
                    <tr className={style.header}>
                        {props.columns?.map((c, i) => {
                            return <th className={i > 0 && !props.showAllColumns ? (isOverflow ? style.hiddencolumn : style.hiddenmd) : ""} key={i}>{c.columnName}</th>
                        })}
                        {props.openDetail && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {props.rows.length > 0 ? props.rows.map(
                        (x, i) => (
                            <tr key={`table${i}`} className={`${i < (props.rows || []).length - 1 ? style.divider : ""}`}>
                                {props.columns?.filter(s => s.visible).map((column, ic) => {
                                    return <td className={ic > 0 && !props.showAllColumns ? (isOverflow ? style.hiddencolumn : style.hiddenmd) : ""} key={`data-${ic}`}>{x.data[column.index]}</td>
                                })}
                                <td className={style.showbuttoncolumn}>
                                    {props.openDetail && <CTAButton color={"var(--ctabuttoncolor)"} onClick={() => props.openDetail!(x)}>{props.showButtonLabel || "Vis"}</CTAButton>}
                                    {props.openDetail && props.openCancel && <span style={{ paddingLeft: 5 }} /> }
                                    {props.openDetail && <CancelButton color={"var(--red)"} onClick={() => props.openCancel!(x)}>{props.cancelButtonLabel || "Aflys"}</CancelButton>}
                                </td>
                                
                            </tr>)) :
                        <tr><td colSpan={100}>{props.emptyText}</td></tr>}
                </tbody>
            </table>
            </div>
        }
    </>)
}
export default BookingTable;