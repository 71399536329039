import React from 'react';
import * as api from '../../api';
import { UserConfirmationElement } from '../../models/umbracoElement';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';
import ButtonZone from '../shared/ButtonZone/button-zone';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import Wrapper from '../shared/Wrapper/wrapper';
import style from './user-confirmation.module.scss';
interface UserConfirmationProps {
    data: any;
    action: string;
    id: string;
    customparams?: string;
}
function UserConfirmation(props: UserConfirmationProps) {

    const confirmationElem: UserConfirmationElement = JSON.parse(props.data);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);

    const onClick = () => {
        setLoading(true);
        api.UserconfirmationService.userConfirmation({ id: props.id, action: props.action, customParams: props.customparams })
            .then(() => setConfirmed(true))
            .catch((error) => {
                let errorstr = "Der er sket fejl.";
                if (error.status === 409 && error.body)
                    errorstr = error.body
                setErrorMsg(errorstr);
                console.log(JSON.stringify(error));
                setConfirmed(false);
            })
            .finally(() => setLoading(false));
    }
    return (
        <Wrapper properties={confirmationElem}
            notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}
            bottomZone={<ButtonZone><FlowButton isSubmit={true} loading={loading} onClick={onClick} disabled={loading || confirmed} hidden={confirmed}>{confirmationElem.confirmButtonLabel}</FlowButton></ButtonZone>}
        >
            {!confirmed ?
                <div className={`${style.content}`} >
                    <div dangerouslySetInnerHTML={{ __html: confirmationElem.beforeConfirmMessage || "<div></div>" }} />
                </div>
                : <div className={style.content}>
                    <div className={`${style.elementcontent} ${style.richtext}`} dangerouslySetInnerHTML={{ __html: confirmationElem.message || "<div></div>" }} />
                </div>

            }
        </Wrapper>)
}

export default UserConfirmation;