import React, { useCallback, useEffect } from 'react';
import * as api from '../../api';
import { getEventImageUrl } from '../../common/getEventImageUrl';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import { defaultEventImage, fetchErrorMsg } from '../../constants/common-constants';
import { EventSignupElement } from '../../models/umbracoElement';
import EventDeregistrationForm from '../EventDeregistration/event-deregistration-form';
import EventSignup from '../EventSignup/event-signup';
import SessionDeregistration from './SessionDeregistration/session-deregistration';
import SessionRegistration from './SessionRegistration/session-registration';
import EventDetailInfoWindow from './EventDetailInfoWindow/event-detail-info-window';
import Wrapper from '../shared/Wrapper/wrapper';
import EventDetailMain from './EventDetailMain/event-detail-main';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
interface EventDetailsProps {
    data: any,
    eventid: string,
    memberid: string
}

function EventDetails(props: EventDetailsProps) {
    const [settingdata] = React.useState<EventSignupElement>(props.data ? JSON.parse(props.data) : {});
    const [currentEvent, setCurrentEvent] = React.useState<api.Event>({} as api.Event);
    const [loading, setLoading] = React.useState(false)
    const [signedUpSessionIds, setSignedUpSessionIds] = React.useState<string[]>([]);
    const [signingUp, setSigningUp] = React.useState(false);
    const [cancelling, setCancelling] = React.useState(false);
    const [sessionSigningUp, setSessionSigningUp] = React.useState(false);
    const [sessionCancelling, setSessionCancelling] = React.useState(false);
    const [chosenSession, setChosenSession] = React.useState<api.EventSession>();
    const [infomessage, setInfomessage] = React.useState("");
    const [errormessage, setErrormessage] = React.useState("");
    const [hasEnded, setHasEnded] = React.useState(false);
    const [signedIn, setSignedIn] = React.useState(false);
    const [eventImageUrl, setEventImageUrl] = React.useState<string | undefined>();

    const setupData = useCallback(async () => {
        const getInfoMessasge = (
            signedup: boolean,
            cannotcancel: boolean,
            signupOverdue: boolean,
            signupWithoutLogin: boolean,
            preRequiredList: api.Event[],
            blockingList: api.Event[],
            hasEnded: boolean,
            inOnWaitingList: boolean,
            webSignUpStartDate: Date | null | undefined,
            eventExpiratedText: string | null | undefined) => {
            let msg = "";
            //TODO: remove following settings from Umbraco, as new design has view for these

            // if (signedup && hasEnded) {
            //     msg += settingdata.alreadyJoinedText || "Du har deltaget i dette arrangement";
            // }
            // else if (signedup) {
            //     msg += settingdata.alreadySignedupText || "Du er tilmeldt dette arrangement";
            // }
            // else if (inOnWaitingList) {
            //     msg += settingdata.isOnWaitingListText || "Du har tilmeldt dette arrangement og afventer på godkendelse";
            // }
            // if (!signupWithoutLogin) {
            //     msg += settingdata.loginNeededText || "Du skal være medlem for at tilmelde dig til dette arrangement"
            // }
            // if (signupOverdue && !signedup) {
            //     msg += currentEvent.eventInformation?.webRegistrationStoppedMessage || "Tilmeldingsfristen er overskredet";
            // }

            if (preRequiredList.length > 0) {
                msg += (settingdata.prerequiredMissingText || "Du kan ikke tilmelde dette arrangement, fordi du har endnu ikke deltaget de følgende arrangementer:") + "<br/>";
                preRequiredList.forEach(x => {
                    const url = window.location.href.replace(props.eventid, x.eventId!);
                    msg += `<br /> - <a href="${url}" target="_blank">${x.eventInformation?.name}</a>`
                })
            }

            if (blockingList.length > 0) {
                msg += (msg ? "<br /><br />" : "")
                msg += (settingdata.blockingEventSignedUpText || "Du kan ikke tilmelde dette arrangement, fordi du allerede har deltaget de følgende arrangementer:") + "<br/>";
                blockingList.forEach(x => {
                    const url = window.location.href.replace(props.eventid, x.eventId!);
                    msg += `<br /> - <a href="${url}" target="_blank">${x.eventInformation?.name}</a>`

                })
            }

            if (cannotcancel && signedup && !hasEnded) {
                msg += (msg ? "<br /><br />" : "") + (settingdata.deregistrationDeadlineExceededText || "Afmeldingsfristen er overskredet. Kontakt os for afmelding.")
            }
            if (signupOverdue && !signedup) {
                msg += (msg ? "<br /><br />" : "") + (eventExpiratedText || "Tilmeldingsfristen er overskredet.")
            }
            
            if (webSignUpStartDate && new Date().getTime() < webSignUpStartDate.getTime()) {
                const formattedDate = webSignUpStartDate.toLocaleDateString("da-DK", { day: 'numeric', month: 'short', year: 'numeric' });
                msg += (msg ? "<br /><br />" : "") + (settingdata.preEventText || "Tilmeldingsperioden er endnu ikke startet, vi venter spændt på at gå i gang d. ") + formattedDate;
            }

            return msg;
        }

        try {
            const userSignedIn = isUserLoggedIn(props.memberid);
            setSignedIn(userSignedIn);
            if (props.eventid) {
                const newEvent: api.Event = await api.EventsService.getEventById(props.eventid);
                let eventSignedUp = undefined;
                let eventWaitingList = undefined;
                let cannotcancel = false;
                let signupdeadlineoverdue = false;
                let signupWithoutLogin = true;
                let preRequiredList: api.Event[] = [];
                let blockingList: api.Event[] = [];
                const enddate = newEvent?.eventInformation?.enddate;
                const eventEnded = !!enddate && (new Date(enddate) < new Date());
                setHasEnded(eventEnded)
                setCurrentEvent(newEvent)
                if (newEvent.eventInformation?.imageUrl) {
                    setEventImageUrl(newEvent.eventInformation?.imageUrl);
                }

                if (userSignedIn) {
                    //member is logged in. get my events to see if the user is signed up to this event
                    const myEvents: api.MyEvents = await api.MyeventsService.getMyEvents();
                    const allmyEvents = (myEvents.futureEvents || []).concat((myEvents.previousEvents || []));
                    preRequiredList = checkPreRequiredEvents(newEvent, allmyEvents || []);
                    blockingList = checkBlockingEvents(newEvent, allmyEvents || []);
                    eventSignedUp = allmyEvents.find(x => x.eventId === props.eventid);
                    eventWaitingList = myEvents.waitingEvents?.find(x => x.eventId === props.eventid);
                    cannotcancel = (!!newEvent.eventInformation?.cancelDeadline && (new Date() > new Date(newEvent.eventInformation?.cancelDeadline))) || eventEnded;
                    const signedUpSessionIds = eventSignedUp?.sessions?.map(x => x.sessionId!) || [];

                    setSignedUpSessionIds(signedUpSessionIds);
                }

                if (newEvent) {
                    signupdeadlineoverdue = !!newEvent.eventInformation?.stopWebRegistrationDate && (new Date() > new Date(newEvent.eventInformation?.stopWebRegistrationDate));
                    signupWithoutLogin = Boolean(newEvent.eventInformation?.signupWithoutLogin) || userSignedIn;
                }

                setInfomessage(getInfoMessasge(!!eventSignedUp, cannotcancel, signupdeadlineoverdue, signupWithoutLogin, preRequiredList, blockingList, eventEnded, !!eventWaitingList, new Date(newEvent.eventInformation?.startWebRegistrationDate!), newEvent.eventInformation?.webRegistrationStoppedMessage))
            }
        } catch (error: any) {
            if (error.status === 401) {
                setErrormessage("Du skal være medlem for at kunne se dette arrangement");

            } else if (error.status === 404) {
                setErrormessage("Dette arrangement findes ikke");
            }
            else {
                setErrormessage(fetchErrorMsg);
            }
        };

    }, [props.eventid, props.memberid, settingdata.alreadyJoinedText, settingdata.alreadySignedupText, settingdata.blockingEventSignedUpText,
    settingdata.deregistrationDeadlineExceededText, settingdata.isOnWaitingListText, settingdata.loginNeededText, settingdata.prerequiredMissingText,
    ])

    const checkPreRequiredEvents = (joiningevent: api.Event, joinedevents: api.Event[]) => {
        if (!joiningevent.preRequiredEvents || joiningevent.preRequiredEvents.length === 0)
            return [];
        const previousEventIds = joinedevents.map(x => x.eventId) || [] as string[];
        return joiningevent.preRequiredEvents?.filter(x => !previousEventIds.includes(x.eventId)) || [];

    }
    const checkBlockingEvents = (joiningevent: api.Event, joinedevents: api.Event[]) => {
        if (!joiningevent.blockingEvents || joiningevent.blockingEvents.length === 0)
            return [];
        const previousEventIds = joinedevents.map(x => x.eventId) || [] as string[];
        return joiningevent.blockingEvents?.filter(x => previousEventIds.includes(x.eventId)) || [];

    }
    const setupImage = (imageid: string) => {
        getEventImageUrl(imageid).then((url) => { setEventImageUrl(url || defaultEventImage) });

    }
    const setup = useCallback((openSignupWhenReady?: boolean) => {
        setLoading(true);
        const call = async () => {
            await setupData();
        }
        call().finally(() => {
            setLoading(false)

            if (openSignupWhenReady) {
                setSigningUp(true)
            }
        });
    }, [setupData])
    useEffect(() => {
        const query = window.location.search;
        if (query) {
            const queryvalues = query.substring(1).split("=");
            const openSignupWhenReady = (queryvalues.length > 1 && queryvalues[0] === "registering" && queryvalues[1] === "true")
            setup(openSignupWhenReady);
            const url = new URL(window.location.href);
            url.searchParams.delete('registering');
            (window.history as any).replaceState(null, null, url);
        } else { setup() }
    }, [setup]);

    const onSignupClick = () => {
        if (isPublicView()) {
            const url = `/kurser-offentlig/tilmelding/${props.eventid}`;
            window.location.href = isUserLoggedIn(props.memberid) ? url : `/member/stepupauth?redirectUrl=${url}`;
        }
        else {

            setSigningUp(true)
        }
    }
    const onDeregistratonClick = () => {
        setCancelling(true)
    }

    const openSessionForm = (session: api.EventSession, cancel: boolean = false) => {
        setChosenSession(session)
        if (cancel) setSessionCancelling(true)
        else setSessionSigningUp(true);
    }

    const onSignupCancelClick = async () => {
        setSigningUp(false);
    }
    const handleClosed = (submitted: boolean) => {
        if (submitted) {
            setup();
        }
    }
    const handleDeregistrationClosed = (submitted: boolean) => {
        handleClosed(submitted);
        setCancelling(false);
    }
    const handleSessionSignUpClosed = (submitted: boolean) => {
        handleClosed(submitted);
        setSessionSigningUp(false);
    }
    

    const isPublicView = () => {
        return window.location.pathname.toLocaleLowerCase().indexOf("/kurser-offentlig") > -1;
    }

    const onBackTolistClicked = () => {
        window.location.href = isPublicView() ? "/kurser-offentlig" : "/kurser";
    }

    return (<>{signingUp ?
        <EventSignup
            infomessage={infomessage}
            event={currentEvent}
            settingdata={settingdata}
            memberid={props.memberid}
            isPublicSignup={false}
            closeForm={onSignupCancelClick}
            eventDetail={<EventDetailInfoWindow
                currentEvent={currentEvent}
                eventImageUrl={eventImageUrl}
                onClickBack={() => setSigningUp(false)} />
            }

        /> :
        cancelling ? <EventDeregistrationForm
            settingdata={settingdata}
            eventid={props.eventid}
            closeModal={(registered) => handleDeregistrationClosed(registered)}
        ></EventDeregistrationForm>
            :
            sessionSigningUp ? <SessionRegistration
                session={chosenSession}
                eventid={props.eventid}
                memberid={props.memberid}
                settingdata={settingdata}
                onCancelClicked={(registered) => handleSessionSignUpClosed(registered)}
            />
                : sessionCancelling ? <SessionDeregistration
                    session={chosenSession}
                    eventid={props.eventid}
                    memberid={props.memberid}
                    settingdata={settingdata}
                    closeModal={(registered) => handleSessionSignUpClosed(registered)}
                /> :

                    <Wrapper
                        fillWidth={true}
                        properties={{ ...settingdata, title: "" }}
                        backBarSettings={{ backLabel: "Tilbage til oversigten", onBackClick: onBackTolistClicked }}
                        loading={loading}
                        notification={errormessage ? { message: errormessage, type: NotificationBarType.Danger, onClick: () => setErrormessage("") } : undefined}
                        children={loading ? null : <>
                            <EventDetailMain
                                memberid={props.memberid}
                                
                                onEventCancelClick={onDeregistratonClick}
                                onSessionSignupClick={openSessionForm}
                                signedUpSessionIds={signedUpSessionIds}
                                eventEnded={hasEnded}
                                signedIn={signedIn}
                                currentEvent={currentEvent}
                                eventImageUrl={eventImageUrl}
                                errorMsg={{ title: "", description: errormessage }}
                                onSignupClick={onSignupClick}
                                infoMsg={infomessage}
                                settingdata={settingdata} />
                        </>}
                    />}
    </>
    )
}
export default EventDetails;