import style from './consent-form.module.scss'
import CustomCheckbox from '../CustomCheckbox/custom-checkbox'
import { ConsentListItem } from '../../../models/umbracoElement';
import { MemberConsent } from '../../../api';
import InputWrapper from '../InputWrapper/input-wrapper';
import YesNoInput from '../YesNoInput/yes-no-input';
interface ConsentFormProps {
    handleChange: (name: string, value: boolean | undefined) => void;
    consentlist?: ConsentListItem[];
    consentText?: string;
    value: boolean | MemberConsent | undefined;
}
function ConsentForm(props: ConsentFormProps) {

    const getValue = (value: boolean | MemberConsent | undefined, propsKey: string) => {
        if ((typeof (value) === "boolean")) {
            return value as boolean;
        }
        if (!propsKey) {
            return false;
        }
        return (value as MemberConsent)[propsKey as keyof MemberConsent];
    }

    return (
        <>
            <div className={style.stepTitle}>Samtykke</div>
            {props.consentText ? <div dangerouslySetInnerHTML={{ __html: props.consentText }} /> : <div></div>}
            {props.consentlist && props.consentlist.map((x, i) =>
                <div key={"consentbox-" + i} className={style.consentboxcontainer}>

                    {x.isOptionalConsent ? <div className={style.optionalconsent}>
                        <div dangerouslySetInnerHTML={{ __html: x.consentItemText! }} />
                        <InputWrapper
                            component={YesNoInput}
                            name={x.consentType!}
                            shouldFocus={i === 0 ? true : false}
                            required={x.isRequired}
                            setInput={(input?: boolean) => props.handleChange(x.consentType, input)} />

                    </div> :
                        <InputWrapper
                            component={CustomCheckbox}
                            name={x.consentType!}
                            setFocus={i === 0 ? true : false}
                            deactivateClickLabel={true}
                            required={x.isRequired}
                            htmlLabel={x.consentItemText}
                            checked={getValue(props.value, x.consentType)}
                            setInput={(input?: boolean) => props.handleChange(x.consentType, input)} />}
                </div>
            )}
        </>
    )
}

export default ConsentForm;