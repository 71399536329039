import CheckmarkCircle from "../components/shared/icons/CheckmarkCircle/checkmark-circle";
import Watch from "../components/shared/icons/Watch/Watch";

export enum EventStatus {
    DEFAULT = "",
    AWAITING = "Din ansøgning er modtaget, du får svar efter fristen.",
    SIGNEDUP = "Du er tilmeldt kurset",
    ENDED = "Er afholdt",
    SIGNUPENDED = "Tilmeldingsfristen er overskredet"
}

export const getStatusElement = (style: any, signedUp?: boolean | null, onWaitlist?: boolean | null, endDate?: string | null, signupEndDate?: string | null) => {
    let status = EventStatus.DEFAULT;
    if (endDate && new Date(endDate) <= new Date())
        status = EventStatus.ENDED;
    else if (signedUp)
        status = EventStatus.SIGNEDUP;
    else if (onWaitlist)
        status = EventStatus.AWAITING
    else if (signupEndDate && new Date(signupEndDate) <= new Date())
        status = EventStatus.SIGNUPENDED;

    switch (status) {
        case EventStatus.DEFAULT:
            return null;
        case EventStatus.AWAITING:
            return <div className={style.awaiting}>
                <Watch />{EventStatus.AWAITING}
            </div>;
        case EventStatus.SIGNEDUP:
            return <div className={style.signedup}>
                <CheckmarkCircle />{EventStatus.SIGNEDUP}
            </div>;

        case EventStatus.ENDED:
            return <div className={style.ended}>
                {EventStatus.ENDED}
            </div>;

        case EventStatus.SIGNUPENDED:
            return <div className={style.ended}>
                {EventStatus.SIGNUPENDED}
            </div>;
        default:
            return null;
    }
}
