/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventTargetCategory } from '../models/EventTargetCategory';
import { request as __request } from '../core/request';

export class TargetcategoriesService {

    /**
     * Description: Get a list of eventtarget categories.
 * Operation: GetEventTargetCategories.
 * Area: Targetcategories.
     * @returns EventTargetCategory Success
     * @throws ApiError
     */
    public static async getEventTargetCategories(): Promise<Array<EventTargetCategory>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/targetcategories`,
        });
        return result.body;
    }

}