import { IconProps } from "../../../../models/iconProps";



function ArrowBack(props:IconProps) {
    return (
        <svg className={props.className} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.80274 0.677523L1.0001 7.12485L6.80273 13.5723" stroke="var(--darkturquoise)" strokeWidth="2" strokeLinejoin="round"/>
        <path d="M1 7.1249L13.25 7.1249" stroke="var(--darkturquoise)" strokeWidth="2"/>
        </svg>   
    )
  }
  
  export default ArrowBack;