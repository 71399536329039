import React from 'react';
import * as api from '../../api';
import { isUserLoggedIn } from '../../common/isUserLoggedIn';
import { fetchErrorMsg } from '../../constants/common-constants';
import { defaultEventSignUpModel } from '../../models/eventSignupModel';
import { EventRegistrationButtonElement, EventSignupElement } from '../../models/umbracoElement';
import EventDetailInfoWindow from '../EventDetails/EventDetailInfoWindow/event-detail-info-window';
import EventSignup from '../EventSignup/event-signup';
import Loader from '../shared/Loader/loader';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import Wrapper from '../shared/Wrapper/wrapper';

export type EventRegistrationButtonProps = {
    data: any;
    eventid: string;
    memberid: string;
    cpr: string;
    firstname: string;
    lastname: string;
}

function EventRegistrationButton(props: EventRegistrationButtonProps) {
    

    const [currentEvent, setCurrentEvent] = React.useState<api.Event>({} as api.Event);
    const [loading, setLoading] = React.useState(false)
    const [infomessage, setInfomessage] = React.useState("");
    const [eventImageUrl, setEventImageUrl] = React.useState<string | undefined>();
    const [errormessage, setErrormessage] = React.useState("");
    const [settingdata] = React.useState<EventSignupElement>(props.data ? JSON.parse(props.data) : {});
    const [eventSignUpModel, SetEventSignUpModel] = React.useState<api.EventSignup>({ ...defaultEventSignUpModel, contact: { ...defaultEventSignUpModel.contact, firstname: props.firstname, lastname: props.lastname } });

    const setupData = React.useCallback(async () => {
        try {

            if (props.eventid) {
                const newEvent: api.Event = await api.EventsService.getEventById(props.eventid);

                if (newEvent.signedUp || newEvent.onWaitList) {
                    redirectToDetails(newEvent.eventId!);
                    return false;
                }

                setCurrentEvent(newEvent)


                if (newEvent.eventInformation?.imageUrl) {
                    setEventImageUrl(newEvent.eventInformation?.imageUrl);
                }

                setInfomessage("");                
            }
        } catch (error: any) {
            if (error.status === 401) {
                setErrormessage("Du skal være medlem for at kunne se dette arrangement");

            } else if (error.status === 404) {
                setErrormessage("Dette arrangement findes ikke");
            }
            else {
                setErrormessage(fetchErrorMsg);
            }
        };
        return true;
    }, [props.eventid, props.memberid])

    React.useEffect(() => {
        let spinner = true;
        setLoading(spinner);
        const call = async () => {
            spinner = !(await setupData());
        }
        call().finally(() => {
            setLoading(spinner)
        });
    }, [setupData])

    const redirectToDetails = (id: string) => {
        window.location.href = detailPageUrl(id);
    }

    const detailPageUrl = (id: string) => {
        return `/kurser-offentlig/detaljer/${id}`;
    }


    const onSignupCancelClick = async () => {
        if (isUserLoggedIn(props.memberid)) {
            redirectToDetails(currentEvent.eventId!);
        }
        else {
            window.location.href = `/Member/ReChallengeAuth?redirectUrl=${detailPageUrl(currentEvent.eventId!)}`;
        }
    }

    return (
        <>
            {loading ? <Loader /> :
                <>
                    <EventSignup
                        infomessage={infomessage}
                        event={currentEvent}
                        eventSignUpModel={eventSignUpModel}
                        settingdata={settingdata}
                        memberid={props.memberid}
                        isPublicSignup={true}
                        closeForm={onSignupCancelClick}
                        eventDetail={<EventDetailInfoWindow
                            currentEvent={currentEvent}
                            eventImageUrl={eventImageUrl}
                            onClickBack={() => onSignupCancelClick()} />
                        }
                    />
                </>}
        </>
    )
}

export default EventRegistrationButton;